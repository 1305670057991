/**@jsxImportSource @emotion/react */
import React from 'react';
import { useStyles } from './style';

export const English = () => {
    const styles = useStyles();

    return (
        <div style={styles.root}>
            <h3 css={styles.titel}>ORANJEGROEP Privacy policy statement</h3>
            <p style={styles.bottomSpaceParagraph}>
                ORANJEGROEP takes your privacy very seriously and will process and use information
                about you (the data subject) in a secure manner. In this Privacy Statement we
                explain to you which data we process and for what purpose we do so. In addition, you
                can read in this Privacy Statement about your rights regarding our processing of
                your personal data. We recommend that you read this Privacy Statement carefully.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                When you provide information to us, we will handle it with care. This privacy
                statement describes (together with the documents referred to) which personal data we
                collect from you and how we process it. We recommend that you read this Privacy
                Statement carefully.
            </p>
            <p style={styles.subTitel}>Who are we?</p>
            <p style={styles.bottomSpaceParagraph}>
                Oranjegroep is a temporary employment agency in construction, technology and
                industry. Naturally, a temporary employment agency often comes into contact with
                personal data of both job seekers and employees (in whatever form) as well as that
                of its suppliers and clients.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                This privacy statement applies to all personal data that Oranjegroep processes of
                everyone who has ever had contact with Oranjegroep, such as our visitors, customers
                and business contacts.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Oranjegroep has both domestic and foreign operating companies that provide services
                related to labor and job placement. We describe our measures for protecting all this
                personal data in this privacy statement.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Our parent company is called Oranjegroep Holding BV, which has its head office at
                Van Vollenhovenstraat 10 (3016 BH) in Rotterdam.
            </p>
            <p style={styles.subTitel}>What are personal data?</p>
            <p style={styles.bottomSpaceParagraph}>
                Personal data is all data that can be traced back to you as an individual. Just
                think of your name, telephone number, address or email address. But data such as
                your IP address are also personal data.
            </p>
            <p style={styles.subTitel}>What do we use this personal data for?</p>
            <p style={styles.bottomSpaceParagraph}>
                Your personal data is processed in accordance with laws and regulations and for as
                long as necessary for the purposes for which this data was collected.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ providing access to the website, portals and systems of Oranjegroep;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ using the functionalities on our website, portals and systems;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ entering into and executing agreements via our website, portals and systems;
            </p>
            <p style={styles.firstTabParagraph}>⦿ contacting you if requested;</p>
            <p style={styles.firstTabParagraph}>⦿ gaining insight into the use of our website;</p>
            <p style={styles.firstTabParagraph}>
                ⦿ improving and evaluating our website and services;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ mapping our target group and associated interests, including to make the internet
                offering more interesting for you by showing advertising that matches your interests
                via both our own websites and third-party websites;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ sending (one of) our general and/or personalized newsletters if you have
                registered for this;
            </p>
            <p style={styles.firstTabParagraph}>⦿ sending vacancy alerts;</p>
            <p style={styles.firstTabParagraph}>⦿ registration for events;</p>
            <p style={styles.firstTabParagraph}>⦿ job placement;</p>
            <p style={styles.firstTabParagraph}>
                ⦿ applications for permanent/indirect employees (at your own company without job
                placement);
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ (potential) business relationship (client or supplier);
            </p>
            <p style={styles.lastFirstTabParagraph}>
                ⦿ processing applications. A separate privacy statement applies for submitting
                applications, which can be found with the registration/application form.
            </p>
            <p style={styles.subTitel}>Which personal data do we process?</p>
            <p style={styles.bottomSpaceParagraph}>
                Registration process via registration form ( hard copy ), website (future) or
                registration in broadcasting software.
            </p>
            <p style={styles.firstTabParagraph}>⦿ Job seekers</p>
            <p style={styles.secondTabParagraph}>
                ‣ Name and address details (name, address, place of residence)
            </p>
            <p style={styles.secondTabParagraph}>
                ‣ E-mail address, telephone number and any other contact details
            </p>
            <p style={styles.secondTabParagraph}>‣ Nationality, gender</p>
            <p style={styles.secondTabParagraph}>‣ Date of birth place of birth</p>
            <p style={styles.secondTabParagraph}>
                ‣ Curriculum vitae (CV), work experience, education and training details
            </p>
            <p style={styles.secondTabParagraph}>‣ IP address</p>
            <p style={styles.secondTabParagraph}>‣ Cookie IDs</p>
            <p style={styles.lastSecondTabParagraph}>
                ‣ Any personal data that you enter as the content of a message, for example via the
                chat function, the contact form, social media, competition registration forms or
                feedback tools
            </p>
            <p style={styles.firstTabParagraph}>⦿ Clients</p>
            <p style={styles.secondTabParagraph}>
                ‣ Name and address details (name, address, place of residence) of the company
            </p>
            <p style={styles.secondTabParagraph}>
                ‣ Name and address details (name, address, place of residence) project locations
            </p>
            <p style={styles.secondTabParagraph}>
                ‣ E-mail address, telephone number and any other contact details
            </p>
            <p style={styles.secondTabParagraph}>‣ Clientnumber</p>
            <p style={styles.secondTabParagraph}>‣ Financial data such as bank account number</p>
            <p style={styles.lastSecondTabParagraph}>
                ‣ Other information required for the execution of our collaboration, assignment
                and/or work.
            </p>
            <p style={styles.firstTabParagraph}>⦿ Suppliers</p>
            <p style={styles.secondTabParagraph}>
                ‣ Name and address details (name, address, place of residence)
            </p>
            <p style={styles.secondTabParagraph}>
                ‣ E-mail address, telephone number and any other contact details
            </p>
            <p style={styles.secondTabParagraph}>‣ Supplier number</p>
            <p style={styles.lastSecondTabParagraph}>
                ‣ Other information required for the performance of your employment, assignment
                and/or work.
            </p>
            <p style={styles.subTitel}>How is the personal data collected?</p>
            <p style={styles.bottomSpaceParagraph}>
                The personal data of visitors is obtained in the following ways:
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ Through the use of the website and the cookies placed on it;
            </p>
            <p style={styles.lastFirstTabParagraph}>
                ⦿ By providing personal data by the user himself.
            </p>
            <p style={styles.subTitel}>On what legal grounds do we process your personal data?</p>
            <p style={styles.bottomSpaceParagraph}>
                We process personal data on the basis of the following legal grounds:
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ the processing is necessary for the execution of an agreement (including
                employment contract, assignment agreement, internship agreement) that is being
                entered into or has been entered into and to which you are a party;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ the data processing is necessary for us to comply with a legal obligation, for
                example, checking your ID when entering into an employment contract;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ the data processing is necessary in connection with a legitimate (business)
                interest that we have, namely:
            </p>
            <p style={styles.secondTabParagraph}>
                ‣ legitimate business processes and activities, including generating management
                information and reports, conducting market analyses, data analyses, maintaining
                statistics, (internal and external) controls, audits and audits, business
                transactions (partnerships, purchase/sale) and promoting and maintaining of company
                safety;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ compliance with or investigation into (suspected) violations of agreed agreements,
                internal policies/instructions and/or laws and regulations;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ to inform you about matters that are relevant to the performance of your work,
                about our organization, activities and/or professional information (for example via
                e-mail, newsletters, company magazines and professional literature).
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ the personal data are processed with your consent or your explicit permission, for
                example permission to process personal data in the context of an application
                process. You can withdraw given permission at any time, which means that from that
                moment on we will no longer process the personal data in question for the purpose
                for which permission was requested.
            </p>
            <p style={styles.lastFirstTabParagraph}>
                ⦿ the data processing is necessary for the implementation of obligations that we
                have as an employer in the field of labor law and social security law in the event
                of absenteeism and reintegration
            </p>
            <p style={styles.subTitel}>With whom is your personal data shared?</p>
            <p style={styles.bottomSpaceParagraph}>
                Oranjegroep never sells your data to third parties. We do use the services of other
                parties that process data on our behalf. We conclude processing agreements with our
                processors (partners/suppliers) so that they also handle your personal data with
                care. After all, we remain responsible for protecting your data. We may provide your
                data to the following companies that process this data on our behalf:
            </p>
            <p style={styles.firstTabParagraph}>⦿ External IT service providers</p>
            <p style={styles.lastFirstTabParagraph}>⦿ The parties that supply cookies</p>
            <p style={styles.bottomSpaceParagraph}>
                We do not store your data for longer than is strictly necessary for the purpose for
                which the data was obtained.
            </p>
            <p style={styles.subTitel}>How is your data secured?</p>
            <p style={styles.bottomSpaceParagraph}>
                We take the protection of the personal data of visitors to our website seriously and
                therefore take appropriate organizational and technical measures to prevent misuse,
                loss, unauthorized access, unwanted disclosure, unauthorized modification or
                otherwise unlawful processing.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Our employees who have access to your personal data are bound by a confidentiality
                clause and only have access to this personal data if necessary for the performance
                of their duties and/or supporting the website, portals and systems of Oranjegroep.
            </p>
            <p style={styles.subTitel}>What are your rights?</p>
            <p style={styles.bottomSpaceParagraph}>
                Under the General Data Protection Regulation (GDPR), you have the following rights:
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Right to information</strong> <br /> You have the right to an
                understandable and transparent explanation of how we handle your personal data and
                what rights you can exercise in that regard. In this Privacy Statement we have
                therefore explained in detail what data we collect from you and how we handle your
                data.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Right of access</strong> <br /> You have the right to have your personal
                data corrected if it is incorrect or outdated and/or to have it supplemented if it
                is incomplete.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Right to correction</strong> <br /> You have the right to have your
                personal data corrected if it is incorrect or outdated and/or to have it
                supplemented if it is incomplete.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Right to object</strong> <br /> You have the right to object to the
                processing of your data if you do not agree with the way we process your personal
                data.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Right to data portability</strong> <br /> You have the right to receive
                data that you have provided to us in the context of the agreement(s) you have
                concluded with us in a machine-readable format, so that you can store this data in a
                database of yours or from another party.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Right to restriction</strong> <br /> You have the right to request
                restriction of the processing of your data. This means that we may keep your data
                but not use it. This right arises in a number of cases. If you believe that this is
                the case, you can contact us.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Right to be forgotten/request to delete account</strong> <br /> You have
                the right to request us to delete all data we have about you.
            </p>
            <p style={styles.lastFirstTabParagraph}>
                ⦿ <strong>Right to file a complaint</strong> <br /> You have the right to file a
                complaint about the way we handle your data.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Depending on the extent to which the processing of your personal data is based on
                your consent, you also have the right to withdraw your consent at any time. However,
                withdrawing your consent will not affect the lawfulness of any processing based on
                your previous consent before we have received such withdrawal.
            </p>
            <p style={styles.subTitel}>What are cookies?</p>
            <p style={styles.bottomSpaceParagraph}>
                Cookies are small pieces of (text) information that are sent to your browser when
                you visit the website and are then stored on the hard drive or in the memory of your
                computer, tablet, mobile phone or other device (hereinafter: “Device”) . The cookies
                placed via the website cannot damage your Device or the files stored on it. When we
                talk about 'cookies', we not only mean these small pieces of (text) information, but
                also similar techniques that collect information, such as device fingerprinting.
            </p>
            <p style={styles.subTitel}>Questions, comments or complaints?</p>
            <p style={styles.bottomSpaceParagraph}>
                If you have any questions about this Privacy Statement and the way in which we
                process your data, you can contact us via the details at the bottom of the document.
                If you have a complaint about the way we process your data, you can also contact us
                using the details at the bottom of the document. You can also always contact the
                competent national supervisory authority in the field of privacy protection. In the
                Netherlands this is the Dutch Data Protection Authority .
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Do you have a question about the security of your personal data or would you like to
                make a request? Please contact us by email: plas@oranjegroep.nl or by telephone
                number 010-4123399.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                If you have any complaints, you can directly contact our Data Protection Officer,
                also known as the Data Privacy Officer (DPO).
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Name: Corniels van der Plas <br /> Email address: plas@oranjegroep.nl <br /> <br />
                ORANJEGROEP <br />
                Attn: Data Protection Officer <br /> Van Vollenhovenstraat 10 <br /> 3016 BH
                Rotterdam
            </p>
            <p style={styles.subTitel}>Data security</p>
            <p style={styles.bottomSpaceParagraph}>
                Although we will do our utmost to protect your personal information, you should be
                aware that the transmission of information over the internet is never completely
                secure and we cannot guarantee the security of your personal information sent to the
                website, portals or systems of Oranjegroep or a third party; for this reason, any
                transfer is at your own risk. We will apply strict operational procedures and
                adequate technical and business organizational security measures to prevent
                unauthorized access, modification, deletion or transfer of personal data.
            </p>
            <p style={styles.subTitel}>More information</p>
            <p style={styles.bottomSpaceParagraph}>
                We may adjust this privacy policy statement. We therefore advise you to regularly
                read the Privacy Statement for any changes. The most current privacy statement can
                always be consulted via our websites.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                <i>
                    This privacy policy and cookie statement was last modified on February 5, 2024.
                </i>
            </p>
        </div>
    );
};
