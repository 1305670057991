/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';

interface IProps {
    titleEffect?: boolean;
}

export const Dutch = ({ titleEffect }: IProps) => {
    const styles = useStyles(titleEffect);

    return (
        <div style={styles.root}>
            <h3 css={styles.titel}>Algemene gebruiksvoorwaarden ORANJELINK portal</h3>
            <p style={styles.bottomSpaceParagraph}>
                De ORANJELINK Portal (hierna: Portal) is een dienst die verleend wordt door
                Oranjegroep Holding B.V. ten behoeve van Oranjegroep. Met betrekking tot het gebruik
                van de Portal gelden deze specifieke Algemene Voorwaarden ORANJELINK Portal (hierna:
                Algemene Voorwaarden) in het kader van de zogenaamde Ambassadeursregeling (hierna:
                Regeling). De Algemene Voorwaarden worden aangevuld voor zover met Gebruiker van de
                Portal een addendum op de arbeidsrechtelijke overeenkomst of overeenkomst van
                opdracht (hierna: Addendum) is overeengekomen. Bij tegenstrijdigheden tussen de
                Algemene Voorwaarden en het Addendum prevaleert het Addendum.
            </p>
            <div style={styles.mainSubTitle}>
                <p style={styles.mainSubTitelFirstParagraph}>A.</p>
                <p style={styles.mainSubTitelSecondParagraph}>Akkoord</p>
            </div>
            <p style={styles.bottomSpaceParagraph}>
                Lees deze Algemene Voorwaarden zorgvuldig door. Door registratie in de Portal
                verklaart u gehouden te zijn aan de Algemene Voorwaarden zoals van tijd tot tijd
                gewijzigd en kwalificeert u als Gebruiker in de zin van deze voorwaarden.
            </p>
            <div style={styles.mainSubTitle}>
                <p style={styles.mainSubTitelFirstParagraph}>B.</p>
                <p style={styles.mainSubTitelSecondParagraph}>Algemene gebruikersrechten</p>
            </div>
            <p style={styles.bottomSpaceParagraph}>
                Oranjegroep heeft de Portal ontwikkeld met het oogmerk om Gebruiker bepaalde
                rapportage- en gerelateerde informatiediensten (hierna afzonderlijk te noemen een
                'Dienst' en gezamenlijk de 'Diensten') te bieden. De inhoud van de Portal kan
                veranderen. Oranjegroep wijst u erop dat bepaalde Diensten in bepaalde
                rechtsgebieden niet beschikbaar kunnen zijn. Oranjegroep kan naar eigen goeddunken
                de Gebruiker toegang verlenen tot alle of bepaalde specifieke Diensten. Gebruiker
                gaat ermee akkoord deze Diensten uitsluitend te gebruiken voor diens (interne)
                zakelijke doeleinden en wel met inachtneming van de voorwaarden.
            </p>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>1.</p>
                <p style={styles.subTitelSecondParagraph}>Definities</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep: Oranjegroep Holding B.V. en/of de aan haar gelieerde
                            partijen. Onder gelieerde partij wordt betrekking tot enige
                            rechtspersoon verstaan iedere entiteit die direct of indirect onder
                            zeggenschap staat van die rechtspersoon, iedere entiteit die direct of
                            indirect zeggenschap heeft over de rechtspersoon dan wel iedere entiteit
                            die direct of indirect onder gemeenschappelijke zeggenschap staat met de
                            rechtspersoon. Daarbij wordt onder zeggenschap over een entiteit of
                            rechtspersoon verstaan het eigendom van een meerderheid van de stemmen
                            voor de entiteit of rechtspersoon.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker: elk natuurlijke persoon of rechtspersoon die via de Portal in
                            contact staat of wenst te staan met Oranjegroep. Waar in deze
                            voorwaarden het begrip Gebruiker wordt gehanteerd, wordt indien en voor
                            zover van toepassing, Gebruiker en/of Eindgebruiker bedoeld.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Wettelijke vertegenwoordiger: statutair bestuurder(s) van een
                            onderneming. Hieronder wordt niet verstaan gemachtigden/geautoriseerde
                            vertegenwoordigers.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            Diensten: alle formulieren en downloads en andere gegevens die via de
                            Portal ter beschikking worden gesteld. Waar in deze voorwaarden het
                            begrip Dienst wordt gehanteerd, wordt product en/of dienst bedoeld.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            Account: toegang tot diverse beveiligde diensten; een Gebruiker krijgt
                            toegang door de invoer van een toegangscode en bijbehorend wachtwoord.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            Portal: Beveiligde online omgeving waarin Gebruikers en Eindgebruikers
                            gegevens en documenten met Oranjegroep kunnen delen.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            Eindgebruiker: een Gebruiker die kwalificeert als Wettelijke
                            Vertegenwoordiger en een natuurlijke persoon toewijst om een Account aan
                            te maken in de Portal.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.8</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruikersreferentie: personen aan wie Oranjegroep een heeft Account
                            verstrekt, die op beveiligde wijze toegang verschaft tot de site.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>2.</p>
                <p style={styles.subTitelSecondParagraph}>Toegang tot de Diensten</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep kan naar eigen goeddunken van tijd tot tijd besluiten om
                            bepaalde Diensten in te trekken om de functionaliteit van de Diensten te
                            wijzigen of de toegang van de Gebruiker tot de Diensten uit te breiden
                            dan wel te beperken.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Tevens kan Oranjegroep de Gebruiker nieuwe Diensten aanbieden dan wel
                            bestaande Diensten uitbreiden naarmate deze op de Portal beschikbaar
                            komen. Oranjegroep is niet gehouden Gebruiker te informeren over iedere
                            (wezenlijke) verandering in de aangeboden Diensten dan wel enige
                            intrekking of uitbreiding van de Diensten via de Portal.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker is niet gerechtigd om door Oranjegroep ingetrokken specifieke
                            Diensten te eisen.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            De Portal geeft de Gebruiker de mogelijkheid om gegevens en documenten
                            elektronisch en op een beveiligde wijze bij Oranjegroep in te dienen.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruikers loggen in met hun account voor de Portal. De Portal is
                            uitsluitend toegankelijk voor personen aan wie Oranjegroep een
                            Gebruikersreferentie heeft verstrekt, die op beveiligde wijze toegang
                            verschaft tot de site. Leden 6 tot en met 9 zijn alleen van toepassing
                            bij gebruik door derden.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker informeert Oranjegroep over de personen (binnen de organisatie
                            van Gebruiker) waaraan toegang mag worden verleend tot de Portal
                            (hierna: Eindgebruiker).
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            Een Gebruiker die ook wettelijk vertegenwoordiger is, is bevoegd één of
                            meerdere Eindgebruikers aan te wijzen voor het gebruik van de Portal. De
                            bestuurder van een onderneming staat ervoor in dat Eindgebruikers zich
                            houden aan deze voorwaarden en is aansprakelijk voor de consequenties
                            van niet-naleving daarvan. De bestuurder van een onderneming is
                            verplicht om Eindgebruiker te verwijderen wanneer een Eindgebruiker niet
                            langer bevoegd is om namens de onderneming op te treden.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.8</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker verklaart en garandeert hierbij, dat: <br /> (i) de
                            Eindgebruiker(s) bevoegd is/zijn zich toegang te verschaffen tot en
                            gebruik te maken van de Portal en de Diensten namens de Gebruiker, dat{' '}
                            <br /> (ii) de Eindgebruiker(s) beschikt/beschikken over ervaring en
                            kennis met betrekking tot de zaken waarvoor zij de Portal en de Diensten
                            zullen gebruiken en dat <br /> (iii) Gebruiker verantwoordelijk is voor
                            de handelingen en verzuimen van iedere Eindgebruiker.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.9</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker onderschrijft, dat een ieder die zich toegang verschaft tot de
                            Portal met behulp van een toewijzing die door Oranjegroep is verstrekt
                            aan een aan de Gebruiker verbonden Eindgebruiker moet worden beschouwd
                            als een Eindgebruiker die optreedt namens de Gebruiker. De Gebruiker en
                            alle Eindgebruikers dienen de Gebruikersreferenties van de
                            Eindgebruikers strikt vertrouwelijk te behandelen en alles te doen wat
                            redelijkerwijs in hun vermogen ligt om die vertrouwelijkheid te
                            waarborgen.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>3.</p>
                <p style={styles.subTitelSecondParagraph}>
                    Gebruik van de accounts, toegangscodes en wachtwoorden
                </p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker erkent en onderschrijft, dat elke aanvraag voor het
                            verschaffen van toegang tot de Portal aan een Gebruiker of Eindgebruiker
                            door Oranjegroep naar eigen goeddunken mag worden goedgekeurd of
                            afgewezen en dat er geen reden vereist is voor een dergelijke afwijzing
                            of goedkeuring. Oranjegroep is te allen tijde gerechtigd een account te
                            wijzigen, te blokkeren, of te verwijderen. Oranjegroep is niet gehouden
                            Gebruiker of Eindgebruiker hierover te informeren.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker is geheel verantwoordelijk voor het gebruik en het beheer van
                            haar account(s), toegangscode(s) en wachtwoord(en) door of namens hem.
                            Gebruiker zal het (de) wachtwoord(en) volstrekt geheimhouden en erop
                            toezien dat deze niet door onbevoegde derden of voor andere doeleinden
                            worden gebruikt. Ook zal Gebruiker zorgvuldig zijn in het gebruik van de
                            toegangscode(s).
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker zal (i) steeds alle redelijke instructies van Oranjegroep
                            opvolgen; (ii) de normale werking of integriteit van de Portal niet
                            verstoren; (iii) geen wijzigingen aanbrengen in de informatie die het
                            portaal bevat, noch de toegang tot het portaal beperken of op een andere
                            manier beïnvloeden; (iv) overlast voor andere gebruikers van het portaal
                            voorkomen; en (v) ervoor zorgen dat informatie die hij via het portaal
                            communiceert geen rechten van derden schendt of virussen of wormen
                            bevat.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep behoudt zich het recht voor om iedere actie in de Portal,
                            zoals inloggen, lezen, downloaden, uploaden en het verzenden van
                            elektronische formulieren, te traceren naar het account waarmee
                            Gebruiker inlogt.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            Indien Gebruiker geen toegang meer heeft tot de Portal, zal Oranjegroep
                            doen wat in haar vermogen ligt om deze toegang binnen een redelijke
                            termijn te herstellen.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            Zodra Gebruiker weet of vermoedt dat een toegangscode of wachtwoord bij
                            iemand anders die daartoe niet bevoegd is bekend is of kan zijn, meldt
                            hij dit direct aan Oranjegroep.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep behoudt zich het recht voor om de Gebruikersreferenties in
                            te trekken van een Eindgebruiker, de toegang van een Eindgebruiker te
                            beëindigen en alle verdere maatregelen te nemen, die Oranjegroep zelf
                            geschikt acht na oneigenlijk gebruik dan wel om enige andere reden.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.8</td>
                        <td style={styles.textTableRowSecondCell}>
                            Een verzoek tot wijziging van de rechten en bevoegdheden van
                            Eindgebruikers dan wel het blokkeren of deblokkeren van een
                            Eindgebruiker moet door Gebruiker worden ingediend via het in de Portal
                            aanwezige digitale meldformulier.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.9</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep aanvaardt geen enkele aansprakelijkheid voor schade die
                            wordt geleden door Gebruiker als gevolg van of in verband met verlies,
                            diefstal of misbruik van een Gebruikersreferentie dan wel het onvermogen
                            voor een Gebruiker om zich toegang te verschaffen tot de Portal vanwege
                            verlies, diefstal of misbruik van een Gebruikersreferentie dan wel het
                            blokkeren of deblokkeren van een Gebruikersreferentie.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>4.</p>
                <p style={styles.subTitelSecondParagraph}>Gegevensuitwisseling</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker verklaart Oranjegroep onverwijld op de hoogte te zullen
                            brengen van alle wijzigingen in de gegevens van Gebruiker en/of
                            Eindgebruikers (met inbegrip van woon- en/of bedrijfsadres, persoonlijke
                            gegevens, financiële gegevens, de bevoegdheid en status met betrekking
                            tot Eindgebruikers) door middel van het digitale meldformulier dan wel
                            verzending van een e-mail naar info@oranjegroep.nl.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker verklaart, dat de informatie die hij bij registratie, of op
                            enig moment daarna, indient waarheidsgetrouw, nauwkeurig en volledig is.
                            Oranjegroep mag ervan uitgaan, dat dergelijke informatie
                            waarheidsgetrouw, nauwkeurig en volledig is en hoeft dergelijke
                            informatie niet te controleren tenzij er, op het moment waarop
                            Oranjegroep de informatie bekijkt, sprake is van gegronde twijfel ten
                            aanzien van waarheidsgetrouwheid, nauwkeurigheid of volledigheid van de
                            ingediende informatie.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep zorgt er naar beste vermogen voor dat de
                            gegevensuitwisseling via de Portal op een voldoende betrouwbare manier
                            plaatsvindt, gelet op de aard en de inhoud van de gegevensuitwisseling
                            en het doel daarvan.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            De door Gebruiker verstrekte gegevens worden door Oranjegroep gebruikt
                            voor de uitvoering van de overeenkomst met Gebruiker (de onderliggende
                            en toekomstige arbeidsrechtelijke overeenkomst(en) en/of overeenkomst
                            van opdracht(en)).
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker blijft te allen tijde zelfstandig verantwoordelijk voor de
                            naleving van de op haar rustende (wettelijke) verplichtingen.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>5.</p>
                <p style={styles.subTitelSecondParagraph}>Technische voorzieningen</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>5.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker zorgt zelf voor eigen rekening en risico voor de noodzakelijke
                            technische voorzieningen, zoals hard- en software, die nodig zijn om
                            gebruik te kunnen maken van de Portal. De door Gebruiker ingezette hard-
                            en software is volgens gangbare inzichten beveiligd tegen ongeoorloofd
                            gebruik. Hard- en software mogen evenmin schadelijke software bevatten.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>5.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            De Gebruiker is verantwoordelijk en dient geheel voor eigen rekening
                            zorg te dragen voor alle software, telefoon- en internetverbindingen en
                            andere apparatuur die noodzakelijk is/zijn voor de Gebruiker zijn
                            toegang tot en het gebruik van de Portal en de Diensten. Gebruiker is
                            tevens verantwoordelijk voor alle daartoe vereiste relevante licenties
                            en/of toestemmingen.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>6.</p>
                <p style={styles.subTitelSecondParagraph}>Intellectuele eigendomsrechten</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep Holding B.V., alle rechten voorbehouden.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Alle aanspraken, eigendomsrechten en intellectuele eigendomsrechten
                            (inclusief maar niet beperkt tot het auteursrecht, octrooirecht,
                            databankenrecht en merkenrecht) met betrekking tot de Portal, alsmede
                            alle informatie die wordt overgedragen naar of via de Portal enig
                            daaraan gelinkt domein, software en de inhoud die daarop door of namens
                            Oranjegroep is geplaatst berusten bij en blijven in handen van
                            Oranjegroep of haar licentiegevers.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker mag de Portal en enig daaraan gelinkt domein, software en de
                            inhoud die daarop door of namens Oranjegroep is geplaatst uitsluitend,
                            op niet-exclusieve basis, gebruiken voor de doeleinden die in deze
                            voorwaarden zijn vermeld.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            Alle intellectuele eigendomsrechten (inclusief maar niet beperkt tot
                            auteursrecht, octrooien, databanken en merkenrechten) met betrekking tot
                            enige informatie uitgewisseld door of namens Gebruiker berusten bij
                            Gebruiker. Oranjegroep gebruikt deze informatie uitsluitend voor de
                            doeleinden die in deze voorwaarden zijn vermeld.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            Het is Gebruiker niet toegestaan om enig onderzoek dat via de Diensten
                            aan Gebruiker wordt verstrekt over te dragen aan derden, te kopiëren of
                            aan te passen dan wel dit op enige wijze commercieel te exploiteren.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            Het is Gebruiker niet toegestaan de volgende zaken te verveelvoudigen,
                            te verspreiden, door te zenden, openbaar te maken dan wel op enig andere
                            wijze over te dragen, commercieel te exploiteren dan wel in een ander
                            product te gebruiken: gegevens, informatie, knowhow, ideeën,
                            mededelingen of inhoud ontvangen of geraadpleegd via dan wel in verband
                            met de Portal of Diensten.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            Het bovenstaande leidt niet tot overdracht van intellectuele
                            eigendomsrechten.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>7.</p>
                <p style={styles.subTitelSecondParagraph}>Kosten</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>7.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Aan het gebruik van de Portal en de Diensten zijn vooralsnog geen kosten
                            verbonden. Oranjegroep kan de Algemene Voorwaarden te allen tijde per
                            direct en zonder voorafgaande kennisgeving wijzigen.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>7.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker verklaart zich akkoord met het in rekening brengen van de
                            kosten van een dienst, zodra de gebruiker deze aanvraagt.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>8.</p>
                <p style={styles.subTitelSecondParagraph}>
                    Beschikbaarheid, onderhoud en beheer van het portaal
                </p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>8.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep doet redelijkerwijs al het mogelijke om het portaal
                            voortdurend ter beschikking te stellen en te houden. Oranjegroep
                            garandeert niet dat de Portal, de inhoud en gerelateerde diensten
                            ononderbroken en volledig beschikbaar zijn.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>8.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep kan de Portal zonder aankondiging tijdelijk geheel of
                            gedeeltelijk buiten gebruik (laten) stellen wanneer zij dat nodig vindt,
                            bijvoorbeeld in verband met een door te voeren wijziging of in verband
                            met overige onderhouds- en beheerwerkzaamheden. Oranjegroep streeft
                            ernaar, voor zover redelijkerwijs mogelijk, de gebruiker vooraf te
                            informeren over de tijdstippen en duur hiervan, voor zover dit
                            aanmerkelijke consequenties heeft voor de gebruiker. Oranjegroep
                            aanvaardt geen aansprakelijkheid voor de schadelijke gevolgen die deze
                            (tijdelijke) buitengebruikstelling voor de gebruiker met zich mee zou
                            kunnen brengen.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>9.</p>
                <p style={styles.subTitelSecondParagraph}>Aansprakelijkheid en garanties</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep zorgt er naar beste vermogen voor dat de
                            gegevensuitwisseling via het portaal zo betrouwbaar mogelijk plaatsvindt
                            en dat de informatie op het portaal actueel is. Hoewel de Portal met de
                            grootst mogelijke zorgvuldigheid wordt beheerd, kan Oranjegroep geen
                            garantie geven dat het portaal altijd beschikbaar zal zijn, dat de
                            beschikbare informatie actueel is en dat gegevens altijd onbeschadigd
                            bij Oranjegroep aankomen. Bij uitwisseling van gegevens kunnen fouten in
                            de gegevensuitwisseling ontstaan, zoals het niet of niet volledig
                            overkomen van de gegevens. Oranjegroep sluit iedere aansprakelijkheid
                            uit voor fouten en schade ontstaan door het gebruik van de Portal.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep zal met de grootst mogelijke zorgvuldigheid omgaan met de
                            gegevens die de gebruiker via de Portal verstuurt. Wanneer het voor
                            Oranjegroep duidelijk is dat elektronische gegevens beschadigd zijn
                            overgekomen, bericht zij Gebruiker hierover. Als Gebruiker bij het
                            gebruik van een dienst verplicht is ook een papieren versie van de
                            gegevens te sturen en de elektronische gegevens beschadigd via de Portal
                            zijn overkomen, gaat bij de behandeling van die gegevens uit van de
                            papieren versie. Onverminderd het bovenstaande is Oranjegroep is in elk
                            geval niet aansprakelijk voor schade, van welke aard dan ook, die de
                            gebruiker lijdt als gevolg van een handelen of nalaten van Gebruiker in
                            strijd met deze voorwaarden: <br /> • diefstal, vermissing en/of het
                            misbruik van de account gegevens; <br /> • informatie van websites van
                            derden die gelinkt zijn aan de Portal; of <br /> • het niet opvolgen van
                            door de Oranjegroep gegeven instructies.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Eventueel op de Portal beschikbare (rapportage)functies worden
                            uitsluitend om redenen van gemak beschikbaar gesteld. Oranjegroep
                            verstrekt geen garantie inzake het direct bijwerken van de informatie
                            betreffende de uitstaande contracten en posities van een Gebruiker en
                            garandeert evenmin de volledigheid dan wel nauwkeurigheid van dergelijke
                            informatie. De rapportage op de Portal met betrekking tot
                            (vergoeding)saldi behelst geen garantie ten aanzien van de vergoeding
                            voor Gebruiker. Op de Portal aangegeven waarderingen zijn uitsluitend
                            indicatief. Oranjegroep wijst nadrukkelijk iedere aansprakelijkheid af
                            voor ieder gebruik waarvoor Gebruiker op de Portal verstrekte
                            vergoedingen eventueel inzet. Bij dergelijke waarderingen gaat het om de
                            raming door Oranjegroep op basis van schattingen die in de ogen van
                            Oranjegroep relevant zijn. De vergoedingen worden berekend
                            overeenkomstig de beleidslijnen van Oranjegroep met betrekking tot
                            dergelijke berekeningen en overeenkomstig de naar het oordeel van
                            Oranjegroep beste handelspraktijken. Zij behelzen geen voorwaarden op
                            basis waarvan Oranjegroep nieuwe regelingen zou aangaan dan wel
                            bestaande regelingen zou afwikkelen.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep wijst elke garantie uitdrukkelijk, wettelijk voorgeschreven
                            dan wel stilzwijgend, af met betrekking tot de Portal en alle resultaten
                            verkregen middels het gebruik van de Portal. Oranjegroep doet geen
                            toezeggingen met betrekking tot de nauwkeurigheid, juistheid en
                            volledigheid van de informatie en het materiaal op de Portal en evenmin
                            met betrekking tot de geschiktheid of deugdelijkheid van de informatie
                            en het materiaal voor een bepaald doeleinde of gebruik.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep, enig ander bedrijf binnen Oranjegroep (met inbegrip van
                            dochter- en houdstermaatschappijen) dan wel directeuren,
                            leidinggevenden, werknemers, agenten of onderaannemers van Oranjegroep
                            of enig ander bedrijf binnen Oranjegroep is/zijn niet aansprakelijk, op
                            welke al dan niet contractuele gronden dan ook (waaronder maar niet
                            beperkt tot het Addendum met Gebruiker), voor enige schade die door
                            Gebruiker wordt geleden als gevolg van de toegang tot of het gebruik van
                            de Portal dan wel het onvermogen om zich toegang te verschaffen tot of
                            gebruik te maken van de Portal, ongeacht of het hierbij gaat om directe
                            of indirecte schade, dan wel voor enige claim of eis van derden, tenzij
                            er sprake is van opzettelijk wangedrag of grove nalatigheid.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep is onder geen beding aansprakelijk voor enige verstoring van
                            de op de Portal verstrekte gegevens vanwege technische storting (d.w.z.
                            fouten in de overdracht, technische defecten, onderbrekingen, ingrepen
                            door derden, enz).
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker erkent dat Oranjegroep geen garantie verstrekt met betrekking
                            tot de beschikbaarheid, veiligheid of prestaties van de Portal of de
                            daarop aangeboden Diensten. Oranjegroep zal alle redelijke middelen
                            aanwenden om de juiste werking en prestaties van de Portal te allen
                            tijde te waarborgen. Dientengevolge is Oranjegroep niet aansprakelijk
                            voor enige directe of indirecte schade die Gebruiker eventueel lijdt
                            (met inbegrip van schade door claims of eisen van derden) als gevolg van
                            dan wel in verband met de volgende zaken: (i) onderbrekingen dan wel
                            storingen in de Diensten, (ii) in het bijzonder bij onderbreking voor
                            onderhoud of reparatie van het computersysteem of technisch defect in
                            het computersysteem, de servers of communicatielijnen, (iii) diefstal,
                            verlies, vernietiging of aanpassing van gegevens, software of
                            computerapparatuur als gevolg van onwettige toegang tot de
                            computersystemen van Oranjegroep of die van de Gebruiker of als gevolg
                            van een virus afkomstig van de Portal, het internet of het
                            computersysteem dan wel een server van Oranjegroep of een Gebruiker,
                            (iv) het niet uitvoeren van opdrachten, fouten, vertragingen of
                            onderbrekingen in de overdracht van gegevens of, in algemene zin,
                            problemen van welke aard dan ook bij de overdracht en ontvangst van
                            opdrachten.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.8</td>
                        <td style={styles.textTableRowSecondCell}>
                            In gevallen waarbij Oranjegroep aansprakelijk wordt gesteld voor schade
                            of verlies, beperkt haar aansprakelijkheid zich tot de daadwerkelijk
                            direct door Gebruiker geleden schade. Oranjegroep is niet
                            verantwoordelijk voor de niet-verwezenlijking van een verwachte
                            vergoeding.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>10.</p>
                <p style={styles.subTitelSecondParagraph}>Geheimhouding en beveiliging</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>10.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Behalve voor de wettelijk verplichte publicatie van gegevens mag
                            Oranjegroep de gegevens en inlichtingen die zij via de Portal ontvangt
                            niet openbaar maken. Deze vallen onder de geheimhoudingsbepalingen uit
                            de voor de Oranjegroep relevante (toezicht)wetgeving.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>10.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep treft naar beste vermogen passende maatregelen om de Portal
                            en de uitgewisselde informatie zoveel mogelijk te beveiligen tegen
                            verlies of onrechtmatig gebruik.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>11.</p>
                <p style={styles.subTitelSecondParagraph}>Privacy</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>11.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep en Gebruiker nemen alle wettelijke voorschriften met
                            betrekking tot de verwerking van persoonsgegevens in acht. Oranjegroep
                            en Gebruiker verstrekken elkaar direct alle relevante informatie als
                            daarnaar gevraagd wordt.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>11.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            De privacyverklaring van Oranjegroep is beschikbaar op haar website.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>11.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Gebruiker vrijwaart Oranjegroep voor alle aanspraken van derden die
                            jegens Oranjegroep worden ingesteld wegens een niet aan Oranjegroep toe
                            te rekenen schending van de Algemene Verordening Gegevensbescherming
                            en/of andere wetgeving met betrekking tot het verwerken en/of bewaren
                            van persoonsgegevens.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>12.</p>
                <p style={styles.subTitelSecondParagraph}>Geschillen en bevoegde rechter</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>12.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Op deze voorwaarden is het Nederlands recht van toepassing.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>12.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Een juridisch geschil in verband met of door deze voorwaarden kan alleen
                            worden voorgelegd aan een (kanton)rechter in Rotterdam.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>13.</p>
                <p style={styles.subTitelSecondParagraph}>Overmacht</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>13.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep of Gebruiker kan niet worden gedwongen haar verplichtingen
                            na te komen als zij daartoe niet in staat is als gevolg van overmacht.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>13.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Onder overmacht aan de zijde van Oranjegroep wordt ook verstaan het
                            gebrekkig functioneren van netspanning, telecommunicatie- of
                            netwerkvoorzieningen en een niet-toerekenbare tekortkoming van door
                            Oranjegroep ingeschakelde derden.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>14.</p>
                <p style={styles.subTitelSecondParagraph}>Overige bepalingen</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>14.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Deze voorwaarden kunnen worden aangepast. Gebruiker wordt verondersteld
                            bekend te zijn met de actuele voorwaarden voor het gebruik van de
                            Portal.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>14.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Eventuele inkoop-, leverings- of andere algemene voorwaarden van
                            Gebruiker zijn uitdrukkelijk niet van toepassing.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>14.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            De uit deze voorwaarden voortvloeiende rechten en plichten kunnen door
                            Gebruiker niet aan derden worden overgedragen zonder de schriftelijke
                            toestemming van Oranjegroep.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
