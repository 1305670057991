import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { IProfessionGroup } from '../../../hooks';
import { ICreateProfessionGroupVariables } from './types';
import { useApi } from '../../../../../../helpers';

export const useCreateProfessionGroup = () => {
    const api = useApi();

    return useMutation<AxiosResponse<IProfessionGroup>, any, ICreateProfessionGroupVariables>(
        ({ body }) => api.post('/profession-groups', body)
    );
};
