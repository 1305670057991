/**@jsxImportSource @emotion/react */
import { designStyles } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        form: {
            margin: '4rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: widthBelow600px ? '30rem' : '40rem',
        },
        formAmbassadorButtonsContainer: {
            width: '100%',
            display: 'flex',
            marginBottom: '4rem',
            flexDirection: 'column',
            alignItems: 'center',
        },
        ambassadorButton: {
            width: '30rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        formField: {
            marginBottom: '2rem',
        },
        formButton: {
            width: '15rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        ambassadorButtonSkeleton: {
            width: '30rem',
            height: '3.5rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        fieldSkeleton: {
            width: '100%',
            height: '5rem',
            marginBottom: '5rem',
        },
        contentFieldSkeleton: {
            width: '100%',
            height: '25rem',
            marginBottom: '5rem',
        },
        buttonSkeleton: {
            width: '15rem',
            height: '3.5rem',
            '&:not(:last-child)': {
                marginBottom: '2rem',
            },
        },
    });
