/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IContext } from './types';
import { IAdmin } from '../../../hooks/types';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@mui/material';
import { BiEdit, BiTrash, BiLockAlt, BiEnvelope } from 'react-icons/bi';
import { AiOutlineQrcode } from 'react-icons/ai';

export const ActionsCell = ({
    data,
    context: {
        handleChangeEmailClick,
        handleChangePasswordClick,
        handleResendQRClick,
        handelUpdateClick,
        handleDeleteClick,
    },
}: ICellRendererParams<IAdmin, any, IContext>) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            <React.Fragment>
                <Tooltip arrow title={<h1>{t('text.changeEmail')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handleChangeEmailClick(data);
                            }
                        }}
                    >
                        <BiEnvelope style={styles.emailButton} />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={<h1>{t('text.changePassword')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handleChangePasswordClick(data);
                            }
                        }}
                    >
                        <BiLockAlt style={styles.passwordButton} />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={<h1>{t('text.resendQR')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handleResendQRClick(data);
                            }
                        }}
                    >
                        <AiOutlineQrcode style={styles.qrCodeButton} />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
            <Tooltip arrow title={<h1>{t('text.update')}</h1>}>
                <IconButton
                    onClick={() => {
                        if (data) {
                            return handelUpdateClick(data);
                        }
                    }}
                >
                    <BiEdit style={styles.updateButton} />
                </IconButton>
            </Tooltip>
            <Tooltip arrow title={<h1>{t('text.delete')}</h1>}>
                <IconButton
                    onClick={() => {
                        if (data) {
                            return handleDeleteClick(data);
                        }
                    }}
                >
                    <BiTrash style={styles.deleteButton} />
                </IconButton>
            </Tooltip>
        </div>
    );
};
