/**@jsxImportSource @emotion/react */
import React from 'react';
import { useStyles } from './style';
import { useFetchAmbassadorCandidates, useDeleteAmbassador } from './hooks';
import { handleError, Cache, routePath, colors, handleQueryError } from '../../../helpers';
import { IAmbassador } from '../hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { useMediaQuery, IconButton } from '@mui/material';
import { Backdrop, Button, Spinner } from '../../../components';
import { AiOutlineClose } from 'react-icons/ai';
import { SiMicrosoftexcel } from 'react-icons/si';

interface IProps {
    open: boolean;
    itemToDeleteId: string;
    itemToDeleteName: string;
    closeModal: () => void;
}

export const DeleteModal = ({ open, itemToDeleteId, itemToDeleteName, closeModal }: IProps) => {
    const styles = useStyles();
    const history = useHistory();
    const widthBelow250px = useMediaQuery('(max-width: 250px)');
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { isLoading, data, error } = useFetchAmbassadorCandidates(itemToDeleteId);
    const deleteAmbassador = useDeleteAmbassador();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Backdrop open={open}>
            <div style={styles.root}>
                <div style={styles.header}>
                    <h2 style={styles.headerTitle}>{t('text.deleteAmbassador')}</h2>
                    <AiOutlineClose
                        css={styles.headerCloseButton}
                        onClick={() => {
                            if (!deleteAmbassador.isLoading) {
                                closeModal();
                            }
                        }}
                    />
                </div>
                <div style={styles.content}>
                    <p style={styles.contentQuestionText}>{`${itemToDeleteName} ${t(
                        'text.ambassadorDeleteModalText'
                    )}`}</p>
                    {isLoading ? (
                        <div style={styles.contentSpinnerBox}>
                            <Spinner variant="rond" cs={{ color: colors.blue, radius: '10rem' }} />
                        </div>
                    ) : null}
                    {data ? (
                        <React.Fragment>
                            <div style={styles.contentRelatedCandidateBox}>
                                <p style={styles.relatedCandidateBoxText}>{`${t(
                                    'text.relatedCandidates'
                                )}:`}</p>
                                {!widthBelow250px ? (
                                    <IconButton
                                        style={styles.realatedCondidateBoxButton}
                                        onClick={async () => {
                                            const workbook = new Workbook();
                                            workbook.created = new Date();
                                            const worksheet =
                                                workbook.addWorksheet('Related candidates');
                                            worksheet.columns = [
                                                {
                                                    header: 'Employee nr.',
                                                    key: 'employeeNumber',
                                                    width: 20,
                                                },
                                                {
                                                    header: 'Name',
                                                    key: 'name',
                                                    width: 80,
                                                },
                                            ];
                                            data.forEach((candidate) => {
                                                worksheet.addRow({
                                                    employeeNumber: candidate.employeeNumber
                                                        ? candidate.employeeNumber
                                                        : '',
                                                    name: `${candidate.firstName} ${candidate.lastName}`,
                                                });
                                            });
                                            try {
                                                const buffer = await workbook.xlsx.writeBuffer();
                                                saveAs(
                                                    new Blob([buffer]),
                                                    'related_candidates.xlsx'
                                                );
                                            } catch (e) {
                                                alert(t('text.failedToImport'));
                                            }
                                        }}
                                    >
                                        <SiMicrosoftexcel />
                                    </IconButton>
                                ) : null}
                            </div>
                            <div style={styles.contentRelatedCandidateList}>
                                {data.length > 0 ? (
                                    data.map((candidate, index) =>
                                        candidate.employeeNumber ? (
                                            <p key={index} css={styles.relatedCandidateListItem}>
                                                {`${candidate.employeeNumber} - ${candidate.firstName} ${candidate.lastName}`}
                                            </p>
                                        ) : (
                                            <p key={index} css={styles.relatedCandidateListItem}>
                                                {`${candidate.firstName} ${candidate.lastName}`}
                                            </p>
                                        )
                                    )
                                ) : (
                                    <p>{t('text.noRelatedCandidate')}</p>
                                )}
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
                <div style={styles.actions}>
                    <Button
                        style={styles.actionsDeleteButton}
                        disabled={deleteAmbassador.isLoading}
                        spinner={deleteAmbassador.isLoading}
                        cs={{
                            color: colors.red,
                            padding: '1rem 1.5rem',
                            textColor: colors.white,
                        }}
                        onClick={async () => {
                            try {
                                const { data } = await deleteAmbassador.mutateAsync({
                                    ambassadorId: itemToDeleteId,
                                });
                                queryClient.setQueryData<IAmbassador[]>(
                                    [Cache.ambassadors, ''],
                                    (ambassadors) => {
                                        if (ambassadors) {
                                            return ambassadors.filter(
                                                (ambassador) => ambassador.id !== data
                                            );
                                        }
                                        return [];
                                    }
                                );
                                closeModal();
                            } catch (e) {
                                handleError(e, ({ status, statusText }) => {
                                    if (status === 401 || status || 403) {
                                        history.push(routePath.unauthorizedError.create());
                                    } else if (status === 404) {
                                        history.push(routePath.notFoundError.create());
                                    } else if (status === 500) {
                                        history.push(routePath.internalServerError.create());
                                    } else {
                                        alert(statusText);
                                        closeModal();
                                    }
                                });
                            }
                        }}
                    >
                        {t('text.delete')}
                    </Button>
                    <Button
                        disabled={deleteAmbassador.isLoading}
                        spinner={deleteAmbassador.isLoading}
                        onClick={closeModal}
                        cs={{
                            color: colors.blue,
                            padding: '1rem 1.5rem',
                            fontSize: '1rem',
                        }}
                    >
                        {t('text.cancel')}
                    </Button>
                </div>
            </div>
        </Backdrop>
    );
};
