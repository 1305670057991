/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IAmbassador } from '../../../hooks';
import { IPayload as IDeleteModalPayload } from '../../../../../helpers/reducers/delete/types';
import { IPayload as IResendQRModalPayload } from '../../../../../helpers/reducers/resend-qr-code/types';
import { colors, useAuth, routePath, Role } from '../../../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '../../../../../components';
import { useMediaQuery, Tooltip, IconButton } from '@mui/material';
import { BiEdit, BiLockAlt, BiEnvelope, BiTrash } from 'react-icons/bi';
import { FiActivity } from 'react-icons/fi';
import { AiOutlineQrcode } from 'react-icons/ai';

interface IProps {
    ambassador: IAmbassador;
    openDelete: (payload: IDeleteModalPayload) => void;
    openResendQR: (payload: IResendQRModalPayload) => void;
}

export const Actions = ({ ambassador, openDelete, openResendQR }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            <LinkButton
                style={styles.candidatesButton}
                to={routePath.ambassadorCandidates.create(ambassador.id)}
                cs={{
                    color: colors.blue,
                    padding: '1rem 1rem',
                    fontSize: '1rem',
                }}
            >
                {t('text.candidates')}
            </LinkButton>
            <div style={styles.actions}>
                {currentUserHasRoles(Role.superAdmin) ? (
                    <React.Fragment>
                        <Tooltip arrow title={<h1>{t('text.changeEmail')}</h1>}>
                            <IconButton
                                onClick={() =>
                                    history.push(
                                        routePath.changeEmailAmbassador.create(ambassador.id)
                                    )
                                }
                            >
                                <BiEnvelope style={styles.actionsEmailButton} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow title={<h1>{t('text.changePassword')}</h1>}>
                            <IconButton
                                onClick={() =>
                                    history.push(
                                        routePath.changePasswordAmbassador.create(ambassador.id)
                                    )
                                }
                            >
                                <BiLockAlt style={styles.actionsPasswordButton} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow title={<h1>{t('text.resendQR')}</h1>}>
                            <IconButton
                                onClick={() =>
                                    openResendQR({
                                        resendQRUserId: ambassador.user.id,
                                        resendQRUsername: ambassador.user.name,
                                    })
                                }
                            >
                                <AiOutlineQrcode style={styles.actionsResendQRButton} />
                            </IconButton>
                        </Tooltip>
                    </React.Fragment>
                ) : null}
                <Tooltip arrow title={<h1>{t('text.activity')}</h1>}>
                    <IconButton
                        onClick={() =>
                            history.push(routePath.ambassadorActivityPeriods.create(ambassador.id))
                        }
                    >
                        <FiActivity style={styles.actionsActivityButton} />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={<h1>{t('text.update')}</h1>}>
                    <IconButton
                        onClick={() =>
                            history.push(routePath.updateAmbassador.create(ambassador.id))
                        }
                    >
                        <BiEdit style={styles.actionsUpdateButton} />
                    </IconButton>
                </Tooltip>
                {currentUserHasRoles(Role.superAdmin) ? (
                    <Tooltip arrow title={<h1>{t('text.delete')}</h1>}>
                        <IconButton
                            onClick={() =>
                                openDelete({
                                    itemToDeleteId: ambassador.id,
                                    itemToDeleteName: `${ambassador.user.firstName} ${ambassador.user.lastName}`,
                                })
                            }
                        >
                            <BiTrash style={styles.actionsDeleteButton} />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
        </div>
    );
};
