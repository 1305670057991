/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { formatNumber } from '../../../helpers';
import { IPipelineAmbassador, IPipelineDepartment } from '../../../pages/dashboards/hooks';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

interface IProps {
    ambassadors: IPipelineAmbassador[];
    maxHeight: string;
}

export const PipelineAmbassadorsTable = ({ ambassadors, maxHeight }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px, maxHeight);
    const { t, i18n } = useTranslation();

    return (
        <div css={styles.root}>
            <div css={styles.wrapper}>
                <table css={styles.table}>
                    <tbody css={styles.tableBody}>
                        <tr css={styles.firstRow}>
                            <td css={styles.firstRowFirstCell} colSpan={3}>
                                {t('text.ambassadors')}
                            </td>
                            <td css={styles.firstRowSecondCell} colSpan={5}>
                                {`${t('text.tier')} 1`}
                            </td>
                            <td css={styles.firstRowThirdCell}>{`${t('text.tier')} 2`}</td>
                            <td css={styles.firstRowFourthCell}>{`${t('text.tier')} 3`}</td>
                            <td css={styles.firstRowFifthCell}>
                                {`${t('text.tier')} 1 - ${t('text.tier')} 3`}
                            </td>
                        </tr>
                        <tr css={styles.secondRow}>
                            <td css={styles.secondRowFirstCell}>{t('text.department')}</td>
                            <td css={styles.secondRowSecondCell}>{t('text.ambassador')}</td>
                            <td css={styles.secondRowThirdCell}>{t('text.status')}</td>
                            <td css={styles.dataCells}>{t('text.candidates')}</td>
                            <td css={styles.dataCells}>{t('text.invited')}</td>
                            <td css={styles.dataCells}>{t('text.registered')}</td>
                            <td css={styles.dataCells}>{t('text.rejected')}</td>
                            <td css={styles.dataCells}>{t('text.totalHours')}</td>
                            <td css={styles.dataCells}>{t('text.totalHours')}</td>
                            <td css={styles.dataCells}>{t('text.totalHours')}</td>
                            <td css={styles.dataCells}>{t('text.paidFee')}</td>
                        </tr>
                        {ambassadors.map((ambassador, index) => (
                            <tr css={styles.ambassadorRow} key={index}>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowFirstCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowFirstCell
                                    }
                                >
                                    {
                                        ambassador.department[
                                            i18n.language as keyof IPipelineDepartment
                                        ]
                                    }
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowSecondCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowSecondCell
                                    }
                                >
                                    {`${ambassador.name} - ${ambassador.employeeNumber}`}
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowThirdCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowThirdCell
                                    }
                                >
                                    {ambassador.isActive ? t('text.active') : t('text.inactive')}
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowDataCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowDataCell
                                    }
                                >
                                    {ambassador.wrokedCandidateAmount}
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowDataCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowDataCell
                                    }
                                >
                                    {ambassador.invitationAmount}
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowDataCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowDataCell
                                    }
                                >
                                    {ambassador.registeredAmount}
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowDataCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowDataCell
                                    }
                                >
                                    {ambassador.rejectedAmount}
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowDataCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowDataCell
                                    }
                                >
                                    {ambassador.firstTierCandidateHours
                                        ? formatNumber(ambassador.firstTierCandidateHours)
                                        : 0}
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowDataCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowDataCell
                                    }
                                >
                                    {ambassador.secondTierCandidateHours
                                        ? formatNumber(ambassador.secondTierCandidateHours)
                                        : 0}
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowDataCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowDataCell
                                    }
                                >
                                    {ambassador.thirdTierCandidateHours
                                        ? formatNumber(ambassador.thirdTierCandidateHours)
                                        : 0}
                                </td>
                                <td
                                    css={
                                        index === ambassadors.length - 1
                                            ? [
                                                  styles.ambassadorRowDataCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.ambassadorRowDataCell
                                    }
                                >
                                    {`€${
                                        ambassador.paidFee ? formatNumber(ambassador.paidFee) : 0
                                    }`}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
