import { IDepartment } from '../../../hooks';
import { useApi, Cache } from '../../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchDepartment = (departmentId: string) => {
    const api = useApi();

    return useQuery<IDepartment, any>([Cache.departments, departmentId], () =>
        api.get(`/departments/${departmentId}`).then((res) => res.data)
    );
};
