import { IChangeEmailVariables } from './types';
import { IAdmin } from '../../../hooks/types';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useChangeEmail = (adminId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<IAdmin>, any, IChangeEmailVariables>(({ body }) =>
        api.patch(`/admins/${adminId}/email`, body)
    );
};
