import { IDeleteAdminVariables } from './types';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useDeleteAdmin = () => {
    const api = useApi();

    return useMutation<AxiosResponse<string>, any, IDeleteAdminVariables>(({ adminId }) =>
        api.delete(`/admins/${adminId}`)
    );
};
