import { ICandidate } from './types';
import { Cache, useApi } from '../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchCandidates = (search: string) => {
    const api = useApi();

    return useQuery<ICandidate[], any>([Cache.candidates, search], () =>
        api.get(`/candidates?search=${search}`).then((res) => res.data)
    );
};
