/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        ambassadorName: {
            fontFamily: 'inherit',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: colors.blue,
            marginTop: '1rem',
            userSelect: 'none',
        },
        actionBar: {
            marginTop: '2rem',
            height: '4rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        actionBarViewToogle: {
            marginLeft: '2rem',
        },
        actionBarCreateInviteIcon: {
            backgroundColor: colors.blue,
            color: colors.white,
            marginRight: '2rem',
        },
        ambassadorNameSkeleton: {
            width: '40rem',
            height: '1.5rem',
            marginTop: '1rem',
        },
    });
