/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useLogin } from './hooks';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Globe from 'react-globe.gl';
import { globeArcs, globePoints } from './globe';
import { useMediaQuery } from '@mui/material';
import { Formik, Form, Field, FormikErrors } from 'formik';
import validator from 'validator';
import { TextFieldInput, Button, LanguageMenu } from '../../components';
import { colors, routePath, handleError, SocialMediaLink } from '../../helpers';
import logo from '../../assets/oranje-logo-orange.png';
import earth from '../../assets/earth-night.jpeg';
import { BiEnvelope, BiLockAlt } from 'react-icons/bi';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';

export const Login = () => {
    const widthBelow1380px = useMediaQuery('(max-width: 1380px)');
    const widthBelow980px = useMediaQuery('(max-width: 980px)');
    const widthBelow400px = useMediaQuery('(max-width: 400px)');
    const widthBelow200px = useMediaQuery('(max-width: 200px)');
    const styles = useStyles(widthBelow1380px, widthBelow980px, widthBelow200px);
    const history = useHistory();
    const login = useLogin();
    const { t } = useTranslation();
    const globeRef = React.useRef<any>();
    const componentMounted = React.useRef(true);

    React.useLayoutEffect(() => {
        if (componentMounted.current) {
            globeRef.current!.controls().enableZoom = false;
            globeRef.current!.controls().autoRotate = true;
            globeRef.current!.controls().autoRotateSpeed = 0.5;
            globeRef.current!.pointOfView({ lat: 23.792884, lng: 6.27879, altitude: 1.5 }, 5000);
        }
    }, [globeRef]);

    React.useEffect(() => {
        return () => {
            componentMounted.current = false;
        };
    }, []);

    return (
        <div style={styles.root}>
            <div style={styles.header}>
                <div style={styles.headerContent}>
                    <div style={styles.headerContentTextBox}>
                        <h1 css={styles.textBoxMainText}>Oranjelink</h1>
                        <h2 css={styles.textBoxSubText}>{t('text.connectUs')}</h2>
                    </div>
                    <img css={styles.headerContentLogo} src={logo} alt="logo" draggable="false" />
                </div>
                <LanguageMenu style={styles.headerLngMenu} />
            </div>
            <div style={styles.content}>
                <div style={styles.contentGlobe}>
                    <Globe
                        ref={globeRef}
                        globeImageUrl={earth}
                        backgroundColor={colors.globeBackground}
                        height={
                            widthBelow200px
                                ? 120
                                : widthBelow400px
                                ? 200
                                : widthBelow980px
                                ? 400
                                : widthBelow1380px
                                ? 600
                                : 700
                        }
                        width={
                            widthBelow200px
                                ? 120
                                : widthBelow400px
                                ? 200
                                : widthBelow980px
                                ? 400
                                : widthBelow1380px
                                ? 600
                                : 700
                        }
                        arcsData={globeArcs}
                        pointsData={globePoints}
                        arcColor="color"
                        arcStroke="stroke"
                        arcDashLength="dashLength"
                        arcDashGap="dashGap"
                        arcDashAnimateTime="dashAnimateTime"
                        pointAltitude="size"
                        pointColor="color"
                        pointRadius="radius"
                        pointLabel="label"
                    />
                </div>
                <Formik
                    initialValues={
                        {
                            email: '',
                            password: '',
                        } as IFormValues
                    }
                    validate={({ email, password }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!email) errors.email = t('form.error.requiredField');
                        else if (email.length > 127) {
                            errors.email = t('form.error.max127Chars');
                        } else if (!validator.isEmail(email)) {
                            errors.email = t('form.error.invalidEmail');
                        }
                        if (!password) errors.password = t('form.error.requiredField');
                        else if (password.length < 6) {
                            errors.password = t('form.error.minSixChars');
                        } else if (password.length > 32) {
                            errors.password = t('form.error.max32Chars');
                        } else if (!validator.matches(password, '(?=.*?[#?!@$%^&*-])')) {
                            errors.password = t('form.error.minOneSpecialChar');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ email, password }, { setErrors }) => {
                        try {
                            const {
                                data: { token, areTermsAccepted },
                            } = await login.mutateAsync({
                                body: {
                                    email: email.trim(),
                                    password: password.trim(),
                                },
                            });
                            localStorage.setItem('lvid', token);
                            areTermsAccepted
                                ? history.push(routePath.verifyLogin.create())
                                : history.push(routePath.acceptTerms.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 404 || status === 401) {
                                    setErrors({
                                        email: t('form.error.invalidCredentials'),
                                        password: t('form.error.invalidCredentials'),
                                    });
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else {
                                    alert(statusText);
                                }
                            });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form noValidate style={styles.contentForm}>
                            <h1 css={styles.formHeader}>{t('text.login')}</h1>
                            <div style={styles.formFieldBox}>
                                <Field
                                    style={styles.formField}
                                    name="email"
                                    type="email"
                                    label={t('form.label.email')}
                                    placeholder={t('form.placeholder.email')}
                                    icon={<BiEnvelope />}
                                    spinner={isSubmitting}
                                    disabled={isSubmitting}
                                    cs={{
                                        fontSize: '2.2rem',
                                        inputColor: colors.orange,
                                        textColor: colors.white,
                                        disabledColor: colors.gray,
                                    }}
                                    component={TextFieldInput}
                                />
                                <Field
                                    style={styles.formField}
                                    name="password"
                                    type="password"
                                    label={t('form.label.password')}
                                    placeholder={t('form.placeholder.password')}
                                    icon={<BiLockAlt />}
                                    spinner={isSubmitting}
                                    disabled={isSubmitting}
                                    cs={{
                                        fontSize: '2.2rem',
                                        inputColor: colors.orange,
                                        textColor: colors.white,
                                        disabledColor: colors.gray,
                                    }}
                                    component={TextFieldInput}
                                />
                            </div>
                            <Link
                                css={styles.formForgotPassword}
                                to={routePath.resetPasswordEmailCheck.create()}
                            >
                                {t('text.forgotPassword')}
                            </Link>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.orange,
                                    boxShadowColor: colors.lightBoxShadow,
                                }}
                            >
                                {t('text.login')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div style={styles.footer}>
                <div style={styles.footerLinkBox}>
                    <a
                        css={styles.linkBoxLink}
                        href={SocialMediaLink.Facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillFacebook />
                    </a>
                    <a
                        css={styles.linkBoxLink}
                        href={SocialMediaLink.Instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillInstagram />
                    </a>
                    <a
                        css={styles.linkBoxLink}
                        href={SocialMediaLink.LinkedIn}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillLinkedin />
                    </a>
                </div>
                <div style={styles.footerRightContainer}>
                    <div>
                        <Link css={styles.footerRightContainerLink} to={routePath.terms.create()}>
                            {t('text.generalTerms')}
                        </Link>
                        &nbsp;/&nbsp;
                        <Link
                            css={styles.footerRightContainerLink}
                            to={routePath.privacyPolicy.create()}
                        >
                            {t('text.privacyPolicy')}
                        </Link>
                    </div>
                    <p>
                        {`De Oranjegroep \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop - Public Bèta`}
                    </p>
                </div>
            </div>
        </div>
    );
};
