/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { routePath, useAuth, colors, Role } from '../../helpers';
import { useIsFetching, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Tooltip, IconButton, Box, LinearProgress } from '@mui/material';
import { LanguageMenu } from '../inputs/language-menu/LanguageMenu';
import { RiLogoutBoxRLine, RiUser3Line, RiClipboardLine, RiSettings5Fill } from 'react-icons/ri';
import { BiMessageRoundedDots } from 'react-icons/bi';
import { MdHelp } from 'react-icons/md';
import { DashboardsMenuItem, MenuMenuItem, DeclarationsMenuItem } from './menu-items';
import { CollapsedMenu } from './collapsed-menu/CollapsedMenu';

interface IProps {
    isPageSubmitting: boolean;
}

export const Navigation = ({ isPageSubmitting }: IProps) => {
    const widthBelow500px = useMediaQuery('(max-width: 500px)');
    const { currentUser, setCurrentUser, currentUserHasRoles } = useAuth();
    const client = useQueryClient();
    const isFetching = useIsFetching();
    const history = useHistory();
    const { t } = useTranslation();
    const styles = useStyles();

    console.log();

    React.useEffect(() => {
        if (!currentUser) history.push('/');
    }, [currentUser, history]);

    return (
        <div style={styles.root}>
            <div style={styles.panelWrapper}>
                <div style={styles.panelWrapperLeftPanel}>
                    {!widthBelow500px && !isPageSubmitting ? (
                        <React.Fragment>
                            <DashboardsMenuItem />
                            <MenuMenuItem />
                            {currentUserHasRoles(Role.superAdmin) ? <DeclarationsMenuItem /> : null}
                        </React.Fragment>
                    ) : null}
                </div>
                <div style={styles.panelWrapperRightPanel}>
                    {!widthBelow500px && !isPageSubmitting ? (
                        <React.Fragment>
                            <LanguageMenu cs={{ size: '1.5rem' }} />
                            <Tooltip arrow title={<h1>{t('text.help')}</h1>}>
                                <span>
                                    <IconButton
                                        onClick={(event) => {
                                            event.preventDefault();
                                            client.cancelQueries();
                                            history.push(routePath.help.create());
                                        }}
                                        disabled={isPageSubmitting}
                                    >
                                        <MdHelp color={colors.buttonTextColor} fontSize="2rem" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow title={<h1>Feedback</h1>}>
                                <span>
                                    <IconButton
                                        onClick={(event) => {
                                            event.preventDefault();
                                            client.cancelQueries();
                                            history.push(routePath.feedback.create());
                                        }}
                                        disabled={isPageSubmitting}
                                    >
                                        <BiMessageRoundedDots
                                            color={colors.buttonTextColor}
                                            fontSize="2rem"
                                        />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {currentUserHasRoles(Role.superAdmin) ? (
                                <React.Fragment>
                                    <Tooltip arrow title={<h1>{t('text.settings')}</h1>}>
                                        <span>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    client.cancelQueries();
                                                    history.push(routePath.settings.create());
                                                }}
                                                disabled={isPageSubmitting}
                                            >
                                                <RiSettings5Fill
                                                    color={colors.buttonTextColor}
                                                    fontSize="2rem"
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip arrow title={<h1>{t('text.logs')}</h1>}>
                                        <span>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    client.cancelQueries();
                                                    history.push(routePath.logs.create());
                                                }}
                                                disabled={isPageSubmitting}
                                            >
                                                <RiClipboardLine
                                                    color={colors.buttonTextColor}
                                                    fontSize="2rem"
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </React.Fragment>
                            ) : null}
                            <Tooltip arrow title={<h1>{t('text.myAccount')}</h1>}>
                                <span>
                                    <IconButton
                                        onClick={(event) => {
                                            event.preventDefault();
                                            client.cancelQueries();
                                            history.push(routePath.user.create());
                                        }}
                                        disabled={isPageSubmitting}
                                    >
                                        <RiUser3Line
                                            color={colors.buttonTextColor}
                                            fontSize="2rem"
                                        />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow title={<h1>{t('text.logout')}</h1>}>
                                <span>
                                    <IconButton
                                        onClick={(event) => {
                                            event.preventDefault();
                                            client.cancelQueries();
                                            history.push('/');
                                            localStorage.removeItem('rtid');
                                            localStorage.removeItem('lvid');
                                            localStorage.removeItem('rpvid');
                                            localStorage.removeItem('esvid');
                                            client.clear();
                                            setCurrentUser(null);
                                        }}
                                        disabled={isPageSubmitting}
                                    >
                                        <RiLogoutBoxRLine
                                            color={colors.buttonTextColor}
                                            fontSize="2rem"
                                        />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </React.Fragment>
                    ) : null}
                    {widthBelow500px && !isPageSubmitting ? (
                        <React.Fragment>
                            <LanguageMenu
                                style={styles.rightPanelLngSelect}
                                cs={{ size: '1.5rem' }}
                            />
                            <CollapsedMenu isPageSubmitting={isPageSubmitting} />
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
            {isFetching ? (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            ) : (
                <div style={styles.emptyFetcher} />
            )}
        </div>
    );
};
