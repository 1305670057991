export const dateFilter = (filterLocalDateAtMidnight: Date, cellValue: string): number => {
    const dateAsString = new Date(cellValue).toLocaleDateString();
    if (dateAsString == null) return 0;

    const dateParts = dateAsString.split('-');
    const day = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const year = Number(dateParts[2]);
    const cellDate = new Date(year, month, day);

    if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
        return -1;
    } else if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
        return 1;
    } else {
        return 0;
    }
};
