import { IProfession } from '../../../hooks';
import { useApi, Cache } from '../../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchProfession = (professionId: string) => {
    const api = useApi();

    return useQuery<IProfession, any>([Cache.professions, professionId], () =>
        api.get(`/professions/${professionId}`).then((res) => res.data)
    );
};
