import { ILog } from './types';
import { Cache, useApi } from '../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchLogs = () => {
    const api = useApi();

    return useQuery<ILog[], any>(Cache.logs, ({ signal }) =>
        api.get('/logs', { signal }).then((res) => res.data)
    );
};
