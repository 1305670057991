import { IAmbassador } from '../../../ambassadors/hooks';
import { Cache, useApi } from '../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAmbassador = (userId: string, enabled: boolean) => {
    const api = useApi();

    return useQuery<IAmbassador, any>(
        [Cache.ambassadors, userId],
        ({ signal }) => api.get(`/ambassadors/users/${userId}`, { signal }).then((res) => res.data),
        {
            enabled,
            refetchOnWindowFocus: false,
        }
    );
};
