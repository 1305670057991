/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchSetting } from './hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page, Button } from '../../components';
import { FaSearchPlus } from 'react-icons/fa';
import inviteCandidate from '../../assets/invite-candidate.png';
import createButton from '../../assets/create-button.png';
import hoursDashboard from '../../assets/hours-dashboard.png';
import { HoursDashboardImageModal } from './hours-dashboard-image-modal/HoursDashboardImageModal';
import { colors, formatNumber, handleQueryError } from '../../helpers';

export const Help = () => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [isHoursDashboardImageOpen, setHoursDashboardImageOpen] = React.useState(false);

    const { data, isLoading, error } = useFetchSetting();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page title={t('text.helpPage')} style={styles.root}>
            {isHoursDashboardImageOpen && (
                <HoursDashboardImageModal
                    open={isHoursDashboardImageOpen}
                    closeModal={() => setHoursDashboardImageOpen(false)}
                />
            )}
            <div style={styles.content}>
                <div style={styles.prolog}>
                    <p style={styles.prologTitle}>{t('help.explanation')}</p>
                    <p style={styles.prologSubTitle}>{t('text.general')}</p>
                    <p style={styles.prologText}>►&nbsp;{t('help.generalParagraph')}</p>
                    <p style={styles.prologSubTitle}>{t('text.hoursDashboard')}</p>
                    <p style={styles.prologText}>►&nbsp;{t('help.urenDashboardParagraph1')}</p>
                    <p style={styles.prologText}>►&nbsp;{t('help.urenDashboardParagraph2')}</p>
                    <p style={styles.prologText}>►&nbsp;{t('help.urenDashboardParagraph3')}</p>
                </div>
                <section id="general" style={styles.section}>
                    <p style={styles.sectionTitle}>{t('text.general')}</p>
                    <p style={styles.title}>{t('text.login')}:</p>
                    <p style={styles.text}>{t('help.general.loginParagraph')}</p>
                    <p style={styles.title}>{t('text.resetPassword')}:</p>
                    <p style={styles.text}>{t('help.general.resetPasswordParagraph')}</p>
                    <p style={styles.title}>{t('text.languagePreference')}:</p>
                    <p style={styles.text}>{t('help.general.languagePreferenceParagraph')}</p>
                    <p style={styles.title}>{t('text.accountSettings')}:</p>
                    <p style={styles.text}>{t('help.general.accountSettingsParagraph')}</p>
                    <p style={styles.title}>{t('text.personalMessages')}:</p>
                    <p style={styles.text}>{t('help.general.personalMessagesParagraph')}</p>
                    <p style={styles.title}>{t('text.hoursRegistration')}:</p>
                    <p style={styles.text}>{t('help.general.hoursRegistrationParagraph')}</p>
                    <p style={styles.title}>{t('text.fee')}:</p>
                    <p style={styles.text}>{t('help.general.feeParagraph')}</p>
                    <p style={styles.title}>{t('text.inviteNewCandidate')}:</p>
                    <p style={styles.text}>
                        <span style={styles.textImageContainer}>
                            <img
                                src={inviteCandidate}
                                alt={t('text.inviteNewCandidate')}
                                style={styles.textImage}
                            />
                            <span style={styles.textImageCaption}>
                                {t('text.inviteNewCandidate')}
                            </span>
                        </span>
                        {t('help.general.inviteNewCandidateParagraph')}
                    </p>
                    <p style={styles.title}>{t('text.createAmbassador')}:</p>
                    <p style={styles.text}>
                        <span style={styles.textImageContainer}>
                            <img
                                src={createButton}
                                alt={t('text.createAmbassador')}
                                style={styles.textImage}
                            />
                            <span style={styles.textImageCaption}>
                                {t('text.createAmbassador')}
                            </span>
                        </span>
                        {t('help.general.createAmbassadorParagraph')}
                    </p>
                    <p style={styles.title}>{t('text.dashboards')}:</p>
                    <p style={styles.text}>{t('help.general.dashboardsParagraph')}</p>
                    <p style={styles.title}>{t('text.dashboardHours')}:</p>
                    <p style={styles.text}>{t('help.general.dashboardHoursParagraph')}</p>
                    <p style={styles.title}>{t('text.dashboardPipeline')}:</p>
                    <p style={styles.text}>{t('help.general.dashboardPipelineParagraph')}</p>
                    <p style={styles.title}>{t('text.dashboardRecruitment')}:</p>
                    <p style={styles.text}>{t('help.general.dashboardRecruitmentParagraph')}</p>
                    <p style={styles.title}>{t('text.dashboardScoreboard')}:</p>
                    <p style={styles.text}>{t('help.general.dashboardScoreboardParagraph')}</p>
                </section>
                <section id="urenDashboard" style={styles.section}>
                    <p style={styles.sectionTitle}>{t('text.hoursDashboard')}</p>
                    <p style={styles.sectionGeneralInfo}>
                        {t('help.hoursDashboard.generalInfoParagraph')}
                    </p>
                    <p style={styles.title}>{t('text.ambassador')}:</p>
                    <p style={styles.text}>
                        {t('help.hoursDashboard.ambassadorParagraph')}
                        <br />
                        <strong>
                            {t('text.minWorkedHourPerWeekForFee')}:{' '}
                            {data
                                ? formatNumber(data.ambassadorMinWorkedHourPerWeekForFee)
                                : formatNumber(37.5)}
                        </strong>
                    </p>
                    <p style={styles.title}>{t('text.candidate')}:</p>
                    <p style={styles.text}>
                        {t('help.hoursDashboard.candidateParagraph')}
                        <br />
                        <strong>
                            {t('text.minWorkedHourPerWeekForFee')}:{' '}
                            {data
                                ? formatNumber(data.candidateMinWorkedHourPerWeekForFee)
                                : formatNumber(37.5)}
                        </strong>
                    </p>
                    <div
                        style={styles.blockImageContainer}
                        onClick={() => setHoursDashboardImageOpen(true)}
                    >
                        <div css={styles.blockImageFrame}>
                            <img
                                src={hoursDashboard}
                                alt={t('help.hoursDashboard.hoursDashboardExample')}
                                css={styles.blockImage}
                            />
                            <div style={styles.blockImageOverlay}>
                                <div style={styles.blockImageOverlayContent}>
                                    <p style={styles.blockImageOverlayContentText}>
                                        {t('text.clickForFullscreen')}
                                    </p>
                                    <FaSearchPlus />
                                </div>
                            </div>
                        </div>
                    </div>
                    <p style={styles.blockImageCaption}>
                        {t('help.hoursDashboard.hoursDashboardExample')}
                    </p>
                    <p style={styles.title}>{t('text.totalHours')}:</p>
                    <p style={styles.text}>{t('help.hoursDashboard.totalHoursParagraph')}</p>
                    <p style={styles.title}>{t('text.statusHours')}:</p>
                    <p style={styles.text}>{t('help.hoursDashboard.statusHoursParagraph')}</p>
                    <p style={styles.title}>{t('text.weekFeePaid')}:</p>
                    <p style={styles.text}>{t('help.hoursDashboard.weekFeePaidParagraph')}</p>
                    <p style={styles.title}>{t('text.statusAmbassador')}:</p>
                    <p style={styles.text}>
                        ►&nbsp;{t('help.hoursDashboard.ambassadorActiveStatusParagraph')}
                        <br />
                        ►&nbsp;{t('help.hoursDashboard.ambassadorInactiveStatusParagraph')}
                    </p>
                    <p style={styles.title}>{t('text.statusCandidate')}:</p>
                    <p style={styles.text}>
                        ►&nbsp;
                        {`${t('candidateStatus.invited')}: ${t(
                            'help.hoursDashboard.candidateInvitedStatusParagraph'
                        )}`}
                        <br />
                        ►&nbsp;
                        {`${t('candidateStatus.registered')}: ${t(
                            'help.hoursDashboard.candidateRegisteredStatusParagraph'
                        )}`}
                        <br />
                        ►&nbsp;
                        {`${t('candidateStatus.rejected')}: ${t(
                            'help.hoursDashboard.candidateRejectedStatusParagraph'
                        )}`}
                        <br />
                        ►&nbsp;
                        {`${t('candidateStatus.linked')}: ${t(
                            'help.hoursDashboard.candidateLinkedStatusParagraph'
                        )}`}
                    </p>
                    <p style={styles.title}>{`${t('text.tier')} 1 - ${t('text.tier')} 3`}:</p>
                    <p style={styles.text}>{t('help.hoursDashboard.tier1TillTier3Paragraph')}</p>
                    <p style={styles.title}>{`${t('text.tier')} 1`}:</p>
                    <p style={styles.text}>
                        {t('help.hoursDashboard.tier1Paragraph1')}
                        <br />
                        <strong>
                            {t('text.firstTierFee')}:{' '}
                            {data ? formatNumber(data.ambassadorFirstTierFee) : formatNumber(0.3)}
                        </strong>
                        <br />
                        {t('help.hoursDashboard.tier1Paragraph2')}
                        <br />
                        <strong>
                            {t('text.secondTierOpen')}: {data ? data.ambassadorSecondTierOpen : 10}
                        </strong>
                    </p>
                    <p style={styles.title}>{`${t('text.tier')} 2`}:</p>
                    <p style={styles.text}>
                        {t('help.hoursDashboard.tier2Paragraph')}
                        <br />
                        <strong>
                            {t('text.secondTierFee')}:{' '}
                            {data ? formatNumber(data.ambassadorSecondTierFee) : formatNumber(0.2)}
                        </strong>
                        <br />
                        <strong>
                            {t('text.thirdTierOpen')}: {data ? data.ambassadorThirdTierOpen : 20}
                        </strong>
                    </p>
                    <p style={styles.title}>{`${t('text.tier')} 3`}:</p>
                    <p style={styles.text}>
                        {t('help.hoursDashboard.tier3Paragraph')}
                        <br />
                        <strong>
                            {t('text.thirdTierFee')}:{' '}
                            {data ? formatNumber(data.ambassadorThirdTierFee) : formatNumber(0.1)}
                        </strong>
                    </p>
                </section>
                <div style={styles.backButtonContainer}>
                    <Button
                        css={styles.backButton}
                        type="button"
                        disabled={isLoading}
                        spinner={isLoading}
                        cs={{
                            color: colors.blue,
                        }}
                        onClick={() => history.goBack()}
                    >
                        {t('text.back')}
                    </Button>
                </div>
            </div>
        </Page>
    );
};
