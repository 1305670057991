/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useAuth, Role, routePath, colors } from '../../../../../helpers';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '../../../../buttons/link-button/LinkButton';

interface IProps {
    inputRef: React.RefObject<HTMLInputElement>;
    closeCollapsedMenu: () => void;
}

export const MenuMenuList = ({ inputRef, closeCollapsedMenu }: IProps) => {
    const styles = useStyles();
    const { currentUserHasRoles } = useAuth();
    const client = useQueryClient();
    const { t } = useTranslation();

    return (
        <ul style={styles.navBoxList} className="navLinks">
            {currentUserHasRoles(Role.superAdmin) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.admins.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.administrators')}
                    </LinkButton>
                </li>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.ambassadors.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.ambassadors')}
                    </LinkButton>
                </li>
            ) : null}
            {currentUserHasRoles(
                Role.superAdmin,
                Role.admin,
                Role.recruiter,
                Role.recruiter,
                Role.ambassador
            ) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.candidates.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {currentUserHasRoles(Role.ambassador)
                            ? t('text.myCandidates')
                            : t('text.candidates')}
                    </LinkButton>
                </li>
            ) : null}
        </ul>
    );
};
