/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import {
    FormControlLabel,
    Checkbox,
    FormHelperText,
    FormControlLabelProps,
    CheckboxProps,
} from '@mui/material';
import { FieldProps } from 'formik';
import { ICustomStyle, IElementStyle, IElementSxProps } from './types';
import { useStyles } from './style';

interface IProps {
    cs?: ICustomStyle;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
}

export const CheckboxInput = ({
    label,
    disabled,
    cs,
    es,
    esx,
    style,
    className,
    field,
    form: { errors, touched, handleChange, handleBlur },
}: IProps & FormControlLabelProps & CheckboxProps & FieldProps) => {
    const styles = useStyles(cs);

    return (
        <div
            css={
                es && es.root
                    ? [es.root as Interpolation<Theme>, style as Interpolation<Theme>]
                    : (style as Interpolation<Theme>)
            }
        >
            <FormControlLabel
                label={label}
                css={es && es.formControl ? (es.formControl as Interpolation<Theme>) : undefined}
                sx={
                    esx && esx.formControl
                        ? { ...styles.formControl, ...esx.formControl }
                        : styles.formControl
                }
                className={className}
                control={
                    <Checkbox
                        {...field}
                        css={es && es.checkbox ? (es.checkbox as Interpolation<Theme>) : undefined}
                        sx={
                            esx && esx.checkbox
                                ? { ...styles.checkbox, ...esx.checkbox }
                                : styles.checkbox
                        }
                        checked={field.value}
                        disabled={disabled}
                        onChange={(event) => {
                            handleChange(event);
                        }}
                        onBlur={(event) => {
                            handleBlur(event);
                        }}
                    />
                }
            ></FormControlLabel>
            <FormHelperText
                css={es && es.formHelper ? (es.formHelper as Interpolation<Theme>) : undefined}
                sx={
                    esx && esx.formHelper
                        ? { ...styles.formHelper, ...esx.formHelper }
                        : styles.formHelper
                }
            >
                {Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? errors[field.name]
                    : ' '}
            </FormHelperText>
        </div>
    );
};
