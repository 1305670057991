/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IAdmin, IDepartment } from '../../../../hooks/types';
import { Role } from '../../../../../../helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
    admin: IAdmin;
}

export const MainPanel = ({ admin }: IProps) => {
    const styles = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <div style={styles.panel}>
            <p style={styles.panelText}>{`${admin.user.firstName} ${admin.user.lastName}`}</p>
            <p style={styles.panelEmailText}>{admin.user.email}</p>
            <div>
                <p style={styles.panelText}>{t('text.role')}:&nbsp;</p>
                <p style={styles.panelDataText}>
                    {admin.user.appRoles.map((appRole) => appRole.role).includes(Role.superAdmin)
                        ? t('text.superAdmin')
                        : admin.user.appRoles.map((appRole) => appRole.role).includes(Role.admin)
                        ? t('text.admin')
                        : t('text.recruiter')}
                </p>
            </div>
            {admin.user.department ? (
                <div>
                    <p style={styles.panelText}>{t('text.department')}:&nbsp;</p>
                    <p style={styles.panelDataText}>
                        {admin.user.department[i18n.language as keyof IDepartment]}
                    </p>
                </div>
            ) : null}
            <div>
                <p style={styles.panelText}>{t('text.language')}:&nbsp;</p>
                <p style={styles.panelDataText}>{admin.user.language.toUpperCase()}</p>
            </div>
        </div>
    );
};
