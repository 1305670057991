import { IProfessionGroup } from '../types';
import { useQuery } from 'react-query';
import { useApi, Cache } from '../../../../../helpers';

export const useFetchProfessionGroups = () => {
    const api = useApi();

    return useQuery<IProfessionGroup[], any>(Cache.professionGroups, ({ signal }) =>
        api.get('/profession-groups', { signal }).then((res) => res.data)
    );
};
