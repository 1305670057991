import { AxiosResponse } from 'axios';
import { useApi } from '../../../../../../../helpers';
import { useMutation } from 'react-query';
import { IDeleteActivityPeriodVariables } from './types';

export const useDeleteActivityPeriod = () => {
    const api = useApi();

    return useMutation<AxiosResponse<string>, any, IDeleteActivityPeriodVariables>(
        ({ ambassadorActivityPeriodId }) =>
            api.delete(`/ambassador-activity-periods/${ambassadorActivityPeriodId}`)
    );
};
