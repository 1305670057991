import { IAmbassador } from './types';
import { Cache, useApi } from '../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAmbassadors = (enabled: boolean) => {
    const api = useApi();

    return useQuery<IAmbassador[], any>(
        Cache.dashboardComboBoxAmbassadors,
        ({ signal }) =>
            api.get('/dashboards/ambassadors/combo-box', { signal }).then((res) => res.data),
        {
            enabled,
            refetchOnWindowFocus: false,
        }
    );
};
