import { IAfasEmployee } from './types';
import { Cache, useApi } from '../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAfasEmployees = (candidateId: string) => {
    const api = useApi();

    return useQuery<IAfasEmployee[], any>(Cache.afasEmployeesCreateCandidates, () =>
        api.get(`/afas-employees/candidate/update/${candidateId}`).then((res) => res.data)
    );
};
