/**@jsxImportSource @emotion/react */
import { colors } from '../../../helpers';

export const useStyles = () => ({
    textField: {
        // Standard
        '& .MuiInput-underline': {
            fontSize: '1.5rem',
            color: colors.blue,
            fontFamily: 'inherit',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: colors.blue,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: colors.blue,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: `${colors.blue} !important`,
        },
        // Label
        '& .MuiInputLabel-animated': {
            fontSize: '1.5rem',
            color: colors.blue,
            fontFamily: 'inherit',
            userSelect: 'none',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: colors.blue,
        },
        // Error label
        /* '& .MuiFormLabel-root.Mui-error': {
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
        }, */
        // Error text
        /* '& .MuiFormHelperText-root': {
            fontSize:
                cs && cs.errorFontSize
                    ? cs.errorFontSize
                    : cs && cs.fontSize
                    ? `calc(${cs.fontSize} * 0.6)`
                    : '1.2rem',
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            fontWeight: 'bold',
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
            userSelect: 'none',
        },
        '& .MuiInput-underline.Mui-error:after': {
            ccolor: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
        }, */
        // Disabled
        '& .MuiFormLabel-root.Mui-disabled': {
            color: colors.blue,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: `${colors.blue} !important`,
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.blue}`,
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: colors.blue,
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: colors.blue,
        },
    },
    endAdornment: {
        paddingRight: '1rem',
    },
});
