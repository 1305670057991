/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { ComboBoxInput } from '../../../../components';

export const AfasEmployeeComboBox = (props: any) => {
    return (
        <ComboBoxInput
            {...props}
            options={props.data}
            isLoading={props.isLoading}
            getOptionLabel={(data) => {
                if (data.lastName && data.initials) {
                    return `${data.lastName}, ${data.initials} - ${data.id}`;
                } else if (data.lastName && !data.initials) {
                    return `${data.lastName} - ${data.id}`;
                } else {
                    return data.id;
                }
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};
