/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { formatNumber } from '../../../helpers';
import { IPipelineCandidate, IPipelineDepartment } from '../../../pages/dashboards/hooks';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

interface IProps {
    candidates: IPipelineCandidate[];
    maxHeight: string;
}

export const PipelineCandidatesTable = ({ candidates, maxHeight }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px, maxHeight);
    const { t, i18n } = useTranslation();

    return (
        <div css={styles.root}>
            <div css={styles.wrapper}>
                <table css={styles.table}>
                    <tbody css={styles.tableBody}>
                        <tr css={styles.firstRow}>
                            <td css={styles.firstRowFirstCell} colSpan={4}>
                                {t('text.candidates')}
                            </td>
                            <td css={styles.firstRowSecondCell} colSpan={6}>
                                {''}
                            </td>
                        </tr>
                        <tr css={styles.secondRow}>
                            <td css={styles.secondRowFirstCell}>{t('text.department')}</td>
                            <td css={styles.secondRowSecondCell}>{t('text.ambassador')}</td>
                            <td css={styles.secondRowThirdCell}>{t('text.candidate')}</td>
                            <td css={styles.secondRowFourthCell}>{t('text.status')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.dateInvited')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.dateRegistered')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.dateRejected')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.firstWorkWeek')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.totalHours')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.paidFee')}</td>
                        </tr>
                        {candidates.map((candidate, index) => (
                            <tr css={styles.candidateRow} key={index}>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowFirstCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowFirstCell
                                    }
                                >
                                    {
                                        candidate.department[
                                            i18n.language as keyof IPipelineDepartment
                                        ]
                                    }
                                </td>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowSecondCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowSecondCell
                                    }
                                >
                                    {`${candidate.ambassadorName} - ${candidate.ambassadorEmployeeNumber}`}
                                </td>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowThirdCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowThirdCell
                                    }
                                >
                                    {candidate.employeeNumber
                                        ? `${candidate.name} - ${candidate.employeeNumber}`
                                        : candidate.name}
                                </td>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowFourthCell,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowFourthCell
                                    }
                                >
                                    {t(`candidateStatus.${candidate.status}`)}
                                </td>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowDataCells,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowDataCells
                                    }
                                >
                                    {candidate.firstInvitedAt
                                        ? new Date(candidate.firstInvitedAt).toLocaleDateString()
                                        : ''}
                                </td>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowDataCells,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowDataCells
                                    }
                                >
                                    {candidate.invitationCompletedAt
                                        ? new Date(
                                              candidate.invitationCompletedAt
                                          ).toLocaleDateString()
                                        : ''}
                                </td>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowDataCells,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowDataCells
                                    }
                                >
                                    {candidate.rejectedAt
                                        ? new Date(candidate.rejectedAt).toLocaleDateString()
                                        : ''}
                                </td>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowDataCells,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowDataCells
                                    }
                                >
                                    {candidate.firstWorkedWeek && candidate.firstWorkedYear
                                        ? `W${candidate.firstWorkedWeek}-${candidate.firstWorkedYear}`
                                        : ''}
                                </td>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowDataCells,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowDataCells
                                    }
                                >
                                    {candidate.workedHours
                                        ? formatNumber(candidate.workedHours)
                                        : 0}
                                </td>
                                <td
                                    css={
                                        index === candidates.length - 1
                                            ? [
                                                  styles.candidateRowDataCells,
                                                  { borderBottom: 'none' },
                                              ]
                                            : styles.candidateRowDataCells
                                    }
                                >
                                    {`€${candidate.paidFee ? formatNumber(candidate.paidFee) : 0}`}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
