import { IUpdateAdminVariables } from './types';
import { IAdmin } from '../../../hooks/types';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useUpdateAdmin = (adminId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<IAdmin>, any, IUpdateAdminVariables>(({ body }) =>
        api.patch(`/admins/${adminId}`, body)
    );
};
