import { colors, designStyles } from '../../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        task: {
            color: colors.blue,
            userSelect: 'text',
            margin: '2rem 0',
            fontSize: '1.5rem',
            fontWeight: 'bold',
        },
        errorContainer: {
            width: '80vw',
            backgroundColor: colors.white,
            borderRadius: '0.5rem',
            overflow: 'hidden',
            boxShadow: `5px 5px 8px 5px ${colors.darkBoxShadow}`,
            padding: '1.5rem',
            color: colors.blue,
            fontFamily: 'inherit',
            fontSize: '1.25rem',
            marginBottom: '2rem',
        },
        taskSkeleton: {
            width: '40rem',
            height: '2rem',
            marginBottom: '2rem',
        },
        button: {
            width: widthBelow600px ? '30rem' : '40rem',
            marginBottom: '2rem',
        },
        errorSkeleton: {
            width: '80vw',
            height: '5rem',
            marginBottom: '2rem',
        },
    });
