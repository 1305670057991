import { IAmbassadorWorkedHour } from '../hooks/fetch-ambassador-declaration-period/types/ambassador-worked-hour.interface';

export const isAnyAmbassadorCandidateWorkedHoursByTier = (
    ambassadorWorkedHour: IAmbassadorWorkedHour,
    tier: number
): boolean => {
    return (
        ambassadorWorkedHour.ambassadorCandidateWorkedHours.filter(
            (ambassadorCandidate) => ambassadorCandidate.tier === tier
        ).length > 0
    );
};
