import * as React from 'react';
import { IRefreshTokenResponse } from './types';
import { useAuth } from '../../auth-context/AuthContext';
import axios from 'axios';
import { decodeToken } from './helpers';
import { history } from '../../routing/history';
import { routePath } from '../../routing/route-path';

export const useRefreshToken = () => {
    const { setCurrentUser } = useAuth();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (window.location.href === 'https://www.oranjelink.com/') {
            window.location.assign('https://oranjelink.com/');
        }
        const source = axios.CancelToken.source();
        axios
            .post<IRefreshTokenResponse>(
                `${process.env.REACT_APP_BACKEND_URL}/refresh-tokens`,
                {
                    refreshToken: localStorage.getItem('rtid'),
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((res) => {
                const { isStatusOk, accessToken, refreshToken } = res.data;
                if (isStatusOk) {
                    localStorage.setItem('rtid', refreshToken);
                    setCurrentUser(decodeToken(accessToken));
                    setLoading(false);
                    if (history.location.pathname === routePath.login.path) {
                        history.push(routePath.ambassadorHoursDashboard.create());
                    }
                } else {
                    setLoading(false);
                    //Beter not to redirect to login,
                    //because it couses maybe issues with the pages whitout authorization
                    //history.push(routePath.login.create());
                    localStorage.removeItem('rtid');
                    setCurrentUser(null);
                }
            })
            .catch((e) => {
                setLoading(false);
                if (axios.isCancel(e)) {
                    // Request is cancelled do nothing
                } else {
                    console.log('useRefreshToken: request is failed');
                    throw new Error(e.message);
                }
            });

        return () => {
            source.cancel('Cancelling cleanup');
        };
    }, [setCurrentUser]);

    return loading;
};
