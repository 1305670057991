/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useCreateActivityPeriod, useFetchAmbassadorInfo } from './hooks';
import {
    IRouteParams,
    Role,
    colors,
    handleError,
    handleQueryError,
    routePath,
    useAuth,
} from '../../../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Page, DatePickerInput, Button } from '../../../../../components';

export const CreateAmbassadorActivityPeriod = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles, getCurrentUser } = useAuth();
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchAmbassadorInfo(id);
    const createActivityPeriod = useCreateActivityPeriod(id);

    React.useEffect(() => {
        if (data) {
            if (
                currentUserHasRoles(Role.admin) &&
                getCurrentUser().departmentId !== data.ambassadorDepartmentId &&
                getCurrentUser().userId !== data.ambassadorAdminUserId
            ) {
                history.push(routePath.unauthorizedError.create());
            }
        }
    }, [getCurrentUser, currentUserHasRoles, data, history]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page isPageSubmitting={createActivityPeriod.isLoading} style={styles.root}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.createActivityPeriod')}</h1>
                {isLoading && (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.ambassadorSkeleton}
                    />
                )}
                {data && <h2 style={styles.contentAmbassador}>{data.ambassador}</h2>}
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            startDate: '',
                        } as IFormValues
                    }
                    validate={({ startDate }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!startDate) {
                            errors.startDate = t('form.error.requiredField');
                        } else if (!validator.isDate(startDate, { format: 'YYYY-MM-DD' })) {
                            errors.startDate = t('form.error.invalidDate');
                        } else if (new Date(startDate).getDay() !== 1) {
                            errors.startDate = t('form.error.startDateMustBeMonday');
                        } else if (
                            new Date(startDate).getTime() < new Date('2023-01-02').getTime()
                        ) {
                            errors.startDate = t('form.error.startDateEarlierThanOL');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ startDate }, { setErrors }) => {
                        try {
                            await createActivityPeriod.mutateAsync({
                                body: { startDate: new Date(startDate).toISOString() },
                            });
                            history.push(routePath.ambassadorActivityPeriods.create(id));
                        } catch (e) {
                            handleError(e, ({ status, statusText, data }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 400) {
                                    if (data.message === 'Start date is earlier than 02-01-2023') {
                                        setErrors({
                                            startDate: t('form.error.startDateEarlierThanOL'),
                                        });
                                    } else if (data.message === 'Start date must be a Monday') {
                                        setErrors({
                                            startDate: t('form.error.startDateMustBeMonday'),
                                        });
                                    } else if (data.message === 'There is an open period') {
                                        setErrors({
                                            startDate: t('form.error.openPeriod'),
                                        });
                                    } else if (
                                        data.message === 'The start date is before a closed period'
                                    ) {
                                        setErrors({
                                            startDate: t('form.error.startDateBeforeClosedPeriod'),
                                        });
                                    } else if (
                                        data.message === 'The start date overlaps a closed period'
                                    ) {
                                        setErrors({
                                            startDate: t('form.error.startDateOverlap'),
                                        });
                                    } else {
                                        alert(statusText);
                                    }
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form noValidate style={styles.contentForm}>
                            <Field
                                style={styles.formField}
                                name="startDate"
                                label={t('form.label.startDate')}
                                disabled={isSubmitting}
                                component={DatePickerInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.create')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() =>
                                    history.push(routePath.ambassadorActivityPeriods.create(id))
                                }
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Page>
    );
};
