/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { colors, useCollapsedMenu } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { IconButton, MenuItem } from '@mui/material';
import { MainMenuList, DashboardsMenuList, MenuMenuList } from './menu-lists';
import { BiMenu } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';

interface IProps {
    isPageSubmitting: boolean;
}

export const CollapsedMenu = ({ isPageSubmitting }: IProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const inputRef = React.createRef<HTMLInputElement>();
    const { isMenuOpen, menuList, setCollapsedMenuList, openCollapsedMenu, closeCollapsedMenu } =
        useCollapsedMenu();

    return (
        <React.Fragment>
            <input css={styles.input} ref={inputRef} type="checkbox" id="input" />
            <IconButton
                style={styles.button}
                size="small"
                onClick={() => {
                    if (inputRef.current!.checked) {
                        document.body.style.overflow = 'initial';
                        inputRef.current!.checked = false;
                        closeCollapsedMenu();
                    } else {
                        document.body.style.overflow = 'hidden';
                        inputRef.current!.checked = true;
                        openCollapsedMenu();
                    }
                }}
                disabled={isPageSubmitting}
            >
                {isMenuOpen ? (
                    <AiOutlineClose color={colors.buttonTextColor} size="2rem" />
                ) : (
                    <BiMenu color={colors.buttonTextColor} size="2rem" />
                )}
            </IconButton>
            <div css={styles.background} className="background">
                &nbsp;
            </div>
            <div style={styles.navBox} className="navContainer">
                {menuList === 'main' ? (
                    <MainMenuList
                        inputRef={inputRef}
                        closeCollapsedMenu={closeCollapsedMenu}
                        setCollapsedMenuList={setCollapsedMenuList}
                    />
                ) : menuList === 'dashboards' ? (
                    <DashboardsMenuList
                        inputRef={inputRef}
                        closeCollapsedMenu={closeCollapsedMenu}
                    />
                ) : (
                    <MenuMenuList inputRef={inputRef} closeCollapsedMenu={closeCollapsedMenu} />
                )}
                {menuList === 'dashboards' || menuList === 'menu' ? (
                    <MenuItem
                        style={styles.backButton}
                        onClick={() => setCollapsedMenuList('main')}
                    >
                        {`◄${t('text.back')}`}
                    </MenuItem>
                ) : null}
            </div>
        </React.Fragment>
    );
};
