/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        header: {
            margin: '2rem 0 0 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
        headerAmbassador: {
            color: colors.blue,
            userSelect: 'text',
        },
        headerCreateButton: {
            position: 'absolute',
            right: '2rem',
            backgroundColor: colors.blue,
            color: colors.white,
        },
        button: {
            width: widthBelow600px ? '25rem' : '30rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        ambassadorSkeleton: {
            marginTop: '2rem',
            width: '15rem',
            height: '2rem',
        },
    });
