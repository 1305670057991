import { ISendNewsletterVariables } from './types';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useSendNewsletter = () => {
    const api = useApi();

    return useMutation<AxiosResponse<void>, any, ISendNewsletterVariables>(({ body }) =>
        api.post('/emails/newsletter', body)
    );
};
