import * as React from 'react';
import { useStyles } from './styles';
import { useMediaQuery, Skeleton } from '@mui/material';

export const CardViewSkeleton = () => {
    const widthBelow800px = useMediaQuery('(max-width: 800px)');
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow800px, widthBelow600px);

    return (
        <React.Fragment>
            <Skeleton variant="rectangular" animation="wave" style={styles.searchSkeleton} />
            <div style={styles.content}>
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
                <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />
            </div>
        </React.Fragment>
    );
};
