import { IChangeEmailVariables } from './types';
import { IAmbassador } from '../../../hooks';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useChangeEmail = (adminId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<IAmbassador>, any, IChangeEmailVariables>(({ body }) =>
        api.patch(`/ambassadors/${adminId}/email`, body)
    );
};
