import { IDeleteAmbassadorVariables } from './types';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useDeleteAmbassador = () => {
    const api = useApi();

    return useMutation<AxiosResponse<string>, any, IDeleteAmbassadorVariables>(({ ambassadorId }) =>
        api.delete(`/ambassadors/${ambassadorId}`)
    );
};
