/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { ICandidateActivityPeriod } from '../../hooks';
import { routePath } from '../../../../helpers';
import { IPayload } from '../../../../helpers/reducers/delete/types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    CandidateActivityPeriodTable,
    ICandiateActivityPeriodTableData,
} from '../../../../components';
import { ActionCell } from './action-cell/ActionCell';

interface IProps {
    data: ICandidateActivityPeriod[];
    openDelete: (payload: IPayload) => void;
}

export const ActivityPeriods = ({ data, openDelete }: IProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <CandidateActivityPeriodTable
            title={t('text.activityPeriods')}
            data={data.map((period) => {
                return {
                    id: period.id,
                    serialNumber: period.serialNumber,
                    empolyeeNumber: period.candidateEmployeeNumber,
                    startDate: period.startDate,
                    endDate: period.endDate ? period.endDate : undefined,
                };
            })}
            context={{
                handleClosePeriod: (data: ICandiateActivityPeriodTableData) =>
                    history.push(routePath.closeCandidateActivityPeriod.create(data.id)),
                handleDeletePeriod: (data: ICandiateActivityPeriodTableData) =>
                    openDelete({
                        itemToDeleteId: data.id,
                        itemToDeleteName: '',
                    }),
            }}
            actionCell={ActionCell}
        />
    );
};
