/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import {
    useFetchCandidate,
    useFetchAfasEmployees,
    useUpdateCandidate,
    useFetchDepartments,
    IAfasEmployee,
} from './hooks';
import { IDepartment } from '../hooks';
import {
    handleError,
    handleQueryError,
    IRouteParams,
    isoDateStringToDatePicker,
    useAuth,
    Role,
    routePath,
    colors,
    isValidPhoneNumber,
} from '../../../helpers';
import { getEmployeeNumber, hasOpenPeriod } from './helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikErrors } from 'formik';
import validator from 'validator';
import { useMediaQuery } from '@mui/material';
import {
    Page,
    YesNoModal,
    TextFieldInput,
    DatePickerInput,
    Button,
    SelectInput,
    PhoneFieldInput,
    LanguageSelectInput,
} from '../../../components';
import { BiEnvelope } from 'react-icons/bi';
import { SkeletonForm } from './skeleton-form/SkeletonForm';
import { AfasEmployeeComboBox } from './afas-employee-combo-box/AfasEmployeeComboBox';
import { ProfessionComboBox } from '../profession-combo-box/ProfessionComboBox';

export const UpdateCandidate = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles, getCurrentUser } = useAuth();
    const history = useHistory();
    const { id } = useParams<IRouteParams>();
    const { t, i18n } = useTranslation();
    //const [isChangeAppliedByOpen, setChangeAppliedByOpen] = React.useState(false);
    const [isChangeEmployeeNumberOpen, setChangeEmployeeNumberOpen] = React.useState(false);

    const candidateResponse = useFetchCandidate(id);
    const afasEmployeesResponse = useFetchAfasEmployees(id, currentUserHasRoles(Role.superAdmin));
    const departmentsResponse = useFetchDepartments(currentUserHasRoles(Role.superAdmin));
    const updateCandidate = useUpdateCandidate(id);

    React.useEffect(() => {
        if (candidateResponse.data) {
            if (
                currentUserHasRoles(Role.admin) &&
                ((candidateResponse.data.ambassador &&
                    candidateResponse.data.ambassador.admin.user.id !== getCurrentUser().userId &&
                    candidateResponse.data.user.department!.id !== getCurrentUser().departmentId) ||
                    (candidateResponse.data.recruitmentAgency &&
                        candidateResponse.data.recruitmentAgency.admin.user.id !==
                            getCurrentUser().userId))
            ) {
                history.push(routePath.unauthorizedError.create());
            } else if (
                currentUserHasRoles(Role.ambassador) &&
                candidateResponse.data.ambassador &&
                candidateResponse.data.ambassador.user.id !== getCurrentUser().userId
            ) {
                history.push(routePath.unauthorizedError.create());
            } else if (
                currentUserHasRoles(Role.recruitmentAgency) &&
                candidateResponse.data.recruitmentAgency &&
                candidateResponse.data.recruitmentAgency.user.id !== getCurrentUser().userId
            ) {
                history.push(routePath.unauthorizedError.create());
            }
        }
    }, [currentUserHasRoles, getCurrentUser, history, candidateResponse.data]);

    const getAfasEmployee = React.useCallback((): IAfasEmployee | null => {
        if (
            currentUserHasRoles(Role.superAdmin) &&
            candidateResponse.data &&
            candidateResponse.data.employeeNumber &&
            afasEmployeesResponse.data
        ) {
            const filter = afasEmployeesResponse.data.filter(
                (afasEmployee) => afasEmployee.id.trim() === candidateResponse.data.employeeNumber
            );
            return filter.length > 0 ? filter[0] : null;
        }
        return null;
    }, [candidateResponse.data, afasEmployeesResponse.data, currentUserHasRoles]);

    if (candidateResponse.error) {
        return handleQueryError(candidateResponse.error, history);
    }
    if (afasEmployeesResponse.error) {
        return handleQueryError(afasEmployeesResponse.error, history);
    }
    if (departmentsResponse.error) {
        return handleQueryError(afasEmployeesResponse.error, history);
    }
    return (
        <Page
            isPageSubmitting={updateCandidate.isLoading}
            title={t('text.updateCandidate')}
            style={styles.root}
        >
            {candidateResponse.isLoading ||
            afasEmployeesResponse.isLoading ||
            departmentsResponse.isLoading ? (
                <SkeletonForm />
            ) : null}
            {(currentUserHasRoles(
                Role.ambassador,
                Role.recruitmentAgency,
                Role.admin,
                Role.recruiter
            ) &&
                candidateResponse.data) ||
            (currentUserHasRoles(Role.superAdmin) &&
                candidateResponse.data &&
                afasEmployeesResponse.data &&
                departmentsResponse.data) ? (
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            firstName: candidateResponse.data.user.firstName,
                            lastName: candidateResponse.data.user.lastName,
                            afasEmployee: getAfasEmployee(),
                            email: candidateResponse.data.user.email,
                            confirmEmail: candidateResponse.data.user.email,
                            birthdate: candidateResponse.data.user.birthdate
                                ? isoDateStringToDatePicker(candidateResponse.data.user.birthdate)
                                : '',
                            phoneNumber: candidateResponse.data.user.phoneNumber
                                ? candidateResponse.data.user.phoneNumber
                                : '',
                            language: candidateResponse.data.user.language,
                            professions: candidateResponse.data.professions,
                            department: candidateResponse.data.user.department!.id,
                        } as IFormValues
                    }
                    validate={({
                        firstName,
                        lastName,
                        email,
                        confirmEmail,
                        afasEmployee,
                        birthdate,
                        phoneNumber,
                        language,
                        professions,
                    }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!firstName.trim()) errors.firstName = t('form.error.requiredField');
                        if (!lastName.trim()) errors.lastName = t('form.error.requiredField');
                        if (!email) {
                            errors.email = t('form.error.requiredField');
                        } else if (email.trim().length > 127) {
                            errors.email = t('form.error.max127Chars');
                        } else if (!validator.isEmail(email.trim())) {
                            errors.email = t('form.error.invalidEmail');
                        }
                        if (!confirmEmail.trim())
                            errors.confirmEmail = t('form.error.requiredField');
                        else if (confirmEmail.trim().length > 127) {
                            errors.confirmEmail = t('form.error.max127Chars');
                        } else if (!validator.isEmail(confirmEmail.trim())) {
                            errors.confirmEmail = t('form.error.invalidEmail');
                        } else if (email.trim() !== confirmEmail.trim()) {
                            errors.email = t('form.error.valuesDoNotMatch');
                            errors.confirmEmail = t('form.error.valuesDoNotMatch');
                        }
                        if (birthdate && !validator.isDate(birthdate, { format: 'YYYY-MM-DD' })) {
                            errors.birthdate = t('form.error.invalidDate');
                        } else if (
                            birthdate &&
                            new Date().getFullYear() - new Date(birthdate).getFullYear() < 16
                        ) {
                            errors.birthdate = t('form.error.youngerThen16');
                        } else if (
                            birthdate &&
                            new Date().getFullYear() - new Date(birthdate).getFullYear() > 70
                        ) {
                            errors.birthdate = t('form.error.olderThen70');
                        }
                        if (phoneNumber && !isValidPhoneNumber(phoneNumber.trim())) {
                            errors.phoneNumber = t('form.error.invalidPhoneNumber');
                        }
                        if (!language) errors.language = t('form.error.requiredField');
                        if (professions.length <= 0) {
                            errors.professions = t('form.error.minOneProfession');
                        }
                        if (candidateResponse.data.employeeNumber && !afasEmployee) {
                            errors.afasEmployee = t('form.error.requiredField');
                        } else if (!candidateResponse.data.employeeNumber && afasEmployee) {
                            errors.afasEmployee = t('from.error.canBeConnectedViaAfasLink');
                        }
                        return errors;
                    }}
                    onSubmit={async (
                        {
                            firstName,
                            lastName,
                            afasEmployee,
                            email,
                            birthdate,
                            phoneNumber,
                            language,
                            professions,
                            department,
                        },
                        { setErrors }
                    ) => {
                        try {
                            await updateCandidate.mutateAsync({
                                body: {
                                    firstName: firstName.trim(),
                                    lastName: lastName.trim(),
                                    employeeNumber: getEmployeeNumber(
                                        currentUserHasRoles(Role.superAdmin),
                                        afasEmployee,
                                        candidateResponse.data.employeeNumber
                                    ),
                                    email: email.trim(),
                                    birthdate: birthdate
                                        ? new Date(birthdate).toISOString()
                                        : undefined,
                                    phoneNumber: phoneNumber ? phoneNumber.trim() : undefined,
                                    language,
                                    professions,
                                    departmentId: currentUserHasRoles(Role.superAdmin)
                                        ? department
                                        : candidateResponse.data.user.department!.id,
                                },
                            });
                            history.push(routePath.candidates.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText, data }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 400) {
                                    if (data.message === 'Employee number is already registered') {
                                        setErrors({
                                            afasEmployee: t('form.error.candidateIsRegistered'),
                                        });
                                    } else if (data.message === 'Email is already registered') {
                                        setErrors({
                                            email: t('form.error.emailIsRegistered'),
                                            confirmEmail: t('form.error.emailIsRegistered'),
                                        });
                                    } else if (
                                        data.message === 'Phone number is already registered'
                                    ) {
                                        setErrors({
                                            phoneNumber: t('form.error.phoneNumberIsRegistered'),
                                        });
                                    } else if (data.message === 'No employer number') {
                                        setErrors({
                                            afasEmployee: t('from.error.canBeConnectedViaAfasLink'),
                                        });
                                    } else {
                                        alert(statusText);
                                    }
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting, submitForm, initialValues, values }) => (
                        <Form noValidate style={styles.form}>
                            {isChangeEmployeeNumberOpen ? (
                                <YesNoModal
                                    open={isChangeEmployeeNumberOpen}
                                    title={t('text.changeEmployeeNumber')}
                                    question={t('text.changeEmployeeNumberQuestion')}
                                    isSubmitting={isSubmitting}
                                    closeModal={() => setChangeEmployeeNumberOpen(false)}
                                    handleYesClick={submitForm}
                                />
                            ) : null}
                            <Field
                                style={styles.formField}
                                name="firstName"
                                type="text"
                                label={t('form.label.firstName')}
                                placeholder={t('form.placeholder.firstName')}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="lastName"
                                type="text"
                                label={t('form.label.lastName')}
                                placeholder={t('form.placeholder.lastName')}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="email"
                                type="email"
                                label={t('form.label.email')}
                                placeholder={t('form.placeholder.email')}
                                icon={<BiEnvelope />}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="confirmEmail"
                                type="email"
                                label={t('form.label.confirmEmail')}
                                placeholder={t('form.placeholder.confirmEmail')}
                                icon={<BiEnvelope />}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            {currentUserHasRoles(Role.superAdmin) &&
                            candidateResponse.data.employeeNumber ? (
                                <Field
                                    style={styles.formField}
                                    data={afasEmployeesResponse.data}
                                    isLoading={afasEmployeesResponse.isLoading}
                                    name="afasEmployee"
                                    label={t('form.label.employeeNumber')}
                                    placeholder={t('form.placeholder.employeeNumber')}
                                    fontSize="2rem"
                                    inputColor={colors.blue}
                                    textColor={colors.blue}
                                    disabled={isSubmitting}
                                    component={AfasEmployeeComboBox}
                                />
                            ) : null}
                            <Field
                                style={styles.formField}
                                name="birthdate"
                                label={t('form.label.birthdate')}
                                disabled={isSubmitting}
                                component={DatePickerInput}
                            />
                            <Field
                                style={styles.formField}
                                name="phoneNumber"
                                type="tel"
                                label={t('form.label.phoneNumber')}
                                placeholder={t('form.placeholder.phoneNumber')}
                                disabled={isSubmitting}
                                component={PhoneFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="language"
                                label={t('form.label.language')}
                                disabled={isSubmitting}
                                component={LanguageSelectInput}
                            />
                            <Field
                                style={styles.formField}
                                name="professions"
                                label={t('form.label.professions')}
                                placeholder={t('form.placeholder.professions')}
                                fontSize="2rem"
                                inputColor={colors.blue}
                                disabled={isSubmitting}
                                component={ProfessionComboBox}
                            />
                            {currentUserHasRoles(Role.superAdmin) && departmentsResponse.data ? (
                                <Field
                                    style={styles.formField}
                                    name="department"
                                    label={t('form.label.department')}
                                    items={departmentsResponse.data.map((department) => {
                                        return {
                                            value: department.id,
                                            item: department[i18n.language as keyof IDepartment],
                                        };
                                    })}
                                    disabled={isSubmitting}
                                    component={SelectInput}
                                />
                            ) : null}
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{ color: colors.blue }}
                                onClick={async () => {
                                    if (
                                        hasOpenPeriod(candidateResponse.data.activityPeriods) &&
                                        initialValues.afasEmployee &&
                                        values.afasEmployee &&
                                        initialValues.afasEmployee.id.trim() !==
                                            values.afasEmployee.id.trim()
                                    ) {
                                        setChangeEmployeeNumberOpen(true);
                                    } else {
                                        await submitForm();
                                    }
                                }}
                            >
                                {t('text.update')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.goBack()}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </Page>
    );
};
