/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { routePath } from '../../../../helpers';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const DeclarationsMenuItem = () => {
    const styles = useStyles();
    const client = useQueryClient();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <button
                css={styles.menuItem}
                onClick={(event) => {
                    event.preventDefault();
                    client.cancelQueries();
                    history.push(routePath.ambassadorDeclarations.create());
                }}
            >
                {t('text.declarations')}
            </button>
        </React.Fragment>
    );
};
