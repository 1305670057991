import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useApi } from '../../../../../helpers';

export const useSyncAmbassadorDeclarationPeriods = () => {
    const api = useApi();

    return useMutation<AxiosResponse<void>, any>(() =>
        api.post('/tasks/ambassador-declaration-periods/sync')
    );
};
