/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchDepartments, IDepartment } from '../hooks';
import { useCreateCandidate } from './hooks';
import {
    handleError,
    routePath,
    colors,
    isValidPhoneNumber,
    handleQueryError,
    Language,
} from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikErrors } from 'formik';
import validator from 'validator';
import {
    Page,
    TextFieldInput,
    PhoneFieldInput,
    DatePickerInput,
    SelectInput,
    Button,
    LanguageSelectInput,
} from '../../../components';
import { useMediaQuery, Skeleton } from '@mui/material';
import { BiEnvelope } from 'react-icons/bi';
import { AfasEmployeeComboBox } from '../afas-employee-combo-box/AfasEmployeeComboBox';
import { AppliedByComboBox } from '../applied-by-combo-box/AppliedByComboBox';
import { ProfessionComboBox } from '../profession-combo-box/ProfessionComboBox';

export const CreateCandidate = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const { isLoading, data, error } = useFetchDepartments();
    const createCandidate = useCreateCandidate();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            isPageSubmitting={createCandidate.isLoading}
            title={t('text.createCandidate')}
            style={styles.root}
        >
            {isLoading ? (
                <div style={styles.form}>
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            ) : null}
            {data ? (
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            firstName: '',
                            lastName: '',
                            afasEmployee: null,
                            email: '',
                            confirmEmail: '',
                            birthdate: '',
                            phoneNumber: '',
                            language: '' as Language,
                            appliedBy: null,
                            professions: [],
                            department: '',
                        } as IFormValues
                    }
                    validate={({
                        firstName,
                        lastName,
                        email,
                        confirmEmail,
                        birthdate,
                        phoneNumber,
                        language,
                        appliedBy,
                        professions,
                        department,
                    }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!firstName.trim()) errors.firstName = t('form.error.requiredField');
                        if (!lastName.trim()) errors.lastName = t('form.error.requiredField');
                        if (!email) {
                            errors.email = t('form.error.requiredField');
                        } else if (email.trim().length > 127) {
                            errors.email = t('form.error.max127Chars');
                        } else if (!validator.isEmail(email.trim())) {
                            errors.email = t('form.error.invalidEmail');
                        }
                        if (!confirmEmail.trim())
                            errors.confirmEmail = t('form.error.requiredField');
                        else if (confirmEmail.trim().length > 127) {
                            errors.confirmEmail = t('form.error.max127Chars');
                        } else if (!validator.isEmail(confirmEmail.trim())) {
                            errors.confirmEmail = t('form.error.invalidEmail');
                        } else if (email.trim() !== confirmEmail.trim()) {
                            errors.email = t('form.error.valuesDoNotMatch');
                            errors.confirmEmail = t('form.error.valuesDoNotMatch');
                        }
                        if (!birthdate) {
                            errors.birthdate = t('form.error.requiredField');
                        } else if (!validator.isDate(birthdate, { format: 'YYYY-MM-DD' })) {
                            errors.birthdate = t('form.error.invalidDate');
                        } else if (
                            new Date().getFullYear() - new Date(birthdate).getFullYear() <
                            16
                        ) {
                            errors.birthdate = t('form.error.youngerThen16');
                        } else if (
                            new Date().getFullYear() - new Date(birthdate).getFullYear() >
                            70
                        ) {
                            errors.birthdate = t('form.error.olderThen70');
                        }
                        if (!phoneNumber) {
                            errors.phoneNumber = t('form.error.requiredField');
                        } else if (!isValidPhoneNumber(phoneNumber.trim())) {
                            errors.phoneNumber = t('form.error.invalidPhoneNumber');
                        }
                        if (!language) errors.language = t('form.error.requiredField');
                        if (!appliedBy) {
                            errors.appliedBy = t('form.error.requiredField');
                        }
                        if (appliedBy && appliedBy.role === 'recruitmentAgency' && !department) {
                            errors.department = t('form.error.requiredField');
                        }
                        if (professions.length <= 0) {
                            errors.professions = t('form.error.minOneProfession');
                        }
                        return errors;
                    }}
                    onSubmit={async (
                        {
                            firstName,
                            lastName,
                            afasEmployee,
                            email,
                            birthdate,
                            phoneNumber,
                            language,
                            appliedBy,
                            professions,
                            department,
                        },
                        { setErrors }
                    ) => {
                        try {
                            await createCandidate.mutateAsync({
                                body: {
                                    firstName: firstName.trim(),
                                    lastName: lastName.trim(),
                                    employeeNumber: afasEmployee
                                        ? afasEmployee.id.trim()
                                        : undefined,
                                    email: email.trim(),
                                    birthdate: new Date(birthdate).toISOString(),
                                    phoneNumber,
                                    language,
                                    ambassadorId:
                                        appliedBy!.role === 'ambassador'
                                            ? appliedBy!.id
                                            : undefined,
                                    recruitmentAgencyId:
                                        appliedBy!.role === 'recruitmentAgency'
                                            ? appliedBy!.id
                                            : undefined,
                                    professions,
                                    departmentId:
                                        appliedBy!.role === 'ambassador'
                                            ? appliedBy!.departmentId!
                                            : department,
                                },
                            });
                            history.push(routePath.candidates.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText, data }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 400) {
                                    if (data.message === 'Employee number is already registered') {
                                        setErrors({
                                            afasEmployee: t('form.error.candidateIsRegistered'),
                                        });
                                    } else if (data.message === 'Email is already registered') {
                                        setErrors({
                                            email: t('form.error.emailIsRegistered'),
                                            confirmEmail: t('form.error.emailIsRegistered'),
                                        });
                                    } else if (
                                        data.message === 'Phone number is already registered'
                                    ) {
                                        setErrors({
                                            phoneNumber: t('form.error.phoneNumberIsRegistered'),
                                        });
                                    } else if (data.message === 'Contract is not signed') {
                                        setErrors({
                                            appliedBy:
                                                appliedBy!.role === 'ambassador'
                                                    ? t('form.error.ambassadorAddendumIsNotSigned')
                                                    : t(
                                                          'form.error.recruitmentAgencyContractIsNotSigned'
                                                      ),
                                        });
                                    } else {
                                        alert(statusText);
                                    }
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting, values }) => (
                        <Form noValidate style={styles.form}>
                            <Field
                                style={styles.formField}
                                name="firstName"
                                type="text"
                                label={t('form.label.firstName')}
                                placeholder={t('form.placeholder.firstName')}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="lastName"
                                type="text"
                                label={t('form.label.lastName')}
                                placeholder={t('form.placeholder.lastName')}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="email"
                                type="email"
                                label={t('form.label.email')}
                                placeholder={t('form.placeholder.email')}
                                icon={<BiEnvelope />}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="confirmEmail"
                                type="email"
                                label={t('form.label.confirmEmail')}
                                placeholder={t('form.placeholder.confirmEmail')}
                                icon={<BiEnvelope />}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="afasEmployee"
                                label={t('form.label.employeeNumber')}
                                placeholder={t('form.placeholder.employeeNumber')}
                                fontSize="2rem"
                                inputColor={colors.blue}
                                textColor={colors.blue}
                                disabled={isSubmitting}
                                component={AfasEmployeeComboBox}
                            />
                            <Field
                                style={styles.formField}
                                name="birthdate"
                                label={t('form.label.birthdate')}
                                disabled={isSubmitting}
                                component={DatePickerInput}
                            />
                            <Field
                                style={styles.formField}
                                name="phoneNumber"
                                type="tel"
                                label={t('form.label.phoneNumber')}
                                placeholder={t('form.placeholder.phoneNumber')}
                                disabled={isSubmitting}
                                component={PhoneFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="language"
                                label={t('form.label.language')}
                                disabled={isSubmitting}
                                component={LanguageSelectInput}
                            />
                            <Field
                                style={styles.formField}
                                name="appliedBy"
                                label={t('form.label.appliedBy')}
                                placeholder={t('form.placeholder.appliedBy')}
                                fontSize="2rem"
                                inputColor={colors.blue}
                                textColor={colors.blue}
                                disabled={isSubmitting}
                                component={AppliedByComboBox}
                            />
                            {values.appliedBy && values.appliedBy.role === 'recruitmentAgency' ? (
                                <Field
                                    style={styles.formField}
                                    name="department"
                                    label={t('form.label.department')}
                                    items={data.map((department) => {
                                        return {
                                            value: department.id,
                                            item: department[i18n.language as keyof IDepartment],
                                        };
                                    })}
                                    disabled={isSubmitting}
                                    component={SelectInput}
                                />
                            ) : null}
                            <Field
                                style={styles.formField}
                                name="professions"
                                label={t('form.label.professions')}
                                placeholder={t('form.placeholder.professions')}
                                fontSize="2rem"
                                inputColor={colors.blue}
                                disabled={isSubmitting}
                                component={ProfessionComboBox}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.create')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.goBack()}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </Page>
    );
};
