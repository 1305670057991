/**@jsxImportSource @emotion/react */
import { colors, designStyles } from '../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        content: {
            width: '50%',
            color: colors.blue,
            fontFamily: 'inherit',
            userSelect: 'none',
        },
        prolog: {
            marginTop: '4rem',
        },
        prologTitle: {
            fontSize: '1.8rem',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
        },
        prologSubTitle: {
            marginTop: '1rem',
            marginLeft: '1rem',
            fontSize: '1.6rem',
            fontWeight: 'bold',
        },
        prologText: {
            margin: '0.5rem 0',
            marginLeft: '4rem',
            fontSize: '1.6rem',
            fontStyle: 'italic',
        },
        section: {
            marginTop: '4rem',
        },
        sectionTitle: {
            marginBottom: '2rem',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        sectionGeneralInfo: {
            marginBottom: '2rem',
            fontSize: '1.6rem',
            fontStyle: 'italic',
        },
        title: {
            fontSize: '1.6rem',
            fontWeight: 'bold',
        },
        text: {
            marginTop: '1rem',
            marginBottom: '2rem',
            marginLeft: '2rem',
            fontSize: '1.6rem',
            fontStyle: 'italic',
        },
        textImageContainer: {
            float: 'right',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0 0 0.5rem 1rem',
        },
        textImage: {
            height: '6rem',
            width: '6rem',
        },
        textImageCaption: {
            marginTop: '0.5rem',
            fontSize: '1rem',
        },
        blockImageContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        blockImageFrame: {
            position: 'relative',
            '&:hover': {
                '& > img': {
                    filter: 'blur(4px) brightness(50%)',
                },
                '& > div': {
                    color: `${colors.white} !important`,
                },
            },
        },
        blockImageOverlay: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            color: 'transparent',
            fontSize: '2rem',
            userSelect: 'none',
            transition: 'all 0.6s',
        },
        blockImageOverlayContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        blockImageOverlayContentText: {
            marginBottom: '1rem',
        },
        blockImage: {
            width: '100%',
            transition: 'all 0.6s',
        },
        blockImageCaption: {
            marginTop: '1rem',
            marginBottom: '2rem',
            fontSize: '1.2rem',
            textAlign: 'center',
        },
        backButtonContainer: {
            margin: '2rem 0',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        backButton: {
            width: '15rem',
        },
    });
