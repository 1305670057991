/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IPipelineOranjegroepData } from './types';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { formatNumber } from '../../../helpers';

interface IProps {
    data: IPipelineOranjegroepData;
}

export const PipelineOranjegroepTable = ({ data }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { t } = useTranslation();

    return (
        <div css={styles.root}>
            <div css={styles.wrapper}>
                <table css={styles.table}>
                    <tbody css={styles.tableBody}>
                        <tr css={styles.firstRow}>
                            <td css={styles.firstRowFirstCell}>Oranjegroep</td>
                            <td css={styles.firstRowSecondCell} colSpan={5}>
                                {`${t('text.tier')} 1`}
                            </td>
                            <td css={styles.firstRowThirdCell}>{`${t('text.tier')} 2`}</td>
                            <td css={styles.firstRowFourthCell}>{`${t('text.tier')} 3`}</td>
                            <td css={styles.firstRowFifthCell}>
                                {`${t('text.tier')} 1 - ${t('text.tier')} 3`}
                            </td>
                        </tr>
                        <tr css={styles.secondRow}>
                            <td css={styles.secondRowFirstCell}>{t('text.ambassadors')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.candidates')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.invited')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.registered')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.rejected')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.totalHours')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.totalHours')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.totalHours')}</td>
                            <td css={styles.secondRowDataCells}>{t('text.paidFee')}</td>
                        </tr>
                        <tr css={styles.thirdRow}>
                            <td css={styles.thirdRowFirstCell}>{data.ambassadorAmount}</td>
                            <td css={styles.thirdRowDataCells}>{data.workedCandidateAmount}</td>
                            <td css={styles.thirdRowDataCells}>{data.invitationAmount}</td>
                            <td css={styles.thirdRowDataCells}>{data.registrationAmount}</td>
                            <td css={styles.thirdRowDataCells}>{data.rejectedAmount}</td>
                            <td css={styles.thirdRowDataCells}>
                                {formatNumber(data.firstTierCandidateHours)}
                            </td>
                            <td css={styles.thirdRowDataCells}>
                                {formatNumber(data.secondTierCandidateHours)}
                            </td>
                            <td css={styles.thirdRowDataCells}>
                                {formatNumber(data.thirdTierCandidateHours)}
                            </td>
                            <td css={styles.thirdRowDataCells}>{formatNumber(data.paidFee)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
