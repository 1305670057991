import { useQuery } from 'react-query';
import { Cache, useApi } from '../../../../../../helpers';
import { ITask } from './types';

export const useFetchTask = (taskId: string) => {
    const api = useApi();

    return useQuery<ITask, any>([Cache.tasks, taskId], () => {
        return api.get(`/tasks/${taskId}`).then((res) => res.data);
    });
};
