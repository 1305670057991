/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { ICustomStyle } from './types';
import { colors } from '../../../helpers';

export const useStyles = (cs?: ICustomStyle) => ({
    root: css({
        padding: '0.5rem',
        display: 'flex',
        alignContent: 'center',
        borderRadius: '1rem',
        boxShadow: cs && cs.boxShadow ? cs.boxShadow : `5px 5px 8px 5px ${colors.darkBoxShadow}`,
        background: colors.white,
    }),
    inputBase: css({
        padding: cs && cs.inputPadding ? cs.inputPadding : '0 1rem 0 1rem',
        '& .MuiInputBase-input': {
            fontSize: cs && cs.fontSize ? cs.fontSize : '1.5rem',
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            color: cs && cs.color ? cs.color : colors.blue,
            width: cs && cs.width ? cs.width : '20rem',
        },
    }),
    searchIcon: css({
        fontSize: cs && cs.fontSize ? `calc(1.4 * ${cs.fontSize})` : '2.1rem',
        color: cs && cs.color ? cs.color : colors.blue,
    }),
    closeIconHidden: css({
        visibility: 'hidden',
        fontSize: cs && cs.fontSize ? cs.fontSize : '1.5rem',
        color: cs && cs.color ? cs.color : colors.blue,
    }),
    closeIconVisible: css({
        visibility: 'visible',
        fontSize: cs && cs.fontSize ? cs.fontSize : '1.5rem',
        color: cs && cs.color ? cs.color : colors.blue,
    }),
});
