/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        filter: {
            width: '90vw',
            marginTop: '4rem',
            display: 'flex',
            justifyContent: 'center',
        },
        filterNumberSelect: {
            width: '11rem',
        },
        oranjegroepGridSkeleton: {
            marginTop: '4rem',
            width: '90vW',
            height: '8vh',
        },
        gridSkeleton: {
            marginTop: '4rem',
            width: '90vW',
            height: '22.5vh',
        },
        noDataContainer: {
            marginTop: '4rem',
        },
        noData: {
            color: colors.blue,
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '80%',
            textAlign: 'center',
            transform: 'translate(-50%, -50%)',
            userSelect: 'none',
            backgroundColor: colors.white,
            padding: '10rem 5rem',
            borderRadius: '0.5rem',
            boxShadow: `5px 5px 8px 5px ${colors.darkBoxShadow}`,
            fontSize: '2rem',
            fontStyle: 'italic',
        },
    });
