/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchCandidates } from '../hooks';
import { handleQueryError } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components';
import { Grid } from './grid/Grid';
import { GridSkeleton } from './grid-skeleton/GridSkeleton';

export const CandidatesDashboard = () => {
    const styles = useStyles();
    const history = useHistory();
    const { i18n } = useTranslation();

    const { data, isLoading, error } = useFetchCandidates();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page style={styles.root}>
            {isLoading && <GridSkeleton />}
            {data && <Grid data={data} lng={i18n.language} />}
        </Page>
    );
};
