/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchActivityPeriod, useCloseActivityPeriod } from './hooks';
import {
    IRouteParams,
    colors,
    handleError,
    handleQueryError,
    isoDateStringToDatePicker,
    routePath,
} from '../../../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Page, DatePickerInput, Button } from '../../../../../components';

export const CloseAmbassadorActivityPeriod = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchActivityPeriod(id);
    const updateEndDate = useCloseActivityPeriod(id);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page isPageSubmitting={updateEndDate.isLoading} style={styles.root}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.closeActivityPeriod')}</h1>
                {isLoading ? (
                    <React.Fragment>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.ambassadorSkeleton}
                        />
                        <div style={styles.contentForm}>
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                style={styles.fieldSkeleton}
                            />
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                css={styles.buttonSkeleton}
                            />
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                css={styles.buttonSkeleton}
                            />
                        </div>
                    </React.Fragment>
                ) : null}
                {data ? (
                    <React.Fragment>
                        <h2 style={styles.contentAmbassador}>{data.ambassador}</h2>
                        <Formik
                            enableReinitialize
                            initialValues={
                                {
                                    endDate: data.ambassadorActivityPeriod.endDate
                                        ? isoDateStringToDatePicker(
                                              data.ambassadorActivityPeriod.startDate
                                          )
                                        : '',
                                } as IFormValues
                            }
                            validate={({ endDate }) => {
                                let errors: FormikErrors<IFormValues> = {};
                                if (!validator.isDate(endDate, { format: 'YYYY-MM-DD' })) {
                                    errors.endDate = t('form.error.invalidDate');
                                } else if (
                                    new Date(endDate).getTime() <
                                    new Date(data.ambassadorActivityPeriod.startDate).getTime()
                                ) {
                                    errors.endDate = t('form.error.endDateIsEarlierThanStartDate');
                                } else if (data.ambassadorActivityPeriod.endDate) {
                                    errors.endDate = t('form.error.periodAlreadyClosed');
                                } else if (new Date(endDate).getTime() < new Date().getTime()) {
                                    errors.endDate = t('form.error.endDateMustBeInFuture');
                                } else if (new Date(endDate).getDay() !== 0) {
                                    errors.endDate = t('form.error.endDateMustBeSunday');
                                }
                                return errors;
                            }}
                            onSubmit={async ({ endDate }, { setErrors }) => {
                                try {
                                    await updateEndDate.mutateAsync({
                                        body: {
                                            ambassadorId: data.id,
                                            endDate: new Date(endDate).toISOString(),
                                        },
                                    });
                                    history.push(
                                        routePath.ambassadorActivityPeriods.create(data.id)
                                    );
                                } catch (e) {
                                    handleError(e, ({ status, statusText, data }) => {
                                        if (status === 401 || status === 403) {
                                            history.push(routePath.unauthorizedError.create());
                                        } else if (status === 404) {
                                            history.push(routePath.notFoundError.create());
                                        } else if (status === 400) {
                                            if (
                                                data.message ===
                                                'End date is earlier than the start date'
                                            ) {
                                                setErrors({
                                                    endDate: t(
                                                        'form.error.endDateIsEarlierThanStartDate'
                                                    ),
                                                });
                                            } else if (
                                                data.message === 'Period is already closed'
                                            ) {
                                                setErrors({
                                                    endDate: t('form.error.periodAlreadyClosed'),
                                                });
                                            } else if (
                                                data.message === 'End date must be a Sunday'
                                            ) {
                                                setErrors({
                                                    endDate: t('form.error.endDateMustBeSunday'),
                                                });
                                            } else if (data.message === 'There is an open period') {
                                                setErrors({
                                                    endDate: t('form.error.openPeriod'),
                                                });
                                            } else if (
                                                data.message ===
                                                'The end date overlaps another period'
                                            ) {
                                                setErrors({
                                                    endDate: t('form.error.endDateOverlap'),
                                                });
                                            } else if (
                                                data.message === 'Placement cannot be updated'
                                            ) {
                                                setErrors({
                                                    endDate: t(
                                                        'form.error.afasPlacementCannotBeUpdated'
                                                    ),
                                                });
                                            } else if (
                                                (data.message as string).includes(
                                                    'The end date is eerlier than the last processed preriod: '
                                                )
                                            ) {
                                                setErrors({
                                                    endDate: `${t(
                                                        'form.error.endDateEerlierThanTheLastProcessedPeriod'
                                                    )} ${(data.message as string)
                                                        .split(':')[1]
                                                        .trim()}!`,
                                                });
                                            } else {
                                                alert(statusText);
                                            }
                                        } else if (status === 500) {
                                            history.push(routePath.internalServerError.create());
                                        } else alert(statusText);
                                    });
                                }
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form noValidate style={styles.contentForm}>
                                    <Field
                                        style={styles.formField}
                                        name="endDate"
                                        label={t('form.label.endDate')}
                                        disabled={isSubmitting}
                                        component={DatePickerInput}
                                    />
                                    <Button
                                        css={styles.formButton}
                                        type="submit"
                                        disabled={isSubmitting}
                                        spinner={isSubmitting}
                                        cs={{
                                            color: colors.blue,
                                        }}
                                    >
                                        {t('text.update')}
                                    </Button>
                                    <Button
                                        css={styles.formButton}
                                        type="button"
                                        disabled={isSubmitting}
                                        spinner={isSubmitting}
                                        cs={{
                                            color: colors.blue,
                                        }}
                                        onClick={() =>
                                            history.push(
                                                routePath.ambassadorActivityPeriods.create(data.id)
                                            )
                                        }
                                    >
                                        {t('text.back')}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </React.Fragment>
                ) : null}
            </div>
        </Page>
    );
};
