/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { Role, useAuth } from '../../../../../helpers';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@mui/material';
import { IActionCellProps } from '../../../../../components';
import { BiTrash } from 'react-icons/bi';
import { BsCalendar2DateFill } from 'react-icons/bs';

export const ActionCell = ({
    data,
    context: { handleClosePeriod, handleDeletePeriod },
}: IActionCellProps) => {
    const styles = useStyles();
    const { currentUserHasRoles } = useAuth();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            {currentUserHasRoles(Role.superAdmin) ? (
                !data.endDate ? (
                    <Tooltip arrow title={<h1>{t('text.closePeriod')}</h1>}>
                        <IconButton onClick={() => handleClosePeriod(data)}>
                            <BsCalendar2DateFill style={styles.button} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip arrow title={<h1>{t('text.delete')}</h1>}>
                        <IconButton onClick={() => handleDeletePeriod(data)}>
                            <BiTrash style={styles.button} />
                        </IconButton>
                    </Tooltip>
                )
            ) : null}
        </div>
    );
};
