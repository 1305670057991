/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle } from './types';
import { Interpolation, Theme } from '@emotion/react';

interface IProps {
    firstPanel: React.ReactNode;
    secondPanel: React.ReactNode;
    actions: React.ReactNode;
    firstPanelLabel: string;
    secondPanelLabel: string;
    cs: ICustomStyle;
    es?: IElementStyle;
    style?: React.CSSProperties;
    className?: string;
}

export const Card = ({
    firstPanel,
    secondPanel,
    actions,
    firstPanelLabel,
    secondPanelLabel,
    cs,
    es,
    style,
    className,
}: IProps) => {
    const [panel, setPanel] = React.useState<'first' | 'second'>('first');
    const styles = useStyles(panel, cs);

    return (
        <div
            css={
                es && es.root
                    ? [styles.root, es.root as Interpolation<Theme>, style as Interpolation<Theme>]
                    : [styles.root, style as Interpolation<Theme>]
            }
            className={className}
        >
            <div css={styles.toggleButtonBox}>
                <button
                    css={
                        panel === 'first'
                            ? es && es.activeButton
                                ? [styles.activeButton, es.activeButton as Interpolation<Theme>]
                                : styles.activeButton
                            : es && es.button
                            ? [styles.button, es.button as Interpolation<Theme>]
                            : styles.button
                    }
                    onClick={() => {
                        if (panel !== 'first') {
                            setPanel('first');
                        }
                    }}
                >
                    {firstPanelLabel}
                </button>
                <button
                    css={
                        panel === 'second'
                            ? es && es.activeButton
                                ? [styles.activeButton, es.activeButton as Interpolation<Theme>]
                                : styles.activeButton
                            : es && es.button
                            ? [styles.button, es.button as Interpolation<Theme>]
                            : styles.button
                    }
                    onClick={() => {
                        if (panel !== 'second') {
                            setPanel('second');
                        }
                    }}
                >
                    {secondPanelLabel}
                </button>
            </div>
            <div
                css={
                    es && es.content
                        ? [styles.content, es.content as Interpolation<Theme>]
                        : styles.content
                }
            >
                <div
                    css={
                        es && es.firstPanel
                            ? [styles.firstPanel, es.firstPanel as Interpolation<Theme>]
                            : styles.firstPanel
                    }
                >
                    {firstPanel}
                </div>
                <div
                    css={
                        es && es.secondPanel
                            ? [styles.secondPanel, es.secondPanel as Interpolation<Theme>]
                            : styles.secondPanel
                    }
                >
                    {secondPanel}
                </div>
            </div>
            {actions}
        </div>
    );
};
