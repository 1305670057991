/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { colors } from '../../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Formik, Form, Field, FormikErrors, FormikHelpers } from 'formik';
import { TextFieldInput, Button } from '../../../../components';
import { ProfessionGroupComboBox } from './profession-group-combo-box/ProfessionGroupComboBox';

interface IProps {
    mutationType: 'create' | 'update';
    initialValues: IFormValues;
    onSubmit: (
        values: IFormValues,
        formikHelpers: FormikHelpers<IFormValues>
    ) => void | Promise<any>;
}

export const ProfessionForm = ({ mutationType, initialValues, onSubmit }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={({ nl, en, ro, pl, hr, pt, hu, professionGroup }) => {
                let errors: FormikErrors<IFormValues> = {};
                if (!nl.trim()) {
                    errors.nl = t('form.error.requiredField');
                } else if (nl.trim().length > 127) {
                    errors.nl = t('form.error.max127Chars');
                }
                if (!en.trim()) {
                    errors.en = t('form.error.requiredField');
                } else if (en.trim().length > 127) {
                    errors.en = t('form.error.max127Chars');
                }
                if (!ro.trim()) {
                    errors.ro = t('form.error.requiredField');
                } else if (ro.trim().length > 127) {
                    errors.ro = t('form.error.max127Chars');
                }
                if (!pl.trim()) {
                    errors.pl = t('form.error.requiredField');
                } else if (pl.trim().length > 127) {
                    errors.pl = t('form.error.max127Chars');
                }
                if (!hr.trim()) {
                    errors.hr = t('form.error.requiredField');
                } else if (hr.trim().length > 127) {
                    errors.hr = t('form.error.max127Chars');
                }
                if (!pt.trim()) {
                    errors.pt = t('form.error.requiredField');
                } else if (pt.trim().length > 127) {
                    errors.pt = t('form.error.max127Chars');
                }
                if (!hu.trim()) {
                    errors.hu = t('form.error.requiredField');
                } else if (hu.trim().length > 127) {
                    errors.hu = t('form.error.max127Chars');
                }
                if (!professionGroup) {
                    errors.professionGroup = t('form.error.requiredField');
                }
                return errors;
            }}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form noValidate style={styles.form}>
                    <Field
                        style={styles.formField}
                        name="en"
                        type="text"
                        label={t('form.label.english')}
                        placeholder={t('form.placeholder.english')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="nl"
                        type="text"
                        label={t('form.label.dutch')}
                        placeholder={t('form.placeholder.dutch')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="ro"
                        type="text"
                        label={t('form.label.romanian')}
                        placeholder={t('form.placeholder.romanian')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="pl"
                        type="text"
                        label={t('form.label.polish')}
                        placeholder={t('form.placeholder.polish')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="hr"
                        type="text"
                        label={t('form.label.croatian')}
                        placeholder={t('form.placeholder.croatian')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="pt"
                        type="text"
                        label={t('form.label.portuguese')}
                        placeholder={t('form.placeholder.portuguese')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="hu"
                        type="text"
                        label={t('form.label.hungarian')}
                        placeholder={t('form.placeholder.hungarian')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="professionGroup"
                        label={t('form.label.professionGroup')}
                        placeholder={t('form.placeholder.professionGroup')}
                        fontSize="2rem"
                        inputColor={colors.blue}
                        textColor={colors.blue}
                        disabled={isSubmitting}
                        component={ProfessionGroupComboBox}
                    />
                    <Button
                        css={styles.formButton}
                        type="submit"
                        disabled={isSubmitting}
                        spinner={isSubmitting}
                        cs={{
                            color: colors.blue,
                        }}
                    >
                        {mutationType === 'create' ? t('text.create') : t('text.update')}
                    </Button>
                    <Button
                        css={styles.formButton}
                        type="button"
                        disabled={isSubmitting}
                        spinner={isSubmitting}
                        cs={{
                            color: colors.blue,
                        }}
                        onClick={() => history.goBack()}
                    >
                        {t('text.back')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
