import axios from 'axios';

interface IAxiosErrorResponse {
    status: number;
    statusText: string;
    data: any;
}

export const handleError = (
    error: any,
    handler: (axiosErrorReponse: IAxiosErrorResponse) => void
) => {
    if (axios.isAxiosError(error)) {
        if (error.response) {
            const { status, statusText, data } = error.response;
            handler({
                status,
                statusText,
                data,
            });
        } else {
            alert(error.message);
        }
    } else {
        alert((error as Error).message);
    }
};
