/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { ICandidate } from '../../hooks/fetch-recruitment/types';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import { localText, dateFilter } from '../../../../helpers';
//import { saveAs } from 'file-saver';
import { Tooltip, IconButton } from '@mui/material';
import { SiMicrosoftexcel } from 'react-icons/si';
import { GiResize } from 'react-icons/gi';
import { GridSkeleton } from '../grid-skeleton/GridSkeleton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../../../helpers/ag-grid/ag-grid.css';

interface IProps {
    data: ICandidate[];
    lng: string;
}

export const Grid = ({ data, lng }: IProps) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const gridRef = React.createRef<AgGridReact>();
    const [destroyed, setDestroyed] = React.useState(false);

    const columnDefs = React.useMemo(
        (): ColDef[] => [
            {
                colId: 'candidate',
                headerName: t('text.candidate'),
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
                valueGetter: (params: ValueGetterParams<ICandidate, string>): string => {
                    const candidate = params.data;
                    if (candidate) {
                        if (candidate.employeeNumber) {
                            return `${
                                candidate.user.lastName
                            }, ${candidate.user.firstName[0].toUpperCase()}. - ${
                                candidate.employeeNumber
                            }`;
                        }
                        return `${
                            candidate.user.lastName
                        }, ${candidate.user.firstName[0].toUpperCase()}.`;
                    }
                    return '';
                },
            },
            {
                colId: 'department',
                headerName: t('text.department'),
                field: `user.department.${lng}`,
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'status',
                headerName: t('text.status'),
                field: 'status',
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? t(`candidateStatus.${value}`) : '';
                },
            },
            {
                colId: 'ambassador',
                headerName: t('text.ambassador'),
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
                valueGetter: (params: ValueGetterParams<ICandidate, string>): string => {
                    const candidate = params.data;
                    if (candidate && candidate.ambassador) {
                        return `${
                            candidate.ambassador.user.lastName
                        }, ${candidate.ambassador.user.firstName[0].toUpperCase()} - ${
                            candidate.ambassador.employeeNumber
                        }`;
                    }
                    return '';
                },
            },
            {
                colId: 'firstInvitedAt',
                headerName: t('text.dateInvited'),
                field: 'firstInvitedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 300,
                maxWidth: 600,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'daysInvited',
                headerName: t('text.daysInvited'),
                sortable: true,
                filter: 'agNumberColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueGetter: (params: ValueGetterParams<ICandidate, number>): number => {
                    const candidate = params.data;
                    if (candidate) {
                        if (candidate.firstInvitedAt) {
                            const differenceInTime =
                                new Date().getTime() - new Date(candidate.firstInvitedAt).getTime();
                            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                            return parseInt(differenceInDays.toString());
                        }
                        return 0;
                    }
                    return 0;
                },
            },
            {
                colId: 'invitationCompletedAt',
                headerName: t('text.dateRegistered'),
                field: 'invitationCompletedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'daysRegistered',
                headerName: t('text.daysRegistered'),
                sortable: true,
                filter: 'agNumberColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueGetter: (params: ValueGetterParams<ICandidate, number>): number => {
                    const candidate = params.data;
                    if (candidate) {
                        if (candidate.invitationCompletedAt) {
                            const differenceInTime =
                                new Date().getTime() -
                                new Date(candidate.invitationCompletedAt).getTime();
                            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                            return parseInt(differenceInDays.toString());
                        }
                        return 0;
                    }
                    return 0;
                },
            },
        ],
        [lng, t]
    );

    React.useEffect(() => {
        setDestroyed(true);
        const timer = setTimeout(() => setDestroyed(false));
        return () => {
            clearTimeout(timer);
        };
    }, [lng, setDestroyed]);

    if (destroyed) return <GridSkeleton />;
    return (
        <div style={styles.gridContainer}>
            <div style={styles.gridHeader}>
                <p style={styles.gridHeaderText}>{t('text.candidates')}</p>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.resize')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderResizeButton}
                        onClick={() => {
                            const allColumnIds: string[] = [];
                            if (gridRef.current) {
                                gridRef.current.api.getColumns()!.forEach((column) => {
                                    allColumnIds.push(column.getId());
                                });

                                gridRef.current.api.autoSizeColumns(allColumnIds);
                            }
                        }}
                    >
                        <GiResize />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.exportToExcel')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderExportButton}
                        onClick={async () => {
                            /* try {
                                const buffer = await excelExport(gridRef);
                                if (buffer) {
                                    saveAs(new Blob([buffer]), 'logs.xlsx');
                                }
                            } catch (e) {
                                alert(t('text.failedToExport'));
                            } */
                        }}
                    >
                        <SiMicrosoftexcel />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="ag-theme-quartz" style={styles.gridBox}>
                <React.StrictMode>
                    <AgGridReact<ICandidate>
                        ref={gridRef}
                        rowData={data}
                        columnDefs={columnDefs}
                        autoSizeStrategy={{
                            type: 'fitCellContents',
                        }}
                        suppressDragLeaveHidesColumns={true}
                        localeText={localText[lng]}
                        rowSelection="single"
                        rowBuffer={50}
                    />
                </React.StrictMode>
            </div>
        </div>
    );
};
