import { IUpdateInvitedCandidateVariables } from './types';
import { ICandidate } from '../../../hooks';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useUpdateInvitedCandidate = (invitationToken: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<ICandidate>, any, IUpdateInvitedCandidateVariables>(
        ({ body }) => api.patch(`/candidates/${invitationToken}/invited`, body)
    );
};
