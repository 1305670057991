import * as React from 'react';
import { IAuthContext, ICurrentUser } from './types';
import { Role } from '../shared-types/role.enum';

const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

interface IProps {
    children?: React.ReactNode;
}

export const AuthProvider = ({ children }: IProps): JSX.Element => {
    const [currentUser, setCurrentUser] = React.useState<ICurrentUser | null>(null);

    const getCurrentUser = React.useCallback((): ICurrentUser => {
        if (currentUser) {
            return currentUser;
        }
        console.log("User isn't logged in");
        throw new Error("User isn't logged in");
    }, [currentUser]);

    const currentUserHasRoles = React.useCallback(
        (...roles: Role[]): boolean => {
            let result = false;
            if (currentUser) {
                for (let i = 0; i < currentUser.roles.length; i++) {
                    const role = currentUser.roles[i];
                    if (roles.includes(role)) {
                        result = true;
                        break;
                    }
                }
            }
            return result;
        },
        [currentUser]
    );

    return (
        <AuthContext.Provider
            value={{ currentUser, setCurrentUser, getCurrentUser, currentUserHasRoles }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);
