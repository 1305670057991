import { useQuery } from 'react-query';
import { Cache, useApi } from '../../../../helpers';
import { ICandidate } from './types';

export const useFetchRecruitment = () => {
    const api = useApi();

    return useQuery<ICandidate[], any>(
        Cache.dashboardRecruitment,
        ({ signal }) => api.get(`/dashboards/recruitment`, { signal }).then((res) => res.data),
        { refetchOnWindowFocus: false }
    );
};
