/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchProfessionGroup, useUpdateProfessionGroup } from './hooks';
import { IRouteParams, handleError, handleQueryError, routePath } from '../../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Page } from '../../../../components';
import { ProfessionGroupForm } from '../profession-group-form/ProfessionGroupForm';

export const UpdateProfessionGroup = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchProfessionGroup(id);
    const update = useUpdateProfessionGroup(id);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            isPageSubmitting={update.isLoading}
            title={t('text.updateProfessionGroup')}
            style={styles.root}
        >
            {isLoading && (
                <div style={styles.formSkeleton}>
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            )}
            {data && (
                <ProfessionGroupForm
                    mutationType="update"
                    initialValues={{
                        en: data.en,
                        nl: data.nl,
                        ro: data.ro,
                        pl: data.pl,
                        hr: data.hr,
                        pt: data.pt,
                        hu: data.hu,
                        importId: data.importId ? data.importId : '',
                    }}
                    onSubmit={async ({ en, nl, ro, pl, hr, pt, hu, importId }, { setErrors }) => {
                        try {
                            await update.mutateAsync({
                                body: {
                                    en: en.trim(),
                                    nl: nl.trim(),
                                    ro: ro.trim(),
                                    pl: pl.trim(),
                                    hr: hr.trim(),
                                    pt: pt.trim(),
                                    hu: hu.trim(),
                                    importId: importId.trim(),
                                },
                            });
                            history.push(routePath.professionGroupsSetting.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 400) {
                                    setErrors({
                                        en: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                        nl: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                        ro: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                        pl: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                        hr: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                        pt: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                        hu: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                    });
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                />
            )}
        </Page>
    );
};
