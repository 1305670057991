/**@jsxImportSource @emotion/react */
import { colors, designStyles } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        form: {
            margin: '4rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: widthBelow600px ? '30rem' : '40rem',
        },
        formCategory: {
            width: '100%',
            textAlign: 'center',
            color: colors.blue,
            userSelect: 'none',
            marginBottom: '2rem',
            borderBottom: `solid 1px ${colors.blue}`,
        },
        formFeeContainer: {
            width: '100%',
            height: '15rem',
            marginBottom: '4rem',
            padding: '2rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            borderRadius: '2rem',
            backgroundColor: colors.lightGrey,
            color: colors.blue,
            fontSize: '1.6rem',
            boxShadow: `5px 5px 8px 5px ${colors.darkBoxShadow}`,
            userSelect: 'none',
        },
        feeContainerText: {
            display: 'inline-block',
            fontWeight: 'bold',
        },
        feeContainerDataText: {
            display: 'inline-block',
            fontStyle: 'italic',
            userSelect: 'text',
        },
        formField: {
            marginBottom: '2rem',
        },
        formButton: {
            width: '15rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        categorySkeleton: {
            width: '20rem',
            height: '2rem',
            marginBottom: '2rem',
        },
        formFeeContainerSkeleton: {
            width: '100%',
            height: '15rem',
            marginBottom: '4rem',
        },
        fieldSkeleton: {
            width: '100%',
            height: '5rem',
            marginBottom: '5rem',
        },
        buttonSkeleton: {
            width: '15rem',
            height: '3.5rem',
            '&:not(:last-child)': {
                marginBottom: '2rem',
            },
        },
    });
