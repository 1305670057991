import { IUpdateCandidateInvitationVariables } from './types';
import { ICandidate } from '../../../hooks';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useUpdateCandidateInvitation = (candidateId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<ICandidate>, any, IUpdateCandidateInvitationVariables>(
        ({ body }) => api.patch(`/candidates/${candidateId}/invitation`, body)
    );
};
