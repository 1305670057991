/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useAmbassadorDeclarationPeriod } from './hooks';
import {
    getAmbassadorDeclaration,
    getCandidateDeclaration,
    isAnyAmbassadorCandidateWorkedHours,
    isAnyAmbassadorCandidateWorkedHoursByTier,
    getAmbassadorCandidateWorkedHoursByTier,
} from './helpers';
import { IAmbassadorWorkedHour } from './hooks/fetch-ambassador-declaration-period/types/ambassador-worked-hour.interface';
import { IAmbassadorAfasDeclaration } from './hooks/fetch-ambassador-declaration-period/types/ambassador-afas-declaration.interface';
import { IRouteParams, handleQueryError, routePath, colors } from '../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, AccordionDetails, Skeleton } from '@mui/material';
import { Page, Button } from '../../../components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FcApproval, FcCancel } from 'react-icons/fc';

export const AmbassadorDeclarationPeriod = () => {
    const styles = useStyles();
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading, data, error } = useAmbassadorDeclarationPeriod(
        Number(id.split('-')[0]),
        Number(id.split('-')[1])
    );

    const getAmbassadorWorkedHours = React.useCallback((): IAmbassadorWorkedHour[] => {
        if (data) {
            return data.ambassadorWorkedHours;
        }
        return [] as IAmbassadorWorkedHour[];
    }, [data]);

    const getAmbassadorAfasDeclarations = React.useCallback((): IAmbassadorAfasDeclaration[] => {
        if (data) {
            return data.ambassadorAfasDeclarations;
        }
        return [] as IAmbassadorAfasDeclaration[];
    }, [data]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page title={t('text.ambassadorDeclarations')} style={styles.root}>
            {isLoading ? (
                <div style={styles.content}>
                    <div style={styles.skeletonBox}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.weekSkeleton}
                        />
                    </div>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        css={styles.accordionSkeleton}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        css={styles.accordionSkeleton}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        css={styles.accordionSkeleton}
                    />
                    <div style={styles.skeletonBox}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.buttonSkeletonBoxButton}
                        />
                    </div>
                </div>
            ) : null}
            {data ? (
                <React.Fragment>
                    <h2 style={styles.week}>
                        {`${Number(id.split('-')[0])}-${Number(id.split('-')[1])} ${t(
                            'text.week'
                        )}`}
                    </h2>
                    {getAmbassadorWorkedHours().map((ambassadorWorkedHour, index) => (
                        <div key={index} style={styles.content}>
                            <Accordion style={styles.contentAccordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                >
                                    <div style={styles.accordionSummaryContent}>
                                        <p style={styles.accordionSummaryContentAmbassador}>
                                            {`${ambassadorWorkedHour.ambassador.user.firstName} ${ambassadorWorkedHour.ambassador.user.lastName} - ${ambassadorWorkedHour.ambassador.employeeNumber}`}
                                        </p>
                                        {ambassadorWorkedHour.isActive ? (
                                            <div>
                                                <p style={styles.accordionText}>
                                                    {t('text.active')}:&nbsp;
                                                </p>
                                                <FcApproval style={styles.accordionBooleanIcon} />
                                            </div>
                                        ) : (
                                            <div style={styles.accordionBooleanBox}>
                                                <p style={styles.accordionText}>
                                                    {t('text.active')}:&nbsp;
                                                </p>
                                                <FcCancel style={styles.accordionBooleanIcon} />
                                            </div>
                                        )}
                                        <div>
                                            <p style={styles.accordionText}>
                                                {t('text.minWorkedHourPerWeekForFee')}:&nbsp;
                                            </p>
                                            <p style={styles.accordionDataText}>
                                                {ambassadorWorkedHour.minWorkedHourPerWeekForFee}
                                            </p>
                                        </div>
                                        <div>
                                            <p style={styles.accordionText}>
                                                {t('text.workedHours')}:&nbsp;
                                            </p>
                                            <p style={styles.accordionDataText}>
                                                {ambassadorWorkedHour.amount}
                                            </p>
                                        </div>
                                        <div>
                                            <p style={styles.accordionText}>
                                                {t('text.declaration')}:&nbsp;
                                            </p>
                                            <p style={styles.accordionDataText}>
                                                {`€ ${getAmbassadorDeclaration(
                                                    ambassadorWorkedHour,
                                                    getAmbassadorAfasDeclarations()
                                                )}`}
                                            </p>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={styles.accordionDetailsContent}>
                                        {!isAnyAmbassadorCandidateWorkedHours(
                                            ambassadorWorkedHour
                                        ) ? (
                                            <p style={styles.accordionDetailsContentNoCandidates}>
                                                {t('text.noCandidates')}
                                            </p>
                                        ) : null}
                                        {isAnyAmbassadorCandidateWorkedHoursByTier(
                                            ambassadorWorkedHour,
                                            1
                                        ) ? (
                                            <React.Fragment>
                                                <p style={styles.accordionDetailsContentTier}>
                                                    {t('text.firstTierCandidates')}
                                                </p>
                                                <div
                                                    style={styles.accordionDetailsContentCandidates}
                                                >
                                                    {getAmbassadorCandidateWorkedHoursByTier(
                                                        ambassadorWorkedHour,
                                                        1
                                                    ).map((candidateWorkedHour, index) => (
                                                        <div
                                                            key={index}
                                                            css={styles.candidatesCandidateBox}
                                                        >
                                                            <p style={styles.candidateBoxCandidate}>
                                                                {candidateWorkedHour.employeeNumber
                                                                    ? `${candidateWorkedHour.candidate.user.firstName} ${candidateWorkedHour.candidate.user.lastName} - ${candidateWorkedHour.employeeNumber}`
                                                                    : `${candidateWorkedHour.candidate.user.firstName} ${candidateWorkedHour.candidate.user.lastName}`}
                                                            </p>
                                                            {candidateWorkedHour.isActive ? (
                                                                <div>
                                                                    <p style={styles.accordionText}>
                                                                        {t('text.active')}
                                                                        :&nbsp;
                                                                    </p>
                                                                    <FcApproval
                                                                        style={
                                                                            styles.accordionBooleanIcon
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={
                                                                        styles.accordionBooleanBox
                                                                    }
                                                                >
                                                                    <p style={styles.accordionText}>
                                                                        {t('text.active')}
                                                                        :&nbsp;
                                                                    </p>
                                                                    <FcCancel
                                                                        style={
                                                                            styles.accordionBooleanIcon
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t(
                                                                        'text.minWorkedHourPerWeekForFee'
                                                                    )}
                                                                    :&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {
                                                                        candidateWorkedHour.minWorkedHourPerWeekForFee
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t('text.workedHours')}
                                                                    :&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {candidateWorkedHour.amount}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t('text.fee')}:&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {`€ ${candidateWorkedHour.ambassadorFee}`}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t('text.declaration')}
                                                                    :&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {`€ ${getCandidateDeclaration(
                                                                        ambassadorWorkedHour,
                                                                        candidateWorkedHour
                                                                    )}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        ) : null}
                                        {isAnyAmbassadorCandidateWorkedHoursByTier(
                                            ambassadorWorkedHour,
                                            2
                                        ) ? (
                                            <React.Fragment>
                                                <p style={styles.accordionDetailsContentTier}>
                                                    {t('text.secondTierCandidates')}
                                                </p>
                                                <div
                                                    style={styles.accordionDetailsContentCandidates}
                                                >
                                                    {getAmbassadorCandidateWorkedHoursByTier(
                                                        ambassadorWorkedHour,
                                                        2
                                                    ).map((candidateWorkedHour, index) => (
                                                        <div
                                                            key={index}
                                                            css={styles.candidatesCandidateBox}
                                                        >
                                                            <p style={styles.candidateBoxCandidate}>
                                                                {candidateWorkedHour.employeeNumber
                                                                    ? `${candidateWorkedHour.candidate.user.firstName} ${candidateWorkedHour.candidate.user.lastName} - ${candidateWorkedHour.employeeNumber}`
                                                                    : `${candidateWorkedHour.candidate.user.firstName} ${candidateWorkedHour.candidate.user.lastName}`}
                                                            </p>
                                                            {candidateWorkedHour.isActive ? (
                                                                <div>
                                                                    <p style={styles.accordionText}>
                                                                        {t('text.active')}
                                                                        :&nbsp;
                                                                    </p>
                                                                    <FcApproval
                                                                        style={
                                                                            styles.accordionBooleanIcon
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={
                                                                        styles.accordionBooleanBox
                                                                    }
                                                                >
                                                                    <p style={styles.accordionText}>
                                                                        {t('text.active')}
                                                                        :&nbsp;
                                                                    </p>
                                                                    <FcCancel
                                                                        style={
                                                                            styles.accordionBooleanIcon
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t(
                                                                        'text.minWorkedHourPerWeekForFee'
                                                                    )}
                                                                    :&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {
                                                                        candidateWorkedHour.minWorkedHourPerWeekForFee
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t('text.workedHours')}
                                                                    :&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {candidateWorkedHour.amount}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t('text.fee')}:&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {`€ ${candidateWorkedHour.ambassadorFee}`}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t('text.declaration')}
                                                                    :&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {`€ ${getCandidateDeclaration(
                                                                        ambassadorWorkedHour,
                                                                        candidateWorkedHour
                                                                    )}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        ) : null}
                                        {isAnyAmbassadorCandidateWorkedHoursByTier(
                                            ambassadorWorkedHour,
                                            3
                                        ) ? (
                                            <React.Fragment>
                                                <p style={styles.accordionDetailsContentTier}>
                                                    {t('text.thirdTierCandidates')}
                                                </p>
                                                <div
                                                    style={styles.accordionDetailsContentCandidates}
                                                >
                                                    {getAmbassadorCandidateWorkedHoursByTier(
                                                        ambassadorWorkedHour,
                                                        3
                                                    ).map((candidateWorkedHour, index) => (
                                                        <div
                                                            key={index}
                                                            css={styles.candidatesCandidateBox}
                                                        >
                                                            <p style={styles.candidateBoxCandidate}>
                                                                {candidateWorkedHour.employeeNumber
                                                                    ? `${candidateWorkedHour.candidate.user.firstName} ${candidateWorkedHour.candidate.user.lastName} - ${candidateWorkedHour.employeeNumber}`
                                                                    : `${candidateWorkedHour.candidate.user.firstName} ${candidateWorkedHour.candidate.user.lastName}`}
                                                            </p>
                                                            {candidateWorkedHour.isActive ? (
                                                                <div>
                                                                    <p style={styles.accordionText}>
                                                                        {t('text.active')}
                                                                        :&nbsp;
                                                                    </p>
                                                                    <FcApproval
                                                                        style={
                                                                            styles.accordionBooleanIcon
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={
                                                                        styles.accordionBooleanBox
                                                                    }
                                                                >
                                                                    <p style={styles.accordionText}>
                                                                        {t('text.active')}
                                                                        :&nbsp;
                                                                    </p>
                                                                    <FcCancel
                                                                        style={
                                                                            styles.accordionBooleanIcon
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t(
                                                                        'text.minWorkedHourPerWeekForFee'
                                                                    )}
                                                                    :&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {
                                                                        candidateWorkedHour.minWorkedHourPerWeekForFee
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t('text.workedHours')}
                                                                    :&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {candidateWorkedHour.amount}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t('text.fee')}:&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {`€ ${candidateWorkedHour.ambassadorFee}`}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={styles.accordionText}>
                                                                    {t('text.declaration')}
                                                                    :&nbsp;
                                                                </p>
                                                                <p style={styles.accordionDataText}>
                                                                    {`€ ${getCandidateDeclaration(
                                                                        ambassadorWorkedHour,
                                                                        candidateWorkedHour
                                                                    )}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        ) : null}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))}
                    <Button
                        css={styles.button}
                        type="button"
                        cs={{
                            color: colors.blue,
                        }}
                        onClick={() => history.push(routePath.ambassadorDeclarations.create())}
                    >
                        {t('text.back')}
                    </Button>
                </React.Fragment>
            ) : null}
        </Page>
    );
};
