/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { ICandidate, IProfession } from '../../hooks';
import { IPayload as IDeletePayload } from '../../../../helpers/reducers/delete/types';
import { IPayload as IRejectPayload } from '../../../../helpers/reducers/reject/types';
import { isCandidateActive } from '../../helpers';
import {
    Role,
    dateFilter,
    localText,
    routePath,
    useAuth,
    isoDateStringToLocaleDateString,
} from '../../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import { ActionsCell } from './action-cell/ActionCell';
//import { saveAs } from 'file-saver';
import { Tooltip, IconButton } from '@mui/material';
import { SiMicrosoftexcel } from 'react-icons/si';
import { GiResize } from 'react-icons/gi';
import { GridSkeleton } from './grid-skeleton/GridSkeleton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../../../helpers/ag-grid/ag-grid.css';

interface IProps {
    data: ICandidate[];
    lng: string;
    shorter: boolean;
    openDelete: (payload: IDeletePayload) => void;
    openReject: (payload: IRejectPayload) => void;
}

export const Grid = ({ data, lng, shorter, openDelete, openReject }: IProps) => {
    const styles = useStyles(shorter);
    const { currentUserHasRoles } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const gridRef = React.createRef<AgGridReact>();
    const [destroyed, setDestroyed] = React.useState(false);

    const columnDefs = React.useMemo(
        (): ColDef[] => [
            {
                colId: 'firstName',
                headerName: t('text.firstName'),
                field: 'user.firstName',
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'lastName',
                headerName: t('text.lastName'),
                field: 'user.lastName',
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'email',
                headerName: t('text.email'),
                field: 'user.email',
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },

            {
                colId: 'employeeNumber',
                headerName: t('text.employeeNumber'),
                field: 'employeeNumber',
                sortable: true,
                filter: true,
                resizable: true,
                hide: currentUserHasRoles(Role.ambassador, Role.recruitmentAgency),
                width: 200,
                maxWidth: 400,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? value : '';
                },
            },
            {
                colId: 'appliedBy',
                headerName: t('text.appliedBy'),
                sortable: true,
                filter: true,
                resizable: true,
                hide: currentUserHasRoles(Role.ambassador, Role.recruitmentAgency),
                width: 200,
                maxWidth: 400,
                valueGetter: (params: ValueGetterParams<ICandidate>): string => {
                    const candidate = params.data;
                    if (candidate) {
                        if (candidate.ambassador) {
                            return `${candidate.ambassador.user.firstName} ${candidate.ambassador.user.lastName}`;
                        } else if (candidate.recruitmentAgency) {
                            return candidate.recruitmentAgency.user.name;
                        }
                        return '';
                    }
                    return '';
                },
            },
            {
                colId: 'active',
                headerName: t('text.active'),
                sortable: true,
                filter: true,
                resizable: true,
                width: 100,
                maxWidth: 200,
                valueGetter: (params: ValueGetterParams<ICandidate>): string => {
                    const candidate = params.data;
                    if (candidate) {
                        return isCandidateActive(candidate) ? t('text.active') : t('text.inactive');
                    }
                    return '';
                },
            },
            {
                colId: 'status',
                headerName: t('text.status'),
                field: 'status',
                sortable: true,
                filter: true,
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? t(`candidateStatus.${value}`) : '';
                },
            },
            {
                colId: 'department',
                headerName: t('text.department'),
                field: `user.department.${lng}`,
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'isPolicyAccepted',
                headerName: t('text.privacyPolicy'),
                field: 'isPolicyAccepted',
                sortable: true,
                filter: true,
                resizable: true,
                hide: currentUserHasRoles(Role.ambassador, Role.recruitmentAgency),
                width: 200,
                maxWidth: 400,
                valueFormatter: (params: ValueFormatterParams<ICandidate, boolean>): string => {
                    const { value } = params;
                    return value ? t('text.yes') : t('text.no');
                },
            },
            {
                colId: 'birthdate',
                headerName: t('text.birthdate'),
                field: 'user.birthdate',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? isoDateStringToLocaleDateString(value) : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'phoneNumber',
                headerName: t('text.phoneNumber'),
                field: 'user.phoneNumber',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? value : '';
                },
            },
            {
                colId: 'professions',
                headerName: t('text.professions'),
                field: 'professions',
                sortable: true,
                filter: true,
                resizable: true,
                width: 400,
                maxWidth: 800,
                valueFormatter: (
                    params: ValueFormatterParams<ICandidate, IProfession[]>
                ): string => {
                    const { value } = params;
                    if (value) {
                        return value
                            .map((profession: IProfession) => profession[lng as keyof IProfession])
                            .join(', ');
                    }
                    return '';
                },
            },
            {
                colId: 'firstInvitedAt',
                headerName: t('text.firstInvitedOn'),
                field: 'firstInvitedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'invitedBy',
                headerName: t('text.invitedBy'),
                field: 'invitedBy',
                sortable: true,
                filter: true,
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? value : '';
                },
            },
            {
                colId: 'invitedOn',
                headerName: t('text.invitedOn'),
                field: 'invitedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },

            {
                colId: 'invitationValidUntil',
                headerName: t('text.invitationValidUntil'),
                field: 'invitationValidUntil',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'invitationCompletedOn',
                headerName: t('text.invitationCompletedOn'),
                field: 'invitationCompletedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'rejectedBy',
                headerName: t('text.rejectedBy'),
                field: 'rejectedBy',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? value : '';
                },
            },
            {
                colId: 'rejectedAt',
                headerName: t('text.rejectedOn'),
                field: 'rejectedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'createdBy',
                headerName: t('text.createdBy'),
                field: 'createdBy',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
            },
            {
                colId: 'createdOn',
                headerName: t('text.createdOn'),
                field: 'createdAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'createdAt',
                headerName: t('text.createdAt'),
                field: 'createdAt',
                sortable: true,
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleTimeString() : '';
                },
            },
            {
                colId: 'lastModifiedBy',
                headerName: t('text.modifiedBy'),
                field: 'lastModifiedBy',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
            },
            {
                colId: 'modifiedOn',
                headerName: t('text.modifiedOn'),
                field: 'lastModifiedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (paramas: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = paramas;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'lastModifiedAt',
                headerName: t('text.modifiedAt'),
                field: 'lastModifiedAt',
                sortable: true,
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ICandidate, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleTimeString() : '';
                },
            },
            {
                colId: 'actions',
                headerName: t('text.actions'),
                cellRenderer: ActionsCell,
                suppressMovable: true,
                lockPosition: 'right',
                sortable: false,
                resizable: false,
                width: currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? 220 : 150,
            },
        ],
        [lng, t, currentUserHasRoles]
    );

    React.useEffect(() => {
        setDestroyed(true);
        const timer = setTimeout(() => setDestroyed(false));
        return () => {
            clearTimeout(timer);
        };
    }, [lng, setDestroyed]);

    if (destroyed) return <GridSkeleton shorter={shorter} />;
    return (
        <div style={styles.gridContainer}>
            <div style={styles.gridHeader}>
                <p style={styles.gridHeaderText}>{t('text.candidates')}</p>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.resize')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderResizeButton}
                        onClick={() => {
                            const allColumnIds: string[] = [];
                            if (gridRef.current) {
                                gridRef.current.api.getColumns()!.forEach((column) => {
                                    allColumnIds.push(column.getId());
                                });

                                gridRef.current.api.autoSizeColumns(allColumnIds);
                            }
                        }}
                    >
                        <GiResize />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.exportToExcel')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderExportButton}
                        onClick={async () => {
                            /* try {
                                const buffer = await excelExport(gridRef);
                                if (buffer) {
                                    saveAs(new Blob([buffer]), 'logs.xlsx');
                                }
                            } catch (e) {
                                alert(t('text.failedToExport'));
                            } */
                        }}
                    >
                        <SiMicrosoftexcel />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="ag-theme-quartz" style={styles.gridBox}>
                <React.StrictMode>
                    <AgGridReact<ICandidate>
                        ref={gridRef}
                        rowData={data}
                        columnDefs={columnDefs}
                        autoSizeStrategy={{
                            type: 'fitCellContents',
                        }}
                        suppressDragLeaveHidesColumns={true}
                        localeText={localText[lng]}
                        rowSelection="single"
                        rowBuffer={50}
                        context={{
                            handelUpdateClick: (candidate: ICandidate) =>
                                history.push(routePath.updateCandidate.create(candidate.id)),
                            handelActivityClick: (candidate: ICandidate) =>
                                history.push(
                                    routePath.candidateActivityPeriods.create(candidate.id)
                                ),
                            handleResendInvitationClick: (candidate: ICandidate) =>
                                history.push(
                                    routePath.updateCandidateInvitation.create(candidate.id)
                                ),
                            handelAfasLinkClick: (candidate: ICandidate) =>
                                history.push(
                                    routePath.updateCandidateEmployeeNumber.create(candidate.id)
                                ),
                            handelPromoteClick: (candidate: ICandidate) =>
                                history.push(routePath.promoteCandidate.create(candidate.id)),
                            handelRejectClick: (candidate: ICandidate) =>
                                openReject({
                                    itemToRejectId: candidate.id,
                                    itemToRejectName: `${candidate.user.firstName} ${candidate.user.lastName}`,
                                }),
                            handleDeleteClick: (candidate: ICandidate) =>
                                openDelete({
                                    itemToDeleteId: candidate.id,
                                    itemToDeleteName: `${candidate.user.firstName} ${candidate.user.lastName}`,
                                }),
                        }}
                    />
                </React.StrictMode>
            </div>
        </div>
    );
};
