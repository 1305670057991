/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useAuth, Role, routePath, colors } from '../../../../../helpers';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../buttons/button/Button';
import { LinkButton } from '../../../../buttons/link-button/LinkButton';

interface IProps {
    inputRef: React.RefObject<HTMLInputElement>;
    closeCollapsedMenu: () => void;
    setCollapsedMenuList: (menuList: 'main' | 'menu' | 'dashboards') => void;
}

export const MainMenuList = ({ inputRef, closeCollapsedMenu, setCollapsedMenuList }: IProps) => {
    const styles = useStyles();
    const { currentUserHasRoles, setCurrentUser } = useAuth();
    const client = useQueryClient();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <ul style={styles.navBoxList} className="navLinks">
            <li>
                <Button
                    style={styles.listButton}
                    cs={{
                        color: colors.white,
                        fontSize: '1.5rem',
                        textColor: colors.blue,
                        boxShadowColor: colors.darkBoxShadow,
                    }}
                    onClick={() => {
                        document.body.style.overflow = 'initial';
                        setCollapsedMenuList('dashboards');
                    }}
                >
                    {t('text.dashboards')}
                </Button>
            </li>
            <li>
                <Button
                    style={styles.listButton}
                    cs={{
                        color: colors.white,
                        fontSize: '1.5rem',
                        textColor: colors.blue,
                        boxShadowColor: colors.darkBoxShadow,
                    }}
                    onClick={() => {
                        document.body.style.overflow = 'initial';
                        setCollapsedMenuList('menu');
                    }}
                >
                    {t('text.persons')}
                </Button>
            </li>
            <li>
                <LinkButton
                    style={styles.listButton}
                    to={routePath.help.create()}
                    cs={{
                        color: colors.white,
                        textColor: colors.blue,
                        fontSize: '1.5rem',
                        boxShadowColor: colors.darkBoxShadow,
                    }}
                    onClick={() => {
                        document.body.style.overflow = 'initial';
                        inputRef.current!.checked = false;
                        closeCollapsedMenu();
                        client.cancelQueries();
                    }}
                >
                    {t('text.help')}
                </LinkButton>
            </li>
            <li>
                <LinkButton
                    style={styles.listButton}
                    to={routePath.feedback.create()}
                    cs={{
                        color: colors.white,
                        textColor: colors.blue,
                        fontSize: '1.5rem',
                        boxShadowColor: colors.darkBoxShadow,
                    }}
                    onClick={() => {
                        document.body.style.overflow = 'initial';
                        inputRef.current!.checked = false;
                        closeCollapsedMenu();
                        client.cancelQueries();
                    }}
                >
                    Feedback
                </LinkButton>
            </li>
            {currentUserHasRoles(Role.superAdmin) ? (
                <React.Fragment>
                    <li>
                        <LinkButton
                            style={styles.listButton}
                            to={routePath.ambassadorDeclarations.create()}
                            cs={{
                                color: colors.white,
                                textColor: colors.blue,
                                fontSize: '1.5rem',
                                boxShadowColor: colors.darkBoxShadow,
                            }}
                            onClick={() => {
                                document.body.style.overflow = 'initial';
                                inputRef.current!.checked = false;
                                closeCollapsedMenu();
                                client.cancelQueries();
                            }}
                        >
                            {t('text.declarations')}
                        </LinkButton>
                    </li>
                    <li>
                        <LinkButton
                            style={styles.listButton}
                            to={routePath.settings.create()}
                            cs={{
                                color: colors.white,
                                textColor: colors.blue,
                                fontSize: '1.5rem',
                                boxShadowColor: colors.darkBoxShadow,
                            }}
                            onClick={() => {
                                document.body.style.overflow = 'initial';
                                inputRef.current!.checked = false;
                                closeCollapsedMenu();
                                client.cancelQueries();
                            }}
                        >
                            {t('text.settings')}
                        </LinkButton>
                    </li>
                    <li>
                        <LinkButton
                            style={styles.listButton}
                            to={routePath.logs.create()}
                            cs={{
                                color: colors.white,
                                textColor: colors.blue,
                                fontSize: '1.5rem',
                                boxShadowColor: colors.darkBoxShadow,
                            }}
                            onClick={() => {
                                document.body.style.overflow = 'initial';
                                inputRef.current!.checked = false;
                                closeCollapsedMenu();
                                client.cancelQueries();
                            }}
                        >
                            {t('text.logs')}
                        </LinkButton>
                    </li>
                </React.Fragment>
            ) : null}
            <li>
                <LinkButton
                    style={styles.listButton}
                    to={routePath.user.create()}
                    cs={{
                        color: colors.white,
                        textColor: colors.blue,
                        fontSize: '1.5rem',
                        boxShadowColor: colors.darkBoxShadow,
                    }}
                    onClick={() => {
                        document.body.style.overflow = 'initial';
                        inputRef.current!.checked = false;
                        closeCollapsedMenu();
                        client.cancelQueries();
                    }}
                >
                    {t('text.myAccount')}
                </LinkButton>
            </li>
            <li>
                <Button
                    style={styles.listButton}
                    cs={{
                        color: colors.white,
                        fontSize: '1.5rem',
                        textColor: colors.blue,
                        boxShadowColor: colors.darkBoxShadow,
                    }}
                    onClick={(event) => {
                        event.preventDefault();
                        client.cancelQueries();
                        document.body.style.overflow = 'initial';
                        inputRef.current!.checked = false;
                        history.push('/');
                        localStorage.removeItem('rtid');
                        localStorage.removeItem('lvid');
                        localStorage.removeItem('rpvid');
                        localStorage.removeItem('esvid');
                        client.clear();
                        setCurrentUser(null);
                    }}
                >
                    {t('text.logout')}
                </Button>
            </li>
        </ul>
    );
};
