/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        actionBar: {
            marginTop: '2rem',
            height: '4rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        actionBarViewToogle: {
            marginLeft: '2rem',
        },
        actionBarCreateIcon: {
            backgroundColor: colors.blue,
            color: colors.white,
            marginRight: '2rem',
        },
    });
