import { IChangePasswordVariables } from './types';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useChangePassword = (userId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<void>, any, IChangePasswordVariables>(({ body }) =>
        api.patch(`/users/${userId}/password`, body)
    );
};
