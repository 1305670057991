/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { ITask } from '../hooks';
import { colors, dateFilter, localText, routePath } from '../../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, RowClassParams, ValueFormatterParams } from 'ag-grid-community';
//import { saveAs } from 'file-saver';
import { Tooltip, IconButton } from '@mui/material';
import { SiMicrosoftexcel } from 'react-icons/si';
import { GiResize } from 'react-icons/gi';
import { GridSkeleton } from './grid-skeleton/GridSkeleton';
import { ActionsCell } from './action-cell/ActionCell';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../../../helpers/ag-grid/ag-grid.css';

interface IProps {
    data: ITask[];
    lng: string;
}

export const Grid = ({ data, lng }: IProps) => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const gridRef = React.createRef<AgGridReact>();
    const [destroyed, setDestroyed] = React.useState(false);

    const columnDefs = React.useMemo(
        (): ColDef[] => [
            {
                colId: 'taskType',
                headerName: t('text.taskType'),
                field: 'taskType',
                sortable: true,
                filter: true,
                resizable: true,
                width: 500,
                maxWidth: 1000,
                valueFormatter: (params: ValueFormatterParams<ITask, string>): string => {
                    const { value } = params;
                    return value ? t(`task.${value}`) : '';
                },
            },
            {
                colId: 'errorMassage',
                headerName: t('text.errorMessage'),
                field: 'errorMessage',
                sortable: true,
                filter: true,
                resizable: true,
                width: 500,
                maxWidth: 1000,
                valueFormatter: (params: ValueFormatterParams<ITask, string>): string => {
                    const { value } = params;
                    return value ? value : '';
                },
            },
            {
                colId: 'hasError',
                headerName: t('text.hasError'),
                field: 'hasError',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
                valueFormatter: (params: ValueFormatterParams<ITask, boolean>): string => {
                    const { value } = params;
                    return value ? t('text.yes') : t('text.no');
                },
            },
            {
                colId: 'createdBy',
                headerName: t('text.createdBy'),
                field: 'createdBy',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
            },
            {
                colId: 'createdOn',
                headerName: t('text.createdOn'),
                field: 'createdAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ITask, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'createdAt',
                headerName: t('text.createdAt'),
                field: 'createdAt',
                sortable: true,
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<ITask, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleTimeString() : '';
                },
            },
            {
                headerName: t('text.actions'),
                cellRenderer: ActionsCell,
                suppressMovable: true,
                lockPosition: 'right',
                sortable: false,
                resizable: false,
                width: 150,
            },
        ],
        [t]
    );

    React.useEffect(() => {
        setDestroyed(true);
        const timer = setTimeout(() => setDestroyed(false));
        return () => {
            clearTimeout(timer);
        };
    }, [lng, setDestroyed]);

    if (destroyed) return <GridSkeleton />;
    return (
        <div style={styles.gridContainer}>
            <div style={styles.gridHeader}>
                <p style={styles.gridHeaderText}>{t('text.tasks')}</p>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.resize')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderResizeButton}
                        onClick={() => {
                            const allColumnIds: string[] = [];
                            if (gridRef.current) {
                                gridRef.current.api.getColumns()!.forEach((column) => {
                                    allColumnIds.push(column.getId());
                                });

                                gridRef.current.api.autoSizeColumns(allColumnIds);
                            }
                        }}
                    >
                        <GiResize />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.exportToExcel')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderExportButton}
                        onClick={async () => {
                            /* try {
                                const buffer = await excelExport(gridRef);
                                if (buffer) {
                                    saveAs(new Blob([buffer]), 'logs.xlsx');
                                }
                            } catch (e) {
                                alert(t('text.failedToExport'));
                            } */
                        }}
                    >
                        <SiMicrosoftexcel />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="ag-theme-quartz" style={styles.gridBox}>
                <React.StrictMode>
                    <AgGridReact<ITask>
                        ref={gridRef}
                        rowData={data}
                        columnDefs={columnDefs}
                        autoSizeStrategy={{
                            type: 'fitCellContents',
                        }}
                        suppressDragLeaveHidesColumns={true}
                        localeText={localText[lng]}
                        rowSelection="single"
                        rowBuffer={50}
                        context={{
                            handleErrorsClick: (task: ITask) =>
                                history.push(routePath.taskErrors.create(task.id)),
                        }}
                        getRowStyle={(params: RowClassParams<ITask>) => {
                            const task = params.data;
                            if (task && (!task.isCompleted || task.errorMessage || task.hasError)) {
                                return { background: colors.red, color: colors.white };
                            }
                        }}
                    />
                </React.StrictMode>
            </div>
        </div>
    );
};
