/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        header: {
            margin: '2rem 0 0 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        headerAmbassador: {
            color: colors.blue,
            userSelect: 'text',
        },
        headerActivityPeriod: {
            color: colors.blue,
            userSelect: 'none',
            marginTop: '0.5rem',
            fontSize: '1.1rem',
            fontWeight: 'bolder',
        },
        button: {
            width: widthBelow600px ? '25rem' : '30rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        ambassadorSkeleton: {
            marginTop: '2rem',
            width: '15rem',
            height: '2rem',
        },
        activityPeriodSkeleton: {
            marginTop: '0.5rem',
            width: '15rem',
            height: '1.5rem',
        },
    });
