/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useFetchAfasEmployees } from './hooks';
import { handleQueryError } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { ComboBoxInput } from '../../../components';

export const AfasEmployeeComboBox = (props: any) => {
    const history = useHistory();

    const { isLoading, data, error } = useFetchAfasEmployees();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <ComboBoxInput
            {...props}
            options={data}
            isLoading={isLoading}
            getOptionLabel={(data) => {
                if (data.lastName && data.initials) {
                    return `${data.id} - ${data.lastName}, ${data.initials}`;
                } else if (data.lastName && !data.initials) {
                    return `${data.id} - ${data.lastName}`;
                } else {
                    return data.id;
                }
            }}
        />
    );
};
