/**@jsxImportSource @emotion/react */
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { ICustomStyle } from './types';
import { colors } from '../../../helpers';

export const useStyles = (
    field: FieldInputProps<any>,
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    cs?: ICustomStyle
) => ({
    textField: {
        // Standard
        '& .MuiInput-underline': {
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
            color: cs && cs.textColor ? cs.textColor : colors.blue,
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: cs && cs.inputColor ? cs.inputColor : colors.blue,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor:
                Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? cs && cs.errorTextColor
                        ? cs.errorTextColor
                        : colors.red
                    : cs && cs.inputActiveColor
                    ? cs.inputActiveColor
                    : cs && cs.inputColor
                    ? cs.inputColor
                    : colors.blue,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: `${cs && cs.inputColor ? cs.inputColor : colors.blue} !important`,
        },
        // Label
        '& .MuiInputLabel-animated': {
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
            color:
                cs && cs.inputActiveColor
                    ? cs.inputActiveColor
                    : cs && cs.inputColor
                    ? cs.inputColor
                    : colors.blue,
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            userSelect: 'none',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color:
                cs && cs.inputActiveColor
                    ? cs.inputActiveColor
                    : cs && cs.inputColor
                    ? cs.inputColor
                    : colors.blue,
        },
        // Error label
        '& .MuiFormLabel-root.Mui-error': {
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
        },
        // Error text
        '& .MuiFormHelperText-root': {
            fontSize:
                cs && cs.errorFontSize
                    ? cs.errorFontSize
                    : cs && cs.fontSize
                    ? `calc(${cs.fontSize} * 0.6)`
                    : '1.2rem',
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            fontWeight: 'bold',
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
            userSelect: 'none',
        },
        '& .MuiInput-underline.Mui-error:after': {
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
        },
        // Disabled
        '& .MuiFormLabel-root.Mui-disabled': {
            color: cs && cs.textColor ? cs.textColor : colors.blue,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: `${cs && cs.textColor ? cs.textColor : colors.blue} !important`,
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${cs && cs.inputColor ? cs.inputColor : colors.blue}`,
        },
        '& .MuiTypography-root': {
            color: cs && cs.inputColor ? cs.inputColor : colors.blue,
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
            userSelect: 'none',
        },
    },
});
