export const isoDateStringToLocaleDateString = (isoDateString: string): string => {
    const date = String(new Date(isoDateString).getUTCDate());
    const month = String(new Date(isoDateString).getUTCMonth() + 1);
    const year = String(new Date(isoDateString).getUTCFullYear());

    const dateString = String(date).length === 2 ? String(date) : `0${String(date)}`;
    const monthString = String(month).length === 2 ? String(month) : `0${String(month)}`;
    const yearString = String(year);

    return `${dateString}-${monthString}-${yearString}`;
};
