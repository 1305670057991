import { IAfasEmployee } from './types';
import { Cache, useApi } from '../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAfasEmployees = () => {
    const api = useApi();

    return useQuery<IAfasEmployee[], any>(Cache.afasEmployeesCreateCandidates, () =>
        api.get('/afas-employees/create').then((res) => res.data)
    );
};
