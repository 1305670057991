import { IAfasEmployee } from '../hooks';

export const getEmployeeNumber = (
    isSuperAdmin: boolean,
    afasEmployeeFieldValue: IAfasEmployee | null,
    currentEmployeeNumberValue: string | null
): string | undefined => {
    if (isSuperAdmin) {
        return afasEmployeeFieldValue ? afasEmployeeFieldValue.id.trim() : undefined;
    }
    return currentEmployeeNumberValue ? currentEmployeeNumberValue : undefined;
};
