import { IUpdateCandidateVariables } from './types';
import { ICandidate } from '../../../hooks';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useUpdateCandidate = (candidateId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<ICandidate>, any, IUpdateCandidateVariables>(({ body }) =>
        api.patch(`/candidates/${candidateId}`, body)
    );
};
