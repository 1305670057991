import { ICreateActivityPeriodVariables } from './types';
import { useApi } from '../../../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useCreateActivityPeriod = (candidateId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<void>, any, ICreateActivityPeriodVariables>(({ body }) =>
        api.post(`/candidate-activity-periods/${candidateId}`, body)
    );
};
