/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../../helpers';

export const useStyles = (widthBelow800px: boolean, widthBelow600px: boolean) =>
    designStyles({
        content: {
            width: '100%',
            padding: '0 5rem',
            display: 'flex',
            flexDirection: widthBelow800px ? 'column' : 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        searchSkeleton: {
            height: '5rem',
            width: widthBelow600px ? '30rem' : '40rem',
            margin: '4rem 4rem',
        },
        contentSkeleton: {
            width: widthBelow600px ? '30rem' : '40rem',
            height: '33rem',
            boxShadow: `5px 5px 8px 5px ${colors.darkBoxShadow}`,
            margin: widthBelow600px ? '2rem 0' : '3rem',
        },
    });
