/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { colors } from '../../helpers';
import { ICustomStyle } from './types';

export const useStyles = (variant: 'success' | 'error', { top }: ICustomStyle) => ({
    root: css({
        zIndex: 10000,
        position: 'fixed',
        maxWidth: '25%',
        backgroundColor:
            variant === 'success' ? colors.snackSuccessBackground : colors.snackErrorBackground,
        padding: '1.5rem 1rem',
        borderRadius: '0.5rem',
        color: colors.white,
        display: 'flex',
        alignItems: 'center',
        top: top,
        left: '2rem',
    }),
    icon: css({
        display: 'flex',
        fontSize: '3rem',
        marginRight: '1rem',
    }),
    message: css({
        fontWeight: 'bold',
        fontSize: '1.5rem',
        userSelect: 'none',
        marginRight: '2rem',
    }),
    closeIcon: css({
        display: 'flex',
        fontSize: '2rem',
        cursor: 'pointer',
        transition: 'all 0.2s',
        '&:hover': {
            transform: 'scale(1.5)',
        },
    }),
});
