/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useChangePassword } from './hooks';
import { colors, handleError, IRouteParams, routePath } from '../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { useMediaQuery } from '@mui/material';
import { Page, TextFieldInput, Button } from '../../../components';
import { BiLockAlt } from 'react-icons/bi';

export const ChangePasswordAdmin = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();

    const changePassword = useChangePassword(id);

    return (
        <Page isPageSubmitting={changePassword.isLoading} style={styles.root}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.changePassword')}</h1>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            password: '',
                            confirmPassword: '',
                        } as IFormValues
                    }
                    validate={({ password, confirmPassword }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!password.trim()) errors.password = t('form.error.requiredField');
                        else if (password.trim().length < 6) {
                            errors.password = t('form.error.minSixChars');
                        } else if (password.trim().length > 32) {
                            errors.password = t('form.error.max32Chars');
                        } else if (!validator.matches(password.trim(), '(?=.*?[#?!@$%^&*-])')) {
                            errors.password = t('form.error.minOneSpecialChar');
                        }
                        if (!confirmPassword.trim()) {
                            errors.confirmPassword = t('form.error.requiredField');
                        } else if (confirmPassword.trim().length < 6) {
                            errors.confirmPassword = t('form.error.minSixChars');
                        } else if (confirmPassword.trim().length > 32) {
                            errors.confirmPassword = t('form.error.max32Chars');
                        } else if (
                            !validator.matches(confirmPassword.trim(), '(?=.*?[#?!@$%^&*-])')
                        ) {
                            errors.confirmPassword = t('form.error.minOneSpecialChar');
                        } else if (confirmPassword.trim() !== password.trim()) {
                            errors.password = t('form.error.valuesDoNotMatch');
                            errors.confirmPassword = t('form.error.valuesDoNotMatch');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ password }) => {
                        try {
                            await changePassword.mutateAsync({
                                body: { newPassword: password.trim() },
                            });
                            history.push(routePath.admins.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form noValidate style={styles.contentForm}>
                            <Field
                                style={styles.formField}
                                name="password"
                                type="password"
                                label={t('form.label.password')}
                                placeholder={t('form.placeholder.password')}
                                icon={<BiLockAlt />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="confirmPassword"
                                type="password"
                                label={t('form.label.confirmPassword')}
                                placeholder={t('form.placeholder.confirmPassword')}
                                icon={<BiLockAlt />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.changePassword')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.push(routePath.admins.create())}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Page>
    );
};
