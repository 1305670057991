/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { Role, routePath, useAuth } from '../../../../helpers';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, Menu, Fade } from '@mui/material';

export const DashboardsMenuItem = () => {
    const styles = useStyles();
    const { currentUserHasRoles } = useAuth();
    const client = useQueryClient();
    const history = useHistory();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <button css={styles.menuItem} onClick={handleClick}>
                {t('text.dashboards')}
            </button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {currentUserHasRoles(
                    Role.superAdmin,
                    Role.admin,
                    Role.recruiter,
                    Role.ambassador
                ) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.ambassadorHoursDashboard.create());
                        }}
                    >
                        {t('text.ambassadorHours')}
                    </MenuItem>
                ) : null}
                {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.salesHoursDashboard.create());
                        }}
                    >
                        {t('text.salesHours')}
                    </MenuItem>
                ) : null}
                {currentUserHasRoles(
                    Role.superAdmin,
                    Role.admin,
                    Role.recruiter,
                    Role.ambassador
                ) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.ambassadorPipelineDashboard.create());
                        }}
                    >
                        {t('text.ambassadorPipeline')}
                    </MenuItem>
                ) : null}
                {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.salesPipelineDashboard.create());
                        }}
                    >
                        {t('text.salesPipeline')}
                    </MenuItem>
                ) : null}
                {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.oranjelinkSalesPipelineDashboard.create());
                        }}
                    >
                        {t('text.olSalesPipeline')}
                    </MenuItem>
                ) : null}
                {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.candidatesDashboard.create());
                        }}
                    >
                        {t('text.candidates')}
                    </MenuItem>
                ) : null}
                {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.recruitmentDashboard.create());
                        }}
                    >
                        {t('text.recruitment')}
                    </MenuItem>
                ) : null}
                {currentUserHasRoles(
                    Role.superAdmin,
                    Role.admin,
                    Role.recruiter,
                    Role.ambassador
                ) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.scoreboardDashboard.create());
                        }}
                    >
                        {t('text.scoreboard')}
                    </MenuItem>
                ) : null}
            </Menu>
        </React.Fragment>
    );
};
