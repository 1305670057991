/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { IAmbassador } from '../../hooks';
import { IPayload as IDeleteModalPayload } from '../../../../helpers/reducers/delete/types';
import { IPayload as IResendQRModalPayload } from '../../../../helpers/reducers/resend-qr-code/types';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components';
import { MainPanel, InfoPanel } from './panels';
import { Actions } from './actions/Actions';

interface IProps {
    ambassador: IAmbassador;
    openDelete: (payload: IDeleteModalPayload) => void;
    openResendQR: (payload: IResendQRModalPayload) => void;
}

export const AmbassadorCard = ({ ambassador, openDelete, openResendQR }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const { t } = useTranslation();

    return (
        <Card
            firstPanel={<MainPanel ambassador={ambassador} />}
            secondPanel={<InfoPanel ambassador={ambassador} />}
            actions={
                <Actions
                    ambassador={ambassador}
                    openDelete={openDelete}
                    openResendQR={openResendQR}
                />
            }
            firstPanelLabel={t('text.ambassador')}
            secondPanelLabel={t('text.info')}
            cs={{
                width: widthBelow600px ? '30rem' : '40rem',
                margin: widthBelow600px ? '2rem 0' : '3rem',
                contentHeight: '20rem',
            }}
        />
    );
};
