import * as React from 'react';
import { IPayload, IState, TAction } from './types';

const reducer = (state: IState, action: TAction): IState => {
    switch (action.type) {
        case 'OPEN_DELETE':
            return {
                isDeleteOpen: true,
                itemToDeleteId: action.payload!.itemToDeleteId,
                itemToDeleteName: action.payload!.itemToDeleteName,
            };
        case 'CLOSE_DELETE':
            return { isDeleteOpen: false, itemToDeleteId: '', itemToDeleteName: '' };
        default:
            return state;
    }
};

export const useDeleteModal = () => {
    const [{ isDeleteOpen, itemToDeleteId, itemToDeleteName }, dispatch] = React.useReducer(
        reducer,
        {
            isDeleteOpen: false,
            itemToDeleteId: '',
            itemToDeleteName: '',
        }
    );

    return {
        isDeleteOpen,
        itemToDeleteId,
        itemToDeleteName,
        openDelete: (payload: IPayload) => dispatch({ type: 'OPEN_DELETE', payload }),
        closeDelete: () => dispatch({ type: 'CLOSE_DELETE' }),
    };
};
