/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { ICandidateDashboardCandidate } from '../../hooks/fetch-candidates/types';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import { localText, dateFilter } from '../../../../helpers';
//import { saveAs } from 'file-saver';
import { Tooltip, IconButton } from '@mui/material';
import { SiMicrosoftexcel } from 'react-icons/si';
import { GiResize } from 'react-icons/gi';
import { GridSkeleton } from '../grid-skeleton/GridSkeleton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../../../helpers/ag-grid/ag-grid.css';

interface IProps {
    data: ICandidateDashboardCandidate[];
    lng: string;
}

export const Grid = ({ data, lng }: IProps) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const gridRef = React.createRef<AgGridReact>();
    const [destroyed, setDestroyed] = React.useState(false);

    const columnDefs = React.useMemo(
        (): ColDef[] => [
            {
                colId: 'candidate',
                headerName: t('text.candidate'),
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
                valueGetter: (
                    params: ValueGetterParams<ICandidateDashboardCandidate, string>
                ): string => {
                    const candidate = params.data;
                    if (candidate) {
                        return candidate.employeeNumber
                            ? `${candidate.name} - ${candidate.employeeNumber}`
                            : candidate.name;
                    }
                    return '';
                },
            },
            {
                colId: 'department',
                headerName: t('text.department'),
                field: `department.${lng}`,
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'status',
                headerName: t('text.status'),
                field: 'status',
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
                valueFormatter: (
                    params: ValueFormatterParams<ICandidateDashboardCandidate, string>
                ): string => {
                    const { value } = params;
                    return value ? t(`candidateStatus.${value}`) : '';
                },
            },
            {
                colId: 'ambassador',
                headerName: t('text.ambassador'),
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
                valueGetter: (
                    params: ValueGetterParams<ICandidateDashboardCandidate, string>
                ): string => {
                    const candidate = params.data;
                    if (candidate) {
                        return `${candidate.ambassadorName} - ${candidate.ambassadorEmployeeNumber}`;
                    }
                    return '';
                },
            },
            {
                colId: 'firstInvitedAt',
                headerName: t('text.dateInvited'),
                field: 'firstInvitedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'invitationCompletedAt',
                headerName: t('text.dateRegistered'),
                field: 'invitationCompletedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (
                    params: ValueFormatterParams<ICandidateDashboardCandidate, string>
                ): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'rejectedAt',
                headerName: t('text.dateRejected'),
                field: 'rejectedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (
                    params: ValueFormatterParams<ICandidateDashboardCandidate, string>
                ): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'firstWorkWeek',
                headerName: t('text.firstWorkWeek'),
                sortable: true,
                filter: true,
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueGetter: (
                    params: ValueGetterParams<ICandidateDashboardCandidate, number>
                ): string => {
                    const candidate = params.data;
                    if (candidate) {
                        return candidate.firstWorkedYear && candidate.firstWorkedWeek
                            ? `W${candidate.firstWorkedWeek}-${candidate.firstWorkedYear}`
                            : '';
                    }
                    return '';
                },
            },
            {
                colId: 'workedHours',
                headerName: t('text.totalHours'),
                sortable: true,
                filter: 'agNumberColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueGetter: (
                    params: ValueGetterParams<ICandidateDashboardCandidate, number>
                ): number => {
                    const candidate = params.data;
                    if (candidate) {
                        return candidate.workedHours ? candidate.workedHours : 0;
                    }
                    return 0;
                },
            },
            {
                colId: 'paidFee',
                headerName: t('text.paidFee'),
                sortable: true,
                filter: 'agNumberColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueGetter: (
                    params: ValueGetterParams<ICandidateDashboardCandidate, number>
                ): number => {
                    const candidate = params.data;
                    if (candidate) {
                        return candidate.paidFee ? candidate.paidFee : 0;
                    }
                    return 0;
                },
            },
        ],
        [lng, t]
    );

    React.useEffect(() => {
        setDestroyed(true);
        const timer = setTimeout(() => setDestroyed(false));
        return () => {
            clearTimeout(timer);
        };
    }, [lng, setDestroyed]);

    if (destroyed) return <GridSkeleton />;
    return (
        <div style={styles.gridContainer}>
            <div style={styles.gridHeader}>
                <p style={styles.gridHeaderText}>{t('text.candidates')}</p>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.resize')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderResizeButton}
                        onClick={() => {
                            const allColumnIds: string[] = [];
                            if (gridRef.current) {
                                gridRef.current.api.getColumns()!.forEach((column) => {
                                    allColumnIds.push(column.getId());
                                });

                                gridRef.current.api.autoSizeColumns(allColumnIds);
                            }
                        }}
                    >
                        <GiResize />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.exportToExcel')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderExportButton}
                        onClick={async () => {
                            /* try {
                                const buffer = await excelExport(gridRef);
                                if (buffer) {
                                    saveAs(new Blob([buffer]), 'logs.xlsx');
                                }
                            } catch (e) {
                                alert(t('text.failedToExport'));
                            } */
                        }}
                    >
                        <SiMicrosoftexcel />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="ag-theme-quartz" style={styles.gridBox}>
                <React.StrictMode>
                    <AgGridReact<ICandidateDashboardCandidate>
                        ref={gridRef}
                        rowData={data}
                        columnDefs={columnDefs}
                        autoSizeStrategy={{
                            type: 'fitCellContents',
                        }}
                        suppressDragLeaveHidesColumns={true}
                        localeText={localText[lng]}
                        rowSelection="single"
                        rowBuffer={50}
                    />
                </React.StrictMode>
            </div>
        </div>
    );
};
