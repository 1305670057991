/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useResetPassword } from './hooks';
import { colors, handleError, routePath, SocialMediaLink } from '../../helpers';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import validator from 'validator';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { LanguageMenu, TextFieldInput, Button } from '../../components';
import { BiLockAlt } from 'react-icons/bi';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';

export const ResetPassword = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const widthBelow200px = useMediaQuery('(max-width: 200px)');
    const styles = useStyles(widthBelow600px, widthBelow200px);
    const history = useHistory();
    const resetPassword = useResetPassword();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            <div style={styles.header}>
                <h1 css={styles.headerText}>Oranjelink</h1>
                <LanguageMenu />
            </div>
            <div style={styles.content}>
                <Formik
                    initialValues={
                        {
                            password: '',
                            confirmPassword: '',
                        } as IFormValues
                    }
                    validate={({ password, confirmPassword }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!password.trim()) errors.password = t('form.error.requiredField');
                        else if (password.trim().length < 6) {
                            errors.password = t('form.error.minSixChars');
                        } else if (password.trim().length > 32) {
                            errors.password = t('form.error.max32Chars');
                        } else if (!validator.matches(password.trim(), '(?=.*?[#?!@$%^&*-])')) {
                            errors.password = t('form.error.minOneSpecialChar');
                        }
                        if (!confirmPassword.trim()) {
                            errors.confirmPassword = t('form.error.requiredField');
                        } else if (confirmPassword.trim().length < 6) {
                            errors.confirmPassword = t('form.error.minSixChars');
                        } else if (confirmPassword.trim().length > 32) {
                            errors.confirmPassword = t('form.error.max32Chars');
                        } else if (
                            !validator.matches(confirmPassword.trim(), '(?=.*?[#?!@$%^&*-])')
                        ) {
                            errors.confirmPassword = t('form.error.minOneSpecialChar');
                        } else if (confirmPassword.trim() !== password.trim()) {
                            errors.password = t('form.error.valuesDoNotMatch');
                            errors.confirmPassword = t('form.error.valuesDoNotMatch');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ password }) => {
                        const token = localStorage.getItem('rpvid');
                        if (token) {
                            try {
                                await resetPassword.mutateAsync({
                                    body: { token, newPassword: password.trim() },
                                });
                                history.push(routePath.verifyResetPassword.create());
                            } catch (e) {
                                handleError(e, ({ status, statusText }) => {
                                    if (status === 404) {
                                        localStorage.removeItem('rpvid');
                                        history.push(
                                            routePath.notFoundVerifyResetPasswordError.create()
                                        );
                                    } else if (status === 401) {
                                        localStorage.removeItem('rpvid');
                                        history.push(
                                            routePath.unauthorizedVerifyResetPasswordError.create()
                                        );
                                    } else if (status === 500) {
                                        localStorage.removeItem('rpvid');
                                        history.push(
                                            routePath.internalServerVerifyResetPasswordError.create()
                                        );
                                    } else {
                                        alert(statusText);
                                    }
                                });
                            }
                        } else {
                            history.push(routePath.unauthorizedVerifyResetPasswordError.create());
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form noValidate style={styles.contentForm}>
                            <h1 css={styles.formTitle}>{t('text.resetPassword')}</h1>
                            <Field
                                style={styles.formField}
                                name="password"
                                type="password"
                                label={t('form.label.newPassword')}
                                placeholder={t('form.placeholder.newPassword')}
                                icon={<BiLockAlt />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="confirmPassword"
                                type="password"
                                label={t('form.label.confirmPassword')}
                                placeholder={t('form.placeholder.confirmPassword')}
                                icon={<BiLockAlt />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.resetPassword')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div style={styles.footer}>
                <div style={styles.footerLinkBox}>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillFacebook />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillInstagram />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.LinkedIn}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillLinkedin />
                    </a>
                </div>
                <div style={styles.footerRightContainer}>
                    <div>
                        <Link css={styles.footerRightContainerLink} to={routePath.terms.create()}>
                            {t('text.generalTerms')}
                        </Link>
                        &nbsp;/&nbsp;
                        <Link
                            css={styles.footerRightContainerLink}
                            to={routePath.privacyPolicy.create()}
                        >
                            {t('text.privacyPolicy')}
                        </Link>
                    </div>
                    <p>
                        {`De Oranjegroep \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop - Public Bèta`}
                    </p>
                </div>
            </div>
        </div>
    );
};
