/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { isoDateStringToLocaleDateString } from '../../../../helpers';
import { ICandiateActivityPeriodTableData, IActionCellProps } from './types';
import { useTranslation } from 'react-i18next';

interface IProps {
    title: string;
    data: ICandiateActivityPeriodTableData[];
    context?: any;
    actionCell: React.FunctionComponent<IActionCellProps>;
}

export const CandidateActivityPeriodTable = ({ title, data, context, actionCell }: IProps) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            <h2 style={styles.title}>{title}</h2>
            <div style={styles.wrapper}>
                <table style={styles.table}>
                    <thead style={styles.tableHeader}>
                        <tr>
                            <th style={styles.tableHeaderSerialNumberCell}>
                                {t('text.serialNumber')}
                            </th>
                            <th style={styles.tableHeaderEmployeeNumberCell}>
                                {t('text.employeeNumber')}
                            </th>
                            <th style={styles.tableHeaderDateCell}>{t('text.startDate')}</th>
                            <th style={styles.tableHeaderDateCell}>{t('text.endDate')}</th>
                            <th style={styles.tableHeaderActionCell}>{'\u00a0'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length === 0 ? (
                            <tr style={styles.tableNoDataRow}>
                                <td style={styles.tableRowNoDataCell}>{t('text.noPeriod')}</td>
                            </tr>
                        ) : (
                            data.map((dataRow, index) => (
                                <tr key={index} style={styles.tableRow}>
                                    <td style={styles.tableRowSerialNumberCell}>
                                        {dataRow.serialNumber}
                                    </td>
                                    <td style={styles.tableRowEmployeeNumberCell}>
                                        {dataRow.empolyeeNumber}
                                    </td>
                                    <td style={styles.tableRowDateCell}>
                                        {isoDateStringToLocaleDateString(dataRow.startDate)}
                                    </td>
                                    <td style={styles.tableRowDateCell}>
                                        {dataRow.endDate
                                            ? isoDateStringToLocaleDateString(dataRow.endDate)
                                            : '\u00a0'}
                                    </td>
                                    <td style={styles.tableRowActionCell}>
                                        {React.createElement(actionCell, {
                                            data: dataRow,
                                            context,
                                        })}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
