/**@jsxImportSource @emotion/react */
import { designStyles, colors, LightenDarkenColor } from '../../helpers';

export const useStyles = (widthBelow600px: boolean, widthBelow200px: boolean) =>
    designStyles({
        root: {
            minHeight: '100vh',
            maxWidth: '100%',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            background: colors.ghostWhite,
        },
        header: {
            margin: '2rem',
            display: 'flex',
            justifyContent: 'space-between',
        },
        headerText: {
            marginLeft: '1rem',
            fontSize: widthBelow200px ? '1rem' : '2rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            userSelect: 'none',
            color: colors.blue,
        },
        content: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        contentForm: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: widthBelow600px ? '30rem' : '40rem',
        },
        formTitle: {
            marginBottom: '4rem',
            alignSelf: 'center',
            color: colors.blue,
            userSelect: 'none',
            marginTop: '8rem',
            transition: 'all 0.2s',
            '&:hover': {
                transform: 'skewY(2deg) skewx(15deg) scale(1.1)',
                textShadow: `0.5rem 1rem 2rem ${colors.darkBoxShadow}`,
            },
        },
        formField: {
            marginBottom: '2rem',
            justifyContent: 'center',
        },
        footer: {
            display: 'flex',
            color: colors.blue,
            margin: '1rem',
            userSelect: 'none',
        },
        footerLinkBox: {
            flex: 3,
            fontSize: '3rem',
        },
        footerlinkBoxLink: {
            display: 'inline-flex',
            transition: 'all 0.2s',
            '&:link': {
                color: colors.blue,
            },
            '&:visited': {
                color: colors.blue,
            },
            '&:active': {
                outline: 'none',
                transform: 'translateY(-1px)',
                boxShadow: `0 1rem 2rem ${colors.darkBoxShadow}`,
                color: colors.blue,
            },
            '&:focus': {
                outline: 'none',
                transform: 'translateY(-1px)',
                boxShadow: `0 1rem 2rem ${colors.darkBoxShadow}`,
                color: colors.blue,
            },
            '&:hover': {
                transform: 'translateY(-0.3rem)',
                boxShadow: `0 1rem 2rem ${colors.darkBoxShadow}`,
                color: colors.blue,
            },
            '&:focus-visible': {
                outline: 'none',
            },
            '&:not(:last-child)': {
                marginRight: '1rem',
            },
        },
        footerRightContainer: {
            fontWeight: 'bold',
            fontSize: '1.2rem',
            alignSelf: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        footerRightContainerLink: {
            color: colors.blue,
            fontFamily: 'inherit',
            textDecoration: 'none',
            transition: 'all 0.3s',
            '&:hover': {
                color: LightenDarkenColor(colors.blue, 80),
            },
        },
    });
