/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        candidateName: {
            fontFamily: 'inherit',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: colors.blue,
            marginTop: '2rem',
            userSelect: 'none',
        },
        form: {
            margin: '4rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: widthBelow600px ? '30rem' : '40rem',
        },
        formField: {
            marginBottom: '2rem',
        },
        formButton: {
            width: '15rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        fieldSkeleton: {
            width: '100%',
            height: '5rem',
            marginBottom: '5rem',
        },
        buttonSkeleton: {
            width: '15rem',
            height: '3.5rem',
            '&:not(:last-child)': {
                marginBottom: '2rem',
            },
        },
        candidateNameSkeleton: {
            width: '30rem',
            height: '1.5rem',
            marginTop: '2rem',
        },
    });
