/**@jsxImportSource @emotion/react */
import React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import {
    useFetchCandidate,
    useFetchAfasEmployees,
    usePromoteCandidate,
    IAfasEmployee,
} from './hooks';
import {
    IRouteParams,
    Language,
    Role,
    colors,
    handleError,
    handleQueryError,
    routePath,
    useAuth,
} from '../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikErrors } from 'formik';
import validator from 'validator';
import {
    Page,
    TextFieldInput,
    DatePickerInput,
    LanguageSelectInput,
    Button,
} from '../../../components';
import { useMediaQuery, Skeleton } from '@mui/material';
import { BiLockAlt } from 'react-icons/bi';
import { AfasEmployeeComboBox } from './afas-employee-combo-box/AfasEmployeeComboBox';

export const PromoteCandidate = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles, getCurrentUser } = useAuth();
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();

    const candidateResponse = useFetchCandidate(id);
    const afasEmployeesResponse = useFetchAfasEmployees(id);
    const promoteCandidate = usePromoteCandidate(id);

    React.useEffect(() => {
        if (candidateResponse.data) {
            if (
                currentUserHasRoles(Role.admin) &&
                ((candidateResponse.data.ambassador &&
                    candidateResponse.data.ambassador.admin.user.id !== getCurrentUser().userId &&
                    candidateResponse.data.user.department!.id !== getCurrentUser().departmentId) ||
                    (candidateResponse.data.recruitmentAgency &&
                        candidateResponse.data.recruitmentAgency.admin.user.id !==
                            getCurrentUser().userId))
            ) {
                history.push(routePath.unauthorizedError.create());
            }
        }
    }, [currentUserHasRoles, getCurrentUser, history, candidateResponse.data]);

    const getAfasEmployee = React.useCallback((): IAfasEmployee | null => {
        if (
            candidateResponse.data &&
            candidateResponse.data.employeeNumber &&
            afasEmployeesResponse.data
        ) {
            const filter = afasEmployeesResponse.data.filter(
                (afasEmployee) => afasEmployee.id.trim() === candidateResponse.data.employeeNumber
            );
            return filter.length > 0 ? filter[0] : null;
        }
        return null;
    }, [candidateResponse.data, afasEmployeesResponse.data]);

    if (candidateResponse.error) {
        return handleQueryError(candidateResponse.error, history);
    }
    if (afasEmployeesResponse.error) {
        return handleQueryError(afasEmployeesResponse.error, history);
    }
    return (
        <Page
            isPageSubmitting={promoteCandidate.isLoading}
            title={t('text.promoteCandidateToAmbassador')}
            style={styles.root}
        >
            {candidateResponse.isLoading ? (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={styles.candidateNameSkeleton}
                />
            ) : null}
            {candidateResponse.data ? (
                <p css={styles.candidateName}>
                    {`${t('text.candidate')}: ${candidateResponse.data.user.firstName} ${
                        candidateResponse.data.user.lastName
                    }`}
                </p>
            ) : null}
            {candidateResponse.isLoading || afasEmployeesResponse.isLoading ? (
                <div style={styles.form}>
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            ) : null}
            {candidateResponse.data && afasEmployeesResponse.data ? (
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            afasEmployee: getAfasEmployee(),
                            password: '',
                            confirmPassword: '',
                            startDate: '',
                            language: '' as Language,
                        } as IFormValues
                    }
                    validate={({
                        afasEmployee,
                        password,
                        confirmPassword,
                        startDate,
                        language,
                    }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!afasEmployee) {
                            errors.afasEmployee = t('form.error.requiredField');
                        }
                        if (!password.trim()) errors.password = t('form.error.requiredField');
                        else if (password.trim().length < 6) {
                            errors.password = t('form.error.minSixChars');
                        } else if (password.trim().length > 32) {
                            errors.password = t('form.error.max32Chars');
                        } else if (!validator.matches(password.trim(), '(?=.*?[#?!@$%^&*-])')) {
                            errors.password = t('form.error.minOneSpecialChar');
                        }
                        if (!confirmPassword.trim()) {
                            errors.confirmPassword = t('form.error.requiredField');
                        } else if (confirmPassword.trim().length < 6) {
                            errors.confirmPassword = t('form.error.minSixChars');
                        } else if (confirmPassword.trim().length > 32) {
                            errors.confirmPassword = t('form.error.max32Chars');
                        } else if (
                            !validator.matches(confirmPassword.trim(), '(?=.*?[#?!@$%^&*-])')
                        ) {
                            errors.confirmPassword = t('form.error.minOneSpecialChar');
                        } else if (confirmPassword.trim() !== password.trim()) {
                            errors.password = t('form.error.valuesDoNotMatch');
                            errors.confirmPassword = t('form.error.valuesDoNotMatch');
                        }
                        if (!startDate) {
                            errors.startDate = t('form.error.requiredField');
                        } else if (!validator.isDate(startDate, { format: 'YYYY-MM-DD' })) {
                            errors.startDate = t('form.error.invalidDate');
                        } else if (new Date(startDate).getDay() !== 1) {
                            errors.startDate = t('form.error.startDateMustBeMonday');
                        } else if (
                            new Date(startDate).getTime() < new Date('2023-01-02').getTime()
                        ) {
                            errors.startDate = t('form.error.startDateEarlierThanOL');
                        }
                        if (!language) errors.language = t('form.error.requiredField');
                        return errors;
                    }}
                    onSubmit={async (
                        { afasEmployee, password, startDate, language },
                        { setErrors }
                    ) => {
                        try {
                            await promoteCandidate.mutateAsync({
                                body: {
                                    employeeNumber: afasEmployee!.id.trim(),
                                    password: password.trim(),
                                    startDate: new Date(startDate).toISOString(),
                                    language,
                                },
                            });
                            history.push(routePath.candidates.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText, data }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 400) {
                                    if (data.message === 'Employee number is already registered') {
                                        setErrors({
                                            afasEmployee: t('form.error.candidateIsRegistered'),
                                        });
                                    } else if (
                                        data.message === 'Start date is earlier than 02-01-2023'
                                    ) {
                                        setErrors({
                                            startDate: t('form.error.startDateEarlierThanOL'),
                                        });
                                    } else if (data.message === 'Start date must be a Monday') {
                                        setErrors({
                                            startDate: t('form.error.startDateMustBeMonday'),
                                        });
                                    } else if (
                                        data.message === 'Candidate has no employee number'
                                    ) {
                                        setErrors({
                                            startDate: t('form.error.candidateNoEmployeeNumber'),
                                        });
                                    } else {
                                        alert(statusText);
                                    }
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form noValidate style={styles.form}>
                            <Field
                                style={styles.formField}
                                data={afasEmployeesResponse.data}
                                isLoading={afasEmployeesResponse.isLoading}
                                name="afasEmployee"
                                label={t('form.label.employeeNumber')}
                                placeholder={t('form.placeholder.employeeNumber')}
                                fontSize="2rem"
                                inputColor={colors.blue}
                                textColor={colors.blue}
                                disabled={isSubmitting}
                                component={AfasEmployeeComboBox}
                            />
                            <Field
                                style={styles.formField}
                                name="password"
                                type="password"
                                label={t('form.label.password')}
                                placeholder={t('form.placeholder.newPassword')}
                                icon={<BiLockAlt />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="confirmPassword"
                                type="password"
                                label={t('form.label.confirmPassword')}
                                placeholder={t('form.placeholder.confirmPassword')}
                                icon={<BiLockAlt />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="startDate"
                                label={t('form.label.startDate')}
                                disabled={isSubmitting}
                                component={DatePickerInput}
                            />
                            <Field
                                style={styles.formField}
                                name="language"
                                label={t('form.label.language')}
                                disabled={isSubmitting}
                                component={LanguageSelectInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.promote')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.push(routePath.candidates.create())}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </Page>
    );
};
