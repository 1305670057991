import * as React from 'react';
import { IPayload, IState, TAction } from './types';

const reducer = (state: IState, action: TAction): IState => {
    switch (action.type) {
        case 'OPEN_RESEND_QR':
            return {
                isResendQROpen: true,
                resendQRUserId: action.payload!.resendQRUserId,
                resendQRUsername: action.payload!.resendQRUsername,
            };
        case 'CLOSE_RESEND_QR':
            return { isResendQROpen: false, resendQRUserId: '', resendQRUsername: '' };
        default:
            return state;
    }
};

export const useResendQRModal = () => {
    const [{ isResendQROpen, resendQRUserId, resendQRUsername }, dispatch] = React.useReducer(
        reducer,
        {
            isResendQROpen: false,
            resendQRUserId: '',
            resendQRUsername: '',
        }
    );

    return {
        isResendQROpen,
        resendQRUserId,
        resendQRUsername,
        openResendQR: (payload: IPayload) => dispatch({ type: 'OPEN_RESEND_QR', payload }),
        closeResendQR: () => dispatch({ type: 'CLOSE_RESEND_QR' }),
    };
};
