import { ICreateAmbassadorCandidateVariables } from './types';
import { ICandidate } from '../../../hooks';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useCreateCandidate = () => {
    const api = useApi();

    return useMutation<AxiosResponse<ICandidate>, any, ICreateAmbassadorCandidateVariables>(
        ({ body }) => api.post('/candidates', body)
    );
};
