/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IScoreboardData } from '../types';
import { localText, useAuth, Role } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
//import { saveAs } from 'file-saver';
import { Tooltip, IconButton } from '@mui/material';
import { SiMicrosoftexcel } from 'react-icons/si';
import { GiResize } from 'react-icons/gi';
import { GridSkeleton } from '../grid-skeleton/GridSkeleton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../../../helpers/ag-grid/ag-grid.css';

interface IProps {
    label: string;
    amountLabel: string;
    data: IScoreboardData[];
    lng: string;
}

export const Grid = ({ label, amountLabel, data, lng }: IProps) => {
    const styles = useStyles();
    const { currentUserHasRoles } = useAuth();
    const { t } = useTranslation();
    const gridRef = React.createRef<AgGridReact>();
    const [destroyed, setDestroyed] = React.useState(false);

    const columnDefs = React.useMemo(
        (): ColDef[] => [
            {
                colId: 'ambassador',
                headerName: t('text.ambassador'),
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
                valueGetter: ({ data }): string => {
                    const scoreboard = data as IScoreboardData;
                    return !currentUserHasRoles(Role.ambassador)
                        ? `${scoreboard.ambassadorName} - ${scoreboard.ambassadorEmployeeNumber}`
                        : scoreboard.ambassadorName;
                },
            },
            {
                colId: 'department',
                headerName: t('text.department'),
                field: `department.${lng}`,
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'amount',
                headerName: amountLabel,
                field: 'amount',
                sortable: true,
                filter: 'agNumberColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
            },
        ],
        [lng, t, currentUserHasRoles, amountLabel]
    );

    React.useEffect(() => {
        setDestroyed(true);
        const timer = setTimeout(() => setDestroyed(false));
        return () => {
            clearTimeout(timer);
        };
    }, [lng, setDestroyed]);

    if (destroyed) return <GridSkeleton />;
    return (
        <div style={styles.gridContainer}>
            <div style={styles.gridHeader}>
                <p style={styles.gridHeaderText}>{label}</p>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.resize')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderResizeButton}
                        onClick={() => {
                            const allColumnIds: string[] = [];
                            if (gridRef.current) {
                                gridRef.current.api.getColumns()!.forEach((column) => {
                                    allColumnIds.push(column.getId());
                                });

                                gridRef.current.api.autoSizeColumns(allColumnIds);
                            }
                        }}
                    >
                        <GiResize />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.exportToExcel')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderExportButton}
                        onClick={async () => {
                            /* try {
                                const buffer = await excelExport(gridRef);
                                if (buffer) {
                                    saveAs(new Blob([buffer]), 'logs.xlsx');
                                }
                            } catch (e) {
                                alert(t('text.failedToExport'));
                            } */
                        }}
                    >
                        <SiMicrosoftexcel />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="ag-theme-quartz" style={styles.gridBox}>
                <React.StrictMode>
                    <AgGridReact<IScoreboardData>
                        ref={gridRef}
                        rowData={data}
                        columnDefs={columnDefs}
                        autoSizeStrategy={{
                            type: 'fitCellContents',
                        }}
                        suppressDragLeaveHidesColumns={true}
                        localeText={localText[lng]}
                        rowSelection="single"
                        rowBuffer={50}
                    />
                </React.StrictMode>
            </div>
        </div>
    );
};
