/**@jsxImportSource @emotion/react */
import { designStyles } from '../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        yearSelect: {
            margin: '2rem 0',
            width: '11rem',
        },
        content: {
            width: '100%',
            padding: '0 5rem',
            display: 'flex',
            flexDirection: widthBelow600px ? 'column' : 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
    });
