/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchInvitedCandidate, useUpdateInvitedCandidate } from './hooks';
import {
    IRouteParams,
    handleQueryError,
    isoDateStringToDatePicker,
    useSnack,
    colors,
    isValidPhoneNumber,
    routePath,
    handleError,
} from '../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import htmlParse from 'html-react-parser';
import { Formik, Form, Field, FormikErrors } from 'formik';
import validator from 'validator';
import {
    Snack,
    LanguageMenu,
    TextFieldInput,
    DatePickerInput,
    Button,
    CheckboxInput,
    PhoneFieldInput,
} from '../../../components';
import { useMediaQuery, Skeleton } from '@mui/material';
import { BiEnvelope } from 'react-icons/bi';
import { ProfessionComboBox } from '../profession-combo-box/ProfessionComboBox';

export const UpdateInvitedCandidate = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const widthBelow200px = useMediaQuery('(max-width: 200px)');
    const styles = useStyles(widthBelow600px, widthBelow200px);
    const { isSnackOpen, snackVariant, snackMessage, closeSnack, openSnack } = useSnack();
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchInvitedCandidate(id);
    const updateInvitedCandidate = useUpdateInvitedCandidate(id);

    React.useEffect(() => {
        if (data && data.invitationCompletedAt) {
            history.push(routePath.registered.create());
        }
    }, [data, history]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <div style={styles.root}>
            {isSnackOpen ? (
                <Snack
                    open={isSnackOpen}
                    variant={snackVariant}
                    message={snackMessage}
                    closeSnack={closeSnack}
                    cs={{
                        top: '8rem',
                    }}
                />
            ) : null}
            <div style={styles.header}>
                <h1 css={styles.headerText}>Oranjelink</h1>
                <LanguageMenu />
            </div>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.registration')}</h1>
                {data && (
                    <div style={styles.contentInvitationMessage}>
                        <p>
                            <strong>{`${t('candidateMessage.hi')} ${data.firstName},`}</strong>
                        </p>
                        <br />
                        <p>{t('candidateMessage.message')}</p>
                        <br />
                        <p>{t('candidateMessage.becomeColleague')}</p>
                        {data.invitationMessage && (
                            <React.Fragment>
                                <br />
                                <p>
                                    <strong>{`${t('candidateMessage.hostMessage')}:`}</strong>
                                    <br />
                                    {htmlParse(data.invitationMessage)}
                                </p>
                            </React.Fragment>
                        )}
                    </div>
                )}
                {isLoading ? (
                    <div style={styles.contentForm}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.termsSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.checkboxFieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.buttonSkeleton}
                        />
                    </div>
                ) : null}
                {data ? (
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                firstName: data.firstName,
                                lastName: data.lastName,
                                email: data.email,
                                confirmEmail: data.email,
                                birthdate: data.birthdate
                                    ? isoDateStringToDatePicker(data.birthdate)
                                    : '',
                                phoneNumber: data.phoneNumber ? data.phoneNumber : '',
                                professions: data.professions,
                                policy: false,
                            } as IFormValues
                        }
                        validate={({
                            firstName,
                            lastName,
                            email,
                            confirmEmail,
                            birthdate,
                            phoneNumber,
                            professions,
                            policy,
                        }) => {
                            let errors: FormikErrors<IFormValues> = {};
                            if (!firstName.trim()) errors.firstName = t('form.error.requiredField');
                            if (!lastName.trim()) errors.lastName = t('form.error.requiredField');
                            if (!email.trim()) errors.email = t('form.error.requiredField');
                            else if (email.trim().length > 127) {
                                errors.email = t('form.error.max127Chars');
                            } else if (!validator.isEmail(email.trim())) {
                                errors.email = t('form.error.invalidEmail');
                            }
                            if (!confirmEmail.trim())
                                errors.confirmEmail = t('form.error.requiredField');
                            else if (confirmEmail.trim().length > 127) {
                                errors.confirmEmail = t('form.error.max127Chars');
                            } else if (!validator.isEmail(confirmEmail.trim())) {
                                errors.confirmEmail = t('form.error.invalidEmail');
                            } else if (email.trim() !== confirmEmail.trim()) {
                                errors.email = t('form.error.valuesDoNotMatch');
                                errors.confirmEmail = t('form.error.valuesDoNotMatch');
                            }
                            if (!birthdate) {
                                errors.birthdate = t('form.error.requiredField');
                            } else if (!validator.isDate(birthdate, { format: 'YYYY-MM-DD' })) {
                                errors.birthdate = t('form.error.invalidDate');
                            } else if (
                                new Date().getFullYear() - new Date(birthdate).getFullYear() <
                                16
                            ) {
                                errors.birthdate = t('form.error.youngerThen16');
                            } else if (
                                new Date().getFullYear() - new Date(birthdate).getFullYear() >
                                70
                            ) {
                                errors.birthdate = t('form.error.olderThen70');
                            }
                            if (!phoneNumber) {
                                errors.phoneNumber = t('form.error.requiredField');
                            } else if (!isValidPhoneNumber(phoneNumber.trim())) {
                                errors.phoneNumber = t('form.error.invalidPhoneNumber');
                            }
                            if (professions.length <= 0) {
                                errors.professions = t('form.error.minOneProfession');
                            }
                            if (!policy) {
                                errors.policy = t('form.error.needToAgreeWithPrivacyPolicy');
                            }
                            return errors;
                        }}
                        onSubmit={async (
                            { firstName, lastName, email, birthdate, phoneNumber, professions },
                            { setErrors }
                        ) => {
                            try {
                                await updateInvitedCandidate.mutateAsync({
                                    body: {
                                        firstName: firstName.trim(),
                                        lastName: lastName.trim(),
                                        email: email.trim(),
                                        birthdate: new Date(birthdate).toISOString(),
                                        phoneNumber: phoneNumber,
                                        professions,
                                    },
                                });
                                history.push(routePath.welcome.create());
                            } catch (e) {
                                handleError(e, ({ status, statusText, data }) => {
                                    if (status === 404) {
                                        history.push(routePath.notFoundError.create());
                                    } else if (status === 400) {
                                        if (
                                            data.message === 'Employee number is already registered'
                                        ) {
                                            openSnack({
                                                snackVariant: 'error',
                                                snackMessage: t('form.error.candidateIsRegistered'),
                                            });
                                        } else if (data.message === 'Email is already registered') {
                                            setErrors({
                                                email: t('form.error.emailIsRegistered'),
                                                confirmEmail: t('form.error.emailIsRegistered'),
                                            });
                                        } else if (
                                            data.message === 'Phone number is already registered'
                                        ) {
                                            setErrors({
                                                phoneNumber: t(
                                                    'form.error.phoneNumberIsRegistered'
                                                ),
                                            });
                                        } else if (
                                            data.message === 'Candidate is already registered'
                                        ) {
                                            history.push(routePath.registered.create());
                                        } else {
                                            alert(statusText);
                                        }
                                    } else if (status === 500) {
                                        history.push(routePath.internalServerError.create());
                                    } else alert(statusText);
                                });
                            }
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form noValidate style={styles.contentForm}>
                                <Field
                                    style={styles.formField}
                                    name="firstName"
                                    type="text"
                                    label={t('form.label.firstName')}
                                    placeholder={t('form.placeholder.firstName')}
                                    disabled={isSubmitting}
                                    component={TextFieldInput}
                                />
                                <Field
                                    style={styles.formField}
                                    name="lastName"
                                    type="text"
                                    label={t('form.label.lastName')}
                                    placeholder={t('form.placeholder.lastName')}
                                    disabled={isSubmitting}
                                    component={TextFieldInput}
                                />
                                <Field
                                    style={styles.formField}
                                    name="email"
                                    type="email"
                                    label={t('form.label.email')}
                                    placeholder={t('form.placeholder.email')}
                                    icon={<BiEnvelope />}
                                    disabled={isSubmitting}
                                    component={TextFieldInput}
                                />
                                <Field
                                    style={styles.formField}
                                    name="confirmEmail"
                                    type="email"
                                    label={t('form.label.confirmEmail')}
                                    placeholder={t('form.placeholder.confirmEmail')}
                                    icon={<BiEnvelope />}
                                    disabled={isSubmitting}
                                    component={TextFieldInput}
                                />
                                <Field
                                    style={styles.formField}
                                    name="birthdate"
                                    label={t('form.label.birthdate')}
                                    disabled={isSubmitting}
                                    component={DatePickerInput}
                                />
                                <Field
                                    style={styles.formField}
                                    name="phoneNumber"
                                    type="tel"
                                    label={t('form.label.phoneNumber')}
                                    placeholder={t('form.placeholder.phoneNumber')}
                                    disabled={isSubmitting}
                                    component={PhoneFieldInput}
                                />
                                <Field
                                    style={styles.formField}
                                    name="professions"
                                    label={t('form.label.professions')}
                                    placeholder={t('form.placeholder.professions')}
                                    fontSize="2rem"
                                    inputColor={colors.blue}
                                    disabled={isSubmitting}
                                    component={ProfessionComboBox}
                                />
                                <a
                                    css={styles.formLink}
                                    href="https://oranjelink.com/privacy-policy"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    {`${t('text.privacyPolicy')}  →`}
                                </a>
                                <Field
                                    style={styles.formCheckboxField}
                                    es={{
                                        formControl: { alignItems: 'flex-start' },
                                    }}
                                    name="policy"
                                    label={t('form.label.agreePrivacyPolicy')}
                                    component={CheckboxInput}
                                />
                                <Button
                                    css={styles.formButton}
                                    type="submit"
                                    disabled={isSubmitting}
                                    spinner={isSubmitting}
                                    cs={{
                                        color: colors.blue,
                                    }}
                                >
                                    {t('text.register')}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </div>

            <div style={styles.footer}>
                <p style={styles.footerCopyright}>
                    {`ORANJEGROEP \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop`}
                </p>
            </div>
        </div>
    );
};
