import { IUpdateDepartmentVariables } from './types';
import { IDepartment } from '../../../hooks';
import { useApi } from '../../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useUpdateDepartment = (departmentId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<IDepartment>, any, IUpdateDepartmentVariables>(({ body }) =>
        api.patch(`/departments/${departmentId}`, body)
    );
};
