/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle, IElementSxProps } from './types';
import { useTranslation } from 'react-i18next';
import { InputBase, IconButton, InputBaseProps } from '@mui/material';
import { BiSearchAlt } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';

interface IProps {
    search: string;
    cs?: ICustomStyle;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
}

export const Search = ({
    search,
    cs,
    es,
    esx,
    disabled,
    style,
    className,
    setSearch,
}: IProps & InputBaseProps) => {
    const styles = useStyles(cs);
    const { t } = useTranslation();
    const [value, setValue] = React.useState(search);

    return (
        <div
            css={
                es && es.root
                    ? [styles.root, es.root as Interpolation<Theme>, style as Interpolation<Theme>]
                    : [styles.root, style as Interpolation<Theme>]
            }
            className={className}
        >
            <IconButton
                css={
                    es && es.searchIcon
                        ? [styles.searchIcon, es.searchIcon as Interpolation<Theme>]
                        : styles.searchIcon
                }
                sx={esx && esx.searchIcon ? esx.searchIcon : undefined}
                onClick={() => setSearch(value)}
            >
                <BiSearchAlt />
            </IconButton>
            <InputBase
                css={
                    es && es.inputBase
                        ? [styles.inputBase, es.inputBase as Interpolation<Theme>]
                        : styles.inputBase
                }
                sx={esx && esx.inputBase ? esx.inputBase : undefined}
                placeholder={t('form.placeholder.search')}
                value={value}
                disabled={disabled ? disabled : false}
                onChange={(e) => setValue(e.target.value)}
                autoComplete="new-password"
                inputProps={{
                    role: 'presentation',
                    autoComplete: 'new-password',
                    spellCheck: false,
                    'aria-live': 'polite',
                }}
            />
            <IconButton
                css={
                    value
                        ? es && es.closeIconVisible
                            ? [styles.closeIconVisible, es.closeIconVisible as Interpolation<Theme>]
                            : styles.closeIconVisible
                        : es && es.closeIconHidden
                        ? [styles.closeIconHidden, es.closeIconHidden as Interpolation<Theme>]
                        : styles.closeIconHidden
                }
                sx={
                    value
                        ? esx && esx.closeIconVisible
                            ? esx.closeIconVisible
                            : undefined
                        : esx && esx.closeIconHidden
                        ? esx.closeIconHidden
                        : undefined
                }
                onClick={() => {
                    setValue('');
                    setSearch('');
                }}
            >
                <AiOutlineClose />
            </IconButton>
        </div>
    );
};
