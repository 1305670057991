/**@jsxImportSource @emotion/react */
import { CSSProperties } from 'react';
import { colors } from '../../../helpers';
import { ICustomStyle } from './types';

export const useStyles = (cs?: ICustomStyle) => ({
    formControl: {
        '& .MuiTypography-root': {
            color: cs && cs.textColor ? cs.textColor : cs && cs.color ? cs.color : colors.blue,
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            userSelect: 'none',
            fontSize:
                cs && cs.textFontSize ? cs.textFontSize : cs && cs.fontSize ? cs.fontSize : '2rem',
        },
    } as CSSProperties,
    checkbox: {
        color: cs && cs.checkboxColor ? cs.checkboxColor : colors.blue,
        '&.Mui-checked': {
            color:
                cs && cs.checkboxColor ? cs.checkboxColor : cs && cs.color ? cs.color : colors.blue,
        },
        '& .MuiSvgIcon-root': {
            fontSize:
                cs && cs.checkboxFontSize
                    ? cs.checkboxFontSize
                    : cs && cs.fontSize
                    ? cs.fontSize
                    : '2rem',
        },
    },
    formHelper: {
        '&.MuiFormHelperText-root': {
            fontSize:
                cs && cs.errorFontSize
                    ? cs.errorFontSize
                    : cs && cs.fontSize
                    ? `calc(${cs.fontSize} * 0.6)`
                    : '1.2rem',
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            fontWeight: 'bold',
            color: cs && cs.errorColor ? cs.errorColor : colors.red,
            userSelect: 'none',
        },
    },
});
