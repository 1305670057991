/**@jsxImportSource @emotion/react */
import { designStyles } from '../../../helpers';

export const useStyles = (widthBelow1000px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        filter: {
            width: '90vw',
            marginTop: '4rem',
            display: 'flex',
            justifyContent: 'center',
        },
        filterNumberSelect: {
            width: '11rem',
        },
        gridsContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: widthBelow1000px ? 'column' : 'row',
        },
        gridContainer: {
            width: widthBelow1000px ? '100%' : '50%',
            padding: widthBelow1000px ? '0 10rem' : '0 5rem',
        },
    });
