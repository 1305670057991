/**@jsxImportSource @emotion/react */
import React from 'react';
import { useStyles } from './style';
import { useFetchAmbassadorEmployedPeriods } from './hooks';
import {
    IRouteParams,
    useAuth,
    Role,
    routePath,
    handleQueryError,
    colors,
} from '../../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Page, Button } from '../../../../components';
import { Grid } from './grid/Grid';
import { GridSkeleton } from './grid-skeleton/GridSkeleton';

export const AmbassadorEmployedPeriods = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles, getCurrentUser } = useAuth();
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const { data, isLoading, error } = useFetchAmbassadorEmployedPeriods(id);

    React.useEffect(() => {
        if (data) {
            if (
                currentUserHasRoles(Role.admin) &&
                getCurrentUser().departmentId !== data.ambassadorDepartmentId &&
                getCurrentUser().userId !== data.ambassadorAdminUserId
            ) {
                history.push(routePath.unauthorizedError.create());
            }
        }
    }, [currentUserHasRoles, getCurrentUser, data, history]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page title={t('text.employedPeriods')} style={styles.root}>
            {isLoading && (
                <React.Fragment>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.ambassadorSkeleton}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.activityPeriodSkeleton}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.activityPeriodSkeleton}
                    />
                    <GridSkeleton />
                </React.Fragment>
            )}
            {data && (
                <React.Fragment>
                    <div style={styles.header}>
                        <h2 style={styles.headerAmbassador}>{data.ambassador}</h2>
                        <p style={styles.headerActivityPeriod}>{t('text.activityPeriod')}:</p>
                        <p style={styles.headerActivityPeriod}>{`${t('text.startDate')}: W${
                            data.startWeek
                        }-${data.startYear} - ${t('text.endDate')}: ${
                            data.endWeek ? `W${data.endWeek}-${data.endYear}` : ''
                        }`}</p>
                    </div>
                    <Grid employedPeriods={data.employedPeriods} lng={i18n.language} />
                </React.Fragment>
            )}
            <Button
                style={styles.button}
                onClick={() => history.goBack()}
                cs={{
                    color: colors.blue,
                    padding: '1rem 1rem',
                    fontSize: '1.25rem',
                }}
            >
                {t('text.back')}
            </Button>
        </Page>
    );
};
