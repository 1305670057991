import { IAmbassadorWorkedHour } from '../hooks/fetch-ambassador-declaration-period/types/ambassador-worked-hour.interface';
import { IAmbassadorCandidateWorkedHour } from '../hooks/fetch-ambassador-declaration-period/types';

export const getCandidateDeclaration = (
    ambassadorWorkedHour: IAmbassadorWorkedHour,
    candidateWorkedHour: IAmbassadorCandidateWorkedHour
): string => {
    if (
        ambassadorWorkedHour.isActive &&
        ambassadorWorkedHour.amount >= ambassadorWorkedHour.minWorkedHourPerWeekForFee &&
        candidateWorkedHour.isActive &&
        candidateWorkedHour.amount >= candidateWorkedHour.minWorkedHourPerWeekForFee
    ) {
        return (candidateWorkedHour.amount * candidateWorkedHour.ambassadorFee).toFixed(2);
    }
    return '0.00';
};
