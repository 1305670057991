/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { IAdmin } from '../hooks/types';
import { IPayload as IDeleteModalPayload } from '../../../helpers/reducers/delete/types';
import { IPayload as IResendQrModalPayload } from '../../../helpers/reducers/resend-qr-code/types';
import { useTranslation } from 'react-i18next';
import { Grid } from './grid/Grid';

interface IProps {
    data: IAdmin[];
    openDelete: (payload: IDeleteModalPayload) => void;
    openResendQR: (payload: IResendQrModalPayload) => void;
}

export const GridView = ({ data, openDelete, openResendQR }: IProps) => {
    const { i18n } = useTranslation();

    return (
        <Grid data={data} lng={i18n.language} openDelete={openDelete} openResendQR={openResendQR} />
    );
};
