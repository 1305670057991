/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            width: '100%',
            textAlign: 'center',
        },
        closeButton: {
            color: colors.red,
        },
        deleteButton: {
            color: colors.red,
        },
        employedPeriodButton: {
            color: colors.blue,
        },
    });
