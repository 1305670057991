/**@jsxImportSource @emotion/react */
import { designStyles } from '../../../../../helpers';

export const useStyles = () =>
    designStyles({
        navBoxList: {
            position: 'absolute',
            display: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            listStyle: 'none',
            textAlign: 'center',
            width: '100%',
        },
        listButton: {
            width: '30rem',
            marginBottom: '1rem',
        },
    });
