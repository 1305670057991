import { IInviteCreateCandidateVariables } from './types';
import { useApi } from '../../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { ICandidate } from '../../../../hooks';

export const useInviteCreateCandidate = () => {
    const api = useApi();

    return useMutation<AxiosResponse<ICandidate>, any, IInviteCreateCandidateVariables>(
        ({ body }) => api.post('/candidates/invite', body)
    );
};
