/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { routePath, colors, useAuth, Role } from '../../../../../helpers';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '../../../../buttons/link-button/LinkButton';

interface IProps {
    inputRef: React.RefObject<HTMLInputElement>;
    closeCollapsedMenu: () => void;
}

export const DashboardsMenuList = ({ inputRef, closeCollapsedMenu }: IProps) => {
    const styles = useStyles();
    const { currentUserHasRoles } = useAuth();
    const client = useQueryClient();
    const { t } = useTranslation();

    return (
        <ul style={styles.navBoxList} className="navLinks">
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter, Role.ambassador) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.ambassadorHoursDashboard.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.ambassadorHours')}
                    </LinkButton>
                </li>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.salesHoursDashboard.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.salesHours')}
                    </LinkButton>
                </li>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter, Role.ambassador) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.ambassadorPipelineDashboard.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.ambassadorPipeline')}
                    </LinkButton>
                </li>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.salesPipelineDashboard.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.salesPipeline')}
                    </LinkButton>
                </li>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.oranjelinkSalesPipelineDashboard.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.olSalesPipeline')}
                    </LinkButton>
                </li>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.candidatesDashboard.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.candidates')}
                    </LinkButton>
                </li>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.recruitmentDashboard.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.recruitment')}
                    </LinkButton>
                </li>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter, Role.ambassador) ? (
                <li>
                    <LinkButton
                        style={styles.listButton}
                        to={routePath.scoreboardDashboard.create()}
                        cs={{
                            color: colors.white,
                            textColor: colors.blue,
                            fontSize: '1.5rem',
                            boxShadowColor: colors.darkBoxShadow,
                        }}
                        onClick={() => {
                            document.body.style.overflow = 'initial';
                            inputRef.current!.checked = false;
                            closeCollapsedMenu();
                            client.cancelQueries();
                        }}
                    >
                        {t('text.scoreboard')}
                    </LinkButton>
                </li>
            ) : null}
        </ul>
    );
};
