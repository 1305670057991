/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchUser, IDepartment } from './hooks';
import { colors, handleQueryError, useAuth, routePath, Role } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, useMediaQuery } from '@mui/material';
import { Page, LinkButton } from '../../components';
import { BiUserCircle } from 'react-icons/bi';

export const User = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { getCurrentUser, currentUserHasRoles } = useAuth();
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const { isLoading, data, error } = useFetchUser(getCurrentUser().userId);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page style={styles.root}>
            {isLoading ? <Skeleton variant="rectangular" style={styles.cardSkeleton} /> : null}
            {data ? (
                <div style={styles.card}>
                    <div style={styles.cardHeading}>
                        <BiUserCircle size="15%" />
                        <div style={styles.cardHeadingNameBox}>
                            <p style={styles.nameBoxText}>{data.name}</p>
                        </div>
                    </div>
                    <div style={styles.cardContent}>
                        <div style={styles.cardContentTextBox}>
                            <p css={styles.textBoxText}>E-mail:&nbsp;</p>
                            <p style={styles.textBoxDataText}>{data.email}</p>
                        </div>
                        <div style={styles.cardContentTextBox}>
                            <p css={styles.textBoxText}>{t('text.language')}:&nbsp;</p>
                            <p style={styles.textBoxDataText}>{data.language.toUpperCase()}</p>
                        </div>
                        {currentUserHasRoles(Role.admin, Role.ambassador) && (
                            <div style={styles.cardContentTextBox}>
                                <p css={styles.textBoxText}>{t('text.department')}:&nbsp;</p>
                                <p style={styles.textBoxDataText}>
                                    {data.department[i18n.language as keyof IDepartment]}
                                </p>
                            </div>
                        )}
                    </div>
                    <div style={styles.cardActions}>
                        <LinkButton
                            css={styles.cardActionButton}
                            to={routePath.userChangeEmail.create(getCurrentUser().userId)}
                            cs={{
                                color: colors.blue,
                                padding: '1rem 1rem',
                                fontSize: '1.25rem',
                            }}
                        >
                            {t('text.changeEmail')}
                        </LinkButton>
                        <LinkButton
                            css={styles.cardActionButton}
                            to={routePath.userChangeLanguage.create(getCurrentUser().userId)}
                            cs={{
                                color: colors.blue,
                                padding: '1rem 1rem',
                                fontSize: '1.25rem',
                            }}
                        >
                            {t('text.changeLanguage')}
                        </LinkButton>
                        <LinkButton
                            css={styles.cardActionButton}
                            to={routePath.userChangePassword.create(getCurrentUser().userId)}
                            cs={{
                                color: colors.blue,
                                padding: '1rem 1rem',
                                fontSize: '1.25rem',
                            }}
                        >
                            {t('text.changePassword')}
                        </LinkButton>
                        <LinkButton
                            css={styles.cardActionButton}
                            to={routePath.userChangeEmailSubscription.create(
                                getCurrentUser().userId
                            )}
                            cs={{
                                color: colors.blue,
                                padding: '1rem 1rem',
                                fontSize: '1.25rem',
                            }}
                        >
                            {t('text.emailSubscription')}
                        </LinkButton>
                    </div>
                </div>
            ) : null}
        </Page>
    );
};
