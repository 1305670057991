/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { colors, routePath } from '../../helpers';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Page, LinkButton } from '../../components';

export const Settings = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { t } = useTranslation();

    return (
        <Page style={styles.root}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.settings')}</h1>
                <LinkButton
                    css={styles.contentLinkButton}
                    to={routePath.generalSettings.create()}
                    cs={{
                        color: colors.blue,
                        padding: '1rem 1rem',
                        fontSize: '1.25rem',
                    }}
                >
                    {t('text.generalSettings')}
                </LinkButton>
                <LinkButton
                    css={styles.contentLinkButton}
                    to={routePath.departmentSettings.create()}
                    cs={{
                        color: colors.blue,
                        padding: '1rem 1rem',
                        fontSize: '1.25rem',
                    }}
                >
                    {t('text.departments')}
                </LinkButton>
                <LinkButton
                    css={styles.contentLinkButton}
                    to={routePath.professionGroupsSetting.create()}
                    cs={{
                        color: colors.blue,
                        padding: '1rem 1rem',
                        fontSize: '1.25rem',
                    }}
                >
                    {t('text.professionGroups')}
                </LinkButton>
                <LinkButton
                    css={styles.contentLinkButton}
                    to={routePath.professionsSettings.create()}
                    cs={{
                        color: colors.blue,
                        padding: '1rem 1rem',
                        fontSize: '1.25rem',
                    }}
                >
                    {t('text.professions')}
                </LinkButton>
                <LinkButton
                    css={styles.contentLinkButton}
                    to={routePath.tasks.create()}
                    cs={{
                        color: colors.blue,
                        padding: '1rem 1rem',
                        fontSize: '1.25rem',
                    }}
                >
                    {t('text.tasks')}
                </LinkButton>
                <LinkButton
                    css={styles.contentLinkButton}
                    to={routePath.newsletter.create()}
                    cs={{
                        color: colors.blue,
                        padding: '1rem 1rem',
                        fontSize: '1.25rem',
                    }}
                >
                    {t('text.sendNewsletter')}
                </LinkButton>
            </div>
        </Page>
    );
};
