/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { createTheme } from '@mui/material';
import { ICustomStyle, IElementSxProps } from './types';
import { colors } from '../../../helpers';

export const useStyles = (focused: boolean, cs?: ICustomStyle, esx?: IElementSxProps) => {
    const theme = createTheme();

    const root = css({
        '& .MuiIconButton-root': {
            color: cs && cs.color ? cs.color : colors.blue,
        },
        width: '100%',
    });

    const label = css({
        color: cs && cs.color ? cs.color : colors.blue,
        fontSize: cs && cs.fontSize ? `calc(${cs.fontSize} * 1.28)` : '1.6rem',
        fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
        marginBottom: cs && cs.fontSize ? `calc(${cs.fontSize} * 0.4)` : '0.5rem',
        userSelect: 'none',
    });

    const editorRoot = {
        border: cs && cs.color ? `solid 1px ${cs.color}` : `solid 1px ${colors.blue}`,
        outline: focused
            ? cs && cs.color
                ? `solid 1px ${cs.color}`
                : `solid 1px ${colors.blue}`
            : 'none',
        borderRadius: '1rem',
        width: '100%',
    };

    const editorBox = {
        maxHeight: cs && cs.height ? cs.height : '150px',
        height: cs && cs.height ? cs.height : '150px',
        overflow: 'auto',
        borderTop: cs && cs.color ? `solid 1px ${cs.color}` : `solid 1px ${colors.blue}`,
        padding: '1rem',
        color: cs && cs.color ? cs.color : colors.blue,
        fontSize: cs && cs.fontSize ? cs.fontSize : '1.25rem',
        fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
    };

    const editorPlaceholder = {
        position: 'absolute',
        fontSize: cs && cs.fontSize ? cs.fontSize : '1.25rem',
        padding: '1rem',
        fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
        color: cs && cs.color ? cs.color : colors.blue,
    };

    const helperText = {
        '&.MuiFormHelperText-root': {
            fontSize: cs && cs.errorTextFontSize ? cs.errorTextFontSize : '1.2rem',
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            fontWeight: 'bold',
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
            userSelect: 'none',
        },
    };

    Object.assign(theme, {
        overrides: {
            MUIRichTextEditor: {
                root: esx && esx.editorRoot ? { ...editorRoot, ...esx.editorRoot } : editorRoot,
                editor: esx && esx.editorBox ? { ...editorBox, ...esx.editorBox } : editorBox,
                placeHolder:
                    esx && esx.editorPlaceholder
                        ? { ...editorPlaceholder, ...esx.editorPlaceholder }
                        : editorPlaceholder,
            },
        },
    });

    return {
        root,
        label,
        helperText,
        theme,
    };
};
