/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@mui/material';
import { BiMessageError } from 'react-icons/bi';

export const ActionsCell = ({ data, context: { handleErrorsClick } }: ICellRendererParams) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            {data.hasError && (
                <Tooltip arrow title={<h1>{t('text.errors')}</h1>}>
                    <IconButton onClick={() => handleErrorsClick(data)}>
                        <BiMessageError style={styles.errorsButton} />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};
