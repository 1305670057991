import * as React from 'react';
import { IRefreshTokenResponse } from './types';
import { useAuth } from '../../auth-context/AuthContext';
import axios, { AxiosInstance } from 'axios';
import { isTokenExpired, decodeToken } from './helpers';

const ApiContext = React.createContext<AxiosInstance>(
    axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    })
);

interface IProps {
    children?: React.ReactNode;
}

export const ApiProvider = ({ children }: IProps): JSX.Element => {
    const { currentUser, setCurrentUser } = useAuth();
    const api = React.useMemo(() => {
        const axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        axiosInstance.interceptors.request.use(async (config) => {
            const token = currentUser ? currentUser.token : undefined;
            if (token) {
                if (isTokenExpired(token)) {
                    try {
                        const {
                            data: { isStatusOk, refreshToken, accessToken },
                        } = await axios.post<IRefreshTokenResponse>(
                            `${process.env.REACT_APP_BACKEND_URL}/refresh-tokens`,
                            {
                                refreshToken: localStorage.getItem('rtid'),
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            }
                        );
                        if (isStatusOk) {
                            config.headers!.Authorization = `Bearer ${accessToken}`;
                            localStorage.setItem('rtid', refreshToken);
                            setCurrentUser(decodeToken(accessToken));
                            return config;
                        }
                        localStorage.removeItem('rtid');
                        setCurrentUser(null);
                        return config;
                    } catch {
                        return config;
                    }
                }
                config.headers!.Authorization = `Bearer ${token}`;
                return config;
            }
            return config;
        });

        return axiosInstance;
    }, [currentUser, setCurrentUser]);

    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export const useApi = () => React.useContext(ApiContext);
