import { useQuery } from 'react-query';
import { Cache, useApi } from '../../../../helpers';
import { ICandidateDashboardCandidate } from './types';

export const useFetchCandidates = () => {
    const api = useApi();

    return useQuery<ICandidateDashboardCandidate[], any>(
        Cache.dashboardCandidates,
        ({ signal }) => api.get('/dashboards/candidates', { signal }).then((res) => res.data),
        { refetchOnWindowFocus: false }
    );
};
