/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useCreateAmbassador, useFetchDepartments } from './hooks';
import { IDepartment } from '../hooks';
import {
    colors,
    handleError,
    handleQueryError,
    useAuth,
    Role,
    Language,
    routePath,
    isoDateStringToDatePicker,
} from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, useMediaQuery } from '@mui/material';
import validator from 'validator';
import { Formik, Form, Field, FormikErrors } from 'formik';
import {
    Page,
    TextFieldInput,
    LanguageSelectInput,
    Button,
    SelectInput,
    DatePickerInput,
} from '../../../components';
import { BiEnvelope, BiLockAlt } from 'react-icons/bi';
import { AfasEmployeeComboBox } from './afas-employee-combo-box/AfasEmployeeComboBox';

export const CreateAmbassador = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles } = useAuth();
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const { isLoading, data, error } = useFetchDepartments(currentUserHasRoles(Role.superAdmin));
    const createAmbassador = useCreateAmbassador();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            isPageSubmitting={createAmbassador.isLoading}
            style={styles.root}
            title={t('text.createAmbassador')}
        >
            {isLoading ? (
                <div style={styles.form}>
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    {currentUserHasRoles(Role.superAdmin) ? (
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                    ) : null}
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            ) : null}
            {!currentUserHasRoles(Role.superAdmin) ||
            (currentUserHasRoles(Role.superAdmin) && data) ? (
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            ambassador: null,
                            email: '',
                            confirmEmail: '',
                            password: '',
                            confirmPassword: '',
                            startDate: isoDateStringToDatePicker(new Date().toISOString()),
                            department: '',
                            language: '' as Language,
                        } as IFormValues
                    }
                    validate={({
                        ambassador,
                        email,
                        confirmEmail,
                        password,
                        confirmPassword,
                        startDate,
                        department,
                        language,
                    }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (ambassador === null) {
                            errors.ambassador = t('form.error.requiredField');
                        }
                        if (!email.trim()) errors.email = t('form.error.requiredField');
                        else if (email.trim().length > 127) {
                            errors.email = t('form.error.max127Chars');
                        } else if (!validator.isEmail(email.trim())) {
                            errors.email = t('form.error.invalidEmail');
                        }
                        if (!confirmEmail.trim())
                            errors.confirmEmail = t('form.error.requiredField');
                        else if (confirmEmail.trim().length > 127) {
                            errors.confirmEmail = t('form.error.max127Chars');
                        } else if (!validator.isEmail(confirmEmail.trim())) {
                            errors.confirmEmail = t('form.error.invalidEmail');
                        } else if (email.trim() !== confirmEmail.trim()) {
                            errors.email = t('form.error.valuesDoNotMatch');
                            errors.confirmEmail = t('form.error.valuesDoNotMatch');
                        }
                        if (!password.trim()) errors.password = t('form.error.requiredField');
                        else if (password.trim().length < 6) {
                            errors.password = t('form.error.minSixChars');
                        } else if (password.trim().length > 32) {
                            errors.password = t('form.error.max32Chars');
                        } else if (!validator.matches(password.trim(), '(?=.*?[#?!@$%^&*-])')) {
                            errors.password = t('form.error.minOneSpecialChar');
                        }
                        if (!confirmPassword.trim()) {
                            errors.confirmPassword = t('form.error.requiredField');
                        } else if (confirmPassword.trim().length < 6) {
                            errors.confirmPassword = t('form.error.minSixChars');
                        } else if (confirmPassword.trim().length > 32) {
                            errors.confirmPassword = t('form.error.max32Chars');
                        } else if (
                            !validator.matches(confirmPassword.trim(), '(?=.*?[#?!@$%^&*-])')
                        ) {
                            errors.confirmPassword = t('form.error.minOneSpecialChar');
                        } else if (confirmPassword.trim() !== password.trim()) {
                            errors.password = t('form.error.valuesDoNotMatch');
                            errors.confirmPassword = t('form.error.valuesDoNotMatch');
                        }
                        if (!startDate) {
                            errors.startDate = t('form.error.requiredField');
                        } else if (!validator.isDate(startDate, { format: 'YYYY-MM-DD' })) {
                            errors.startDate = t('form.error.invalidDate');
                        } else if (new Date(startDate).getDay() !== 1) {
                            errors.startDate = t('form.error.startDateMustBeMonday');
                        } else if (
                            new Date(startDate).getTime() < new Date('2023-01-02').getTime()
                        ) {
                            errors.startDate = t('form.error.startDateEarlierThanOL');
                        }
                        if (currentUserHasRoles(Role.superAdmin) && !department) {
                            errors.department = t('form.error.requiredField');
                        }

                        if (!language) errors.language = t('form.error.requiredField');
                        return errors;
                    }}
                    onSubmit={async (
                        { ambassador, email, password, startDate, department, language },
                        { setErrors }
                    ) => {
                        try {
                            await createAmbassador.mutateAsync({
                                body: {
                                    email: email.trim(),
                                    password: password.trim(),
                                    firstName: ambassador!.firstName
                                        ? ambassador!.firstName
                                        : 'Unknown',
                                    lastName: ambassador!.lastName
                                        ? ambassador!.lastName
                                        : 'Unknown',
                                    employeeNumber: ambassador!.id.trim(),
                                    startDate: new Date(startDate).toISOString(),
                                    departmentId: currentUserHasRoles(Role.superAdmin)
                                        ? department
                                        : undefined,
                                    language,
                                },
                            });
                            history.push(routePath.ambassadors.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText, data }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 400) {
                                    if (data.message === 'Employee number is already registered') {
                                        setErrors({
                                            ambassador: t('form.error.ambassadorIsRegistered'),
                                        });
                                    } else if (data.message === 'Email is already registered') {
                                        setErrors({
                                            email: t('form.error.emailIsRegistered'),
                                            confirmEmail: t('form.error.emailIsRegistered'),
                                        });
                                    } else if (
                                        data.message === 'Start date is earlier than 02-01-2023'
                                    ) {
                                        setErrors({
                                            startDate: t('form.error.startDateEarlierThanOL'),
                                        });
                                    } else if (data.message === 'Start date must be a Monday') {
                                        setErrors({
                                            startDate: t('form.error.startDateMustBeMonday'),
                                        });
                                    } else {
                                        alert(statusText);
                                    }
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form noValidate style={styles.form}>
                            <Field
                                style={styles.formField}
                                name="ambassador"
                                label={t('form.label.ambassador')}
                                placeholder={t('form.placeholder.ambassador')}
                                fontSize="2rem"
                                inputColor={colors.blue}
                                textColor={colors.blue}
                                disabled={isSubmitting}
                                component={AfasEmployeeComboBox}
                            />
                            <Field
                                style={styles.formField}
                                name="email"
                                type="email"
                                label={t('form.label.email')}
                                placeholder={t('form.placeholder.email')}
                                icon={<BiEnvelope />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="confirmEmail"
                                type="email"
                                label={t('form.label.confirmEmail')}
                                placeholder={t('form.placeholder.confirmEmail')}
                                icon={<BiEnvelope />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="password"
                                type="password"
                                label={t('form.label.password')}
                                placeholder={t('form.placeholder.newPassword')}
                                icon={<BiLockAlt />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="confirmPassword"
                                type="password"
                                label={t('form.label.confirmPassword')}
                                placeholder={t('form.placeholder.confirmPassword')}
                                icon={<BiLockAlt />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="startDate"
                                label={t('form.label.startDate')}
                                disabled={isSubmitting}
                                component={DatePickerInput}
                            />
                            {currentUserHasRoles(Role.superAdmin) ? (
                                <Field
                                    style={styles.formField}
                                    name="department"
                                    label={t('form.label.department')}
                                    items={data!.map((department) => {
                                        return {
                                            value: department.id,
                                            item: department[i18n.language as keyof IDepartment],
                                        };
                                    })}
                                    disabled={isSubmitting}
                                    component={SelectInput}
                                />
                            ) : null}
                            <Field
                                style={styles.formField}
                                name="language"
                                label={t('form.label.language')}
                                disabled={isSubmitting}
                                component={LanguageSelectInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.create')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.push(routePath.ambassadors.create())}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </Page>
    );
};
