/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle, IElementSxProps } from './types';
import { colors } from '../../../helpers';
import { FieldProps } from 'formik';
import { Autocomplete, TextFieldProps, TextField, InputAdornment, Fade } from '@mui/material';
import { Spinner } from '../../../components';

interface IProps {
    options?: any[];
    isLoading?: boolean;
    cs?: ICustomStyle;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
    getOptionLabel?: (option: any) => string;
    isOptionEqualToValue?: (option: any, value: any) => boolean;
}

export const ListComboBoxInput = ({
    options,
    isLoading,
    label,
    placeholder,
    disabled,
    cs,
    es,
    esx,
    style,
    className,
    getOptionLabel,
    isOptionEqualToValue,
    field,
    form: { errors, touched, setFieldValue, setFieldTouched },
}: IProps & TextFieldProps & FieldProps) => {
    const styles = useStyles(field, errors, touched, cs);

    return (
        <div
            css={
                es && es.root
                    ? [styles.root, es.root as Interpolation<Theme>, style as Interpolation<Theme>]
                    : [styles.root, style as Interpolation<Theme>]
            }
            className={className}
        >
            <label
                css={
                    es && es.label ? [styles.label, es.label as Interpolation<Theme>] : styles.label
                }
            >
                {label}
            </label>
            <Autocomplete
                value={field.value}
                multiple
                placeholder={field.value.length === 0 ? placeholder : undefined}
                disabled={disabled}
                options={options ? options : []}
                onChange={(_event, newValue) => {
                    setFieldValue(field.name, newValue, true);
                }}
                onBlur={() => {
                    setFieldTouched(field.name, true, true);
                }}
                onFocus={(event) => {
                    event.target.setAttribute('autocomplete', 'off');
                }}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth
                renderOption={(props: any) => {
                    return (
                        <span {...props} style={{ minHeight: '3rem' }}>
                            {props.key}
                        </span>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        css={
                            es && es.textField ? (es.textField as Interpolation<Theme>) : undefined
                        }
                        sx={
                            esx && esx.textField
                                ? { ...styles.textField, ...esx.textField }
                                : styles.textField
                        }
                        type="text"
                        placeholder={field.value.length === 0 ? placeholder : undefined}
                        autoComplete="new-password"
                        disabled={disabled}
                        fullWidth
                        error={Boolean(errors[field.name]) && Boolean(touched[field.name])}
                        helperText={
                            Boolean(errors[field.name]) && Boolean(touched[field.name])
                                ? errors[field.name]
                                : ' '
                        }
                        inputProps={{
                            ...params.inputProps,
                            role: 'presentation',
                            autoComplete: 'new-password',
                            spellCheck: false,
                            'aria-live': 'polite',
                            disabled: disabled,
                        }}
                        InputProps={
                            isLoading
                                ? {
                                      endAdornment: (
                                          <InputAdornment
                                              position="end"
                                              css={
                                                  es && es.endAdornment
                                                      ? (es.endAdornment as Interpolation<Theme>)
                                                      : undefined
                                              }
                                              sx={
                                                  esx && esx.endAdornment
                                                      ? {
                                                            ...styles.endAdornment,
                                                            ...esx.endAdornment,
                                                        }
                                                      : styles.endAdornment
                                              }
                                          >
                                              <Fade in={isLoading}>
                                                  <Spinner
                                                      variant="rond"
                                                      cs={{
                                                          color:
                                                              cs && cs.inputColor
                                                                  ? cs.inputColor
                                                                  : colors.blue,
                                                          radius:
                                                              cs && cs.fontSize
                                                                  ? cs.fontSize
                                                                  : '2rem',
                                                      }}
                                                  />
                                              </Fade>
                                          </InputAdornment>
                                      ),
                                  }
                                : params.InputProps
                        }
                    />
                )}
            />
        </div>
    );
};
