/**@jsxImportSource @emotion/react */
import { designStyles } from '../../../../../helpers';

export const useStyles = (shorter: boolean) =>
    designStyles({
        skeleton: {
            margin: '4rem 0 4rem 0',
            width: '90vw',
            height: shorter ? '53vh' : '57vh',
        },
    });
