import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useApi } from '../../../../../helpers';
import { IAmbassadorDeclarationPeriod } from '../../../hooks';
import { IProcessAmbassadorDeclarationPeriodsVariables } from './types';

export const useProcessAmbassadorDeclarationPeriods = () => {
    const api = useApi();

    return useMutation<
        AxiosResponse<IAmbassadorDeclarationPeriod>,
        any,
        IProcessAmbassadorDeclarationPeriodsVariables
    >(({ body }) => api.post('/tasks/ambassador-declaration-periods/process', body));
};
