/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchAmbassador, useFetchCandidatesByAmbassador } from './hooks';
import {
    handleQueryError,
    routePath,
    useDeleteModal,
    useRejectModal,
    View,
    getViewLocalStorage,
    IRouteParams,
} from '../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Tooltip, IconButton, Skeleton } from '@mui/material';
import { Page, ViewToggleButton } from '../../../components';
import { FcInvite } from 'react-icons/fc';
import { DeleteModal } from '../delete-modal/DeleteModal';
import { RejectModal } from '../reject-modal/RejectModal';
import { CardView, CardViewSkeleton } from '../card-view';
import { GridView, GridSkeleton } from '../grid-view';

export const AmbassadorCandidates = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles();
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();
    const [view, setView] = React.useState<View>(getViewLocalStorage());
    const [search, setSearch] = React.useState('');
    const { isDeleteOpen, itemToDeleteId, itemToDeleteName, openDelete, closeDelete } =
        useDeleteModal();
    const { isRejectOpen, itemToRejectId, itemToRejectName, openReject, closeReject } =
        useRejectModal();

    const ambassadorResponse = useFetchAmbassador(id);
    const candidatesResponse = useFetchCandidatesByAmbassador(search, id);

    React.useLayoutEffect(() => {
        if (widthBelow600px) {
            setView(View.Card);
        }
    }, [widthBelow600px]);

    if (ambassadorResponse.error) {
        return handleQueryError(ambassadorResponse.error, history);
    }
    if (candidatesResponse.error) {
        return handleQueryError(candidatesResponse.error, history);
    }
    return (
        <Page title={t('text.candidates')} style={styles.root}>
            {isDeleteOpen && (
                <DeleteModal
                    open={isDeleteOpen}
                    itemToDeleteId={itemToDeleteId}
                    itemToDeleteName={itemToDeleteName}
                    closeModal={closeDelete}
                />
            )}
            {isRejectOpen && (
                <RejectModal
                    open={isRejectOpen}
                    itemToRejectId={itemToRejectId}
                    itemToRejectName={itemToRejectName}
                    closeModal={closeReject}
                />
            )}
            <div style={styles.actionBar}>
                <div style={styles.actionBarViewToogle}>
                    {!widthBelow600px && <ViewToggleButton view={view} setView={setView} />}
                </div>
                {ambassadorResponse.data && (
                    <Tooltip arrow placement="bottom" title={<h1>{t('text.inviteCandidate')}</h1>}>
                        <IconButton
                            size="large"
                            style={styles.actionBarCreateInviteIcon}
                            onClick={() =>
                                history.push(
                                    routePath.inviteCreateAmbassadorCandidate.create(
                                        ambassadorResponse.data.id
                                    )
                                )
                            }
                        >
                            <FcInvite />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {ambassadorResponse.isLoading && (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={styles.ambassadorNameSkeleton}
                />
            )}
            {ambassadorResponse.data && (
                <p style={styles.ambassadorName}>
                    {`${t('text.ambassador')}: ${ambassadorResponse.data.user.firstName} ${
                        ambassadorResponse.data.user.lastName
                    } - ${ambassadorResponse.data.employeeNumber}`}
                </p>
            )}
            {candidatesResponse.isLoading && view === View.Card && <CardViewSkeleton />}
            {candidatesResponse.isLoading && view === View.Grid && <GridSkeleton shorter />}
            {candidatesResponse.data && view === View.Card && (
                <CardView
                    data={candidatesResponse.data}
                    isLoading={candidatesResponse.isLoading}
                    search={search}
                    setSearch={setSearch}
                    openDelete={openDelete}
                    openReject={openReject}
                />
            )}
            {candidatesResponse.data && view === View.Grid && (
                <GridView
                    shorter
                    data={candidatesResponse.data}
                    openDelete={openDelete}
                    openReject={openReject}
                />
            )}
        </Page>
    );
};
