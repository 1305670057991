/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import 'react-international-phone/style.css';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle, IElementSxProps } from './types';
import { colors } from '../../../helpers';
import {
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
    TextFieldProps,
    Fade,
} from '@mui/material';
import {
    defaultCountries,
    FlagEmoji,
    parseCountry,
    usePhoneInput,
} from 'react-international-phone';
import { FieldProps } from 'formik';
import { Spinner } from '../../spinner/Spinner';

interface IProps {
    spinner: boolean;
    noPaste?: boolean;
    cs?: ICustomStyle;
    esx?: IElementSxProps;
    es?: IElementStyle;
    style?: React.CSSProperties;
    className?: string;
}

export const PhoneFieldInput = ({
    type,
    label,
    placeholder,
    disabled,
    cs,
    esx,
    es,
    spinner,
    noPaste,
    style,
    className,
    field,
    form: { errors, touched, handleBlur, setFieldValue },
}: IProps & TextFieldProps & FieldProps) => {
    const styles = useStyles(type, field, errors, touched, cs);

    const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
        defaultCountry: 'nl',
        value: field.value,
        countries: defaultCountries,
        onChange: (data) => {
            setFieldValue(field.name, data.phone);
        },
    });

    return (
        <TextField
            {...field}
            css={
                es && es.textField
                    ? [es.textField as Interpolation<Theme>, style as Interpolation<Theme>]
                    : (style as Interpolation<Theme>)
            }
            className={className}
            variant="standard"
            type={type}
            label={label}
            placeholder={placeholder}
            autoComplete="new-password"
            disabled={disabled ? disabled : false}
            fullWidth
            sx={esx && esx.textField ? { ...styles.textField, ...esx.textField } : styles.textField}
            inputProps={{
                role: 'presentation',
                autoComplete: 'new-password',
                spellCheck: false,
                'aria-live': 'polite',
                disabled: disabled ? disabled : false,
            }}
            error={Boolean(errors[field.name]) && Boolean(touched[field.name])}
            helperText={
                Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? errors[field.name]
                    : ' '
            }
            value={phone}
            onChange={handlePhoneValueChange}
            inputRef={inputRef}
            onBlur={(event) => {
                handleBlur(event);
            }}
            onPaste={(event) => {
                if (noPaste) {
                    event.preventDefault();
                }
            }}
            onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        css={
                            es && es.startAdornment
                                ? (es.startAdornment as Interpolation<Theme>)
                                : undefined
                        }
                        sx={
                            esx && esx.startAdornment
                                ? { ...styles.startAdornment, ...esx.startAdornment }
                                : styles.startAdornment
                        }
                    >
                        <Select
                            MenuProps={{
                                style: {
                                    height: '25rem',
                                    width: '30rem',
                                    top: '10px',
                                    left: '-34px',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                            sx={{
                                width: 'max-content',
                                // Remove default outline (display only on focus)
                                fieldset: {
                                    display: 'none',
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                    fieldset: {
                                        display: 'none',
                                    },
                                },
                                // Update default spacing
                                '.MuiSelect-select': {
                                    padding: '0.25rem',
                                    paddingRight: '2rem !important',
                                },
                                svg: {
                                    right: 0,
                                },
                            }}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            renderValue={(value) => (
                                <FlagEmoji
                                    iso2={value}
                                    style={{ display: 'flex', height: '2rem' }}
                                />
                            )}
                        >
                            {defaultCountries.map((c) => {
                                const country = parseCountry(c);
                                return (
                                    <MenuItem
                                        key={country.iso2}
                                        value={country.iso2}
                                        style={{
                                            padding: '0.6rem 1.5rem !important',
                                            minHeight: '5rem',
                                        }}
                                    >
                                        <FlagEmoji
                                            iso2={country.iso2}
                                            style={{ marginRight: '8px', height: '2rem' }}
                                        />
                                        <Typography marginRight="8px">{country.name}</Typography>
                                        <Typography color="gray">+{country.dialCode}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment
                        position="end"
                        css={
                            es && es.endAdornment
                                ? (es.endAdornment as Interpolation<Theme>)
                                : undefined
                        }
                        sx={
                            esx && esx.endAdornment
                                ? { ...styles.endAdornment, ...esx.endAdornment }
                                : styles.endAdornment
                        }
                    >
                        <Fade in={spinner}>
                            <Spinner
                                variant="rond"
                                cs={{
                                    color: cs && cs.inputColor ? cs.inputColor : colors.blue,
                                    radius: cs && cs.fontSize ? cs.fontSize : '2rem',
                                }}
                            />
                        </Fade>
                    </InputAdornment>
                ),
            }}
        />
    );
};
