import { colors } from '../../../helpers';

export const globeArcs = [
    {
        startLat: 45.6523093,
        startLng: 25.6102746,
        endLat: 51.9106641,
        endLng: 4.4769407,
        color: colors.orange,
        stroke: 0.4,
        dashLength: 1,
        dashGap: 0.2,
        dashAnimateTime: 1500,
    },
    {
        startLat: 38.7077507,
        startLng: -9.1365919,
        endLat: 51.9106641,
        endLng: 4.4769407,
        color: colors.orange,
        stroke: 0.4,
        dashLength: 1,
        dashGap: 0.2,
        dashAnimateTime: 2000,
    },
    {
        startLat: 50.6787929,
        startLng: 17.9298844,
        endLat: 51.9106641,
        endLng: 4.4769407,
        color: colors.orange,
        stroke: 0.4,
        dashLength: 1,
        dashGap: 0.2,
        dashAnimateTime: 2500,
    },
    {
        startLat: 45.812945,
        startLng: 15.977938,
        endLat: 51.9106641,
        endLng: 4.4769407,
        color: colors.orange,
        stroke: 0.4,
        dashLength: 1,
        dashGap: 0.2,
        dashAnimateTime: 3000,
    },
];
