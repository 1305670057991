import { useQuery } from 'react-query';
import { useApi, Cache } from '../../../../../../../helpers';
import { IActivityPeriodAmbassador } from '../../../hooks/types';

export const useFetchAmbassadorInfo = (ambassadorId: string) => {
    const api = useApi();

    return useQuery<IActivityPeriodAmbassador>([Cache.ambassadorInfo, ambassadorId], ({ signal }) =>
        api.get(`/ambassadors/${ambassadorId}/info`, { signal }).then((res) => res.data)
    );
};
