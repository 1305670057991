import { IEmailCheckEmailSubscriptionVariables } from './types';
import { useApi } from '../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useRmailCheckEmailSubscription = () => {
    const api = useApi();

    return useMutation<AxiosResponse<string>, any, IEmailCheckEmailSubscriptionVariables>(
        ({ body }) => api.post('/email-check-email-subscription', body)
    );
};
