/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ISelectItem, ICustomStyles, IElementStyle, IElementSxProps } from './types';
import {
    FormControl,
    InputLabel,
    InputLabelProps,
    Select,
    SelectProps,
    FormHelperText,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { FieldProps } from 'formik';

interface IProps {
    label: string;
    items: ISelectItem[];
    cs?: ICustomStyles;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
    onChange?: (event: SelectChangeEvent<any>) => void;
}

export const SelectInput = ({
    label,
    items,
    disabled,
    cs,
    es,
    esx,
    style,
    className,
    onChange,
    field,
    form: { errors, touched, handleChange, handleBlur },
}: IProps & SelectProps & InputLabelProps & FieldProps) => {
    const styles = useStyles(field, errors, touched, cs);

    return (
        <FormControl
            css={
                es && es.formControl
                    ? [es.formControl as Interpolation<Theme>, style as Interpolation<Theme>]
                    : (style as Interpolation<Theme>)
            }
            sx={
                esx && esx.formControl
                    ? { ...styles.formControl, ...esx.formControl }
                    : styles.formControl
            }
            className={className}
            error={Boolean(errors[field.name]) && Boolean(touched[field.name])}
            fullWidth
        >
            <InputLabel
                css={es && es.label ? (es.label as Interpolation<Theme>) : undefined}
                sx={esx && esx.label ? esx.label : undefined}
            >
                {label}
            </InputLabel>
            <Select
                {...field}
                css={es && es.select ? (es.select as Interpolation<Theme>) : undefined}
                sx={esx && esx.select ? esx.select : undefined}
                variant="standard"
                fullWidth
                autoComplete="new-password"
                disabled={disabled ? disabled : false}
                onChange={(event) => {
                    if (onChange) {
                        onChange(event);
                    }
                    handleChange(event);
                }}
                onBlur={(event) => {
                    handleBlur(event);
                }}
                onFocus={(event) => {
                    event.target.setAttribute('autocomplete', 'off');
                }}
                inputProps={{
                    role: 'presentation',
                    autoComplete: 'new-password',
                    spellCheck: false,
                    'aria-live': 'polite',
                    disabled: disabled ? disabled : false,
                }}
            >
                {items.map(({ value, item }, index) => {
                    return (
                        <MenuItem
                            value={value}
                            key={index}
                            css={
                                es && es.menuItem
                                    ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                                    : styles.menuItem
                            }
                            sx={esx && esx.menuItem ? esx.menuItem : undefined}
                        >
                            {item}
                        </MenuItem>
                    );
                })}
            </Select>
            <FormHelperText
                css={es && es.formHelper ? (es.formHelper as Interpolation<Theme>) : undefined}
                sx={esx && esx.formHelper ? esx.formHelper : undefined}
            >
                {Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? errors[field.name]
                    : ' '}
            </FormHelperText>
        </FormControl>
    );
};
