import jwtDecode from 'jwt-decode';
import { ICurrentUser } from '../../../../auth-context/types';
import { ITokenPayload } from '../types';

export const decodeToken = (token: string): ICurrentUser | null => {
    try {
        const { userId, areTermsAccepted, roles, departmentId } = jwtDecode<ITokenPayload>(token);
        const currentUser: ICurrentUser = {
            userId,
            areTermsAccepted,
            roles,
            departmentId,
            token,
        };
        return currentUser;
    } catch {
        return null;
    }
};
