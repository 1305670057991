import * as React from 'react';
import { useStyles } from './style';
import { ICandidate } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { FcCancel } from 'react-icons/fc';

interface IProps {
    candidate: ICandidate;
}

export const InfoPanel = ({ candidate }: IProps) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div style={styles.panel}>
            {candidate.firstInvitedAt ? (
                <div>
                    <p style={styles.panelText}>{t('text.firstInvitedOn')}:&nbsp;</p>
                    <p style={styles.panelDataText}>
                        {new Date(candidate.firstInvitedAt).toLocaleDateString()}
                    </p>
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.firstInvitedOn')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            {candidate.invitedBy ? (
                <div>
                    <p style={styles.panelText}>{t('text.invitedBy')}:&nbsp;</p>
                    <p style={styles.panelDataText}>{candidate.invitedBy}</p>
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.invitedBy')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            {candidate.invitedAt ? (
                <div>
                    <p style={styles.panelText}>{t('text.invitedOn')}:&nbsp;</p>
                    <p style={styles.panelDataText}>
                        {new Date(candidate.invitedAt).toLocaleDateString()}
                    </p>
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.invitedOn')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            {candidate.invitationValidUntil ? (
                <div>
                    <p style={styles.panelText}>{t('text.invitationValidUntil')}:&nbsp;</p>
                    <p style={styles.panelDataText}>
                        {new Date(candidate.invitationValidUntil).toLocaleDateString()}
                    </p>
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.invitationValidUntil')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            {candidate.invitationCompletedAt ? (
                <div>
                    <p style={styles.panelText}>{t('text.invitationCompletedOn')}:&nbsp;</p>
                    <p style={styles.panelDataText}>
                        {new Date(candidate.invitationCompletedAt).toLocaleDateString()}
                    </p>
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.invitationCompletedOn')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            {candidate.rejectedBy ? (
                <div>
                    <p style={styles.panelText}>{t('text.rejectedBy')}:&nbsp;</p>
                    <p style={styles.panelDataText}>{candidate.rejectedBy}</p>
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.rejectedBy')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            {candidate.rejectedAt ? (
                <div>
                    <p style={styles.panelText}>{t('text.rejectedOn')}:&nbsp;</p>
                    <p style={styles.panelDataText}>
                        {new Date(candidate.rejectedAt).toLocaleDateString()}
                    </p>
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.rejectedOn')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            <div>
                <p style={styles.panelText}>{t('text.createdBy')}:&nbsp;</p>
                <p style={styles.panelDataText}>{candidate.createdBy}</p>
            </div>
            <div>
                <p style={styles.panelText}>{t('text.createdOn')}:&nbsp;</p>
                <p style={styles.panelDataText}>
                    {new Date(candidate.createdAt).toLocaleDateString()}
                </p>
            </div>
            <div>
                <p style={styles.panelText}>{t('text.modifiedBy')}:&nbsp;</p>
                <p style={styles.panelDataText}>{candidate.lastModifiedBy}</p>
            </div>
            <div>
                <p style={styles.panelText}>{t('text.modifiedOn')}:&nbsp;</p>
                <p style={styles.panelDataText}>
                    {new Date(candidate.lastModifiedAt).toLocaleDateString()}
                </p>
            </div>
        </div>
    );
};
