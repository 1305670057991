/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchAdmin, useFetchDepartments } from '../hooks';
import { IDepartment } from '../hooks/types';
import { useUpdateAdmin } from './hooks';
import { getAdminRole } from './helper';
import {
    IRouteParams,
    colors,
    handleQueryError,
    handleError,
    routePath,
    Role,
} from '../../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, useMediaQuery } from '@mui/material';
import { Formik, Form, Field, FormikErrors } from 'formik';
import {
    Page,
    TextFieldInput,
    SelectInput,
    LanguageSelectInput,
    Button,
} from '../../../components';

export const UpdateAdmin = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { id } = useParams<IRouteParams>();
    const { t, i18n } = useTranslation();

    const adminResponse = useFetchAdmin(id);
    const departmentsResponse = useFetchDepartments();
    const updateAdmin = useUpdateAdmin(id);

    if (adminResponse.error) {
        return handleQueryError(adminResponse.error, history);
    }
    if (departmentsResponse.error) {
        return handleQueryError(departmentsResponse.error, history);
    }
    return (
        <Page isPageSubmitting={updateAdmin.isLoading} style={styles.root}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.updateAdministrator')}</h1>
                {adminResponse.isLoading || departmentsResponse.isLoading ? (
                    <div style={styles.contentForm}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.buttonSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.buttonSkeleton}
                        />
                    </div>
                ) : null}
                {adminResponse.data && departmentsResponse.data ? (
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                firstName: adminResponse.data.user.firstName,
                                lastName: adminResponse.data.user.lastName,
                                role: getAdminRole(adminResponse.data.user.appRoles),
                                department: adminResponse.data.user.department
                                    ? adminResponse.data.user.department.id
                                    : '',
                                language: adminResponse.data.user.language,
                            } as IFormValues
                        }
                        validate={({ firstName, lastName, role, language, department }) => {
                            let errors: FormikErrors<IFormValues> = {};
                            if (!firstName.trim()) {
                                errors.firstName = t('form.error.requiredField');
                            } else if (firstName.trim().length > 127) {
                                errors.firstName = t('form.error.max127Chars');
                            }
                            if (!lastName.trim()) {
                                errors.lastName = t('form.error.requiredField');
                            } else if (lastName.trim().length > 127) {
                                errors.lastName = t('form.error.max127Chars');
                            }
                            if (!role) {
                                errors.role = t('form.error.requiredField');
                            }
                            if (role === Role.admin && !department) {
                                errors.department = t('form.error.requiredField');
                            }
                            if (!language) {
                                errors.language = t('form.error.requiredField');
                            }
                            return errors;
                        }}
                        onSubmit={async ({ firstName, lastName, role, department, language }) => {
                            try {
                                await updateAdmin.mutateAsync({
                                    body: {
                                        firstName: firstName.trim(),
                                        lastName: lastName.trim(),
                                        role,
                                        departmentId: role === Role.admin ? department : undefined,
                                        language,
                                    },
                                });
                                history.push(routePath.admins.create());
                            } catch (e) {
                                handleError(e, ({ status, statusText }) => {
                                    if (status === 401 || status === 403) {
                                        history.push(routePath.unauthorizedError.create());
                                    } else if (status === 404) {
                                        history.push(routePath.notFoundError.create());
                                    } else if (status === 500) {
                                        history.push(routePath.internalServerError.create());
                                    } else alert(statusText);
                                });
                            }
                        }}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form noValidate style={styles.contentForm}>
                                <Field
                                    style={styles.formField}
                                    name="firstName"
                                    type="text"
                                    label={t('form.label.firstName')}
                                    placeholder={t('form.placeholder.firstName')}
                                    disabled={isSubmitting}
                                    component={TextFieldInput}
                                />
                                <Field
                                    style={styles.formField}
                                    name="lastName"
                                    type="text"
                                    label={t('form.label.lastName')}
                                    placeholder={t('form.placeholder.lastName')}
                                    disabled={isSubmitting}
                                    component={TextFieldInput}
                                />
                                <Field
                                    style={styles.formField}
                                    name="role"
                                    label={t('form.label.role')}
                                    items={[
                                        {
                                            value: 'superAdmin',
                                            item: t('text.superAdmin'),
                                        },
                                        {
                                            value: 'admin',
                                            item: t('text.admin'),
                                        },
                                        {
                                            value: 'recruiter',
                                            item: t('text.recruiter'),
                                        },
                                    ]}
                                    disabled={isSubmitting}
                                    component={SelectInput}
                                    onChange={() => {
                                        if (values.role !== Role.admin) {
                                            setFieldValue('department', '');
                                        }
                                    }}
                                />
                                {values.role === Role.admin ? (
                                    <Field
                                        style={styles.formField}
                                        name="department"
                                        label={t('form.label.department')}
                                        items={departmentsResponse.data.map((department) => {
                                            return {
                                                value: department.id,
                                                item: department[
                                                    i18n.language as keyof IDepartment
                                                ],
                                            };
                                        })}
                                        disabled={isSubmitting}
                                        component={SelectInput}
                                    />
                                ) : null}
                                <Field
                                    style={styles.formField}
                                    name="language"
                                    label={t('form.label.language')}
                                    disabled={isSubmitting}
                                    component={LanguageSelectInput}
                                />
                                <Button
                                    css={styles.formButton}
                                    type="submit"
                                    disabled={isSubmitting}
                                    spinner={isSubmitting}
                                    cs={{
                                        color: colors.blue,
                                    }}
                                >
                                    {t('text.update')}
                                </Button>
                                <Button
                                    css={styles.formButton}
                                    type="button"
                                    disabled={isSubmitting}
                                    spinner={isSubmitting}
                                    cs={{
                                        color: colors.blue,
                                    }}
                                    onClick={() => history.push(routePath.admins.create())}
                                >
                                    {t('text.back')}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </div>
        </Page>
    );
};
