import * as React from 'react';
import { IState, TAction } from './types';

const reducer = (state: IState, action: TAction): IState => {
    switch (action.type) {
        case 'CLOSE_MENU':
            return { ...state, menuList: 'main', isMenuOpen: false };
        case 'OPEN_MENU':
            return { ...state, menuList: 'main', isMenuOpen: true };
        case 'SET_MENU_LIST':
            return { ...state, menuList: action.payload! };
    }
};

export const useCollapsedMenu = () => {
    const [{ menuList, isMenuOpen }, dispatch] = React.useReducer(reducer, {
        menuList: 'main',
        isMenuOpen: false,
    });

    return {
        isMenuOpen,
        menuList,
        setCollapsedMenuList: (menuList: 'main' | 'dashboards' | 'menu') =>
            dispatch({ type: 'SET_MENU_LIST', payload: menuList }),
        openCollapsedMenu: () => dispatch({ type: 'OPEN_MENU' }),
        closeCollapsedMenu: () => dispatch({ type: 'CLOSE_MENU' }),
    };
};
