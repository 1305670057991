/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        candidate: {
            margin: '2rem 0 0 0',
            display: 'inline-block',
            color: colors.blue,
            userSelect: 'text',
        },
        actionBar: {
            marginTop: '2rem',
            height: '4rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            //justifyContent: 'space-between',
        },
        actionBarPeriodToogle: {
            marginLeft: '2rem',
        },
        actionBarCreateIcon: {
            backgroundColor: colors.blue,
            color: colors.white,
            marginRight: '2rem',
        },
        button: {
            width: widthBelow600px ? '25rem' : '30rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        candidateSkeleton: {
            marginTop: '2rem',
            width: '15rem',
            height: '2rem',
        },
        tableSkeleton: {
            width: '90vw',
            height: '45vh',
            margin: '8rem 0 4rem 0',
        },
    });
