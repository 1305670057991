export const routePath = {
    login: {
        path: '/',
        create: () => '/',
    },
    verifyLogin: {
        path: '/verify-login',
        create: () => '/verify-login',
    },
    verifyResetPassword: {
        path: '/verify-reset-password',
        create: () => '/verify-reset-password',
    },
    verifyEmailSubscription: {
        path: '/verify-email-subscription',
        create: () => '/verify-email-subscription',
    },
    resetPasswordEmailCheck: {
        path: '/reset-password/email-check',
        create: () => '/reset-password/email-check',
    },
    emailSubscriptionEmailCheck: {
        path: '/email-subscription/email-check',
        create: () => '/email-subscription/email-check',
    },
    emailSubscription: {
        path: '/email-subscription',
        create: () => '/email-subscription',
    },
    resetPassword: {
        path: '/reset-password',
        create: () => '/reset-password',
    },
    user: {
        path: '/user',
        create: () => '/user',
    },
    userChangeEmail: {
        path: '/user/change-email/:id',
        create: (userId: string) => `/user/change-email/${userId}`,
    },
    userChangeLanguage: {
        path: '/user/change-language/:id',
        create: (userId: string) => `/user/change-language/${userId}`,
    },
    userChangePassword: {
        path: '/user/change-password/:id',
        create: (userId: string) => `/user/change-password/${userId}`,
    },
    userChangeEmailSubscription: {
        path: '/user/change-email-subscription/:id',
        create: (userId: string) => `/user/change-email-subscription/${userId}`,
    },
    admins: {
        path: '/admins',
        create: () => '/admins',
    },
    createAdmin: {
        path: '/admins/create',
        create: () => '/admins/create',
    },
    updateAdmin: {
        path: '/admins/update/:id',
        create: (adminId: string) => `/admins/update/${adminId}`,
    },
    changeEmailAdmin: {
        path: '/admins/change-email/:id',
        create: (adminId: string) => `/admins/change-email/${adminId}`,
    },
    changePasswordAdmin: {
        path: '/admins/change-password/:id',
        create: (adminId: string) => `/admins/change-password/${adminId}`,
    },
    ambassadors: {
        path: '/ambassadors',
        create: () => '/ambassadors',
    },
    createAmbassador: {
        path: '/ambassadors/create',
        create: () => '/ambassadors/create',
    },
    updateAmbassador: {
        path: '/ambassadors/update/:id',
        create: (ambassadorId: string) => `/ambassadors/update/${ambassadorId}`,
    },
    changeEmailAmbassador: {
        path: '/ambassadors/change-email/:id',
        create: (ambassadorId: string) => `/ambassadors/change-email/${ambassadorId}`,
    },
    changePasswordAmbassador: {
        path: '/ambassadors/change-password/:id',
        create: (ambassadorId: string) => `/ambassadors/change-password/${ambassadorId}`,
    },
    ambassadorActivityPeriods: {
        path: '/ambassadors/activity-periods/:id',
        create: (ambassadorId: string) => `/ambassadors/activity-periods/${ambassadorId}`,
    },
    createAmbassadorActivityPeriod: {
        path: '/ambassadors/activity-periods/create/:id',
        create: (ambassadorId: string) => `/ambassadors/activity-periods/create/${ambassadorId}`,
    },
    closeAmbassadorActivityPeriod: {
        path: '/ambassadors/activity-periods/update/end-date/:id',
        create: (activityPeriodId: string) =>
            `/ambassadors/activity-periods/update/end-date/${activityPeriodId}`,
    },
    ambassadorEmployedPeriods: {
        path: '/ambassadors/activity-periods/employed-periods/:id',
        create: (activityPeriodId: string) =>
            `/ambassadors/activity-periods/employed-periods/${activityPeriodId}`,
    },
    candidates: {
        path: '/candidates',
        create: () => '/candidates',
    },
    createCandidate: {
        path: '/candidates/create',
        create: () => '/candidates/create',
    },
    updateCandidate: {
        path: '/candidates/update/:id',
        create: (candidateId: string) => `/candidates/update/${candidateId}`,
    },
    updateInvitedCandidate: {
        path: '/candidates/update/invited/:id',
        create: (invitatinToken: string) => `/candidates/update/invited/${invitatinToken}`,
    },
    updateCandidateEmployeeNumber: {
        path: '/candidates/update/employee-number/:id',
        create: (candidateId: string) => `/candidates/update/employee-number/${candidateId}`,
    },
    updateCandidateInvitation: {
        path: '/candidates/update/invitation/:id',
        create: (candidateId: string) => `/candidates/update/invitation/${candidateId}`,
    },
    inviteCreateCandidate: {
        path: '/candidates/create/invite',
        create: () => '/candidates/create/invite',
    },
    promoteCandidate: {
        path: '/candidates/promote/ambassador/:id',
        create: (candidateId: string) => `/candidates/promote/ambassador/${candidateId}`,
    },
    ambassadorCandidates: {
        path: '/ambassadors/candidates/:id',
        create: (ambassadorId: string) => `/ambassadors/candidates/${ambassadorId}`,
    },
    inviteCreateAmbassadorCandidate: {
        path: '/ambassadors/candidates/create/invite/:id',
        create: (ambassadorId: string) => `/ambassadors/candidates/create/invite/${ambassadorId}`,
    },
    candidateActivityPeriods: {
        path: '/candidates/activity-periods/:id',
        create: (candidateId: string) => `/candidates/activity-periods/${candidateId}`,
    },
    createCandidateActivityPeriod: {
        path: '/candidates/activity-periods/create/:id',
        create: (candidateId: string) => `/candidates/activity-periods/create/${candidateId}`,
    },
    closeCandidateActivityPeriod: {
        path: '/candidates/activity-periods/update/end-date/:id',
        create: (activityPeriodId: string) =>
            `/candidates/activity-periods/update/end-date/${activityPeriodId}`,
    },
    ambassadorDeclarations: {
        path: '/ambassador/declarations',
        create: () => '/ambassador/declarations',
    },
    ambassadorDeclarationPeriod: {
        path: '/ambassador/declarations/:id',
        create: (week: string) => `/ambassador/declarations/${week}`,
    },
    internalServerError: {
        path: '/500',
        create: (): string => '/500',
    },
    internalServerVerifyLoginError: {
        path: '/500/verify-login',
        create: (): string => '/500/verify-login',
    },
    internalServerVerifyEmailSubscriptionError: {
        path: '/500/verify-email-subscription',
        create: (): string => '/500/verify-email-subscription',
    },
    internalServerVerifyResetPasswordError: {
        path: '/500/verify-reset-password',
        create: (): string => '/500/verify-reset-password',
    },
    unauthorizedError: {
        path: '/401',
        create: (): string => '/401',
    },
    unauthorizedVerifyLoginError: {
        path: '/401/verify-login',
        create: (): string => '/401/verify-login',
    },
    unauthorizedVerifyEmailSubscriptionError: {
        path: '/401/verify-email-subscription',
        create: (): string => '/401/verify-email-subscription',
    },
    unauthorizedVerifyResetPasswordError: {
        path: '/401/verify-reset-password',
        create: (): string => '/401/verify-reset-password',
    },
    unavailableError: {
        path: '/503',
        create: (): string => '/503',
    },
    afasUnavailableError: {
        path: '/503/afas',
        create: (): string => '/503/afas',
    },
    gatewayTimeoutError: {
        path: '/504',
        create: (): string => '/504',
    },
    notFoundError: {
        path: '/not-found',
        create: (): string => '/not-found',
    },
    notFoundVerifyLoginError: {
        path: '/not-found/verify-login',
        create: (): string => '/not-found/verify-login',
    },
    notFoundVerifyEmailSubscriptionError: {
        path: '/not-found/verify-email-subscription',
        create: (): string => '/not-found/verify-email-subscription',
    },
    notFoundVerifyResetPasswordError: {
        path: '/not-found/verify-reset-password',
        create: (): string => '/not-found/verify-reset-password',
    },
    logs: {
        path: '/logs',
        create: (): string => '/logs',
    },
    settings: {
        path: '/settings',
        create: (): string => '/settings',
    },
    generalSettings: {
        path: '/settings/general',
        create: (): string => '/settings/general',
    },
    departmentSettings: {
        path: '/settings/departments',
        create: (): string => '/settings/departments',
    },
    createDepartment: {
        path: '/settings/departments/create',
        create: (): string => '/settings/departments/create',
    },
    updateDepartment: {
        path: '/settings/departments/update/:id',
        create: (departmentId: string): string => `/settings/departments/update/${departmentId}`,
    },
    professionsSettings: {
        path: '/settings/professions',
        create: (): string => '/settings/professions',
    },
    createProfession: {
        path: '/settings/professions/create',
        create: (): string => '/settings/professions/create',
    },
    updateProfession: {
        path: '/settings/professions/update/:id',
        create: (professionId: string): string => `/settings/professions/update/${professionId}`,
    },
    professionGroupsSetting: {
        path: '/settings/profession-groups',
        create: (): string => `/settings/profession-groups`,
    },
    createProfessionGroup: {
        path: '/settings/profession-groups/create',
        create: (): string => '/settings/profession-groups/create',
    },
    updateProfessionGroup: {
        path: '/settings/profession-groups/update/:id',
        create: (professionGroupId: string): string =>
            `/settings/profession-groups/update/${professionGroupId}`,
    },
    tasks: {
        path: '/settings/tasks',
        create: (): string => '/settings/tasks',
    },
    taskErrors: {
        path: '/settings/tasks/errors/:id',
        create: (taskId: string): string => `/settings/tasks/errors/${taskId}`,
    },
    newsletter: {
        path: '/settings/newsletter',
        create: (): string => '/settings/newsletter',
    },
    welcome: {
        path: '/welkom',
        create: (): string => '/welkom',
    },
    registered: {
        path: '/registered',
        create: (): string => '/registered',
    },
    ambassadorHoursDashboard: {
        path: '/dashboards/ambassador-hours',
        create: (): string => '/dashboards/ambassador-hours',
    },
    salesHoursDashboard: {
        path: '/dashboards/sales-hours',
        create: (): string => '/dashboards/sales-hours',
    },
    ambassadorPipelineDashboard: {
        path: '/dashboards/ambassador-pipeline',
        create: (): string => '/dashboards/ambassador-pipeline',
    },
    salesPipelineDashboard: {
        path: '/dashboards/sales-pipeline',
        create: (): string => '/dashboards/sales-pipeline',
    },
    oranjelinkSalesPipelineDashboard: {
        path: '/dashboards/oranjelink-sales-pipeline',
        create: (): string => '/dashboards/oranjelink-sales-pipeline',
    },
    recruitmentDashboard: {
        path: '/dashboards/recruitment',
        create: (): string => '/dashboards/recruitment',
    },
    scoreboardDashboard: {
        path: '/dashboards/scoreboard',
        create: (): string => '/dashboards/scoreboard',
    },
    candidatesDashboard: {
        path: '/dashboards/candidates',
        create: (): string => '/dashboards/candidates',
    },
    help: {
        path: '/help',
        create: (): string => '/help',
    },
    terms: {
        path: '/terms',
        create: (): string => '/terms',
    },
    acceptTerms: {
        path: '/accept-terms',
        create: (): string => '/accept-terms',
    },
    privacyPolicy: {
        path: '/privacy-policy',
        create: (): string => '/privacy-policy',
    },
    feedback: {
        path: '/feedback',
        create: () => '/feedback',
    },
};
