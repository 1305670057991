/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IContext } from './types';
import { IAmbassador } from '../../../hooks';
import { Role, useAuth } from '../../../../../helpers';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@mui/material';
import { BiEdit, BiTrash, BiLockAlt, BiEnvelope } from 'react-icons/bi';
import { AiOutlineQrcode } from 'react-icons/ai';
import { FiActivity } from 'react-icons/fi';
import { FaUserGraduate } from 'react-icons/fa';

export const ActionsCell = ({
    data,
    context: {
        handleCanidatesClick,
        handleChangeEmailClick,
        handleChangePasswordClick,
        handleResendQRClick,
        handleActivityClick,
        handelUpdateClick,
        handleDeleteClick,
    },
}: ICellRendererParams<IAmbassador, any, IContext>) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { currentUserHasRoles } = useAuth();

    return (
        <div style={styles.root}>
            <Tooltip arrow title={<h1>{t('text.candidates')}</h1>}>
                <IconButton
                    onClick={() => {
                        if (data) {
                            return handleCanidatesClick(data);
                        }
                    }}
                >
                    <FaUserGraduate style={styles.candidatesButton} />
                </IconButton>
            </Tooltip>
            {currentUserHasRoles(Role.superAdmin) ? (
                <React.Fragment>
                    <Tooltip arrow title={<h1>{t('text.changeEmail')}</h1>}>
                        <IconButton
                            onClick={() => {
                                if (data) {
                                    return handleChangeEmailClick(data);
                                }
                            }}
                        >
                            <BiEnvelope style={styles.emailButton} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow title={<h1>{t('text.changePassword')}</h1>}>
                        <IconButton
                            onClick={() => {
                                if (data) {
                                    return handleChangePasswordClick(data);
                                }
                            }}
                        >
                            <BiLockAlt style={styles.passwordButton} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow title={<h1>{t('text.resendQR')}</h1>}>
                        <IconButton
                            onClick={() => {
                                if (data) {
                                    return handleResendQRClick(data);
                                }
                            }}
                        >
                            <AiOutlineQrcode style={styles.resendQRButton} />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            ) : null}
            <Tooltip arrow title={<h1>{t('text.activity')}</h1>}>
                <IconButton
                    onClick={() => {
                        if (data) {
                            return handleActivityClick(data);
                        }
                    }}
                >
                    <FiActivity style={styles.activityButton} />
                </IconButton>
            </Tooltip>
            <Tooltip arrow title={<h1>{t('text.update')}</h1>}>
                <IconButton
                    onClick={() => {
                        if (data) {
                            return handelUpdateClick(data);
                        }
                    }}
                >
                    <BiEdit style={styles.updateButton} />
                </IconButton>
            </Tooltip>
            {currentUserHasRoles(Role.superAdmin) ? (
                <Tooltip arrow title={<h1>{t('text.delete')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handleDeleteClick(data);
                            }
                        }}
                    >
                        <BiTrash style={styles.deleteButton} />
                    </IconButton>
                </Tooltip>
            ) : null}
        </div>
    );
};
