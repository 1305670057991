import { IAfasEmployee } from './types';
import { useApi, Cache } from '../../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAfasEmployees = () => {
    const api = useApi();

    return useQuery<IAfasEmployee[], any>(Cache.afasEmployeesCreateAmbassadors, () =>
        api.get('/afas-employees/create').then((res) => res.data)
    );
};
