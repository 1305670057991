import { useQuery } from 'react-query';
import { ICandidateActivityPeriod } from './types';
import { Cache, useApi } from '../../../../../../../helpers';

export const useFetchActivityPeriod = (activityPeriodId: string) => {
    const api = useApi();

    return useQuery<ICandidateActivityPeriod, any>(
        [Cache.ambassadorActivityPeriods, activityPeriodId],
        () => api.get(`/candidate-activity-periods/${activityPeriodId}`).then((res) => res.data)
    );
};
