import { IAdmin } from '../types';
import { useApi, Cache } from '../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAdmins = (search: string) => {
    const api = useApi();

    return useQuery<IAdmin[], any>([Cache.admins, search], ({ signal }) => {
        return api.get(`/admins?search=${search}`, { signal }).then((res) => res.data);
    });
};
