/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useCreateAdmin } from './hooks';
import { useFetchDepartments } from '../hooks';
import { IDepartment } from '../hooks/types';
import { colors, handleError, Role, Language, routePath, handleQueryError } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import validator from 'validator';
import { Formik, Form, Field, FormikErrors } from 'formik';
import {
    Page,
    TextFieldInput,
    SelectInput,
    LanguageSelectInput,
    Button,
} from '../../../components';
import { BiEnvelope, BiLockAlt } from 'react-icons/bi';

export const CreateAdmin = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const { isLoading, data, error } = useFetchDepartments();
    const createAdmin = useCreateAdmin();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            isPageSubmitting={createAdmin.isLoading}
            style={styles.root}
            title={t('text.createAdministrator')}
        >
            {isLoading ? (
                <div style={styles.form}>
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            ) : null}
            {data ? (
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            firstName: '',
                            lastName: '',
                            email: '',
                            confirmEmail: '',
                            password: '',
                            confirmPassword: '',
                            role: '' as Role,
                            department: '',
                            language: '' as Language,
                        } as IFormValues
                    }
                    validate={({
                        firstName,
                        lastName,
                        email,
                        confirmEmail,
                        password,
                        confirmPassword,
                        role,
                        department,
                        language,
                    }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!firstName.trim()) {
                            errors.firstName = t('form.error.requiredField');
                        } else if (firstName.trim().length > 127) {
                            errors.firstName = t('form.error.max127Chars');
                        }
                        if (!lastName.trim()) {
                            errors.lastName = t('form.error.requiredField');
                        } else if (lastName.trim().length > 127) {
                            errors.lastName = t('form.error.max127Chars');
                        }
                        if (!email.trim()) errors.email = t('form.error.requiredField');
                        else if (email.trim().length > 127) {
                            errors.email = t('form.error.max127Chars');
                        } else if (!validator.isEmail(email.trim())) {
                            errors.email = t('form.error.invalidEmail');
                        }
                        if (!confirmEmail.trim())
                            errors.confirmEmail = t('form.error.requiredField');
                        else if (confirmEmail.trim().length > 127) {
                            errors.confirmEmail = t('form.error.max127Chars');
                        } else if (!validator.isEmail(confirmEmail.trim())) {
                            errors.confirmEmail = t('form.error.invalidEmail');
                        } else if (email.trim() !== confirmEmail.trim()) {
                            errors.email = t('form.error.valuesDoNotMatch');
                            errors.confirmEmail = t('form.error.valuesDoNotMatch');
                        }
                        if (!password.trim()) errors.password = t('form.error.requiredField');
                        else if (password.trim().length < 6) {
                            errors.password = t('form.error.minSixChars');
                        } else if (password.trim().length > 32) {
                            errors.password = t('form.error.max32Chars');
                        } else if (!validator.matches(password.trim(), '(?=.*?[#?!@$%^&*-])')) {
                            errors.password = t('form.error.minOneSpecialChar');
                        }
                        if (!confirmPassword.trim()) {
                            errors.confirmPassword = t('form.error.requiredField');
                        } else if (confirmPassword.trim().length < 6) {
                            errors.confirmPassword = t('form.error.minSixChars');
                        } else if (confirmPassword.trim().length > 32) {
                            errors.confirmPassword = t('form.error.max32Chars');
                        } else if (
                            !validator.matches(confirmPassword.trim(), '(?=.*?[#?!@$%^&*-])')
                        ) {
                            errors.confirmPassword = t('form.error.minOneSpecialChar');
                        } else if (confirmPassword.trim() !== password.trim()) {
                            errors.password = t('form.error.valuesDoNotMatch');
                            errors.confirmPassword = t('form.error.valuesDoNotMatch');
                        }
                        if (!role) {
                            errors.role = t('form.error.requiredField');
                        }
                        if (role === Role.admin && !department) {
                            errors.department = t('form.error.requiredField');
                        }
                        if (!language) {
                            errors.language = t('form.error.requiredField');
                        }
                        return errors;
                    }}
                    onSubmit={async (
                        { firstName, lastName, email, password, role, department, language },
                        { setErrors }
                    ) => {
                        try {
                            await createAdmin.mutateAsync({
                                body: {
                                    firstName: firstName.trim(),
                                    lastName: lastName.trim(),
                                    email: email.trim(),
                                    password: password.trim(),
                                    role,
                                    departmentId: role === Role.admin ? department : undefined,
                                    language,
                                },
                            });
                            history.push(routePath.admins.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 400) {
                                    setErrors({
                                        email: t('form.error.emailIsRegistered'),
                                        confirmEmail: t('form.error.emailIsRegistered'),
                                    });
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form noValidate style={styles.form}>
                            <Field
                                style={styles.formField}
                                name="firstName"
                                type="text"
                                label={t('form.label.firstName')}
                                placeholder={t('form.placeholder.firstName')}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="lastName"
                                type="text"
                                label={t('form.label.lastName')}
                                placeholder={t('form.placeholder.lastName')}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="email"
                                type="email"
                                label={t('form.label.email')}
                                placeholder={t('form.placeholder.email')}
                                icon={<BiEnvelope />}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="confirmEmail"
                                type="email"
                                label={t('form.label.confirmEmail')}
                                placeholder={t('form.placeholder.confirmEmail')}
                                icon={<BiEnvelope />}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="password"
                                type="password"
                                label={t('form.label.password')}
                                placeholder={t('form.placeholder.password')}
                                icon={<BiLockAlt />}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="confirmPassword"
                                type="password"
                                label={t('form.label.confirmPassword')}
                                placeholder={t('form.placeholder.confirmPassword')}
                                icon={<BiLockAlt />}
                                disabled={isSubmitting}
                                noPaste={true}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="role"
                                label={t('form.label.role')}
                                items={[
                                    {
                                        value: 'superAdmin',
                                        item: t('text.superAdmin'),
                                    },
                                    {
                                        value: 'admin',
                                        item: t('text.admin'),
                                    },
                                    {
                                        value: 'recruiter',
                                        item: t('text.recruiter'),
                                    },
                                ]}
                                disabled={isSubmitting}
                                component={SelectInput}
                                onChange={() => {
                                    if (values.role !== Role.admin) {
                                        setFieldValue('department', '');
                                    }
                                }}
                            />
                            {values.role === Role.admin ? (
                                <Field
                                    style={styles.formField}
                                    name="department"
                                    label={t('form.label.department')}
                                    items={data.map((department) => {
                                        return {
                                            value: department.id,
                                            item: department[i18n.language as keyof IDepartment],
                                        };
                                    })}
                                    disabled={isSubmitting}
                                    component={SelectInput}
                                />
                            ) : null}
                            <Field
                                style={styles.formField}
                                name="language"
                                label={t('form.label.language')}
                                disabled={isSubmitting}
                                component={LanguageSelectInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.create')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.push(routePath.admins.create())}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </Page>
    );
};
