/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IAmbassador } from '../../hooks';
import { IPayload as IDeleteModalPayload } from '../../../../helpers/reducers/delete/types';
import { IPayload as IResendQRModalPayload } from '../../../../helpers/reducers/resend-qr-code/types';
import { Role, dateFilter, localText, routePath, useAuth } from '../../../../helpers';
import { isAmbassadorActive } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import { ActionsCell } from './action-cell/ActionCell';
//import { saveAs } from 'file-saver';
import { Tooltip, IconButton } from '@mui/material';
import { SiMicrosoftexcel } from 'react-icons/si';
import { GiResize } from 'react-icons/gi';
import { GridSkeleton } from './grid-skeleton/GridSkeleton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../../../helpers/ag-grid/ag-grid.css';

interface IProps {
    data: IAmbassador[];
    lng: string;
    openDelete: (payload: IDeleteModalPayload) => void;
    openResendQR: (payload: IResendQRModalPayload) => void;
}

export const Grid = ({ data, lng, openDelete, openResendQR }: IProps) => {
    const styles = useStyles();
    const { currentUserHasRoles } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const gridRef = React.createRef<AgGridReact>();
    const [destroyed, setDestroyed] = React.useState(false);

    const columnDefs = React.useMemo(
        (): ColDef[] => [
            {
                colId: 'firstName',
                headerName: t('text.firstName'),
                field: 'user.firstName',
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'lastName',
                headerName: t('text.lastName'),
                field: 'user.lastName',
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'email',
                headerName: t('text.email'),
                field: 'user.email',
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'isAddendumSigned',
                headerName: t('text.isAddendumSigned'),
                field: 'isContractSigned',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
                valueFormatter: (params: ValueFormatterParams<IAmbassador, boolean>): string => {
                    const { value } = params;
                    return value ? t('text.yes') : t('text.no');
                },
            },
            {
                colId: 'language',
                headerName: t('text.language'),
                field: 'user.language',
                sortable: true,
                filter: true,
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<IAmbassador, string>): string => {
                    const { value } = params;
                    return value ? value.toUpperCase() : '';
                },
            },
            {
                colId: 'department',
                headerName: t('text.department'),
                field: `user.department.${lng}`,
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
                valueFormatter: (params: ValueFormatterParams<IAmbassador, string>): string => {
                    const { value } = params;
                    return value ? value : t('text.doesNotApply');
                },
            },
            {
                colId: 'active',
                headerName: t('text.active'),
                sortable: true,
                filter: true,
                resizable: true,
                width: 100,
                maxWidth: 200,
                valueGetter: (params: ValueGetterParams<IAmbassador>): string => {
                    const ambassador = params.data;
                    return ambassador && isAmbassadorActive(ambassador)
                        ? t('text.active')
                        : t('text.inactive');
                },
            },
            {
                colId: 'isMailingSubscribed',
                headerName: t('text.mailingSubscribed'),
                field: 'user.isMailingSubscribed',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
                valueFormatter: (params: ValueFormatterParams<IAmbassador, boolean>): string => {
                    const { value } = params;
                    return value ? t('text.yes') : t('text.no');
                },
            },
            {
                colId: 'isNewsletterSubscribed',
                headerName: t('text.newsletterSubscribed'),
                field: 'user.isNewsletterSubscribed',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
                valueFormatter: (params: ValueFormatterParams<IAmbassador, boolean>): string => {
                    const { value } = params;
                    return value ? t('text.yes') : t('text.no');
                },
            },
            {
                colId: 'createdBy',
                headerName: t('text.createdBy'),
                field: 'createdBy',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
            },
            {
                colId: 'createdAt',
                headerName: t('text.createdOn'),
                field: 'createdAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<IAmbassador, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'createdAt',
                headerName: t('text.createdAt'),
                field: 'createdAt',
                sortable: true,
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<IAmbassador, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleTimeString() : '';
                },
            },
            {
                colId: 'lastModifiedBy',
                headerName: t('text.modifiedBy'),
                field: 'lastModifiedBy',
                sortable: true,
                filter: true,
                resizable: true,
                width: 200,
                maxWidth: 400,
            },
            {
                colId: 'lastModifiedAt',
                headerName: t('text.modifiedOn'),
                field: 'lastModifiedAt',
                sortable: true,
                filter: 'agDateColumnFilter',
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<IAmbassador, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleDateString() : '';
                },
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                        return dateFilter(filterLocalDateAtMidnight, cellValue);
                    },
                },
            },
            {
                colId: 'lastModifiedAt',
                headerName: t('text.modifiedAt'),
                field: 'lastModifiedAt',
                sortable: true,
                resizable: true,
                width: 150,
                maxWidth: 300,
                valueFormatter: (params: ValueFormatterParams<IAmbassador, string>): string => {
                    const { value } = params;
                    return value ? new Date(value).toLocaleTimeString() : '';
                },
            },
            {
                colId: 'actions',
                headerName: t('text.actions'),
                cellRenderer: ActionsCell,
                suppressMovable: true,
                lockPosition: 'right',
                sortable: false,
                resizable: false,
                width: currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? 250 : 150,
            },
        ],
        [t, lng, currentUserHasRoles]
    );

    React.useEffect(() => {
        setDestroyed(true);
        const timer = setTimeout(() => setDestroyed(false));
        return () => {
            clearTimeout(timer);
        };
    }, [lng, setDestroyed]);

    if (destroyed) return <GridSkeleton />;
    return (
        <div style={styles.gridContainer}>
            <div style={styles.gridHeader}>
                <p style={styles.gridHeaderText}>{t('text.ambassadors')}</p>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.resize')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderResizeButton}
                        onClick={() => {
                            const allColumnIds: string[] = [];
                            if (gridRef.current) {
                                gridRef.current.api.getColumns()!.forEach((column) => {
                                    allColumnIds.push(column.getId());
                                });

                                gridRef.current.api.autoSizeColumns(allColumnIds);
                            }
                        }}
                    >
                        <GiResize />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.exportToExcel')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderExportButton}
                        onClick={async () => {
                            /* try {
                                const buffer = await excelExport(gridRef);
                                if (buffer) {
                                    saveAs(new Blob([buffer]), 'logs.xlsx');
                                }
                            } catch (e) {
                                alert(t('text.failedToExport'));
                            } */
                        }}
                    >
                        <SiMicrosoftexcel />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="ag-theme-quartz" style={styles.gridBox}>
                <React.StrictMode>
                    <AgGridReact
                        ref={gridRef}
                        rowData={data}
                        columnDefs={columnDefs}
                        autoSizeStrategy={{
                            type: 'fitCellContents',
                        }}
                        suppressDragLeaveHidesColumns={true}
                        localeText={localText[lng]}
                        rowSelection="single"
                        rowBuffer={50}
                        context={{
                            handleCanidatesClick: (ambassador: IAmbassador) =>
                                history.push(routePath.ambassadorCandidates.create(ambassador.id)),
                            handleChangeEmailClick: (ambassador: IAmbassador) =>
                                history.push(routePath.changeEmailAmbassador.create(ambassador.id)),
                            handleChangePasswordClick: (ambassador: IAmbassador) =>
                                history.push(
                                    routePath.changePasswordAmbassador.create(ambassador.id)
                                ),
                            handleResendQRClick: (ambassador: IAmbassador) =>
                                openResendQR({
                                    resendQRUserId: ambassador.user.id,
                                    resendQRUsername: ambassador.user.name,
                                }),
                            handleActivityClick: (ambassador: IAmbassador) =>
                                history.push(
                                    routePath.ambassadorActivityPeriods.create(ambassador.id)
                                ),
                            handelUpdateClick: (ambassador: IAmbassador) =>
                                history.push(routePath.updateAmbassador.create(ambassador.id)),
                            handleDeleteClick: (ambassador: IAmbassador) =>
                                openDelete({
                                    itemToDeleteId: ambassador.id,
                                    itemToDeleteName: `${ambassador.user.firstName} ${ambassador.user.lastName}`,
                                }),
                        }}
                    />
                </React.StrictMode>
            </div>
        </div>
    );
};
