/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchSetting, useUpdateSetting } from './hooks';
import { getFee } from './helpers';
import { handleQueryError, colors, routePath, handleError } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Page, NumberFieldInput, Button } from '../../../components';

export const GeneralSetting = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchSetting();
    const update = useUpdateSetting();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            title={t('text.generalSettings')}
            style={styles.root}
            isPageSubmitting={update.isLoading}
        >
            {isLoading ? (
                <div style={styles.form}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.categorySkeleton}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.formFeeContainerSkeleton}
                    />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.categorySkeleton}
                    />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            ) : null}
            {data ? (
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            ambassadorSecondTierOpen: data.ambassadorSecondTierOpen,
                            ambassadorThirdTierOpen: data.ambassadorThirdTierOpen,
                            ambassadorFirstTierFee: data.ambassadorFirstTierFee,
                            ambassadorSecondTierFee: data.ambassadorSecondTierFee,
                            ambassadorThirdTierFee: data.ambassadorThirdTierFee,
                            ambassadorCostPrice: data.ambassadorCostPrice,
                            ambassadorCostPriceFactor: data.ambassadorCostPriceFactor,
                            ambassadorMinWorkedHourPerWeekForFee:
                                data.ambassadorMinWorkedHourPerWeekForFee,
                            candidateMinWorkedHourPerWeekForFee:
                                data.candidateMinWorkedHourPerWeekForFee,
                        } as IFormValues
                    }
                    validate={({
                        ambassadorCostPrice,
                        ambassadorCostPriceFactor,
                        ambassadorSecondTierOpen,
                        ambassadorThirdTierOpen,
                        ambassadorMinWorkedHourPerWeekForFee,
                        candidateMinWorkedHourPerWeekForFee,
                    }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        console.log(ambassadorCostPrice);
                        console.log(ambassadorCostPriceFactor);
                        if (String(ambassadorCostPrice) === '') {
                            ambassadorCostPrice = Number('');
                            errors.ambassadorCostPrice = t('form.error.requiredField');
                        } else if (ambassadorCostPrice < 0.01 || ambassadorCostPrice > 5) {
                            errors.ambassadorCostPrice = t('form.error.withinTheRange001To5');
                        }
                        if (String(ambassadorCostPriceFactor) === '') {
                            ambassadorCostPriceFactor = Number('');
                            errors.ambassadorCostPriceFactor = t('form.error.requiredField');
                        } else if (ambassadorCostPriceFactor < 1 || ambassadorCostPriceFactor > 2) {
                            errors.ambassadorCostPriceFactor = t('form.error.withinTheRange1To2');
                        }
                        if (String(ambassadorSecondTierOpen) === '') {
                            ambassadorSecondTierOpen = Number('');
                            errors.ambassadorSecondTierOpen = t('form.error.requiredField');
                        } else if (!Number.isInteger(ambassadorSecondTierOpen)) {
                            errors.ambassadorSecondTierOpen = t('forms.errors.integer');
                        } else if (ambassadorSecondTierOpen < 1 || ambassadorSecondTierOpen > 20) {
                            errors.ambassadorSecondTierOpen = t('form.error.withinTheRange1To20');
                        }
                        if (String(ambassadorThirdTierOpen) === '') {
                            ambassadorThirdTierOpen = Number('');
                            errors.ambassadorThirdTierOpen = t('form.error.requiredField');
                        } else if (!Number.isInteger(ambassadorThirdTierOpen)) {
                            errors.ambassadorThirdTierOpen = t('form.error.integer');
                        } else if (ambassadorThirdTierOpen < 1 || ambassadorThirdTierOpen > 50) {
                            errors.ambassadorThirdTierOpen = t('form.error.withinTheRange1To50');
                        }
                        if (String(ambassadorMinWorkedHourPerWeekForFee) === '') {
                            ambassadorMinWorkedHourPerWeekForFee = Number('');
                            errors.ambassadorMinWorkedHourPerWeekForFee = t(
                                'form.error.requiredField'
                            );
                        } else if (
                            ambassadorMinWorkedHourPerWeekForFee < 0.01 ||
                            ambassadorMinWorkedHourPerWeekForFee > 40
                        ) {
                            errors.ambassadorMinWorkedHourPerWeekForFee = t(
                                'form.error.withinTheRange001To40'
                            );
                        }
                        if (String(candidateMinWorkedHourPerWeekForFee) === '') {
                            candidateMinWorkedHourPerWeekForFee = Number('');
                            errors.candidateMinWorkedHourPerWeekForFee = t(
                                'form.error.requiredField'
                            );
                        } else if (
                            candidateMinWorkedHourPerWeekForFee < 0.01 ||
                            candidateMinWorkedHourPerWeekForFee > 40
                        ) {
                            errors.candidateMinWorkedHourPerWeekForFee = t(
                                'form.error.withinTheRange001To40'
                            );
                        }
                        return errors;
                    }}
                    onSubmit={async ({
                        ambassadorCostPrice,
                        ambassadorCostPriceFactor,
                        ambassadorFirstTierFee,
                        ambassadorSecondTierFee,
                        ambassadorThirdTierFee,
                        ambassadorSecondTierOpen,
                        ambassadorThirdTierOpen,
                        ambassadorMinWorkedHourPerWeekForFee,
                        candidateMinWorkedHourPerWeekForFee,
                    }) => {
                        try {
                            await update.mutateAsync({
                                body: {
                                    ambassadorCostPrice: Number(ambassadorCostPrice.toFixed(2)),
                                    ambassadorCostPriceFactor: Number(
                                        ambassadorCostPriceFactor.toFixed(2)
                                    ),
                                    ambassadorFirstTierFee: Number(
                                        ambassadorFirstTierFee.toFixed(2)
                                    ),
                                    ambassadorSecondTierFee: Number(
                                        ambassadorSecondTierFee.toFixed(2)
                                    ),
                                    ambassadorThirdTierFee: Number(
                                        ambassadorThirdTierFee.toFixed(2)
                                    ),
                                    ambassadorSecondTierOpen,
                                    ambassadorThirdTierOpen,
                                    ambassadorMinWorkedHourPerWeekForFee: Number(
                                        ambassadorMinWorkedHourPerWeekForFee.toFixed(2)
                                    ),
                                    candidateMinWorkedHourPerWeekForFee: Number(
                                        candidateMinWorkedHourPerWeekForFee.toFixed(2)
                                    ),
                                },
                            });
                            history.push(routePath.settings.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 401 || status === 403) {
                                    history.push('/401');
                                }
                                if (status === 404) {
                                    history.push('/not-found');
                                } else if (status === 500) {
                                    history.push('/500');
                                } else {
                                    alert(statusText);
                                }
                            });
                        }
                    }}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form noValidate style={styles.form}>
                            <h2 style={styles.formCategory}>{t('text.ambassador')}</h2>
                            <div style={styles.formFeeContainer}>
                                <div>
                                    <p style={styles.feeContainerText}>
                                        {t('form.label.firstTierFee')}:&nbsp;
                                    </p>
                                    <p style={styles.feeContainerDataText}>
                                        €{values.ambassadorFirstTierFee.toFixed(2)}
                                    </p>
                                </div>
                                <div>
                                    <p style={styles.feeContainerText}>
                                        {t('form.label.secondTierFee')}:&nbsp;
                                    </p>
                                    <p style={styles.feeContainerDataText}>
                                        €{values.ambassadorSecondTierFee.toFixed(2)}
                                    </p>
                                </div>
                                <div>
                                    <p style={styles.feeContainerText}>
                                        {t('form.label.thirdTierFee')}:&nbsp;
                                    </p>
                                    <p style={styles.feeContainerDataText}>
                                        €{values.ambassadorThirdTierFee.toFixed(2)}
                                    </p>
                                </div>
                            </div>
                            <Field
                                style={styles.formField}
                                name="ambassadorCostPrice"
                                label={t('form.label.costPrice')}
                                placeholder={t('form.placeholder.costPrice')}
                                unit="€"
                                step={0.01}
                                min={0.01}
                                max={5}
                                disabled={isSubmitting}
                                component={NumberFieldInput}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const { firstTierFee, secondTierFee, thirdTierFee } = getFee(
                                        Number(event.currentTarget.value),
                                        values.ambassadorCostPriceFactor
                                    );
                                    setFieldValue('ambassadorFirstTierFee', firstTierFee);
                                    setFieldValue('ambassadorSecondTierFee', secondTierFee);
                                    setFieldValue('ambassadorThirdTierFee', thirdTierFee);
                                }}
                            />
                            <Field
                                style={styles.formField}
                                name="ambassadorCostPriceFactor"
                                label={t('form.label.costPriceFactor')}
                                placeholder={t('form.placeholder.costPriceFactor')}
                                step={0.01}
                                min={1}
                                max={2}
                                disabled={isSubmitting}
                                component={NumberFieldInput}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const { firstTierFee, secondTierFee, thirdTierFee } = getFee(
                                        values.ambassadorCostPrice,
                                        Number(event.currentTarget.value)
                                    );
                                    setFieldValue('ambassadorFirstTierFee', firstTierFee);
                                    setFieldValue('ambassadorSecondTierFee', secondTierFee);
                                    setFieldValue('ambassadorThirdTierFee', thirdTierFee);
                                }}
                            />
                            <Field
                                style={styles.formField}
                                name="ambassadorSecondTierOpen"
                                label={t('form.label.secondTierOpen')}
                                placeholder={t('form.placeholder.secondTierOpen')}
                                unit="🕴"
                                step={1}
                                min={0}
                                max={20}
                                disabled={isSubmitting}
                                component={NumberFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="ambassadorThirdTierOpen"
                                label={t('form.label.thirdTierOpen')}
                                placeholder={t('form.placeholder.thirdTierOpen')}
                                unit="🕴"
                                step={1}
                                min={0}
                                max={50}
                                disabled={isSubmitting}
                                component={NumberFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="ambassadorMinWorkedHourPerWeekForFee"
                                label={t('form.label.minWorkedHourPerWeekForFee')}
                                placeholder={t('form.placeholder.minWorkedHourPerWeekForFee')}
                                unit="⌚"
                                step={0.5}
                                min={0}
                                max={40}
                                disabled={isSubmitting}
                                component={NumberFieldInput}
                            />
                            <h2 style={styles.formCategory}>{t('text.candidate')}</h2>
                            <Field
                                style={styles.formField}
                                name="candidateMinWorkedHourPerWeekForFee"
                                label={t('form.label.minWorkedHourPerWeekForFee')}
                                placeholder={t('form.placeholder.minWorkedHourPerWeekForFee')}
                                unit="⌚"
                                step={0.5}
                                min={0}
                                max={40}
                                disabled={isSubmitting}
                                component={NumberFieldInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.update')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.push(routePath.settings.create())}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </Page>
    );
};
