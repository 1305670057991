import { IAmbassador } from './types';
import { Cache, useApi } from '../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAmbassadors = (search: string) => {
    const api = useApi();

    return useQuery<IAmbassador[], any>([Cache.ambassadors, search], () =>
        api.get(`/ambassadors?search=${search}`).then((res) => res.data)
    );
};
