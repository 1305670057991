/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { IDepartment } from '../hooks';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';

interface IProps {
    options: IDepartment[];
    value: IDepartment | null;
    setDepartment: (ambassador: IDepartment | null) => void;
    style?: React.CSSProperties;
    className?: string;
}

export const DepartmentComboBoxInput = ({
    options,
    value,
    setDepartment,
    label,
    placeholder,
    disabled,
    style,
    className,
}: IProps & TextFieldProps) => {
    const styles = useStyles();
    const [inputValue, setInputValue] = React.useState('');
    const { i18n } = useTranslation();

    return (
        <Autocomplete
            value={value}
            inputValue={inputValue}
            placeholder={placeholder}
            disabled={disabled}
            onInputChange={(_event, newValue) => {
                setInputValue(newValue);
            }}
            onChange={(_event, newValue) => {
                //setValue(newValue);
                setDepartment(newValue);
            }}
            onBlur={() => {}}
            onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
            }}
            options={options ? options : []}
            getOptionLabel={(option) => option[i18n.language as keyof IDepartment]}
            renderOption={(props: any) => {
                return (
                    <span {...props} style={{ minHeight: '3rem' }}>
                        {props.key}
                    </span>
                );
            }}
            //isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    css={style as Interpolation<Theme>}
                    sx={styles.textField}
                    className={className}
                    variant="standard"
                    type="text"
                    label={label}
                    placeholder={placeholder}
                    autoComplete="new-password"
                    disabled={disabled}
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        role: 'presentation',
                        autoComplete: 'new-password',
                        spellCheck: false,
                        'aria-live': 'polite',
                        disabled: disabled,
                    }}
                />
            )}
        />
    );
};
