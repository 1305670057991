import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { ICustomStyle } from './types';
import { colors } from '../../../helpers';

export const useStyles = (
    field: FieldInputProps<any>,
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    cs?: ICustomStyle
) => ({
    textField: {
        // Standard
        '& .MuiInput-underline': {
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
            color: cs && cs.textColor ? cs.textColor : colors.blue,
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: cs && cs.inputColor ? cs.inputColor : colors.blue,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor:
                Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? cs && cs.errorTextColor
                        ? cs.errorTextColor
                        : colors.red
                    : cs && cs.inputActiveColor
                    ? cs.inputActiveColor
                    : cs && cs.inputColor
                    ? cs.inputColor
                    : colors.blue,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: `${cs && cs.inputColor ? cs.inputColor : colors.blue} !important`,
        },
        // Label
        '& .MuiInputLabel-animated': {
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
            color:
                cs && cs.inputActiveColor
                    ? cs.inputActiveColor
                    : cs && cs.inputColor
                    ? cs.inputColor
                    : colors.blue,
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            userSelect: 'none',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color:
                cs && cs.inputActiveColor
                    ? cs.inputActiveColor
                    : cs && cs.inputColor
                    ? cs.inputColor
                    : colors.blue,
        },
        // Error label
        '& .MuiFormLabel-root.Mui-error': {
            color: colors.red,
        },
        // Error text
        '& .MuiFormHelperText-root': {
            fontSize:
                cs && cs.errorFontSize
                    ? cs.errorFontSize
                    : cs && cs.fontSize
                    ? `calc(${cs.fontSize} * 0.6)`
                    : '1.2rem',
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            fontWeight: 'bold',
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
            userSelect: 'none',
        },
        '& .MuiInput-underline.Mui-error:after': {
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
        },
        // Disabled
        '& .MuiFormLabel-root.Mui-disabled': {
            color: cs && cs.textColor ? cs.textColor : colors.blue,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: `${cs && cs.textColor ? cs.textColor : colors.blue} !important`,
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: cs && cs.inputColor ? `1px solid ${cs.inputColor}` : `1px solid ${colors.blue}`,
        },
        'input[type="date"]::-webkit-calendar-picker-indicator': {
            backgroundImage:
                cs && cs.iconColorRGB
                    ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="fill: ${cs.iconColorRGB};" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`
                    : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="fill: ${colors.blueRGB};" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`,
        },
        'input[type="month"]::-webkit-calendar-picker-indicator': {
            backgroundImage:
                cs && cs.iconColorRGB
                    ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="fill: ${cs.iconColorRGB};" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`
                    : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="fill: ${colors.blueRGB};" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`,
        },
        'input[type="week"]::-webkit-calendar-picker-indicator': {
            backgroundImage:
                cs && cs.iconColorRGB
                    ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="fill: ${cs.iconColorRGB};" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`
                    : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="fill: ${colors.blueRGB};" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`,
        },
    },
});
