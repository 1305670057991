import { IAdmin } from '../types';
import { useApi, Cache } from '../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAdmin = (adminId: string) => {
    const api = useApi();

    return useQuery<IAdmin, any>([Cache.admins, adminId], ({ signal }) =>
        api.get(`/admins/${adminId}`, { signal }).then((res) => res.data)
    );
};
