/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchNewsletterMailReceivers, useSendNewsletter } from './hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { Page, TextFieldInput, Button, RichTextEditorInput } from '../../../components';
import {
    handleQueryError,
    colors,
    handleError,
    routePath,
    Role,
    isRichTextEditorEmpty,
} from '../../../helpers';
import { EmailInput } from './email-input/EmailInput';

export const Newsletter = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchNewsletterMailReceivers();
    const sendNewsletter = useSendNewsletter();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            isPageSubmitting={sendNewsletter.isLoading}
            title={t('text.sendNewsletter')}
            style={styles.root}
        >
            {isLoading && (
                <div style={styles.form}>
                    <div style={styles.formAmbassadorButtonsContainer}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.ambassadorButtonSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.ambassadorButtonSkeleton}
                        />
                    </div>
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.contentFieldSkeleton}
                    />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            )}
            {data && (
                <Formik
                    initialValues={
                        {
                            to: [],
                            cc: [],
                            bcc: [],
                            subject: '',
                            content: '',
                        } as IFormValues
                    }
                    validate={({ to, subject, content }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (to.length < 1) {
                            errors.to = t('form.error.requiredField');
                        }
                        if (!subject) {
                            errors.subject = t('form.error.requiredField');
                        }
                        if (isRichTextEditorEmpty(content)) {
                            errors.content = t('form.error.requiredField');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ to, cc, bcc, subject, content }) => {
                        try {
                            await sendNewsletter.mutateAsync({
                                body: {
                                    to: to.map((mailReceiver) => mailReceiver.email),
                                    cc: cc.map((mailReceiver) => mailReceiver.email),
                                    bcc: bcc.map((mailReceiver) => mailReceiver.email),
                                    subject,
                                    content,
                                },
                            });
                            history.push('/settings');
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form noValidate style={styles.form}>
                            <div style={styles.formAmbassadorButtonsContainer}>
                                <Button
                                    css={styles.ambassadorButton}
                                    type="button"
                                    disabled={isSubmitting}
                                    cs={{
                                        color: colors.blue,
                                        padding: '1rem 2rem',
                                        fontSize: '1.25rem',
                                    }}
                                    onClick={() =>
                                        setFieldValue(
                                            'to',
                                            values.to.concat(
                                                data.filter(
                                                    (mailReceiver) =>
                                                        mailReceiver.role === Role.ambassador
                                                )
                                            )
                                        )
                                    }
                                >
                                    {t('text.addAmbassadors')}
                                </Button>
                                <Button
                                    css={styles.ambassadorButton}
                                    type="button"
                                    disabled={isSubmitting}
                                    cs={{
                                        color: colors.blue,
                                        padding: '1rem 2rem',
                                        fontSize: '1.25rem',
                                    }}
                                    onClick={() =>
                                        setFieldValue(
                                            'to',
                                            values.to.filter(
                                                (mailReceiver) =>
                                                    mailReceiver.role !== Role.ambassador
                                            )
                                        )
                                    }
                                >
                                    {t('text.removeAmbassadors')}
                                </Button>
                            </div>
                            <Field
                                style={styles.formField}
                                name="to"
                                label={t('form.label.newsletterTo')}
                                placeholder={t('form.placeholder.newsletterTo')}
                                data={data}
                                ambassadorButtons
                                disabled={isSubmitting}
                                component={EmailInput}
                            />
                            <Field
                                style={styles.formField}
                                name="cc"
                                label={t('form.label.newsletterCC')}
                                placeholder={t('form.placeholder.newsletterCC')}
                                data={data}
                                disabled={isSubmitting}
                                component={EmailInput}
                            />
                            <Field
                                style={styles.formField}
                                name="bcc"
                                label={t('form.label.newsletterBCC')}
                                placeholder={t('form.placeholder.newsletterBCC')}
                                data={data}
                                disabled={isSubmitting}
                                component={EmailInput}
                            />
                            <Field
                                style={styles.formField}
                                name="subject"
                                type="text"
                                label={t('form.label.newsletterSubject')}
                                placeholder={t('form.placeholder.newsletterSubject')}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="content"
                                label={t('form.label.emailContent')}
                                placeholder={t('form.placeholder.emailContent')}
                                controls={['bold', 'italic', 'underline', 'undo', 'redo']}
                                component={RichTextEditorInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.send')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.push(routePath.settings.create())}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            )}
        </Page>
    );
};
