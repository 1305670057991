/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { colors } from '../../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { Formik, Form, Field, FormikErrors, FormikHelpers } from 'formik';
import { useMediaQuery } from '@mui/material';
import { TextFieldInput, Button } from '../../../../components';
import { BiEnvelope } from 'react-icons/bi';

interface IProps {
    mutationType: 'create' | 'update';
    initialValues: IFormValues;
    onSubmit: (
        values: IFormValues,
        formikHelpers: FormikHelpers<IFormValues>
    ) => void | Promise<any>;
}

export const DepartmentForm = ({ mutationType, initialValues, onSubmit }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={(values) => {
                let errors: FormikErrors<IFormValues> = {};
                if (!values.email.trim()) errors.email = t('form.error.requiredField');
                else if (values.email.trim().length > 127) {
                    errors.email = t('form.error.max127Chars');
                } else if (!validator.isEmail(values.email.trim())) {
                    errors.email = t('form.error.invalidEmail');
                }
                if (!values.confirmEmail.trim())
                    errors.confirmEmail = t('form.error.requiredField');
                else if (values.confirmEmail.trim().length > 127) {
                    errors.confirmEmail = t('form.error.max127Chars');
                } else if (!validator.isEmail(values.confirmEmail.trim())) {
                    errors.confirmEmail = t('form.error.invalidEmail');
                } else if (values.email.trim() !== values.confirmEmail.trim()) {
                    errors.email = t('form.error.valuesDoNotMatch');
                    errors.confirmEmail = t('form.error.valuesDoNotMatch');
                }
                for (let key in values) {
                    if (key !== 'email' && !values[key as keyof IFormValues].trim()) {
                        errors[key as keyof IFormValues] = t('form.error.requiredField');
                    } else if (
                        key !== 'email' &&
                        values[key as keyof IFormValues].trim().length > 127
                    ) {
                        errors[key as keyof IFormValues] = t('form.error.max127Chars');
                    }
                }
                return errors;
            }}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form noValidate style={styles.form}>
                    <Field
                        style={styles.formField}
                        name="email"
                        type="email"
                        label={t('form.label.email')}
                        placeholder={t('form.placeholder.email')}
                        icon={<BiEnvelope />}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="confirmEmail"
                        type="email"
                        label={t('form.label.confirmEmail')}
                        placeholder={t('form.placeholder.confirmEmail')}
                        icon={<BiEnvelope />}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="en"
                        type="text"
                        label={t('form.label.english')}
                        placeholder={t('form.placeholder.english')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="nl"
                        type="text"
                        label={t('form.label.dutch')}
                        placeholder={t('form.placeholder.dutch')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="ro"
                        type="text"
                        label={t('form.label.romanian')}
                        placeholder={t('form.placeholder.romanian')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="pl"
                        type="text"
                        label={t('form.label.polish')}
                        placeholder={t('form.placeholder.polish')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="hr"
                        type="text"
                        label={t('form.label.croatian')}
                        placeholder={t('form.placeholder.croatian')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="pt"
                        type="text"
                        label={t('form.label.portuguese')}
                        placeholder={t('form.placeholder.portuguese')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Field
                        style={styles.formField}
                        name="hu"
                        type="text"
                        label={t('form.label.hungarian')}
                        placeholder={t('form.placeholder.hungarian')}
                        disabled={isSubmitting}
                        component={TextFieldInput}
                    />
                    <Button
                        css={styles.formButton}
                        type="submit"
                        disabled={isSubmitting}
                        spinner={isSubmitting}
                        cs={{
                            color: colors.blue,
                        }}
                    >
                        {mutationType === 'create' ? t('text.create') : t('text.update')}
                    </Button>
                    <Button
                        css={styles.formButton}
                        type="button"
                        disabled={isSubmitting}
                        spinner={isSubmitting}
                        cs={{
                            color: colors.blue,
                        }}
                        onClick={() => history.goBack()}
                    >
                        {t('text.back')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
