export const localTextPT = {
    selectAll: '(Selecione Tudo)',
    selectAllSearchResults: '(Selecione todos os resultados da pesquisa)',
    searchOoo: 'Pesquisar...',
    blanks: '(Em branco)',
    noMatches: 'Sem correspondências',
    filterOoo: 'Filtrar...',
    equals: 'Igual',
    notEqual: 'Não é igual',
    empty: 'Escolha um',
    lessThan: 'Menos de',
    greaterThan: 'Maior que',
    lessThanOrEqual: 'Menor ou igual',
    greaterThanOrEqual: 'Maior ou igual',
    inRange: 'No intervalo',
    inRangeStart: 'De',
    inRangeEnd: 'Para',
    contains: 'Contém',
    notContains: 'Não contém',
    startsWith: 'Começa com',
    endsWith: 'Termina com',
    dateFormatOoo: 'AAAA-MM-DD',
    andCondition: 'E AINDA',
    orCondition: 'OU',
    applyFilter: 'Candidatar-se',
    resetFilter: 'Repor',
    clearFilter: 'Limpar',
    cancelFilter: 'Cancelar',
    textFilter: 'Filtro de texto',
    numberFilter: 'Filtro de números',
    dateFilter: 'Filtro de Data',
    setFilter: 'Definir filtro',
    columns: 'Colunas',
    filters: 'Filtros',
    pivotMode: 'Modo Pivot',
    groups: 'Grupos de linhas',
    rowGroupColumnsEmptyMessage: 'Arraste aqui para definir grupos de linhas',
    values: 'Valores',
    valueColumnsEmptyMessage: 'Arraste aqui para agregar',
    pivots: 'Rótulos de coluna',
    pivotColumnsEmptyMessage: 'Arraste aqui para definir rótulos de coluna',
    group: 'Grupo',
    loadingOoo: 'A carregar...',
    noRowsToShow: 'Sem linhas a mostrar',
    enabled: 'Ativado',
    pinColumn: 'Coluna de pinos',
    pinLeft: 'Fixar à esquerda',
    pinRight: 'Fixar à direita',
    noPin: 'Sem pino',
    valueAggregation: 'Agregação de valor',
    autosizeThiscolumn: 'Dimensionar automaticamente esta coluna',
    autosizeAllColumns: 'Dimensionar automaticamente todas as colunas',
    groupBy: 'Grupo por',
    ungroupBy: 'Un-Group por',
    resetColumns: 'Redefinir colunas',
    expandAll: 'Expandir Tudo',
    collapseAll: 'Fechar tudo',
    copy: 'Copiar',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copiar com cabeçalhos',
    paste: 'Colar',
    ctrlV: 'Ctrl+V',
    export: 'Exportação',
    csvExport: 'Exportação CSV',
    excelExport: 'Exportação do Excel',
    sum: 'Soma',
    min: 'Min',
    max: 'Máximo',
    none: 'Nenhum',
    count: 'Contagem',
    avg: 'Média',
    filteredRows: 'Filtrado',
    selectedRows: 'Selecionados',
    totalRows: 'Total de Linhas',
    totalAndFilteredRows: 'Linhas',
    more: 'Saber mais',
    to: 'Para',
    of: 'de',
    page: 'Página',
    nextPage: 'Página Seguinte',
    lastPage: 'Última Página',
    firstPage: 'Primeira Página',
    previousPage: 'Página Anterior',
    pivotColumnGroupTotals: 'Total',
    pivotChartAndPivotMode: 'Gráfico Dinâmico & Modo Pivot',
    pivotChart: 'Gráfico Dinâmico',
    chartRange: 'Intervalo de gráficos',
    columnChart: 'Coluna',
    groupedColumn: 'Agrupados',
    stackedColumn: 'Empilhado',
    normalizedColumn: '100% empilhado',
    barChart: 'Barra',
    groupedBar: 'Agrupados',
    stackedBar: 'Empilhado',
    normalizedBar: '100% empilhado',
    pieChart: 'Torta',
    pie: 'Torta',
    doughnut: 'Donut',
    line: 'Linha',
    xyChart: 'X Y (Dispersão)',
    scatter: 'Dispersão',
    bubble: 'Bolha',
    areaChart: 'Área',
    area: 'Área',
    stackedArea: 'Empilhado',
    normalizedArea: '100% empilhado',
    histogramChart: 'Histograma',
    pivotChartTitle: 'Gráfico Dinâmico',
    rangeChartTitle: 'Gráfico de intervalo',
    settings: 'Configurações',
    data: 'Dados',
    format: 'Formato',
    categories: 'Categorias',
    defaultCategory: '(Nenhum)',
    series: 'Série',
    xyValues: 'Valores X Y',
    paired: 'Modo emparelhado',
    axis: 'Eixo',
    navigator: 'Navegador',
    color: 'Cor',
    thickness: 'Espessura',
    xType: 'Tipo X',
    automatic: 'Automático',
    category: 'Categoria',
    number: 'Número',
    time: 'Hora',
    xRotation: 'Rotação X',
    yRotation: 'Rotação Y',
    ticks: 'Carrapatos',
    width: 'Largura',
    height: 'Altura',
    length: 'Comprimento',
    padding: 'Acolchoamento',
    spacing: 'Espaçamento',
    chart: 'Gráfico',
    title: 'Título',
    titlePlaceholder: 'Título do gráfico - clique duas vezes para editar',
    background: 'Contexto geral',
    font: 'Fonte',
    top: 'Início',
    right: 'Certo',
    bottom: 'Inferior',
    left: 'Esquerda',
    labels: 'Rótulos',
    size: 'Tamanho',
    minSize: 'Tamanho mínimo',
    maxSize: 'Tamanho máximo',
    legend: 'Lenda',
    position: 'Posição',
    markerSize: 'Tamanho do marcador',
    markerStroke: 'Traçado do marcador',
    markerPadding: 'Preenchimento de marcadores',
    itemSpacing: 'Espaçamento entre itens',
    itemPaddingX: 'Preenchimento do item X',
    itemPaddingY: 'Preenchimento do item Y',
    layoutHorizontalSpacing: 'Espaçamento Horizontal',
    layoutVerticalSpacing: 'Espaçamento Vertical',
    strokeWidth: 'Largura do curso',
    offset: 'Deslocamento',
    offsets: 'Compensações',
    tooltips: 'Dicas de ferramentas',
    callout: 'Texto explicativo',
    markers: 'Marcadores',
    shadow: 'Sombra',
    blur: 'Desfoque',
    xOffset: 'Deslocamento X',
    yOffset: 'Deslocamento em Y',
    lineWidth: 'Largura da linha',
    normal: 'Normal',
    bold: 'Negrito',
    italic: 'Itálico',
    boldItalic: 'Negrito Itálico',
    predefined: 'Predefinido',
    fillOpacity: 'Opacidade de enchimento',
    strokeOpacity: 'Opacidade da linha',
    histogramBinCount: 'Contagem de compartimentos',
    columnGroup: 'Coluna',
    barGroup: 'Barra',
    pieGroup: 'Torta',
    lineGroup: 'Linha',
    scatterGroup: 'X Y (Dispersão)',
    areaGroup: 'Área',
    histogramGroup: 'Histograma',
    groupedColumnTooltip: 'Agrupados',
    stackedColumnTooltip: 'Empilhado',
    normalizedColumnTooltip: '100% empilhado',
    groupedBarTooltip: 'Agrupados',
    stackedBarTooltip: 'Empilhado',
    normalizedBarTooltip: '100% empilhado',
    pieTooltip: 'Torta',
    doughnutTooltip: 'Donut',
    lineTooltip: 'Linha',
    groupedAreaTooltip: 'Área',
    stackedAreaTooltip: 'Empilhado',
    normalizedAreaTooltip: '100% empilhado',
    scatterTooltip: 'Dispersão',
    bubbleTooltip: 'Bolha',
    histogramTooltip: 'Histograma',
    noDataToChart: 'Não há dados disponíveis para serem mapeados.',
    pivotChartRequiresPivotMode: 'O Gráfico Dinâmico requer o Modo Dinâmico ativado.',
    chartSettingsToolbarTooltip: 'Cardápio',
    chartLinkToolbarTooltip: 'Vinculado à grade',
    chartUnlinkToolbarTooltip: 'Desvinculado da grade',
    chartDownloadToolbarTooltip: 'Descarregar Gráfico',
    ariaHidden: 'escondido',
    ariaVisible: 'visível',
    ariaChecked: 'verificada',
    ariaUnchecked: 'não verificado',
    ariaIndeterminate: 'indeterminado',
    ariaColumnSelectAll: 'Alternar Selecionar Todas as Colunas',
    ariaInputEditor: 'Editor de entrada',
    ariaDateFilterInput: 'Entrada do filtro de data',
    ariaFilterInput: 'Entrada de filtro',
    ariaFilterColumnsInput: 'Filtrar colunas de entrada',
    ariaFilterValue: 'Valor do filtro',
    ariaFilterFromValue: 'Filtrar a partir do valor',
    ariaFilterToValue: 'Filtrar por valor',
    ariaFilteringOperator: 'Operador de filtragem',
    ariaColumn: 'Coluna',
    ariaColumnGroup: 'Grupo de colunas',
    ariaRowSelect: 'Pressione SPACE para selecionar esta linha',
    ariaRowDeselect: 'Pressione SPACE para desmarcar esta linha',
    ariaRowToggleSelection: 'Pressione Espaço para alternar a seleção de linhas',
    ariaRowSelectAll: 'Pressione Espaço para alternar a seleção de todas as linhas',
    ariaToggleVisibility: 'Pressione SPACE para alternar a visibilidade',
    ariaSearch: 'Pesquisar',
    ariaSearchFilterValues: 'Valores do filtro de pesquisa',
    ariaLabelColumnMenu: 'Menu Coluna',
    ariaLabelCellEditor: 'Editor de células',
    ariaLabelDialog: 'Caixa de diálogo',
    ariaLabelSelectField: 'Selecionar campo',
    ariaLabelTooltip: 'Dica de ferramenta',
    ariaLabelContextMenu: 'Menu de contexto',
    ariaLabelSubMenu: 'Submenu',
    ariaLabelAggregationFunction: 'Função de agregação',
};
