/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        contentTitle: {
            marginBottom: '4rem',
            color: colors.blue,
            userSelect: 'none',
            transition: 'all 0.2s',
            '&:hover': {
                transform: 'skewY(2deg) skewx(15deg) scale(1.1)',
                textShadow: `0.5rem 1rem 2rem ${colors.darkBoxShadow}`,
            },
        },
        contentForm: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: widthBelow600px ? '30rem' : '40rem',
        },
        formField: {
            marginBottom: '2rem',
        },
        formButton: {
            width: '25rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        fieldSkeleton: {
            width: '100%',
            height: '4rem',
            marginBottom: '4rem',
        },
        buttonSkeleton: {
            width: '18rem',
            height: '5rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
    });
