export const localTextHR = {
    selectAll: '(Odaberi sve)',
    selectAllSearchResults: '(Odaberi sve rezultate pretraživanja)',
    searchOoo: 'Pretraživanje...',
    blanks: '(Praznine)',
    noMatches: 'Nema podudaranja',
    filterOoo: 'Filtriranje...',
    equals: 'Jednako',
    notEqual: 'Nije jednako',
    empty: 'Odaberite jedan',
    lessThan: 'Manje od',
    greaterThan: 'Veće od',
    lessThanOrEqual: 'Manje od ili jednako',
    greaterThanOrEqual: 'Veće od ili jednako',
    inRange: 'U dometu',
    inRangeStart: 'od',
    inRangeEnd: 'do',
    contains: 'Sadržava',
    notContains: 'Ne sadrži',
    startsWith: 'Počinje s',
    endsWith: 'Završava sa',
    dateFormatOoo: 'gggg-mm-dd',
    andCondition: 'I',
    orCondition: 'ILI',
    applyFilter: 'Primijeniti',
    resetFilter: 'Resetirati',
    clearFilter: 'Ukloni',
    cancelFilter: 'Otkazati',
    textFilter: 'Filtar teksta',
    numberFilter: 'Filtar brojeva',
    dateFilter: 'Filtar datuma',
    setFilter: 'Postavi filtar',
    columns: 'Kolone',
    filters: 'Filteri',
    pivotMode: 'Pivot način ',
    groups: 'Grupe redaka',
    rowGroupColumnsEmptyMessage: 'Povucite ovdje da biste postavili grupe redaka',
    values: 'Vrijednosti',
    valueColumnsEmptyMessage: 'Povucite ovdje da biste se zbrajali',
    pivots: 'Natpisi stupaca',
    pivotColumnsEmptyMessage: 'Povucite ovdje da biste postavili natpise stupaca',
    group: 'Grupa',
    loadingOoo: 'Učitavanje...',
    noRowsToShow: 'Nema redaka za prikaz',
    enabled: 'Omogućen',
    pinColumn: 'Prikvači stupac',
    pinLeft: 'Prikvači ulijevo',
    pinRight: 'Prikvači udesno',
    noPin: 'Bez prikvačenja',
    valueAggregation: 'Zbrajanje vrijednosti',
    autosizeThiscolumn: 'Automatska veličina ovog stupca',
    autosizeAllColumns: 'Automatska veličina svih stupaca',
    groupBy: 'Grupiraj po',
    ungroupBy: 'Poništi grupiranje po',
    resetColumns: 'Vrati izvorne stupce',
    expandAll: 'Proširi sve',
    collapseAll: 'Zatvori sve',
    copy: 'Kopija',
    ctrlC: 'Ctrl + C',
    copyWithHeaders: 'Kopiraj sa zaglavljima',
    paste: 'Zalijepi',
    ctrlV: 'Ctrl + V',
    export: 'Izvoz',
    csvExport: 'CSV izvoz',
    excelExport: 'Excel izvoz',
    sum: 'Suma',
    min: 'Min',
    max: 'Max',
    none: 'Nijedan',
    count: 'Brojanje',
    avg: 'Prosjek',
    filteredRows: 'Filtrirani',
    selectedRows: 'Odabrani',
    totalRows: 'Ukupni retci',
    totalAndFilteredRows: 'Retci',
    more: 'Više',
    to: 'do',
    of: 'od',
    page: 'Stranica',
    nextPage: 'Sljedeća stranica',
    lastPage: 'Posljednja stranica',
    firstPage: 'Prva stranica',
    previousPage: 'Prethodna stranica',
    pivotColumnGroupTotals: 'Ukupan',
    pivotChartAndPivotMode: 'Pivot grafikon & način pivot tablice',
    pivotChart: 'Pivot grafikon',
    chartRange: 'Raspon grafikona',
    columnChart: 'Stupac',
    groupedColumn: 'Grupiran',
    stackedColumn: 'Složeni',
    normalizedColumn: '100% složeno',
    barChart: 'Šipka',
    groupedBar: 'Grupirano',
    stackedBar: 'Složeni',
    normalizedBar: '100% složeno',
    pieChart: 'Pita',
    pie: 'Pita',
    doughnut: 'Krafna',
    line: 'Crta',
    xyChart: 'X Y (raspršeni)',
    scatter: 'Raspršiti',
    bubble: 'Mjehur',
    areaChart: 'Područje',
    area: 'Područje',
    stackedArea: 'Složeni',
    normalizedArea: '100% složeno',
    histogramChart: 'Histogram',
    pivotChartTitle: 'Pivot grafikon',
    rangeChartTitle: 'Grafikon raspona',
    settings: 'Postavke',
    data: 'Podaci',
    format: 'Format',
    categories: 'Kategorije',
    defaultCategory: '(Nema)',
    series: 'Serije',
    xyValues: 'Vrijednosti X Y',
    paired: 'Upareni način rada',
    axis: 'Osovina',
    navigator: 'Navigator',
    color: 'Boja',
    thickness: 'Debljina',
    xType: 'Vrsta X',
    automatic: 'Automatski',
    category: 'Kategorija',
    number: 'Broj',
    time: 'Vrijeme',
    xRotation: 'Rotacija X',
    yRotation: 'Rotacija Y',
    ticks: 'Kvačice',
    width: 'Širina',
    height: 'Visina',
    length: 'Duljina',
    padding: 'Ispunjavanje',
    spacing: 'Razmak',
    chart: 'Grafikon',
    title: 'Naslov',
    titlePlaceholder: 'Naslov grafikona – dupli klik da biste uredili',
    background: 'Pozadina',
    font: 'Font',
    top: 'Vrh',
    right: 'Desno',
    bottom: 'Dno',
    left: 'Lijevo',
    labels: 'Natpisi',
    size: 'Veličina',
    minSize: 'Minimalna veličina',
    maxSize: 'Maksimalna veličina',
    legend: 'Legenda',
    position: 'Pozicija',
    markerSize: 'Veličina oznake',
    markerStroke: 'Potez markera',
    markerPadding: 'Ispuna markera',
    itemSpacing: 'Razmak između artikala',
    itemPaddingX: 'Ispuna stavke X',
    itemPaddingY: 'Ispuna stavke Y',
    layoutHorizontalSpacing: 'Vodoravni razmak',
    layoutVerticalSpacing: 'Okomiti razmak',
    strokeWidth: 'Širina poteza',
    offset: 'Offseta',
    offsets: 'Offseti',
    tooltips: 'Opisi alata',
    callout: 'Oblačić',
    markers: 'Markeri',
    shadow: 'Sjena',
    blur: 'Mrlja',
    xOffset: 'Pomak X',
    yOffset: 'Pomak Y',
    lineWidth: 'Širina crte',
    normal: 'Normalno',
    bold: 'Podebljano',
    italic: 'Kurziv',
    boldItalic: 'Podebljano kurziv',
    predefined: 'Unaprijed definiranih',
    fillOpacity: 'Ispuni neprozirnost',
    strokeOpacity: 'Neprozirnost crte',
    histogramBinCount: 'Broj spremnika',
    columnGroup: 'Stupac',
    barGroup: 'Stup',
    pieGroup: 'Pita',
    lineGroup: 'Crta',
    scatterGroup: 'X Y (raspršeni)',
    areaGroup: 'Područje',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grupiran',
    stackedColumnTooltip: 'Složeni',
    normalizedColumnTooltip: '100% složeno',
    groupedBarTooltip: 'Grupiran',
    stackedBarTooltip: 'Složeni',
    normalizedBarTooltip: '100% složeno',
    pieTooltip: 'Pita',
    doughnutTooltip: 'Krafna',
    lineTooltip: 'Crta',
    groupedAreaTooltip: 'Područje',
    stackedAreaTooltip: 'Složeni',
    normalizedAreaTooltip: '100% složeno',
    scatterTooltip: 'Raspršiti',
    bubbleTooltip: 'Mjehur',
    histogramTooltip: 'Histogram',
    noDataToChart: 'Nema dostupnih podataka za grafikon.',
    pivotChartRequiresPivotMode: 'Pivot grafikon zahtijeva omogućen pivotni način rada.',
    chartSettingsToolbarTooltip: 'Izbornik',
    chartLinkToolbarTooltip: 'Povezano s mrežom',
    chartUnlinkToolbarTooltip: 'Nepovezano s mrežom',
    chartDownloadToolbarTooltip: 'Preuzmi grafikon',
    ariaHidden: 'skriven',
    ariaVisible: 'vidljiv',
    ariaChecked: 'provjeren',
    ariaUnchecked: 'neprovjeren',
    ariaIndeterminate: 'neodređeno',
    ariaColumnSelectAll: 'Uključi/isključi odabir svih stupaca',
    ariaInputEditor: 'Uređivač ulaza',
    ariaDateFilterInput: 'Unos filtra datuma',
    ariaFilterInput: 'Unos filtra',
    ariaFilterColumnsInput: 'Unos stupaca filtra',
    ariaFilterValue: 'Vrijednost filtra',
    ariaFilterFromValue: 'Filtriraj iz vrijednosti',
    ariaFilterToValue: 'Filtriraj prema vrijednosti',
    ariaFilteringOperator: 'Operator filtriranja',
    ariaColumn: 'Stupac',
    ariaColumnGroup: 'Grupa stupaca',
    ariaRowSelect: 'Pritisnite RAZMAKNICU da biste odabrali ovaj redak',
    ariaRowDeselect: 'Pritisnite RAZMAKNICU da biste poništili odabir ovog retka',
    ariaRowToggleSelection: 'Pritisnite RAZMAKNICU da biste uključili odabir retka',
    ariaRowSelectAll: 'Pritisnite RAZMAKNICU da biste uključili odabir svih redaka',
    ariaToggleVisibility: 'Pritisnite RAZMAKNICU da biste uključili vidljivost',
    ariaSearch: 'Pretraživanje',
    ariaSearchFilterValues: 'Vrijednosti filtra pretraživanja',
    ariaLabelColumnMenu: 'Izbornik stupca',
    ariaLabelCellEditor: 'Uređivač ćelija',
    ariaLabelDialog: 'Dijalog',
    ariaLabelSelectField: 'Odaberi polje',
    ariaLabelTooltip: 'Opis alata',
    ariaLabelContextMenu: 'Kontekstni izbornik',
    ariaLabelSubMenu: 'Podizbornik',
    ariaLabelAggregationFunction: 'Funkcija zbrajanja',
};
