/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { IAdmin } from '../../hooks/types';
import { IPayload as IDeleteModalPayload } from '../../../../helpers/reducers/delete/types';
import { IPayload as IResendQRModalPayload } from '../../../../helpers/reducers/resend-qr-code/types';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components';
import { useMediaQuery } from '@mui/material';
import { MainPanel, InfoPanel } from './panels';
import { Actions } from './actions/Actions';

interface IProps {
    admin: IAdmin;
    openDelete: (payload: IDeleteModalPayload) => void;
    openResendQR: (payload: IResendQRModalPayload) => void;
}

export const AdminCard = ({ admin, openDelete, openResendQR }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const { t } = useTranslation();

    return (
        <Card
            firstPanel={<MainPanel admin={admin} />}
            secondPanel={<InfoPanel admin={admin} />}
            actions={<Actions admin={admin} openDelete={openDelete} openResendQR={openResendQR} />}
            firstPanelLabel={t('text.administrator')}
            secondPanelLabel={t('text.info')}
            cs={{
                width: widthBelow600px ? '30rem' : '40rem',
                margin: widthBelow600px ? '2rem 0' : '3rem',
                contentHeight: '15rem',
            }}
        />
    );
};
