/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { ICustomStyle } from './types';
import { spin } from '../keyframes';

export const useStyles = ({ radius, color, thickness }: ICustomStyle) => ({
    rondSpinner: css({
        display: 'inline-block',
        width: radius,
        height: radius,
        color: color,
        verticalAlign: 'middle',
        pointerEvents: 'none',
        borderRadius: '50%',
        animation: `1s ${spin} linear infinite`,
        border: thickness
            ? `${thickness} solid currentcolor`
            : `calc(${radius} * 0.1) solid currentcolor`,
        borderBottomColor: 'transparent',
    }),
    dottedSpinner: css({
        display: 'inline-block',
        width: radius,
        height: radius,
        color: color,
        verticalAlign: 'middle',
        pointerEvents: 'none',
        borderRadius: '50%',
        animation: `1s ${spin} linear infinite`,
        border: thickness
            ? `${thickness} dotted currentcolor`
            : `calc(${radius} * 0.1) dotted currentcolor`,
    }),
});
