import { IUser } from './types';
import { Cache, useApi } from '../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchUser = (userId: string) => {
    const api = useApi();

    return useQuery<IUser, any>([Cache.users, userId], ({ signal }) =>
        api.get(`/users/${userId}`, { signal }).then((res) => res.data)
    );
};
