/**@jsxImportSource @emotion/react */
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { ICustomStyles } from './types';
import { colors } from '../../../helpers';
import { css } from '@emotion/react';

export const useStyles = (
    field: FieldInputProps<any>,
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    cs?: ICustomStyles
) => ({
    formControl: {
        // Standard
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
        '& .MuiInput-underline': {
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
            color: cs && cs.textColor ? cs.textColor : colors.blue,
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: cs && cs.inputColor ? cs.inputColor : colors.blue,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor:
                Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? cs && cs.errorTextColor
                        ? cs.errorTextColor
                        : colors.red
                    : cs && cs.inputActiveColor
                    ? cs.inputActiveColor
                    : cs && cs.inputColor
                    ? cs.inputColor
                    : colors.blue,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: `${cs && cs.inputColor ? cs.inputColor : colors.blue} !important`,
        },
        // Outlined
        '& .MuiOutlinedInput-root': {
            fontSize: cs && cs.fontSize ? cs.fontSize : colors.blue,
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            color: cs && cs.textColor ? cs.textColor : colors.blue,
            '& fieldset': {
                borderColor: cs && cs.inputColor ? cs.inputColor : colors.blue,
            },
            '&:hover fieldset': {
                borderColor: cs && cs.inputColor ? cs.inputColor : colors.blue,
                borderWidth: '2px',
            },
            '&.Mui-focused fieldset': {
                borderColor:
                    cs && cs.inputActiveColor
                        ? cs.inputActiveColor
                        : cs && cs.inputColor
                        ? cs.inputColor
                        : colors.blue,
            },
        },
        //Arrow
        '& .MuiSvgIcon-root': {
            fill: cs && cs.inputColor ? cs.inputColor : colors.blue,
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
        },
        // Label
        '& .MuiInputLabel-animated': {
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
            color:
                cs && cs.inputActiveColor
                    ? cs.inputActiveColor
                    : cs && cs.inputColor
                    ? cs.inputColor
                    : colors.blue,
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            userSelect: 'none',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            transform: 'translate(0px, 0px) scale(0.75)',
            color:
                cs && cs.inputActiveColor
                    ? cs.inputActiveColor
                    : cs && cs.inputColor
                    ? cs.inputColor
                    : colors.blue,
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
        },
        '& .MuiFormLabel-filled': {
            transform: 'translate(0px, 0px) scale(0.75)',
        },
        // Error text
        '& .MuiFormHelperText-root': {
            fontSize:
                cs && cs.errorFontSize
                    ? cs.errorFontSize
                    : cs && cs.fontSize
                    ? `calc(${cs.fontSize} * 0.6)`
                    : '1.2rem',
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            fontWeight: 'bold',
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
            userSelect: 'none',
            marginLeft: 0,
        },
        '& .MuiInput-underline.Mui-error:after': {
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
        },
        // Disabled
        '& .MuiFormLabel-root.Mui-disabled': {
            color: cs && cs.inputColor ? cs.inputColor : colors.blue,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: `${cs && cs.textColor ? cs.textColor : colors.blue} !important`,
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: cs && cs.inputColor ? `1px solid ${cs.inputColor}` : `1px solid ${colors.blue}`,
        },
        '& .MuiTypography-colorTextSecondary': {
            color: cs && cs.inputColor ? cs.inputColor : colors.blue,
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
        },
    },
    menuItem: css({
        fontSize: cs && cs.fontSize ? `calc(${cs.fontSize} * 0.6)` : '1.2rem',
        fontWeight: 'bold',
        fontFamily: cs && cs.fontFamily ? `${cs.fontFamily} !important` : 'inherit !important',
        padding: '0.6rem 1.5rem !important',
        minHeight: '5rem',
    }),
});
