import * as React from 'react';
import { IPayload, IState, TAction } from './types';

const reducer = (state: IState, action: TAction): IState => {
    switch (action.type) {
        case 'OPEN_REJECT':
            return {
                isRejectOpen: true,
                itemToRejectId: action.payload!.itemToRejectId,
                itemToRejectName: action.payload!.itemToRejectName,
            };
        case 'CLOSE_REJECT':
            return { isRejectOpen: false, itemToRejectId: '', itemToRejectName: '' };
        default:
            return state;
    }
};

export const useRejectModal = () => {
    const [{ isRejectOpen, itemToRejectId, itemToRejectName }, dispatch] = React.useReducer(
        reducer,
        {
            isRejectOpen: false,
            itemToRejectId: '',
            itemToRejectName: '',
        }
    );

    return {
        isRejectOpen,
        itemToRejectId,
        itemToRejectName,
        openReject: (payload: IPayload) => dispatch({ type: 'OPEN_REJECT', payload }),
        closeReject: () => dispatch({ type: 'CLOSE_REJECT' }),
    };
};
