import { ICreateAdminVariables } from './types';
import { IAdmin } from '../../../hooks/types';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useCreateAdmin = () => {
    const api = useApi();

    return useMutation<AxiosResponse<IAdmin>, any, ICreateAdminVariables>(({ body }) =>
        api.post('/admins', body)
    );
};
