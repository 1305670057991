import { ICreateDepartmentVariables } from './types';
import { IDepartment } from '../../../hooks';
import { useApi } from '../../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useCreateDepartment = () => {
    const api = useApi();

    return useMutation<AxiosResponse<IDepartment>, any, ICreateDepartmentVariables>(({ body }) =>
        api.post('/departments', body)
    );
};
