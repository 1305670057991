/**@jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react/macro';
import { designStyles, colors, LightenDarkenColor } from '../../helpers';
import sky from '../../assets/smallsky.jpg';

const moveInLeft = keyframes`
  0% {
      opacity: 0;
      transform: translateX(-10rem);
  }

  80% {
      transform: translateX(1rem);
  }

  100% {
      opacity: 1;
      transform: translateX(0);
  }
`;

const moveInRight = keyframes`
  0% {
      opacity: 0;
      transform: translateX(10rem);
  }

  80% {
      transform: translateX(-1rem);
  }

  100% {
      opacity: 1;
      transform: translateX(0);
  }
`;

export const useStyles = (
    widthBelow1380px: boolean,
    widthBelow980px: boolean,
    widthBelow200px: boolean
) =>
    designStyles({
        root: {
            minHeight: '100vh',
            maxWidth: '100%',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.black,
            backgroundImage: `url(${sky})`,
            backgroundSize: 'cover',
        },
        header: {
            display: 'flex',
            color: colors.orange,
            marginTop: '2rem',
        },
        headerLngMenu: {
            alignSelf: 'center',
            marginRight: '2rem',
        },
        headerContent: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        headerContentLogo: {
            height: widthBelow200px ? '4rem' : '8rem',
            animation: `${moveInRight} 1s ease-out`,
            userSelect: 'none',
        },
        headerContentTextBox: {
            marginRight: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        textBoxMainText: {
            fontSize: widthBelow200px ? '2rem' : '4rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            userSelect: 'none',
            animation: `${moveInRight} 1s ease-out`,
        },
        textBoxSubText: {
            userSelect: 'none',
            animation: `${moveInLeft} 1s ease-out`,
        },
        content: {
            display: 'flex',
            flex: 1,
            flexDirection: widthBelow980px ? 'column' : 'row',
            width: '100%',
            justifyContent: 'space-between',
        },
        contentGlobe: {
            display: 'flex',
            flex: '1 1',
            justifyContent: 'center',
            alignItems: 'center',
        },
        contentForm: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1',
            justifyContent: 'center',
            alignItems: 'center',
        },
        formField: {
            marginBottom: '2rem',
        },
        formHeader: {
            marginBottom: '4rem',
            color: colors.orange,
            textTransform: 'uppercase',
            userSelect: 'none',
            transition: 'all 0.2s',
            '&:hover': {
                transform: 'skewY(2deg) skewx(15deg) scale(1.1)',
                textShadow: `0 1rem 2rem ${colors.lightBoxShadow}`,
            },
        },
        formFieldBox: {
            maxWidth: widthBelow1380px ? '40rem' : '60rem',
        },
        formForgotPassword: {
            marginBottom: '2rem',
            textTransform: 'uppercase',
            textDecoration: 'none',
            color: colors.orange,
            padding: '1rem',
            display: 'inline-block',
            fontSize: '1.2rem',
            fontFamily: 'inherit',
            transition: 'all 0.2s',
            '&:link': {
                textTransform: 'uppercase',
                textDecoration: 'none',
                color: colors.orange,
                padding: '1rem',
                display: 'inline-block',
                fontSize: '1.2rem',
                transition: 'all 0.2s',
            },
            '&:visited': {
                textTransform: 'uppercase',
                textDecoration: 'none',
                color: colors.orange,
                padding: '1rem',
                display: 'inline-block',
                fontSize: '1.2rem',
                transition: 'all 0.2s',
            },
            '&:hover': {
                color: colors.white,
                textDecoration: 'underline',
            },
            '&:hover::after': {
                color: colors.white,
                textDecoration: 'underline',
            },
            '&:active': {
                color: colors.white,
                textDecoration: 'underline',
            },
            '&:focus': {
                color: colors.white,
                textDecoration: 'underline',
            },
            '&:focus-visible': {
                outline: 'none',
            },
        },
        footer: {
            display: 'flex',
            color: colors.orange,
            margin: '1rem',
            userSelect: 'none',
        },
        footerLinkBox: {
            flex: 3,
            fontSize: '3rem',
        },
        linkBoxLink: {
            display: 'inline-flex',
            transition: 'all 0.2s',
            '&:link': {
                color: colors.orange,
            },
            '&:visited': {
                color: colors.orange,
            },
            '&:active': {
                color: colors.orange,
                outline: 'none',
                transform: 'translateY(-1px)',
                boxShadow: `0 1rem 2rem ${colors.lightBoxShadow}`,
            },
            '&:focus': {
                color: colors.orange,
                outline: 'none',
                transform: 'translateY(-1px)',
                boxShadow: `0 1rem 2rem ${colors.lightBoxShadow}`,
            },
            '&:hover': {
                transform: 'translateY(-0.3rem)',
                boxShadow: `0 1rem 2rem ${colors.lightBoxShadow}`,
                color: colors.orange,
            },
            '&:focus-visible': {
                outline: 'none',
            },
            '&:not(:last-child)': {
                marginRight: '1rem',
            },
        },
        footerRightContainer: {
            fontWeight: 'bold',
            fontSize: '1.2rem',
            alignSelf: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        footerRightContainerLink: {
            color: colors.orange,
            fontFamily: 'inherit',
            textDecoration: 'none',
            transition: 'all 0.3s',
            '&:hover': {
                color: LightenDarkenColor(colors.orange, 80),
            },
        },
    });
