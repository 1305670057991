import { IVerifyLoginVariables, IVerifyLoginResponse } from './types';
import { useApi } from '../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useVerifyLogin = () => {
    const api = useApi();

    return useMutation<AxiosResponse<IVerifyLoginResponse>, any, IVerifyLoginVariables>(
        ({ body }) => api.post('/verify-login', body)
    );
};
