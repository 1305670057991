import { ISetting } from '../types';
import { useApi, Cache } from '../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchSetting = () => {
    const api = useApi();

    return useQuery<ISetting, any>(Cache.setting, () =>
        api.get('/settings').then((res) => res.data)
    );
};
