import { IVerifyEmailSubscriptionVariables } from './types';
import { useApi } from '../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useVerifyEmailSubscription = () => {
    const api = useApi();

    return useMutation<AxiosResponse<void>, any, IVerifyEmailSubscriptionVariables>(({ body }) =>
        api.patch('/verify-email-subscription', body)
    );
};
