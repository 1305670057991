/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { ICandidate } from '../../hooks';
import { useProfessionsModal } from '../../../../helpers';
import { IPayload as IDeletePayload } from '../../../../helpers/reducers/delete/types';
import { IPayload as IRejectPayload } from '../../../../helpers/reducers/reject/types';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Card, ProfessionsModal } from '../../../../components';
import { MainPanel, InfoPanel } from './panels';
import { Actions } from './actions/Actions';

interface IProps {
    candidate: ICandidate;
    openDelete: (payload: IDeletePayload) => void;
    openReject: (payload: IRejectPayload) => void;
}

/* const isCandidateActive = (candidate: ICandidate): boolean => {
    let isActive = false;
    candidate.activityPeriods.forEach((activityPeriod) => {
        if (!activityPeriod.endYear && !activityPeriod.endWeek) isActive = true;
    });
    return isActive;
}; */

export const CandidateCard = ({ candidate, openDelete, openReject }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const { t } = useTranslation();
    const { isProfessionsOpen, candidateName, professions, openProfessions, closeProfessions } =
        useProfessionsModal();

    return (
        <React.Fragment>
            {isProfessionsOpen ? (
                <ProfessionsModal
                    open={isProfessionsOpen}
                    candidateName={candidateName}
                    professions={professions}
                    closeModal={closeProfessions}
                />
            ) : null}
            <Card
                firstPanel={<MainPanel candidate={candidate} />}
                secondPanel={<InfoPanel candidate={candidate} />}
                actions={
                    <Actions
                        candidate={candidate}
                        openDelete={openDelete}
                        openReject={openReject}
                        openProfessions={openProfessions}
                    />
                }
                firstPanelLabel={t('text.candidate')}
                secondPanelLabel={t('text.info')}
                cs={{
                    width: widthBelow600px ? '30rem' : '40rem',
                    margin: widthBelow600px ? '2rem 0' : '3rem',
                    contentHeight: '30rem',
                }}
            />
        </React.Fragment>
    );
};
