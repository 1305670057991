export const localTextRO = {
    selectAll: '(Selectați toate)',
    selectAllSearchResults: '(Selectați toate rezultatele căutării)',
    searchOoo: 'Căutare...',
    blanks: '(Spații libere)',
    noMatches: 'Nu s-a găsit nicio potrivire',
    filterOoo: 'Filtru...',
    equals: 'Egal',
    notEqual: 'Nu este egal',
    empty: 'Alege unul',
    lessThan: 'Mai puţin',
    greaterThan: 'Mai mare de',
    lessThanOrEqual: 'Mai mică sau egală',
    greaterThanOrEqual: 'Mai mare sau egal',
    inRange: 'În raza de acțiune',
    inRangeStart: 'din',
    inRangeEnd: 'spre',
    contains: 'Conţine',
    notContains: 'Nu conține',
    startsWith: 'Începe cu',
    endsWith: 'Se termină cu',
    dateFormatOoo: 'AAAA-LL-ZZ',
    andCondition: 'ȘI',
    orCondition: 'SAU',
    applyFilter: 'Aplica',
    resetFilter: 'Reseta',
    clearFilter: 'Clar',
    cancelFilter: 'Anula',
    textFilter: 'Filtru text',
    numberFilter: 'Filtru numeric',
    dateFilter: 'Filtru dată',
    setFilter: 'Setare filtru',
    columns: 'Coloane',
    filters: 'Filtre',
    pivotMode: 'Modul totativ / Mod de rotatie',
    groups: 'Grupuri de conducere',
    rowGroupColumnsEmptyMessage: 'Trageți aici pentru a seta grupurile de rânduri',
    values: 'Valorile',
    valueColumnsEmptyMessage: 'Trageți aici pentru a agrega',
    pivots: 'Etichete de coloană',
    pivotColumnsEmptyMessage: 'Trageți aici pentru a seta etichetele coloanelor',
    group: 'Grup',
    loadingOoo: 'Încărcare...',
    noRowsToShow: 'Nu există rânduri de afișat',
    enabled: 'Activat',
    pinColumn: 'Coloană de fixare',
    pinLeft: 'Fixare stânga',
    pinRight: 'Fixare dreapta',
    noPin: 'Fără pin',
    valueAggregation: 'Agregarea valorilor',
    autosizeThiscolumn: 'Dimensionarea automată a acestei coloane',
    autosizeAllColumns: 'Dimensionarea automată a tuturor coloanelor',
    groupBy: 'Grupează după',
    ungroupBy: 'Degrupare după',
    resetColumns: 'Resetarea coloanelor',
    expandAll: 'Extinde tot',
    collapseAll: 'Închide tot',
    copy: 'Copie',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copiere cu anteturi',
    paste: 'Pastă',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',
    sum: 'Sumă',
    min: 'Min',
    max: 'Max',
    none: 'Niciunul',
    count: 'Număra',
    avg: 'Medie',
    filteredRows: 'Filtrate',
    selectedRows: 'Selectat',
    totalRows: 'Total rânduri',
    totalAndFilteredRows: 'Rânduri',
    more: 'Mai mult',
    to: 'spre',
    of: 'de',
    page: 'Pagină',
    nextPage: 'Pagina următoare',
    lastPage: 'Ultima pagină',
    firstPage: 'Prima pagină',
    previousPage: 'Pagina anterioară',
    pivotColumnGroupTotals: 'Total',
    pivotChartAndPivotMode: 'Diagramă pivot și mod pivot',
    pivotChart: 'Diagramă pivot',
    chartRange: 'Interval de diagrame',
    columnChart: 'Coloană',
    groupedColumn: 'Grupat',
    stackedColumn: 'Stivuite',
    normalizedColumn: '100% Stivuite',
    barChart: 'Bar',
    groupedBar: 'Grupat',
    stackedBar: 'Stivuite',
    normalizedBar: '100% Stivuite',
    pieChart: 'Plăcintă',
    pie: 'Plăcintă',
    doughnut: 'Gogoașă',
    line: 'Linie',
    xyChart: 'X Y (Scatter)',
    scatter: 'Răspândește ',
    bubble: 'Bulă',
    areaChart: 'Zonă',
    area: 'Zonă',
    stackedArea: 'Stivuite',
    normalizedArea: '100% Stivuite',
    histogramChart: 'Histogramă',
    pivotChartTitle: 'Diagramă pivot',
    rangeChartTitle: 'Diagrama intervalului',
    settings: 'Setări',
    data: 'Date',
    format: 'Format',
    categories: 'Categorii',
    defaultCategory: '(Niciunul)',
    series: 'Serie',
    xyValues: 'Valorile X Y',
    paired: 'Modul asociat',
    axis: 'Axă',
    navigator: 'Navigator',
    color: 'Culoare',
    thickness: 'Grosime',
    xType: 'Tipul X',
    automatic: 'Automat',
    category: 'Categorie',
    number: 'Număr',
    time: 'Oră',
    xRotation: 'X Rotație',
    yRotation: 'Rotația Y',
    ticks: 'Căpuşe',
    width: 'Lățime',
    height: 'Înălțime',
    length: 'Lungime',
    padding: 'Captusire',
    spacing: 'Spaţiere',
    chart: 'Diagramă/Grafic',
    title: 'Titlu',
    titlePlaceholder: 'Titlul graficului - faceți dublu clic pentru a edita',
    background: 'Fond',
    font: 'Font',
    top: 'Culme',
    right: 'Dreapta',
    bottom: 'Fund',
    left: 'Stânga',
    labels: 'Etichete',
    size: 'Mărime',
    minSize: 'Dimensiunea minimă',
    maxSize: 'Dimensiunea maximă',
    legend: 'Legendă',
    position: 'Poziție',
    markerSize: 'Dimensiune marker',
    markerStroke: 'Cursa marker',
    markerPadding: 'Umplerea markerului',
    itemSpacing: 'Spațiere elemente',
    itemPaddingX: 'Umplutura X a articolului',
    itemPaddingY: 'Element Umplutura Y',
    layoutHorizontalSpacing: 'Spațiere orizontală',
    layoutVerticalSpacing: 'Spațiere verticală',
    strokeWidth: 'Lățime contur',
    offset: 'Compensa',
    offsets: 'Compensează',
    tooltips: 'Sfaturi ecran',
    callout: 'Explicaţie',
    markers: 'Markeri',
    shadow: 'Umbră',
    blur: 'Blur',
    xOffset: 'X Decalaj',
    yOffset: 'Decalaj Y',
    lineWidth: 'Lățimea liniei',
    normal: 'Normal',
    bold: 'Îndrăzneț',
    italic: 'Cursiv',
    boldItalic: 'Cursiv îndrăzneț',
    predefined: 'Predefinite',
    fillOpacity: 'Opacitatea umplerii',
    strokeOpacity: 'Opacitatea liniei',
    histogramBinCount: 'Număr de pubele',
    columnGroup: 'Coloană',
    barGroup: 'Bar',
    pieGroup: 'Plăcintă',
    lineGroup: 'Linie',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Zonă',
    histogramGroup: 'Histogramă',
    groupedColumnTooltip: 'Grupat',
    stackedColumnTooltip: 'Stivuite',
    normalizedColumnTooltip: '100% Stivuite',
    groupedBarTooltip: 'Grupat',
    stackedBarTooltip: 'Stivuite',
    normalizedBarTooltip: '100% Stivuite',
    pieTooltip: 'Plăcintă',
    doughnutTooltip: 'Gogoașă',
    lineTooltip: 'Linie',
    groupedAreaTooltip: 'Zonă',
    stackedAreaTooltip: 'Stivuite',
    normalizedAreaTooltip: '100% Stivuite',
    scatterTooltip: 'Risipi',
    bubbleTooltip: 'Bulă',
    histogramTooltip: 'Histogramă',
    noDataToChart: 'Nu există date disponibile pentru grafic.',
    pivotChartRequiresPivotMode: 'Graficul de rotație necesită ca modul de rotație să fie activat.',
    chartSettingsToolbarTooltip: 'Meniu',
    chartLinkToolbarTooltip: 'Conectat la rețea',
    chartUnlinkToolbarTooltip: 'Deconectat de la grilă',
    chartDownloadToolbarTooltip: 'Descărcați graficul',
    ariaHidden: 'ascuns',
    ariaVisible: 'vizibil',
    ariaChecked: 'Verificat',
    ariaUnchecked: 'Bifat',
    ariaIndeterminate: 'Nedeterminat',
    ariaColumnSelectAll: 'Selectați toate coloanele',
    ariaInputEditor: 'Editor de intrări',
    ariaDateFilterInput: 'Intrare filtru dată',
    ariaFilterInput: 'Filtrare intrare',
    ariaFilterColumnsInput: 'Filtrare intrare coloane',
    ariaFilterValue: 'Valoare filtru',
    ariaFilterFromValue: 'Filtrare după valoare',
    ariaFilterToValue: 'Filtrare la valoare',
    ariaFilteringOperator: 'Operator filtrare',
    ariaColumn: 'Coloană',
    ariaColumnGroup: 'Grup coloană',
    ariaRowSelect: 'Apăsați SPACE pentru a selecta acest rând',
    ariaRowDeselect: 'Apăsați SPACE pentru a deselecta acest rând',
    ariaRowToggleSelection: 'Apăsați Space pentru a comuta selecția rândurilor',
    ariaRowSelectAll: 'Apăsați Space pentru a comuta selecția tuturor rândurilor',
    ariaToggleVisibility: 'Apăsați SPACE pentru a comuta vizibilitatea',
    ariaSearch: 'Căutare',
    ariaSearchFilterValues: 'Valorile filtrului de căutare',
    ariaLabelColumnMenu: 'Meniu coloană',
    ariaLabelCellEditor: 'Editor de celule',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Selectați câmpul',
    ariaLabelTooltip: 'Sfat ecran',
    ariaLabelContextMenu: 'Meniu contextual',
    ariaLabelSubMenu: 'Submeniu',
    ariaLabelAggregationFunction: 'Funcția de agregare',
};
