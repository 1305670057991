/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { ICandidate } from '../../../hooks';
import { IPayload as IDeletePayload } from '../../../../../helpers/reducers/delete/types';
import { IPayload as IRejectPayload } from '../../../../../helpers/reducers/reject/types';
import { IPayload as IProfessionsPayload } from '../../../../../helpers/reducers/professions/types';
import { Role, routePath, useAuth } from '../../../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Tooltip, IconButton } from '@mui/material';
import { BiEdit, BiTrash, BiLinkAlt, BiEraser, BiArrowToTop } from 'react-icons/bi';
import { FiActivity } from 'react-icons/fi';
import { AiFillFormatPainter } from 'react-icons/ai';
import { GiEnvelope } from 'react-icons/gi';
import { CandidateStatus } from '../../../hooks';

interface IProps {
    candidate: ICandidate;
    openDelete: (payload: IDeletePayload) => void;
    openReject: (payload: IRejectPayload) => void;
    openProfessions: (payload: IProfessionsPayload) => void;
}

export const Actions = ({ candidate, openDelete, openReject, openProfessions }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            <Tooltip arrow title={<h1>{t('text.update')}</h1>}>
                <IconButton
                    onClick={() => history.push(routePath.updateCandidate.create(candidate.id))}
                >
                    <BiEdit style={styles.updateButton} />
                </IconButton>
            </Tooltip>
            <Tooltip arrow title={<h1>{t('text.professions')}</h1>}>
                <IconButton
                    onClick={() =>
                        openProfessions({
                            candidateName: `${candidate.user.firstName} ${candidate.user.lastName}`,
                            professions: candidate.professions,
                        })
                    }
                >
                    <AiFillFormatPainter style={styles.professionButton} />
                </IconButton>
            </Tooltip>
            <Tooltip arrow title={<h1>{t('text.resendInvitation')}</h1>}>
                <IconButton
                    onClick={() =>
                        history.push(routePath.updateCandidateInvitation.create(candidate.id))
                    }
                >
                    <GiEnvelope style={styles.resendInvitation} />
                </IconButton>
            </Tooltip>
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) && (
                <Tooltip arrow title={<h1>{t('text.activity')}</h1>}>
                    <IconButton
                        onClick={() =>
                            history.push(routePath.candidateActivityPeriods.create(candidate.id))
                        }
                    >
                        <FiActivity style={styles.activityButton} />
                    </IconButton>
                </Tooltip>
            )}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) &&
            !candidate.employeeNumber ? (
                <Tooltip arrow title={<h1>{t('text.afasLink')}</h1>}>
                    <IconButton
                        onClick={() =>
                            history.push(
                                routePath.updateCandidateEmployeeNumber.create(candidate.id)
                            )
                        }
                    >
                        <BiLinkAlt style={styles.afasLinkButton} />
                    </IconButton>
                </Tooltip>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin) && candidate.employeeNumber ? (
                <Tooltip arrow title={<h1>{t('text.promoteToAmbassador')}</h1>}>
                    <IconButton
                        onClick={() =>
                            history.push(routePath.promoteCandidate.create(candidate.id))
                        }
                    >
                        <BiArrowToTop style={styles.promoteButton} />
                    </IconButton>
                </Tooltip>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) &&
            candidate.status !== CandidateStatus.rejected ? (
                <Tooltip arrow title={<h1>{t('text.reject')}</h1>}>
                    <IconButton
                        onClick={() =>
                            openReject({
                                itemToRejectId: candidate.id,
                                itemToRejectName: `${candidate.user.firstName} ${candidate.user.lastName}`,
                            })
                        }
                    >
                        <BiEraser style={styles.rejectButton} />
                    </IconButton>
                </Tooltip>
            ) : null}
            {currentUserHasRoles(Role.superAdmin) ? (
                <Tooltip arrow title={<h1>{t('text.delete')}</h1>}>
                    <IconButton
                        onClick={() =>
                            openDelete({
                                itemToDeleteId: candidate.id,
                                itemToDeleteName: `${candidate.user.firstName} ${candidate.user.lastName}`,
                            })
                        }
                    >
                        <BiTrash style={styles.deleteButton} />
                    </IconButton>
                </Tooltip>
            ) : null}
        </div>
    );
};
