import { IDeleteCandidateVariables } from './types';
import { useMutation } from 'react-query';
import { useApi } from '../../../../../helpers';
import { AxiosResponse } from 'axios';

export const useDeleteCandidate = () => {
    const api = useApi();

    return useMutation<AxiosResponse<string>, any, IDeleteCandidateVariables>(({ candidateId }) =>
        api.delete(`/candidates/${candidateId}`)
    );
};
