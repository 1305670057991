import * as React from 'react';
import { ICandidate } from '../hooks';
import { IPayload as IDeletePayload } from '../../../helpers/reducers/delete/types';
import { IPayload as IRejectPayload } from '../../../helpers/reducers/reject/types';
import { useTranslation } from 'react-i18next';
import { Grid } from './grid/Grid';

interface IProps {
    data: ICandidate[];
    shorter?: boolean;
    openDelete: (payload: IDeletePayload) => void;
    openReject: (payload: IRejectPayload) => void;
}

export const GridView = ({ data, openDelete, shorter, openReject }: IProps) => {
    const { i18n } = useTranslation();

    return (
        <Grid
            data={data}
            lng={i18n.language}
            shorter={shorter ? true : false}
            openDelete={openDelete}
            openReject={openReject}
        />
    );
};
