/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useEmailCheckResetPassword, useRmailCheckEmailSubscription } from './hooks';
import { colors, handleError, routePath, SocialMediaLink } from '../../helpers';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import validator from 'validator';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { LanguageMenu, TextFieldInput, Button } from '../../components';
import { BiEnvelope } from 'react-icons/bi';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';

export const EmailCheck = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const widthBelow200px = useMediaQuery('(max-width: 200px)');
    const styles = useStyles(widthBelow600px, widthBelow200px);
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const emailCheckResetPassword = useEmailCheckResetPassword();
    const emailCheckEmailSubscription = useRmailCheckEmailSubscription();

    return (
        <div style={styles.root}>
            <div style={styles.header}>
                <h1 css={styles.headerText}>Oranjelink</h1>
                <LanguageMenu />
            </div>
            <div style={styles.content}>
                <Formik
                    initialValues={{ email: '' } as IFormValues}
                    validate={({ email }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!email) errors.email = t('form.error.requiredField');
                        else if (email.length > 127) {
                            errors.email = t('form.error.max127Chars');
                        } else if (!validator.isEmail(email)) {
                            errors.email = t('form.error.invalidEmail');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ email }, { setErrors }) => {
                        if (location.pathname.split('/')[1] === 'reset-password') {
                            try {
                                const { data: token } = await emailCheckResetPassword.mutateAsync({
                                    body: {
                                        email: email.trim(),
                                    },
                                });
                                localStorage.setItem('rpvid', token);
                                history.push(routePath.resetPassword.create());
                            } catch (e) {
                                handleError(e, ({ status, statusText }) => {
                                    if (status === 404) {
                                        setErrors({
                                            email: t('form.error.emailIsNotRegistered'),
                                        });
                                    } else if (status === 500) {
                                        history.push(routePath.internalServerError.create());
                                    } else {
                                        alert(statusText);
                                    }
                                });
                            }
                        } else {
                            try {
                                const { data: token } =
                                    await emailCheckEmailSubscription.mutateAsync({
                                        body: {
                                            email: email.trim(),
                                        },
                                    });
                                localStorage.setItem('esvid', token);
                                history.push(routePath.emailSubscription.create());
                            } catch (e) {
                                handleError(e, ({ status, statusText }) => {
                                    if (status === 404) {
                                        setErrors({
                                            email: t('form.error.emailIsNotRegistered'),
                                        });
                                    } else if (status === 500) {
                                        history.push(routePath.internalServerError.create());
                                    } else {
                                        alert(statusText);
                                    }
                                });
                            }
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form noValidate style={styles.contentForm}>
                            <h1 css={styles.formTitle}>{t('text.yourEmail')}</h1>
                            <Field
                                style={styles.formField}
                                name="email"
                                type="email"
                                label={t('form.label.email')}
                                placeholder={t('form.placeholder.email')}
                                autoFocus
                                icon={<BiEnvelope />}
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {location.pathname.split('/')[1] === 'reset-password'
                                    ? t('text.resetPassword')
                                    : t('text.emailSubscription')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div style={styles.footer}>
                <div style={styles.footerLinkBox}>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillFacebook />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillInstagram />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.LinkedIn}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillLinkedin />
                    </a>
                </div>
                <div style={styles.footerRightContainer}>
                    <div>
                        <Link css={styles.footerRightContainerLink} to={routePath.terms.create()}>
                            {t('text.generalTerms')}
                        </Link>
                        &nbsp;/&nbsp;
                        <Link
                            css={styles.footerRightContainerLink}
                            to={routePath.privacyPolicy.create()}
                        >
                            {t('text.privacyPolicy')}
                        </Link>
                    </div>
                    <p>
                        {`De Oranjegroep \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop - Public Bèta`}
                    </p>
                </div>
            </div>
        </div>
    );
};
