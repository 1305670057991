import { IDeleteActivityPeriodVariables } from './types';
import { ICandidate } from '../../../../hooks';
import { useApi } from '../../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useDeleteActivityPeriod = () => {
    const api = useApi();

    return useMutation<AxiosResponse<ICandidate>, any, IDeleteActivityPeriodVariables>(
        ({ candidateActivityPeriodId }) =>
            api.delete(`/candidate-activity-periods/${candidateActivityPeriodId}`)
    );
};
