/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useFetchCandidateOwners } from './hooks';
import { handleQueryError } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { ComboBoxInput } from '../../../components';

export const AppliedByComboBox = (props: any) => {
    const history = useHistory();

    const { isLoading, data, error } = useFetchCandidateOwners();

    if (error) {
        handleQueryError(error, history);
    }
    return (
        <ComboBoxInput
            {...props}
            options={data}
            isLoading={isLoading}
            getOptionLabel={(option) => option.text}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};
