/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ISelectItem, ICustomStyles, IElementStyle, IElementSxProps } from './types';
import {
    FormControl,
    InputLabel,
    InputLabelProps,
    Select,
    SelectProps,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';

interface IProps {
    label: string;
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>> | ((number: number) => void);
    items: ISelectItem[];
    cs?: ICustomStyles;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
    onChange?: (event: SelectChangeEvent<any>) => void;
}

export const NumberSelectInput = ({
    label,
    value,
    setValue,
    items,
    disabled,
    cs,
    es,
    esx,
    style,
    className,
    onChange,
}: IProps & SelectProps & InputLabelProps) => {
    const styles = useStyles(cs);

    return (
        <FormControl
            css={
                es && es.formControl
                    ? [es.formControl as Interpolation<Theme>, style as Interpolation<Theme>]
                    : (style as Interpolation<Theme>)
            }
            sx={
                esx && esx.formControl
                    ? { ...styles.formControl, ...esx.formControl }
                    : styles.formControl
            }
            className={className}
            fullWidth
        >
            <InputLabel
                css={es && es.label ? (es.label as Interpolation<Theme>) : undefined}
                sx={esx && esx.label ? esx.label : undefined}
            >
                {label}
            </InputLabel>
            <Select
                value={value}
                css={es && es.select ? (es.select as Interpolation<Theme>) : undefined}
                sx={esx && esx.select ? esx.select : undefined}
                variant="standard"
                fullWidth
                autoComplete="new-password"
                disabled={disabled ? disabled : false}
                onChange={(event) => {
                    if (onChange) {
                        onChange(event);
                    }
                    setValue(event.target.value as number);
                }}
                onBlur={(event) => {
                    //handleBlur(event);
                }}
                onFocus={(event) => {
                    event.target.setAttribute('autocomplete', 'off');
                }}
                inputProps={{
                    role: 'presentation',
                    autoComplete: 'new-password',
                    spellCheck: false,
                    'aria-live': 'polite',
                    disabled: disabled ? disabled : false,
                }}
                MenuProps={{
                    style: {
                        maxHeight: '20rem',
                    },
                }}
            >
                {items.map(({ value, item }, index) => {
                    return (
                        <MenuItem
                            value={value}
                            key={index}
                            css={
                                es && es.menuItem
                                    ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                                    : styles.menuItem
                            }
                            sx={esx && esx.menuItem ? esx.menuItem : undefined}
                        >
                            {item}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
