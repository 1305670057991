/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { Role, useAuth } from '../../../../../helpers';
import { ICandidate } from '../../../hooks';
import { IContext } from './types';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@mui/material';
import { BiEdit, BiTrash, BiLinkAlt, BiEraser, BiArrowToTop } from 'react-icons/bi';
import { FiActivity } from 'react-icons/fi';
import { GiEnvelope } from 'react-icons/gi';
import { CandidateStatus } from '../../../hooks';

export const ActionsCell = ({
    data,
    context: {
        handelUpdateClick,
        handelActivityClick,
        handleResendInvitationClick,
        handelAfasLinkClick,
        handelPromoteClick,
        handelRejectClick,
        handleDeleteClick,
    },
}: ICellRendererParams<ICandidate, any, IContext>) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { currentUserHasRoles } = useAuth();

    return (
        <div style={styles.root}>
            <Tooltip arrow title={<h1>{t('text.update')}</h1>}>
                <IconButton
                    onClick={() => {
                        if (data) {
                            return handelUpdateClick(data);
                        }
                    }}
                >
                    <BiEdit style={styles.updateButton} />
                </IconButton>
            </Tooltip>
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) && (
                <Tooltip arrow title={<h1>{t('text.activity')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handelActivityClick(data);
                            }
                        }}
                    >
                        <FiActivity style={styles.activityButton} />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip arrow title={<h1>{t('text.resendInvitation')}</h1>}>
                <IconButton
                    onClick={() => {
                        if (data) {
                            return handleResendInvitationClick(data);
                        }
                    }}
                >
                    <GiEnvelope style={styles.resendInvitationButton} />
                </IconButton>
            </Tooltip>
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) &&
            !(data as ICandidate).employeeNumber ? (
                <Tooltip arrow title={<h1>{t('text.afasLink')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handelAfasLinkClick(data);
                            }
                        }}
                    >
                        <BiLinkAlt style={styles.afasLinkButton} />
                    </IconButton>
                </Tooltip>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin) &&
            (data as ICandidate).employeeNumber ? (
                <Tooltip arrow title={<h1>{t('text.promoteToAmbassador')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handelPromoteClick(data);
                            }
                        }}
                    >
                        <BiArrowToTop style={styles.promoteButton} />
                    </IconButton>
                </Tooltip>
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) &&
            (data as ICandidate).status !== CandidateStatus.rejected ? (
                <Tooltip arrow title={<h1>{t('text.reject')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handelRejectClick(data);
                            }
                        }}
                    >
                        <BiEraser style={styles.rejectButton} />
                    </IconButton>
                </Tooltip>
            ) : null}
            {currentUserHasRoles(Role.superAdmin) ? (
                <Tooltip arrow title={<h1>{t('text.delete')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handleDeleteClick(data);
                            }
                        }}
                    >
                        <BiTrash style={styles.deleteButton} />
                    </IconButton>
                </Tooltip>
            ) : null}
        </div>
    );
};
