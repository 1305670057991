/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { handleError, routePath } from '../../../../helpers';
import { useCreateProfession } from './hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../../components';
import { ProfessionForm } from '../profession-form/ProfessionForm';

export const CreateProfession = () => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const create = useCreateProfession();

    return (
        <Page
            isPageSubmitting={create.isLoading}
            title={t('text.createProfession')}
            style={styles.root}
        >
            <ProfessionForm
                mutationType="create"
                initialValues={{
                    nl: '',
                    en: '',
                    ro: '',
                    pl: '',
                    hr: '',
                    pt: '',
                    hu: '',
                    professionGroup: null,
                }}
                onSubmit={async (
                    { nl, en, ro, pl, hr, pt, hu, professionGroup },
                    { setErrors }
                ) => {
                    try {
                        await create.mutateAsync({
                            body: {
                                nl: nl.trim(),
                                en: en.trim(),
                                ro: ro.trim(),
                                pl: pl.trim(),
                                hr: hr.trim(),
                                pt: pt.trim(),
                                hu: hu.trim(),
                                professionGroupId: professionGroup!.id,
                            },
                        });
                        history.push(routePath.professionsSettings.create());
                    } catch (e) {
                        handleError(e, ({ status, statusText }) => {
                            if (status === 401 || status === 403) {
                                history.push(routePath.unauthorizedError.create());
                            } else if (status === 400) {
                                setErrors({
                                    en: t('form.error.oneOfTheProfessionsIsRegistered'),
                                    nl: t('form.error.oneOfTheProfessionsIsRegistered'),
                                    ro: t('form.error.oneOfTheProfessionsIsRegistered'),
                                    pl: t('form.error.oneOfTheProfessionsIsRegistered'),
                                    hr: t('form.error.oneOfTheProfessionsIsRegistered'),
                                    pt: t('form.error.oneOfTheProfessionsIsRegistered'),
                                    hu: t('form.error.oneOfTheProfessionsIsRegistered'),
                                });
                            } else if (status === 500) {
                                history.push(routePath.internalServerError.create());
                            } else alert(statusText);
                        });
                    }
                }}
            />
        </Page>
    );
};
