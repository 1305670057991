import * as React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { AuthProvider, ApiProvider } from './helpers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import reportWebVitals from './reportWebVitals';
import './helpers/i18n';

const queryClient = new QueryClient();

ReactDOM.render(
    <AuthProvider>
        <ApiProvider>
            <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
        </ApiProvider>
    </AuthProvider>,

    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
