/**@jsxImportSource @emotion/react */
import { colors, designStyles } from '../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '70vw',
            width: 'fit-content',
            borderRadius: '1rem',
            overflow: 'hidden',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            background: colors.blue,
            padding: '1.2rem',
        },
        headerTitle: {
            color: colors.white,
            marginRight: '3rem',
            flex: 2,
            userSelect: 'none',
        },
        headerCloseButton: {
            marginRight: '0.2rem',
            fontSize: '1.5rem',
            color: colors.white,
            transition: 'all 0.2s',
            '&:hover': {
                color: colors.orange,
                transform: 'scale(1.2)',
            },
        },
        content: {
            padding: '3rem 3rem 0 3rem',
            background: colors.white,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        contentSpinnerBox: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1.5rem',
        },
        contentQuestionText: {
            color: colors.blue,
            fontSize: '1.5rem',
            fontWeight: 600,
            userSelect: 'none',
        },
        contentRelatedCandidateBox: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1.5rem',
        },
        relatedCandidateBoxText: {
            color: colors.blue,
            fontSize: '1.5rem',
            fontWeight: 600,
            userSelect: 'none',
        },
        realatedCondidateBoxButton: {
            position: 'absolute',
            fontSize: '2rem',
            color: colors.green,
            padding: '1rem',
            justifySelf: 'flex-end',
            right: 0,
        },
        contentRelatedCandidateList: {
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: colors.blue,
            fontSize: '1.25rem',
            fontWeight: 600,
        },
        relatedCandidateListItem: {
            '&:not(:last-child)': {
                marginBottom: '0.5rem',
            },
        },
        actions: {
            display: 'flex',
            justifyContent: 'center',
            padding: '1.5rem',
            background: colors.white,
        },
        actionsDeleteButton: {
            marginRight: '0.5rem',
        },
    });
