/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '../../backdrop/Backdrop';
import { Button } from '../../buttons/button/Button';
import { colors } from '../../../helpers';
import { AiOutlineClose } from 'react-icons/ai';

interface IProps {
    open: boolean;
    title: string;
    question: string;
    isSubmitting: boolean;
    closeModal: () => void;
    handleYesClick: () => Promise<any>;
}

export const YesNoModal = ({
    open,
    title,
    question,
    isSubmitting,
    closeModal,
    handleYesClick,
}: IProps) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <Backdrop open={open}>
            <div style={styles.root}>
                <div style={styles.header}>
                    <h2 style={styles.headerTitle}>{title}</h2>
                    <AiOutlineClose
                        css={styles.headerCloseButton}
                        onClick={() => {
                            if (!isSubmitting) {
                                closeModal();
                            }
                        }}
                    />
                </div>
                <div style={styles.content}>
                    <p style={styles.contentText}>{question}</p>
                </div>
                <div style={styles.actions}>
                    <Button
                        style={styles.actionsYesButton}
                        type="button"
                        disabled={isSubmitting}
                        spinner={isSubmitting}
                        cs={{
                            color: colors.blue,
                            padding: '1rem 1.5rem',
                            fontSize: '1rem',
                        }}
                        onClick={async () => {
                            await handleYesClick();
                            closeModal();
                        }}
                    >
                        {t('text.yes')}
                    </Button>
                    <Button
                        type="button"
                        disabled={isSubmitting}
                        spinner={isSubmitting}
                        cs={{
                            color: colors.red,
                            padding: '1rem 1.5rem',
                            fontSize: '1rem',
                        }}
                        onClick={closeModal}
                    >
                        {t('text.no')}
                    </Button>
                </div>
            </div>
        </Backdrop>
    );
};
