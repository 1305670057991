/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '../../../components';
import { AiOutlineClose } from 'react-icons/ai';
import hoursDashboard from '../../../assets/hours-dashboard.png';

interface IProps {
    open: boolean;
    closeModal: () => void;
}

export const HoursDashboardImageModal = ({ open, closeModal }: IProps) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <Backdrop open={open}>
            <AiOutlineClose css={styles.closeButton} onClick={closeModal} />
            <img
                src={hoursDashboard}
                alt={t('help.hoursDashboard.hoursDashboardExample')}
                css={styles.image}
            />
        </Backdrop>
    );
};
