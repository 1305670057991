/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { colors, LightenDarkenColor } from '../../../helpers';

export const useStyles = () => ({
    root: css({
        display: 'flex',
        width: '8rem',
        borderRadius: '0.5rem',
        border: `solid 1px ${colors.blue}`,
        overflow: 'hidden',
    }),
    button: css({
        textTransform: 'uppercase',
        textDecoration: 'none',
        border: 'none',
        userSelect: 'none',
        width: '50%',
        height: '100%',
        padding: '0.5rem 1rem',
        backgroundColor: colors.white,
        color: colors.blue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'inherit',
        fontSize: '2.5rem',
        fontWeight: 'bold',
        cursor: 'pointer',
        transition: 'all 0.4s',
        '&:hover': {
            backgroundColor: LightenDarkenColor(colors.white, -20),
        },
    }),
    activeButton: css({
        textTransform: 'uppercase',
        textDecoration: 'none',
        border: 'none',
        userSelect: 'none',
        width: '50%',
        height: '100%',
        padding: '0.5rem 1rem',
        backgroundColor: colors.blue,
        color: colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'inherit',
        fontSize: '2.5rem',
        fontWeight: 'bold',
        cursor: 'pointer',
        transition: 'all 0.4s',
        '&:hover': {
            backgroundColor: LightenDarkenColor(colors.blue, 20),
        },
    }),
});
