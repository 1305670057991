/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchUser } from '../hooks';
import { useChangeLanguage } from './hooks';
import {
    IRouteParams,
    colors,
    handleQueryError,
    handleError,
    useAuth,
    routePath,
} from '../../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { Page, LanguageSelectInput, Button } from '../../../components';

export const Changelanguage = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUser } = useAuth();
    const history = useHistory();
    const { id } = useParams<IRouteParams>();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchUser(id);
    const changeLanguage = useChangeLanguage(id);

    React.useEffect(() => {
        if (id !== currentUser!.userId) {
            history.push(routePath.unauthorizedError.create());
        }
    }, [id, history, currentUser]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page isPageSubmitting={changeLanguage.isLoading} style={styles.root}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.changeLanguage')}</h1>
                {isLoading && (
                    <div style={styles.contentForm}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.buttonSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.buttonSkeleton}
                        />
                    </div>
                )}
                {data && (
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                language: data.language,
                            } as IFormValues
                        }
                        validate={({ language }) => {
                            let errors: FormikErrors<IFormValues> = {};
                            if (!language) errors.language = t('form.error.requiredField');
                            return errors;
                        }}
                        onSubmit={async ({ language }) => {
                            try {
                                await changeLanguage.mutateAsync({
                                    body: { newLanguage: language },
                                });
                                history.push(routePath.user.create());
                            } catch (e) {
                                handleError(e, ({ status, statusText }) => {
                                    if (status === 404) {
                                        history.push(routePath.notFoundError.create());
                                    } else if (status === 401 || status === 403) {
                                        history.push(routePath.unauthorizedError.create());
                                    } else if (status === 500) {
                                        history.push(routePath.internalServerError.create());
                                    } else alert(statusText);
                                });
                            }
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form noValidate style={styles.contentForm}>
                                <Field
                                    style={styles.formField}
                                    name="language"
                                    label={t('form.label.language')}
                                    disabled={isSubmitting}
                                    component={LanguageSelectInput}
                                />
                                <Button
                                    css={styles.formButton}
                                    type="submit"
                                    disabled={isSubmitting}
                                    spinner={isSubmitting}
                                    cs={{
                                        color: colors.blue,
                                    }}
                                >
                                    {t('text.changeLanguage')}
                                </Button>
                                <Button
                                    css={styles.formButton}
                                    type="button"
                                    disabled={isSubmitting}
                                    spinner={isSubmitting}
                                    cs={{
                                        color: colors.blue,
                                    }}
                                    onClick={() => history.push(routePath.user.create())}
                                >
                                    {t('text.back')}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </Page>
    );
};
