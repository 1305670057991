/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react/macro';
import { ICustomStyle } from './types';
import { useStyles } from './style';

interface IProps {
    variant: 'rond' | 'dotted';
    cs: ICustomStyle;
    style?: React.CSSProperties;
    className?: string;
}

export const Spinner = React.forwardRef<HTMLSpanElement, IProps>(
    ({ variant, cs, className, style }, ref) => {
        const styles = useStyles(cs);

        switch (variant) {
            case 'rond':
                return (
                    <span
                        ref={ref}
                        className={className}
                        css={[styles.rondSpinner, style as Interpolation<Theme>]}
                    ></span>
                );
            case 'dotted':
                return (
                    <span
                        ref={ref}
                        className={className}
                        css={[styles.dottedSpinner, style as Interpolation<Theme>]}
                    ></span>
                );
        }
    }
);
