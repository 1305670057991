import { IDepartment } from './types';
import { useApi, Cache } from '../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchDepartments = (enabled: boolean) => {
    const api = useApi();

    return useQuery<IDepartment[], any>(
        Cache.departments,
        () => api.get('/departments').then((res) => res.data),
        { enabled }
    );
};
