/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchAmbassadorDeclarationPeriods } from './hooks';
import { getIsoWeeks } from './helpers';
import { handleQueryError, useSnack } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Page, NumberSelectInput, Snack } from '../../components';
import { ISelectItem } from '../../components/inputs/number-select/types';
import { Card } from './card/Card';
import { CardSkeleton } from './card-skeleton/CardSkeleton';

export const AmbassadorDeclarations = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t } = useTranslation();
    const [year, setYear] = React.useState(moment().year());
    const [isPageSubmitting, setPageSubmitting] = React.useState(false);
    const { isSnackOpen, snackVariant, snackMessage, closeSnack, openSnack } = useSnack();

    const { isLoading, data, error, refetch } = useFetchAmbassadorDeclarationPeriods(year);

    const getYears = (): ISelectItem[] => {
        const years: ISelectItem[] = [];
        for (let i = 2024; i <= moment().year(); i++) {
            years.push({
                value: i,
                item: i.toString(),
            });
        }
        return years;
    };

    const getContent = React.useCallback((): EmotionJSX.Element[] | null => {
        if (data) {
            const cards: EmotionJSX.Element[] = [];
            for (let i = 1; i <= getIsoWeeks(year); i++) {
                cards.push(
                    <Card
                        key={i}
                        isCreated={data.map((period) => period.week).includes(i)}
                        year={year}
                        week={i}
                        isPageSubmitting={isPageSubmitting}
                        setPageSubmitting={setPageSubmitting}
                        openSnack={openSnack}
                        refetch={refetch}
                    />
                );
            }
            return cards;
        } else if (isLoading) {
            const cardSkeletons: EmotionJSX.Element[] = [];
            for (let i = 1; i <= getIsoWeeks(year); i++) {
                cardSkeletons.push(<CardSkeleton key={i} />);
            }
            return cardSkeletons;
        }
        return null;
    }, [year, data, isLoading, openSnack, refetch, isPageSubmitting]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            title={t('text.ambassadorDeclarations')}
            style={styles.root}
            isPageSubmitting={isPageSubmitting}
        >
            {isSnackOpen ? (
                <Snack
                    open={isSnackOpen}
                    variant={snackVariant}
                    message={snackMessage}
                    closeSnack={closeSnack}
                    cs={{
                        top: '8rem',
                    }}
                />
            ) : null}
            <NumberSelectInput
                style={styles.yearSelect}
                label={t('form.label.year')}
                value={year}
                setValue={setYear}
                items={getYears()}
            />
            <div style={styles.content}>{getContent()}</div>
        </Page>
    );
};
