/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            color: colors.blue,
            fontSize: '1.75rem',
            userSelect: 'none',
            marginTop: '4rem',
        },
        titel: {
            textAlign: 'center',
            marginBottom: '4rem',
            transition: 'all 0.2s',
            '&:hover': {
                transform: 'skewY(2deg) skewx(15deg) scale(1.1)',
                textShadow: `0.5rem 1rem 2rem ${colors.darkBoxShadow}`,
            },
        },
        bottomSpaceParagraph: {
            marginBottom: '1rem',
        },
        subTitel: {
            fontWeight: 'bold',
            marginBottom: '1rem',
        },
        firstTabParagraph: {
            paddingLeft: '2rem',
        },
        lastFirstTabParagraph: {
            paddingLeft: '2rem',
            marginBottom: '1rem',
        },
        secondTabParagraph: {
            paddingLeft: '4rem',
        },
        lastSecondTabParagraph: {
            paddingLeft: '4rem',
            marginBottom: '1rem',
        },
    });
