export enum Cache {
    users = 'users',
    admins = 'admins',
    ambassadors = 'ambassadors',
    candidates = 'candidates',
    candidateOwners = 'candidateOwners',
    candidateActivityPeriods = 'candidateActivityPeriods',
    afasMembers = 'afasMembers',
    afasHours = 'afasHours',
    afasContracts = 'afasContracts',
    afasContractsAfter01012023 = 'afasContractsAfter01012023',
    afasPlacements = 'afasPlacements',
    logs = 'logs',
    ambassadorEmployeeNumbers = 'ambassadorEmployeeNumbers',
    ambassadorEmployedPeriods = 'ambassadorEmployedPeriods',
    allEmployeeNumbers = 'allEmployeeNumbers',
    invitedCandidates = 'invitedCandidates',
    professions = 'professions',
    professionGroups = 'professionGroups',
    ambassadorInfo = 'ambassadorInfo',
    ambassadorActivityPeriod = 'ambassadorOneActivityPeriods',
    ambassadorActivityPeriods = 'ambassadorManyActivityPeriods',
    syncPeriods = 'syncPeriods',
    approveHoursAmbassadors = 'approveHoursAmbassadors',
    approveHoursCandidates = 'approveHoursCandidates',
    departments = 'departments',
    setting = 'setting',
    afasEmployeesCreateAmbassadors = 'afasEmployeesCreateAmbassadors',
    afasEmployeesCreateCandidates = 'afasEmployeesCreateCandidates',
    afasEmployeesUpdateCandidates = 'afasEmployeesUpdateCandidates',
    ambassadorDeclarationPeriods = 'ambassadorDeclarationPeriods',
    workedHours = 'workedHours',
    dashboardComboBoxAmbassadors = 'dashboardComboBoxAmbassadors',
    dashboardHours = 'dashboardHours',
    dashboardPipeline = 'dashboardPipeline',
    dashboardRecruitment = 'dashboardRecruitment',
    dashboardScoreboard = 'dashboardScoreboard',
    dashboardCandidates = 'dashboardCandidates',
    tasks = 'tasks',
    newsletterMailReceivers = 'newsletterMailReceivers',
}
