/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle, IElementSxProps } from './types';
import { colors } from '../../../helpers';
import { Autocomplete, TextField, TextFieldProps, InputAdornment, Fade } from '@mui/material';
import { FieldProps } from 'formik';
import { Spinner } from '../../spinner/Spinner';

interface IProps {
    options?: any[];
    isLoading?: boolean;
    cs?: ICustomStyle;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
    getOptionLabel?: (option: any) => string;
    isOptionEqualToValue?: (option: any, value: any) => boolean;
}

export const ComboBoxInput = ({
    options,
    isLoading,
    label,
    placeholder,
    disabled,
    cs,
    es,
    esx,
    style,
    className,
    getOptionLabel,
    isOptionEqualToValue,
    field,
    form: { errors, touched, setFieldValue, setFieldTouched },
}: IProps & TextFieldProps & FieldProps) => {
    const styles = useStyles(field, errors, touched, cs);
    const [inputValue, setInputValue] = React.useState('');

    return (
        <Autocomplete
            value={field.value}
            inputValue={inputValue}
            placeholder={placeholder}
            disabled={disabled}
            fullWidth
            onInputChange={(_event, newValue) => {
                setInputValue(newValue);
            }}
            onChange={(_event, newValue) => {
                setFieldValue(field.name, newValue, true);
            }}
            onBlur={() => {
                setFieldTouched(field.name, true, true);
            }}
            onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
            }}
            options={options ? options : []}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props: any) => {
                return (
                    <span {...props} style={{ minHeight: '3rem' }}>
                        {props.key}
                    </span>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    css={
                        es && es.textField
                            ? [es.textField as Interpolation<Theme>, style as Interpolation<Theme>]
                            : (style as Interpolation<Theme>)
                    }
                    sx={
                        esx && esx.textField
                            ? { ...styles.textField, ...esx.textField }
                            : styles.textField
                    }
                    className={className}
                    variant="standard"
                    type="text"
                    label={label}
                    placeholder={placeholder}
                    autoComplete="new-password"
                    disabled={disabled}
                    fullWidth
                    error={Boolean(errors[field.name]) && Boolean(touched[field.name])}
                    helperText={
                        Boolean(errors[field.name]) && Boolean(touched[field.name])
                            ? errors[field.name]
                            : ' '
                    }
                    inputProps={{
                        ...params.inputProps,
                        role: 'presentation',
                        autoComplete: 'new-password',
                        spellCheck: false,
                        'aria-live': 'polite',
                        disabled: disabled,
                    }}
                    InputProps={
                        isLoading
                            ? {
                                  endAdornment: (
                                      <InputAdornment
                                          position="end"
                                          css={
                                              es && es.endAdornment
                                                  ? (es.endAdornment as Interpolation<Theme>)
                                                  : undefined
                                          }
                                          sx={
                                              esx && esx.endAdornment
                                                  ? { ...styles.endAdornment, ...esx.endAdornment }
                                                  : styles.endAdornment
                                          }
                                      >
                                          <Fade in={isLoading}>
                                              <Spinner
                                                  variant="rond"
                                                  cs={{
                                                      color:
                                                          cs && cs.inputColor
                                                              ? cs.inputColor
                                                              : colors.blue,
                                                      radius:
                                                          cs && cs.fontSize ? cs.fontSize : '2rem',
                                                  }}
                                              />
                                          </Fade>
                                      </InputAdornment>
                                  ),
                              }
                            : params.InputProps
                    }
                />
            )}
        />
    );
};
