import * as React from 'react';
import { useStyles } from './style';
import { IAmbassadorActivityPeriod } from '../../hooks/types';
import { IContext } from './types';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@mui/material';
import { BiTrash, BiListUl } from 'react-icons/bi';
import { BsCalendar2DateFill } from 'react-icons/bs';

export const ActionsCell = ({
    data,
    context: { handleClosePeriod, handleDeletePeriod, handleEmployedPeriodClick },
}: ICellRendererParams<IAmbassadorActivityPeriod, any, IContext>) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            <Tooltip arrow title={<h1>{t('text.employedPeriods')}</h1>}>
                <IconButton
                    onClick={() => {
                        if (data) {
                            return handleEmployedPeriodClick(data);
                        }
                    }}
                >
                    <BiListUl style={styles.employedPeriodButton} />
                </IconButton>
            </Tooltip>
            {data && !data.endDate ? (
                <Tooltip arrow title={<h1>{t('text.closePeriod')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handleClosePeriod(data);
                            }
                        }}
                    >
                        <BsCalendar2DateFill style={styles.closeButton} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip arrow title={<h1>{t('text.delete')}</h1>}>
                    <IconButton
                        onClick={() => {
                            if (data) {
                                return handleDeletePeriod(data);
                            }
                        }}
                    >
                        <BiTrash style={styles.deleteButton} />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};
