/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useCreateProfessionGroup } from './hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../../components';
import { ProfessionGroupForm } from '../profession-group-form/ProfessionGroupForm';
import { handleError, routePath } from '../../../../helpers';

export const CreateProfessionGroup = () => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const create = useCreateProfessionGroup();

    return (
        <Page
            isPageSubmitting={create.isLoading}
            title={t('text.createProfessionGroup')}
            style={styles.root}
        >
            <ProfessionGroupForm
                mutationType="create"
                initialValues={{
                    nl: '',
                    en: '',
                    ro: '',
                    pl: '',
                    hr: '',
                    pt: '',
                    hu: '',
                    importId: '',
                }}
                onSubmit={async ({ nl, en, ro, pl, hr, pt, hu, importId }, { setErrors }) => {
                    try {
                        await create.mutateAsync({
                            body: {
                                nl: nl.trim(),
                                en: en.trim(),
                                ro: ro.trim(),
                                pl: pl.trim(),
                                hr: hr.trim(),
                                pt: pt.trim(),
                                hu: hu.trim(),
                                importId: importId.trim(),
                            },
                        });
                        history.push(routePath.professionGroupsSetting.create());
                    } catch (e) {
                        handleError(e, ({ status, statusText }) => {
                            if (status === 401 || status === 403) {
                                history.push(routePath.unauthorizedError.create());
                            } else if (status === 400) {
                                setErrors({
                                    en: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                    nl: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                    ro: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                    pl: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                    hr: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                    pt: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                    hu: t('form.error.oneOfTheProfessionGroupsIsRegistered'),
                                });
                            } else if (status === 500) {
                                history.push(routePath.internalServerError.create());
                            } else alert(statusText);
                        });
                    }
                }}
            />
        </Page>
    );
};
