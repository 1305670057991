/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { Role, routePath, useAuth } from '../../../../helpers';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, Menu, Fade } from '@mui/material';

export const MenuMenuItem = () => {
    const styles = useStyles();
    const { currentUserHasRoles } = useAuth();
    const client = useQueryClient();
    const history = useHistory();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <button css={styles.menuItem} onClick={handleClick}>
                {t('text.persons')}
            </button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {currentUserHasRoles(Role.superAdmin) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.admins.create());
                        }}
                    >
                        {t('text.administrators')}
                    </MenuItem>
                ) : null}
                {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                    <MenuItem
                        css={styles.subMenuItem}
                        onClick={(event) => {
                            event.preventDefault();
                            handleClose();
                            client.cancelQueries();
                            history.push(routePath.ambassadors.create());
                        }}
                    >
                        {t('text.ambassadors')}
                    </MenuItem>
                ) : null}
                <MenuItem
                    css={styles.subMenuItem}
                    onClick={(event) => {
                        event.preventDefault();
                        handleClose();
                        client.cancelQueries();
                        history.push(routePath.candidates.create());
                    }}
                >
                    {t('text.candidates')}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};
