/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchDepartments } from './hooks';
import { handleQueryError, routePath, colors } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Page, LinkButton } from '../../../components';
import { Grid } from './grid/Grid';
import { GridSkeleton } from './grid-skeleton/GridSkeleton';

export const Departments = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const { isLoading, data, error } = useFetchDepartments();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page style={styles.root}>
            {isLoading && (
                <React.Fragment>
                    <GridSkeleton />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.buttonSkeleton}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.buttonSkeleton}
                    />
                </React.Fragment>
            )}
            {data && (
                <React.Fragment>
                    <Grid professions={data} lng={i18n.language} />
                    <LinkButton
                        style={styles.button}
                        to={routePath.createDepartment.create()}
                        cs={{
                            color: colors.blue,
                            padding: '1rem 1rem',
                            fontSize: '1.25rem',
                        }}
                    >
                        {t('text.createDepartment')}
                    </LinkButton>
                    <LinkButton
                        style={styles.button}
                        to={routePath.settings.create()}
                        cs={{
                            color: colors.blue,
                            padding: '1rem 1rem',
                            fontSize: '1.25rem',
                        }}
                    >
                        {t('text.back')}
                    </LinkButton>
                </React.Fragment>
            )}
        </Page>
    );
};
