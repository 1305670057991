/**@jsxImportSource @emotion/react */
import { designStyles, colors, LightenDarkenColor } from '../../helpers';

export const useStyles = (widthBelow200px: boolean) =>
    designStyles({
        root: {
            minHeight: '100vh',
            maxWidth: '100%',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            background: colors.ghostWhite,
        },
        header: {
            margin: '2rem',
            display: 'flex',
            justifyContent: 'space-between',
        },
        headerText: {
            marginLeft: '1rem',
            fontSize: widthBelow200px ? '1rem' : '2rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            userSelect: 'none',
            color: colors.orange,
        },
        content: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        contentThankYouImg: {
            width: '20%',
            userSelect: 'none',
        },
        contentText: {
            color: colors.orange,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            userSelect: 'none',
        },
        footer: {
            display: 'flex',
            color: colors.orange,
            margin: '1rem',
            userSelect: 'none',
        },
        footerlinkBox: {
            flex: 3,
            fontSize: '3rem',
        },
        linkBoxlink: {
            display: 'inline-flex',
            transition: 'all 0.2s',
            '&:link': {
                color: colors.orange,
            },
            '&:visited': {
                color: colors.orange,
            },
            '&:active': {
                outline: 'none',
                transform: 'translateY(-1px)',
                boxShadow: `0 1rem 2rem ${colors.darkBoxShadow}`,
                color: colors.orange,
            },
            '&:focus': {
                outline: 'none',
                transform: 'translateY(-1px)',
                boxShadow: `0 1rem 2rem ${colors.darkBoxShadow}`,
                color: colors.orange,
            },
            '&:hover': {
                transform: 'translateY(-0.3rem)',
                boxShadow: `0 1rem 2rem ${colors.darkBoxShadow}`,
                color: colors.orange,
            },
            '&:focus-visible': {
                outline: 'none',
            },
            '&:not(:last-child)': {
                marginRight: '1rem',
            },
        },
        footerRightContainer: {
            fontWeight: 'bold',
            fontSize: '1.2rem',
            alignSelf: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        footerRightContainerLink: {
            color: colors.orange,
            fontFamily: 'inherit',
            textDecoration: 'none',
            transition: 'all 0.3s',
            '&:hover': {
                color: LightenDarkenColor(colors.orange, -80),
            },
        },
    });
