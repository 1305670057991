/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { ICustomStyle } from './types';
import { ripple } from '../../keyframes';
import { LightenDarkenColor, colors } from '../../../helpers';

export const useStyles = (
    {
        color,
        fontSize,
        fontWeight,
        onHoverColor,
        textColor,
        scaleEffect,
        padding,
        boxShadowColor,
        borderRadius,
    }: ICustomStyle,
    disabled: boolean,
    spinner: boolean,
    coords: { x: number; y: number }
) => ({
    button: css({
        textTransform: 'uppercase',
        textDecoration: 'none',
        marginTop: '0.5rem',
        padding: padding ? padding : '1.25rem 3rem',
        display: 'inline-block',
        borderRadius: borderRadius ? borderRadius : '10rem',
        transition: 'all 0.2s',
        position: 'relative',
        fontSize: fontSize ? fontSize : '1.2rem',
        fontWeight: fontWeight ? fontWeight : 'bold',
        fontFamily: 'inherit',
        border: 'none',
        cursor: 'pointer',
        userSelect: 'none',
        backgroundColor: color,
        color: textColor
            ? spinner && disabled
                ? LightenDarkenColor(color, 50)
                : textColor
            : spinner && disabled
            ? LightenDarkenColor(color, 50)
            : colors.buttonTextColor,
        overflow: 'hidden',
        '&:link': {
            textTransform: 'uppercase',
            textDecoration: 'none',
            marginTop: '0.5rem',
            padding: padding ? padding : '1.25rem 3rem',
            display: 'inline-block',
            borderRadius: borderRadius ? borderRadius : '10rem',
            transition: 'all 0.2s',
            position: 'relative',
            fontSize: fontSize ? fontSize : '1.2rem',
            fontWeight: fontWeight ? fontWeight : 'bold',
            fontFamily: 'inherit',
            border: 'none',
            cursor: 'pointer',
            userSelect: 'none',
            backgroundColor: color,
            color: textColor
                ? spinner && disabled
                    ? LightenDarkenColor(color, 50)
                    : textColor
                : spinner && disabled
                ? LightenDarkenColor(color, 50)
                : colors.buttonTextColor,
            overflow: 'hidden',
        },
        '&:visited': {
            textTransform: 'uppercase',
            textDecoration: 'none',
            marginTop: '0.5rem',
            padding: padding ? padding : '1.25rem 3rem',
            display: 'inline-block',
            borderRadius: borderRadius ? borderRadius : '10rem',
            transition: 'all 0.2s',
            position: 'relative',
            fontSize: fontSize ? fontSize : '1.2rem',
            fontWeight: fontWeight ? fontWeight : 'bold',
            fontFamily: 'inherit',
            border: 'none',
            cursor: 'pointer',
            userSelect: 'none',
            backgroundColor: color,
            color: textColor
                ? spinner && disabled
                    ? LightenDarkenColor(color, 50)
                    : textColor
                : spinner && disabled
                ? LightenDarkenColor(color, 50)
                : colors.buttonTextColor,
            overflow: 'hidden',
        },
        '&::after': {
            content: "''",
            display: 'inline-block',
            height: '100%',
            width: '100%',
            borderRadius: '10rem',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            transition: 'all 0.9s',
        },
        '&:hover': {
            transform: 'translateY(-0.3rem)',
            boxShadow: boxShadowColor
                ? `0 1rem 2rem ${boxShadowColor}`
                : `0 1rem 2rem ${colors.darkBoxShadow}`,
            filter: onHoverColor ? 'none' : disabled ? 'none' : 'brightness(0.85)',
            backgroundColor: onHoverColor ? onHoverColor : color,
        },
        '&:hover::after': {
            transform: scaleEffect ? 'scaleX(1.4) scaleY(1.6);' : 'none',
            opacity: 0,
        },
        '&:active': {
            outline: 'none',
            transform: 'translateY(-1px)',
            boxShadow: boxShadowColor
                ? `'0 0.5rem 1rem ${boxShadowColor}'`
                : `0 0.5rem 1rem ${colors.darkBoxShadow}`,
        },
        '&:focus': {
            outline: 'none',
            transform: 'translateY(-1px)',
            boxShadow: boxShadowColor
                ? `'0 0.5rem 1rem ${boxShadowColor}'`
                : `0 0.5rem 1rem ${colors.darkBoxShadow}`,
        },
        '&:disabled:hover': {
            transform: 'translateY(0)',
            boxShadow: 'none',
            backgroundColor: color,
        },
        '&:disabled::after': {
            backgroundColor: color,
        },
    }),
    buttonContent: css({
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: 2,
    }),
    buttonRipple: css({
        width: fontSize ? `calc(5 * ${fontSize})` : '6rem',
        height: fontSize ? `calc(5 * ${fontSize})` : '6rem',
        position: 'absolute',
        left: coords.x,
        top: coords.y,
        background: LightenDarkenColor(color, 100),
        display: 'block',
        content: "''",
        borderRadius: '100%',
        opacity: 1,
        animation: `0.9s ease 1 forwards ${ripple}`,
    }),
    buttonSpinner: css({
        position: 'absolute',
        right: '50%',
        transform: 'translate(50%)',
    }),
});
