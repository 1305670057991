import { IDepartment } from './types';
import { useApi, Cache } from '../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchDepartments = () => {
    const api = useApi();

    return useQuery<IDepartment[], any>(
        Cache.departments,
        ({ signal }) => api.get('/departments', { signal }).then((res) => res.data),
        { refetchOnWindowFocus: false }
    );
};
