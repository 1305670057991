/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './styles';
import { ICustomStyles, IElementStyle, IElementSxProps } from './types';
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    InputLabel,
    InputLabelProps,
    Select,
    SelectProps,
    FormHelperText,
    MenuItem,
} from '@mui/material';
import { FieldProps } from 'formik';
import { Language } from '../../../helpers';
import netherland from '../../../assets/netherlands.svg';
import england from '../../../assets/england.svg';
import romania from '../../../assets/romania.svg';
import poland from '../../../assets/poland.svg';
import croatia from '../../../assets/croatia.svg';
import portugal from '../../../assets/portugal.svg';
import hungary from '../../../assets/hungary.svg';

interface IProps {
    cs?: ICustomStyles;
    es?: IElementStyle;
    esx: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
}

export const LanguageSelectInput = ({
    label,
    disabled,
    cs,
    es,
    esx,
    style,
    className,
    field,
    form: { errors, touched, handleChange, handleBlur },
}: IProps & SelectProps & InputLabelProps & FieldProps) => {
    const styles = useStyles(field, errors, touched, cs);
    const { t } = useTranslation();

    return (
        <FormControl
            css={
                es && es.formControl
                    ? [es.formControl as Interpolation<Theme>, style as Interpolation<Theme>]
                    : (style as Interpolation<Theme>)
            }
            sx={
                esx && esx.formControl
                    ? { ...styles.formControl, ...esx.formControl }
                    : styles.formControl
            }
            className={className}
            error={Boolean(errors[field.name]) && Boolean(touched[field.name])}
            fullWidth
        >
            <InputLabel
                css={es && es.label ? (es.label as Interpolation<Theme>) : undefined}
                sx={esx && esx.label ? esx.label : undefined}
            >
                {label}
            </InputLabel>
            <Select
                {...field}
                css={es && es.select ? (es.select as Interpolation<Theme>) : undefined}
                sx={esx && esx.select ? esx.select : undefined}
                variant="standard"
                fullWidth
                autoComplete="new-password"
                disabled={disabled ? disabled : false}
                onChange={(event) => {
                    handleChange(event);
                }}
                onBlur={(event) => {
                    handleBlur(event);
                }}
                onFocus={(event) => {
                    event.target.setAttribute('autocomplete', 'off');
                }}
                inputProps={{
                    role: 'presentation',
                    autoComplete: 'new-password',
                    spellCheck: false,
                    'aria-live': 'polite',
                    disabled: disabled ? disabled : false,
                }}
            >
                <MenuItem
                    value={Language.nl}
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.manuItemFlag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.manuItemFlag
                        }
                        src={netherland}
                        alt="fg"
                    />
                    {t('language.dutch')}
                </MenuItem>
                <MenuItem
                    value={Language.en}
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.manuItemFlag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.manuItemFlag
                        }
                        src={england}
                        alt="fg"
                    />
                    {t('language.english')}
                </MenuItem>
                <MenuItem
                    value={Language.ro}
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.manuItemFlag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.manuItemFlag
                        }
                        src={romania}
                        alt="fg"
                    />
                    {t('language.romanian')}
                </MenuItem>
                <MenuItem
                    value={Language.pl}
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.manuItemFlag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.manuItemFlag
                        }
                        src={poland}
                        alt="fg"
                    />
                    {t('language.polish')}
                </MenuItem>
                <MenuItem
                    value={Language.hr}
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.manuItemFlag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.manuItemFlag
                        }
                        src={croatia}
                        alt="fg"
                    />
                    {t('language.croatian')}
                </MenuItem>
                <MenuItem
                    value={Language.pt}
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.manuItemFlag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.manuItemFlag
                        }
                        src={portugal}
                        alt="fg"
                    />
                    {t('language.portuguese')}
                </MenuItem>
                <MenuItem
                    value={Language.hu}
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.manuItemFlag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.manuItemFlag
                        }
                        src={hungary}
                        alt="fg"
                    />
                    {t('language.hungarian')}
                </MenuItem>
            </Select>
            <FormHelperText
                css={es && es.formHelper ? (es.formHelper as Interpolation<Theme>) : undefined}
                sx={esx && esx.formHelper ? esx.formHelper : undefined}
            >
                {Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? errors[field.name]
                    : ' '}
            </FormHelperText>
        </FormControl>
    );
};
