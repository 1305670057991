/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { TextField, TextFieldProps, SxProps, Theme as MuiTheme } from '@mui/material';
import { FieldProps } from 'formik';
import { useStyles } from './style';
import { ICustomStyle } from './types';

interface IProps {
    cs?: ICustomStyle;
    sx?: SxProps<MuiTheme>;
    style?: React.CSSProperties;
    className?: string;
}

export const DatePickerInput = ({
    label,
    placeholder,
    disabled,
    cs,
    sx,
    style,
    className,
    field,
    form: { errors, touched, handleChange, handleBlur },
}: IProps & TextFieldProps & FieldProps) => {
    const styles = useStyles(field, errors, touched, cs);

    return (
        <TextField
            {...field}
            css={style as Interpolation<Theme>}
            sx={sx ? { ...styles.textField, ...sx } : styles.textField}
            className={className}
            variant="standard"
            type="date"
            label={label}
            placeholder={placeholder}
            autoComplete="new-password"
            disabled={disabled ? disabled : false}
            fullWidth
            inputProps={{
                role: 'presentation',
                autoComplete: 'new-password',
                spellCheck: false,
                'aria-live': 'polite',
                disabled: disabled ? disabled : false,
            }}
            error={Boolean(errors[field.name]) && Boolean(touched[field.name])}
            helperText={
                Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? errors[field.name]
                    : ' '
            }
            onChange={(event) => {
                handleChange(event);
            }}
            onBlur={(event) => {
                handleBlur(event);
            }}
            onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
            }}
            InputLabelProps={{ shrink: label ? true : false }}
        />
    );
};
