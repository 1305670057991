/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            padding: '0 1rem 1rem 1rem',
            fontSize: '2rem',
            backgroundColor: colors.white,
        },
        updateButton: {
            color: colors.green,
            fontSize: widthBelow600px ? '1.6rem' : '2rem',
        },
        professionButton: {
            color: colors.blue,
            fontSize: widthBelow600px ? '1.6rem' : '2rem',
        },
        resendInvitation: {
            color: colors.green,
            fontSize: widthBelow600px ? '1.6rem' : '2rem',
        },
        activityButton: {
            color: colors.red,
            fontSize: widthBelow600px ? '1.6rem' : '2rem',
        },
        afasLinkButton: {
            color: colors.blue,
            fontSize: widthBelow600px ? '1.6rem' : '2rem',
        },
        promoteButton: {
            color: colors.blue,
            fontSize: widthBelow600px ? '1.6rem' : '2rem',
        },
        rejectButton: {
            color: colors.orange,
            fontSize: widthBelow600px ? '1.6rem' : '2rem',
        },
        deleteButton: {
            color: colors.red,
            fontSize: widthBelow600px ? '1.6rem' : '2rem',
        },
    });
