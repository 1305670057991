/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useSendFeedback } from './hooks';
import { colors, handleError, isRichTextEditorEmpty, routePath } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { useMediaQuery } from '@mui/material';
import { Page, RichTextEditorInput, Button } from '../../components';

export const Feedback = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t } = useTranslation();

    const sendFeedback = useSendFeedback();

    return (
        <Page style={styles.root} isPageSubmitting={sendFeedback.isLoading}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>Feedback</h1>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            feedback: '',
                        } as IFormValues
                    }
                    validate={({ feedback }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (isRichTextEditorEmpty(feedback)) {
                            errors.feedback = t('form.error.requiredField');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ feedback }) => {
                        try {
                            await sendFeedback.mutateAsync({
                                body: { feedback },
                            });
                            history.goBack();
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form style={styles.contentForm} noValidate>
                            <Field
                                style={styles.formField}
                                name="feedback"
                                label="Feedback"
                                placeholder="Feedback..."
                                controls={['bold', 'italic', 'underline', 'undo', 'redo']}
                                component={RichTextEditorInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.send')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.goBack()}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Page>
    );
};
