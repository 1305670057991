import { useQuery } from 'react-query';
import { useApi, Cache } from '../../../../../../helpers';
import { IAmbassadorEmployedPeriodActivityPeriod, IAmbassadorEmployedPeriod } from '../types';

export const useFetchAmbassadorEmployedPeriods = (activityPeriodId: string) => {
    const api = useApi();

    return useQuery<
        IAmbassadorEmployedPeriodActivityPeriod & { employedPeriods: IAmbassadorEmployedPeriod[] },
        any
    >([Cache.ambassadorEmployedPeriods, activityPeriodId], ({ signal }) =>
        api
            .get(`/ambassador-employed-periods/${activityPeriodId}`, { signal })
            .then((res) => res.data)
    );
};
