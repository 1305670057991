/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            width: '100%',
            textAlign: 'center',
        },
        updateButton: {
            color: colors.green,
        },
        activityButton: {
            color: colors.red,
        },
        resendInvitationButton: {
            color: colors.gray,
        },
        afasLinkButton: {
            color: colors.blue,
        },
        promoteButton: {
            color: colors.blue,
        },
        rejectButton: {
            color: colors.orange,
        },
        deleteButton: {
            color: colors.red,
        },
    });
