/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { ICustomStyle } from './types';
import { colors } from '../../../helpers';

export const useStyles = (
    field: FieldInputProps<any>,
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    cs?: ICustomStyle
) => ({
    root: css({
        width: '100%',
    }),
    label: css({
        fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
        fontSize: cs && cs.fontSize ? `calc(${cs.fontSize} * 0.8)` : '1.6rem',
        color:
            Boolean(errors[field.name]) && Boolean(touched[field.name])
                ? cs && cs.errorTextColor
                    ? cs.errorTextColor
                    : colors.red
                : cs && cs.inputColor
                ? cs.inputColor
                : colors.blue,
        userSelect: 'none',
    }),
    textField: {
        '& .MuiOutlinedInput-root': {
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
            color: cs && cs.inputColor ? cs.inputColor : colors.blue,
            marginTop: '0.25rem',
            padding: 0,
            '& fieldset': {
                borderColor:
                    Boolean(errors[field.name]) && Boolean(touched[field.name])
                        ? cs && cs.errorTextColor
                            ? cs.errorTextColor
                            : colors.red
                        : cs && cs.inputColor
                        ? cs.inputColor
                        : colors.blue,
            },
            '&:hover fieldset': {
                borderColor:
                    Boolean(errors[field.name]) && Boolean(touched[field.name])
                        ? cs && cs.errorTextColor
                            ? cs.errorTextColor
                            : colors.red
                        : cs && cs.inputColor
                        ? cs.inputColor
                        : colors.blue,
            },
            '&.Mui-focused fieldset': {
                borderColor:
                    Boolean(errors[field.name]) && Boolean(touched[field.name])
                        ? cs && cs.errorTextColor
                            ? cs.errorTextColor
                            : colors.red
                        : cs && cs.inputColor
                        ? cs.inputColor
                        : colors.blue,
            },
        },
        '& .MuiFormHelperText-root': {
            fontSize:
                cs && cs.errorFontSize
                    ? cs.errorFontSize
                    : cs && cs.fontSize
                    ? `calc(${cs.fontSize} * 0.6)`
                    : '1.2rem',
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            fontWeight: 'bold',
            color: cs && cs.errorTextColor ? cs.errorTextColor : colors.red,
            userSelect: 'none',
            paddingLeft: 0,
            marginLeft: 0,
        },
        '& .MuiInput-underline.Mui-error:after': {
            color: colors.red,
        },
        '& .MuiChip-label': {
            fontFamily: 'inherit',
            color: cs && cs.inputColor ? cs.inputColor : colors.blue,
            fontSize: '1rem',
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: cs && cs.inputColor ? cs.inputColor : colors.blue,
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: cs && cs.inputColor ? cs.inputColor : colors.blue,
        },
    },
    endAdornment: {
        paddingRight: '1rem',
    },
});
