/**@jsxImportSource @emotion/react */
import React from 'react';
import { useStyles } from './style';
import { useDeleteActivityPeriod } from './hooks';
import { ICandidate } from '../../../hooks';
import { handleError, Cache, routePath, colors } from '../../../../../helpers';
import { ISnackActionPayload } from '../../../../../helpers/reducers/snack/types';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Backdrop, Button } from '../../../../../components';
import { AiOutlineClose } from 'react-icons/ai';

interface IProps {
    open: boolean;
    itemToDeleteId: string;
    openSnack: (payload: ISnackActionPayload) => void;
    closeModal: () => void;
}

export const DeleteModal = ({ open, itemToDeleteId, openSnack, closeModal }: IProps) => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const deletePeriod = useDeleteActivityPeriod();

    return (
        <Backdrop open={open}>
            <div style={styles.root}>
                <div style={styles.header}>
                    <h2 style={styles.headerTitle}>{t('text.deleteActivityPeriod')}</h2>
                    <AiOutlineClose
                        css={styles.headerCloseButton}
                        onClick={() => {
                            if (!deletePeriod.isLoading) {
                                closeModal();
                            }
                        }}
                    />
                </div>
                <div style={styles.content}>
                    <p style={styles.contentText}>
                        {`${t('text.activityPeriod')} ${t('text.deleteModalText')}`}
                    </p>
                </div>
                <div style={styles.actions}>
                    <Button
                        style={styles.actionsDeleteButton}
                        disabled={deletePeriod.isLoading}
                        spinner={deletePeriod.isLoading}
                        cs={{
                            fontSize: '1rem',
                            padding: '1rem 1.5rem',
                            color: colors.red,
                            textColor: colors.white,
                        }}
                        onClick={async () => {
                            try {
                                const { data: candidate } = await deletePeriod.mutateAsync({
                                    candidateActivityPeriodId: itemToDeleteId,
                                });
                                queryClient.setQueriesData<ICandidate>(
                                    [Cache.candidates, candidate.id],
                                    () => candidate
                                );
                                closeModal();
                            } catch (e) {
                                handleError(e, ({ status, statusText, data }) => {
                                    if (status === 401 || status === 403) {
                                        history.push(routePath.unauthorizedError.create());
                                    } else if (status === 400) {
                                        if (data.message === 'Placement cannot be deleted') {
                                            openSnack({
                                                snackVariant: 'error',
                                                snackMessage: t(
                                                    'form.error.afasPlacementCannotBeDeleted'
                                                ),
                                            });
                                        } else if (data.message === 'Period is not closed') {
                                            openSnack({
                                                snackVariant: 'error',
                                                snackMessage: t('form.error.periodStillOpen'),
                                            });
                                        }
                                        closeModal();
                                    } else if (status === 404) {
                                        history.push(routePath.notFoundError.create());
                                    } else if (status === 500) {
                                        history.push(routePath.internalServerError.create());
                                    } else {
                                        alert(statusText);
                                        closeModal();
                                    }
                                });
                            }
                        }}
                    >
                        {t('text.delete')}
                    </Button>
                    <Button
                        disabled={deletePeriod.isLoading}
                        spinner={deletePeriod.isLoading}
                        onClick={closeModal}
                        cs={{
                            fontSize: '1rem',
                            padding: '1rem 1.5rem',
                            color: colors.blue,
                        }}
                    >
                        {t('text.cancel')}
                    </Button>
                </div>
            </div>
        </Backdrop>
    );
};
