import { IEmailSubscriptionVariables } from './types';
import { useApi } from '../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useEmailSubscription = () => {
    const api = useApi();

    return useMutation<AxiosResponse<void>, any, IEmailSubscriptionVariables>(({ body }) =>
        api.patch('/email-subscription', body)
    );
};
