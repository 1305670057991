import { Role } from '../../../../helpers';
import { IAppRole } from '../../hooks/types';

export const getAdminRole = (adminAppRoles: IAppRole[]): Role => {
    const adminRoles = adminAppRoles.map((appRole) => appRole.role);
    if (adminRoles.includes(Role.superAdmin)) {
        return Role.superAdmin;
    } else if (adminRoles.includes(Role.admin)) {
        return Role.admin;
    } else {
        return Role.recruiter;
    }
};
