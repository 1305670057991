/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle, IElementSxProps } from './types';
import {
    FormControlLabel,
    FormControlLabelProps,
    Switch,
    SwitchProps,
    useTheme,
} from '@mui/material';
import { FieldProps } from 'formik';

interface IProps {
    cs?: ICustomStyle;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
}

export const SwitchInput = ({
    label,
    disabled,
    onChange,
    cs,
    es,
    esx,
    style,
    className,
    field,
    form: { handleChange, handleBlur },
}: IProps & FormControlLabelProps & FieldProps & SwitchProps) => {
    const theme = useTheme();
    const styles = useStyles(theme, cs);

    return (
        <FormControlLabel
            label={label}
            css={
                es && es.formControl
                    ? [es.formControl as Interpolation<Theme>, style as Interpolation<Theme>]
                    : (style as Interpolation<Theme>)
            }
            sx={
                esx && esx.formControl
                    ? { ...styles.formControl, ...esx.formControl }
                    : styles.formControl
            }
            className={className}
            control={
                <Switch
                    {...field}
                    css={es && es.switch ? (es.switch as Interpolation<Theme>) : undefined}
                    sx={esx && esx.switch ? esx.switch : undefined}
                    checked={field.value}
                    onChange={onChange ? onChange : handleChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                />
            }
        />
    );
};
