/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { SocialMediaLink, routePath } from '../../../helpers';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { LanguageMenu, Button } from '../../../components';
import { colors } from '../../../helpers';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import errorImg from '../../../assets/oranje-logo-orange-unavailable.png';

export const Unavailable = () => {
    const widthBelow200px = useMediaQuery('(max-width: 200px)');
    const styles = useStyles(widthBelow200px);
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            <div style={styles.header}>
                <h1 css={styles.headerText}>Oranjelink</h1>
                <LanguageMenu />
            </div>
            <div style={styles.content}>
                <img css={styles.contentErrorImg} src={errorImg} alt="errorImg" draggable="false" />
                <p style={styles.contentText}>{t('error.503')}</p>
                <Button
                    cs={{ color: colors.orange, fontSize: '1.5rem' }}
                    onClick={() => history.goBack()}
                >
                    {t('text.back')}
                </Button>
            </div>
            <div style={styles.footer}>
                <div style={styles.footerlinkBox}>
                    <a
                        css={styles.linkBoxlink}
                        href={SocialMediaLink.Facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillFacebook />
                    </a>
                    <a
                        css={styles.linkBoxlink}
                        href={SocialMediaLink.Instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillInstagram />
                    </a>
                    <a
                        css={styles.linkBoxlink}
                        href={SocialMediaLink.LinkedIn}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillLinkedin />
                    </a>
                </div>
                <div style={styles.footerRightContainer}>
                    <div>
                        <Link css={styles.footerRightContainerLink} to={routePath.terms.create()}>
                            {t('text.generalTerms')}
                        </Link>
                        &nbsp;/&nbsp;
                        <Link
                            css={styles.footerRightContainerLink}
                            to={routePath.privacyPolicy.create()}
                        >
                            {t('text.privacyPolicy')}
                        </Link>
                    </div>
                    <p>
                        {`De Oranjegroep \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop - Public Bèta`}
                    </p>
                </div>
            </div>
        </div>
    );
};
