/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        cardSkeleton: {
            width: widthBelow600px ? '30rem' : '50rem',
            height: '45rem',
            boxShadow: `5px 10px 8px 10px ${colors.darkBoxShadow}`,
        },
        card: {
            background: colors.white,
            boxShadow: `5px 10px 8px 10px ${colors.darkBoxShadow}`,
            padding: '4rem',
            width: widthBelow600px ? '30rem' : '50rem',
            color: colors.blue,
            borderRadius: '1rem',
        },
        cardHeading: {
            display: 'flex',
        },
        cardHeadingNameBox: {
            display: 'flex',
            alignItems: 'center',
        },
        nameBoxText: {
            marginLeft: '1rem',
            fontSize: widthBelow600px ? '1.25rem' : '2rem',
            fontWeight: 800,
            userSelect: 'none',
        },
        cardContent: {
            padding: '2rem 0 2rem 0',
        },
        cardContentTextBox: {
            fontSize: widthBelow600px ? '1rem' : '1.75rem',
            userSelect: 'none',
            marginBottom: '0.5rem',
        },
        textBoxText: {
            display: 'inline-block',
            fontWeight: 800,
        },
        textBoxDataText: {
            display: 'inline-block',
            fontStyle: 'italic',
            userSelect: 'text',
        },
        cardActions: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        cardActionButton: {
            width: '80%',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
    });
