/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        week: {
            margin: '2rem 0 0 0',
            display: 'inline-block',
            color: colors.blue,
            userSelect: 'none',
        },
        content: {
            marginTop: '2rem',
            width: '70%',
            marginBottom: '2rem',
        },
        contentAccordion: {
            width: '100%',
        },
        accordionSummaryContent: {
            display: 'flex',
            flexDirection: 'column',
            color: colors.blue,
            fontSize: '1.5rem',
        },
        accordionSummaryContentAmbassador: {
            fontWeight: 'bold',
        },
        accordionDetailsContent: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            color: colors.blue,
            fontSize: '1.5rem',
        },
        accordionText: {
            display: 'inline-block',
            fontWeight: 'bold',
        },
        accordionDataText: {
            display: 'inline-block',
            fontStyle: 'italic',
            userSelect: 'text',
        },
        accordionBooleanBox: {
            display: 'flex',
            alignItems: 'center',
        },
        accordionBooleanIcon: {
            marginLeft: '0.2rem',
            fontSize: '1.6rem',
        },
        accordionDetailsContentNoCandidates: {
            width: '100%',
            textAlign: 'center',
            padding: '2rem 0',
            borderTop: `1px solid ${colors.blue}`,
            userSelect: 'none',
        },
        accordionDetailsContentTier: {
            width: '100%',
            textAlign: 'center',
            padding: '1rem 0',
            fontWeight: 'bold',
            borderBottom: `1px solid ${colors.blue}`,
            userSelect: 'none',
        },
        accordionDetailsContentCandidates: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem 0',
        },
        candidatesCandidateBox: {
            display: 'flex',
            flexDirection: 'column',
            '&:not(:last-child)': {
                marginBottom: '1rem',
            },
        },
        candidateBoxCandidate: {
            fontWeight: 'bold',
        },
        button: {
            width: '30rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
        weekSkeleton: {
            margin: '2rem 0',
            width: '10rem',
            height: '2rem',
        },
        accordionSkeleton: {
            width: '100%',
            height: '12rem',
            '&:not(:last-child)': {
                marginBottom: '4rem',
            },
        },
        skeletonBox: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        buttonSkeletonBoxButton: {
            width: '30rem',
            height: '5rem',
        },
    });
