/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchCandidates } from './hooks';
import {
    handleQueryError,
    useAuth,
    Role,
    routePath,
    useDeleteModal,
    useRejectModal,
    View,
    getViewLocalStorage,
} from '../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Tooltip, IconButton } from '@mui/material';
import { Page, ViewToggleButton } from '../../components';
import { FcInvite } from 'react-icons/fc';
import { AiOutlinePlus } from 'react-icons/ai';
import { DeleteModal } from './delete-modal/DeleteModal';
import { RejectModal } from './reject-modal/RejectModal';
import { CardView, CardViewSkeleton } from './card-view';
import { GridView, GridSkeleton } from './grid-view';

export const Candidates = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles();
    const { currentUserHasRoles } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const [view, setView] = React.useState<View>(
        widthBelow600px ? View.Card : getViewLocalStorage()
    );
    const [search, setSearch] = React.useState('');
    const { isDeleteOpen, itemToDeleteId, itemToDeleteName, openDelete, closeDelete } =
        useDeleteModal();
    const { isRejectOpen, itemToRejectId, itemToRejectName, openReject, closeReject } =
        useRejectModal();

    const { isLoading, data, error } = useFetchCandidates(search);

    React.useLayoutEffect(() => {
        if (widthBelow600px) {
            setView(View.Card);
        }
    }, [widthBelow600px]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page title={t('text.candidates')} style={styles.root}>
            {isDeleteOpen && (
                <DeleteModal
                    open={isDeleteOpen}
                    itemToDeleteId={itemToDeleteId}
                    itemToDeleteName={itemToDeleteName}
                    closeModal={closeDelete}
                />
            )}
            {isRejectOpen && (
                <RejectModal
                    open={isRejectOpen}
                    itemToRejectId={itemToRejectId}
                    itemToRejectName={itemToRejectName}
                    closeModal={closeReject}
                />
            )}
            <div style={styles.actionBar}>
                <div style={styles.actionBarViewToogle}>
                    {!widthBelow600px ? <ViewToggleButton view={view} setView={setView} /> : null}
                </div>
                {currentUserHasRoles(Role.superAdmin) ? (
                    <div style={styles.actionBarRightContainer}>
                        <Tooltip
                            arrow
                            placement="left"
                            title={<h1>{t('text.createCandidate')}</h1>}
                        >
                            <IconButton
                                size="large"
                                style={styles.actionBarRightContainerCreateIcon}
                                onClick={() => history.push(routePath.createCandidate.create())}
                            >
                                <AiOutlinePlus />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            arrow
                            placement="bottom"
                            title={<h1>{t('text.inviteCandidate')}</h1>}
                        >
                            <IconButton
                                size="large"
                                style={styles.actionBarRightContainerInviteIcon}
                                onClick={() =>
                                    history.push(routePath.inviteCreateCandidate.create())
                                }
                            >
                                <FcInvite />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : (
                    <Tooltip arrow placement="bottom" title={<h1>{t('text.inviteCandidate')}</h1>}>
                        <IconButton
                            size="large"
                            style={styles.actionBarCreateInviteIcon}
                            onClick={() => history.push(routePath.inviteCreateCandidate.create())}
                        >
                            <FcInvite />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {isLoading && view === View.Card && <CardViewSkeleton />}
            {isLoading && view === View.Grid && <GridSkeleton />}
            {data && view === View.Card && (
                <CardView
                    data={data}
                    isLoading={isLoading}
                    search={search}
                    setSearch={setSearch}
                    openDelete={openDelete}
                    openReject={openReject}
                />
            )}
            {data && view === View.Grid && (
                <GridView data={data} openDelete={openDelete} openReject={openReject} />
            )}
        </Page>
    );
};
