import { useQuery } from 'react-query';
import { Cache, useApi } from '../../../../../helpers';
import { ITask } from './types';

export const useFetchTasks = () => {
    const api = useApi();

    return useQuery<ITask[], any>(Cache.tasks, () => {
        return api.get('/tasks').then((res) => res.data);
    });
};
