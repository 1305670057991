import { ICandidate } from '../../../../../hooks';
import { Cache, useApi } from '../../../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchCandidate = (candidateId: string) => {
    const api = useApi();

    return useQuery<ICandidate, any>([Cache.candidates, candidateId], () =>
        api.get(`/candidates/${candidateId}`).then((res) => res.data)
    );
};
