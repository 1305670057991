import { useQuery } from 'react-query';
import { Cache, useApi } from '../../../../helpers';
import { ISetting } from './types';

export const useFetchSetting = () => {
    const api = useApi();

    return useQuery<ISetting, any>(Cache.setting, ({ signal }) =>
        api.get('/settings', { signal }).then((res) => res.data)
    );
};
