import { IActivityPeriodAmbassador, IAmbassadorActivityPeriod } from '../types';
import { Cache, useApi } from '../../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAmbassadorActivityPeriods = (ambassadorId: string) => {
    const api = useApi();

    return useQuery<
        IActivityPeriodAmbassador & { ambassadorActivityPeriods: IAmbassadorActivityPeriod[] },
        any
    >([Cache.ambassadorActivityPeriods, ambassadorId], ({ signal }) =>
        api
            .get(`/ambassador-activity-periods/${ambassadorId}/ambassador`, { signal })
            .then((res) => res.data)
    );
};
