/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import {
    useFetchTasks,
    useSyncAmbassadorEmployedPeriods,
    useSyncAmbassadorDeclarationPeriods,
} from './hooks';
import { colors, handleQueryError, routePath } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Page, Button, Loader } from '../../../components';
import { Grid } from './grid/Grid';
import { GridSkeleton } from './grid/grid-skeleton/GridSkeleton';

export const Tasks = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const { data, isLoading, error, refetch } = useFetchTasks();
    const syncAmbassadorEmployedPeriods = useSyncAmbassadorEmployedPeriods();
    const syncAmbassadorDeclarationPeriods = useSyncAmbassadorDeclarationPeriods();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            style={styles.root}
            isPageSubmitting={
                syncAmbassadorEmployedPeriods.isLoading ||
                syncAmbassadorDeclarationPeriods.isLoading
            }
        >
            {syncAmbassadorEmployedPeriods.isLoading ||
            syncAmbassadorDeclarationPeriods.isLoading ? (
                <Loader
                    open={
                        syncAmbassadorEmployedPeriods.isLoading ||
                        syncAmbassadorDeclarationPeriods.isLoading
                    }
                />
            ) : null}
            {isLoading && (
                <React.Fragment>
                    <GridSkeleton />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.buttonSkeleton}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.buttonSkeleton}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.buttonSkeleton}
                    />
                </React.Fragment>
            )}
            {data && (
                <React.Fragment>
                    <Grid data={data} lng={i18n.language} />
                    <Button
                        style={styles.button}
                        cs={{
                            color: colors.blue,
                            padding: '1rem 1rem',
                            fontSize: '1.25rem',
                        }}
                        onClick={async () => {
                            try {
                                await syncAmbassadorEmployedPeriods.mutateAsync();
                            } catch (e) {
                                console.log(e);
                            }
                            await refetch();
                        }}
                    >
                        {t('text.syncAmbassadorEmployedPeriods')}
                    </Button>
                    <Button
                        style={styles.button}
                        cs={{
                            color: colors.blue,
                            padding: '1rem 1rem',
                            fontSize: '1.25rem',
                        }}
                        onClick={async () => {
                            try {
                                await syncAmbassadorDeclarationPeriods.mutateAsync();
                            } catch (e) {
                                console.log(e);
                            }
                            await refetch();
                        }}
                    >
                        {t('text.syncAmbassadorDeclarationPeriods')}
                    </Button>
                    <Button
                        style={styles.button}
                        disabled={
                            syncAmbassadorEmployedPeriods.isLoading ||
                            syncAmbassadorDeclarationPeriods.isLoading
                        }
                        cs={{
                            color: colors.blue,
                            padding: '1rem 1rem',
                            fontSize: '1.25rem',
                        }}
                        onClick={() => history.push(routePath.settings.create())}
                    >
                        {t('text.back')}
                    </Button>
                </React.Fragment>
            )}
        </Page>
    );
};
