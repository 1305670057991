/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useFetchProfessions } from './hooks';
import { handleQueryError } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListComboBoxInput } from '../../../components';

export const ProfessionComboBox = (props: any) => {
    const history = useHistory();
    const { i18n } = useTranslation();

    const { isLoading, data, error } = useFetchProfessions();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <ListComboBoxInput
            {...props}
            options={data}
            isLoading={isLoading}
            getOptionLabel={(profession) => `${profession[i18n.language]}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};
