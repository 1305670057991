import { IEmailCheckResetPasswordVariables } from './types';
import { useApi } from '../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useEmailCheckResetPassword = () => {
    const api = useApi();

    return useMutation<AxiosResponse<string>, any, IEmailCheckResetPasswordVariables>(({ body }) =>
        api.post('/email-check-reset-password', body)
    );
};
