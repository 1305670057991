export const getFee = (costPrice: number, costPriceFactor: number) => {
    const firstTierFee = (costPrice / costPriceFactor / 6) * 3;
    const secondTierFee = (costPrice / costPriceFactor / 6) * 2;
    const thirdTierFee = (costPrice / costPriceFactor / 6) * 1;
    return {
        firstTierFee,
        secondTierFee,
        thirdTierFee,
    };
};
