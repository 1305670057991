/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { Language, useAuth, colors, SocialMediaLink, routePath } from '../../helpers';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { LanguageMenu, Page, Button } from '../../components';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import { English, Dutch } from './contents';

export const PrivacyPolicy = () => {
    const widthBelow200px = useMediaQuery('(max-width: 200px)');
    const styles = useStyles(widthBelow200px);
    const { currentUser } = useAuth();
    const history = useHistory();
    const { t, i18n } = useTranslation();

    if (!currentUser) {
        return (
            <div style={styles.root}>
                <div style={styles.header}>
                    <h1 css={styles.headerText}>Oranjelink</h1>
                    <LanguageMenu />
                </div>
                <div style={styles.content}>
                    {i18n.language === Language.en && <English />}
                    {i18n.language === Language.nl && <Dutch />}
                    {i18n.language === Language.ro && <English />}
                    {i18n.language === Language.pl && <English />}
                    {i18n.language === Language.hr && <English />}
                    {i18n.language === Language.pt && <English />}
                    {i18n.language === Language.hu && <English />}
                    <Button
                        style={styles.button}
                        type="button"
                        cs={{
                            color: colors.blue,
                        }}
                        onClick={() => history.goBack()}
                    >
                        {t('text.back')}
                    </Button>
                </div>
                <div style={styles.footer}>
                    <div style={styles.footerLinkBox}>
                        <a
                            css={styles.footerlinkBoxLink}
                            href={SocialMediaLink.Facebook}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <AiFillFacebook />
                        </a>
                        <a
                            css={styles.footerlinkBoxLink}
                            href={SocialMediaLink.Instagram}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <AiFillInstagram />
                        </a>
                        <a
                            css={styles.footerlinkBoxLink}
                            href={SocialMediaLink.LinkedIn}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <AiFillLinkedin />
                        </a>
                    </div>
                    <div style={styles.footerRightContainer}>
                        <div>
                            <Link
                                css={styles.footerRightContainerLink}
                                to={routePath.terms.create()}
                            >
                                {t('text.generalTerms')}
                            </Link>
                            &nbsp;/&nbsp;
                            <Link
                                css={styles.footerRightContainerLink}
                                to={routePath.privacyPolicy.create()}
                            >
                                {t('text.privacyPolicy')}
                            </Link>
                        </div>
                        <p>
                            {`De Oranjegroep \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop - Public Bèta`}
                        </p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Page style={styles.privateRoot}>
                <div style={styles.content}>
                    {i18n.language === Language.en && <English />}
                    {i18n.language === Language.nl && <Dutch />}
                    {i18n.language === Language.ro && <English />}
                    {i18n.language === Language.pl && <English />}
                    {i18n.language === Language.hr && <English />}
                    {i18n.language === Language.pt && <English />}
                    {i18n.language === Language.hu && <English />}
                    <Button
                        style={styles.button}
                        type="button"
                        cs={{
                            color: colors.blue,
                        }}
                        onClick={() => history.goBack()}
                    >
                        {t('text.back')}
                    </Button>
                </div>
            </Page>
        );
    }
};
