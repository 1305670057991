import { useQuery } from 'react-query';
import { IProfessionGroup } from '../../../hooks';
import { useApi, Cache } from '../../../../../../helpers';

export const useFetchProfessionGroup = (professionGroupId: string) => {
    const api = useApi();

    return useQuery<IProfessionGroup, any>(
        [Cache.professionGroups, professionGroupId],
        ({ signal }) =>
            api.get(`/profession-groups/${professionGroupId}`, { signal }).then((res) => res.data)
    );
};
