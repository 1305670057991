import axios from 'axios';
import { History } from 'history';
import { routePath } from '../routing/route-path';

const {
    unauthorizedError,
    internalServerError,
    unavailableError,
    gatewayTimeoutError,
    notFoundError,
} = routePath;

export const handleQueryError = (error: any, history: History): null => {
    if (axios.isAxiosError(error)) {
        if (error.response) {
            const { status, statusText } = error.response!;
            switch (status) {
                case 500:
                    history.push(internalServerError.create());
                    return null;
                case 503:
                    history.push(unavailableError.create());
                    return null;
                case 504:
                    history.push(gatewayTimeoutError.create());
                    return null;
                case 404:
                    history.push(notFoundError.create());
                    return null;
                case 401:
                    history.push(unauthorizedError.create());
                    return null;
                case 403:
                    history.push(unauthorizedError.create());
                    return null;
                default:
                    alert(statusText);
                    return null;
            }
        } else {
            alert(error.message);
            return null;
        }
    } else {
        alert(error.message);
        return null;
    }
};
