/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IAdmin } from '../../../hooks/types';
import { IPayload as IDeleteModalPayload } from '../../../../../helpers/reducers/delete/types';
import { IPayload as IResendQRModalPayload } from '../../../../../helpers/reducers/resend-qr-code/types';
import { routePath } from '../../../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Tooltip, IconButton } from '@mui/material';
import { BiEnvelope, BiLockAlt, BiEdit, BiTrash } from 'react-icons/bi';
import { AiOutlineQrcode } from 'react-icons/ai';

interface IProps {
    admin: IAdmin;
    openDelete: (payload: IDeleteModalPayload) => void;
    openResendQR: (payload: IResendQRModalPayload) => void;
}

export const Actions = ({ admin, openDelete, openResendQR }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div style={styles.root}>
            <Tooltip arrow title={<h1>{t('text.changeEmail')}</h1>}>
                <IconButton
                    onClick={() => history.push(routePath.changeEmailAdmin.create(admin.id))}
                >
                    <BiEnvelope style={styles.emailButton} />
                </IconButton>
            </Tooltip>
            <Tooltip arrow title={<h1>{t('text.changePassword')}</h1>}>
                <IconButton
                    onClick={() => history.push(routePath.changePasswordAdmin.create(admin.id))}
                >
                    <BiLockAlt style={styles.passwordButton} />
                </IconButton>
            </Tooltip>
            <Tooltip arrow title={<h1>{t('text.resendQR')}</h1>}>
                <IconButton
                    onClick={() =>
                        openResendQR({
                            resendQRUserId: admin.user.id,
                            resendQRUsername: admin.user.name,
                        })
                    }
                >
                    <AiOutlineQrcode style={styles.resendQRButton} />
                </IconButton>
            </Tooltip>
            <Tooltip arrow title={<h1>{t('text.update')}</h1>}>
                <IconButton onClick={() => history.push(routePath.updateAdmin.create(admin.id))}>
                    <BiEdit style={styles.updateButton} />
                </IconButton>
            </Tooltip>
            <Tooltip arrow title={<h1>{t('text.delete')}</h1>}>
                <IconButton
                    onClick={() =>
                        openDelete({
                            itemToDeleteId: admin.id,
                            itemToDeleteName: `${admin.user.firstName} ${admin.user.lastName}`,
                        })
                    }
                >
                    <BiTrash style={styles.deleteButton} />
                </IconButton>
            </Tooltip>
        </div>
    );
};
