import { IAmbassadorWorkedHour } from '../hooks/fetch-ambassador-declaration-period/types/ambassador-worked-hour.interface';
import { IAmbassadorCandidateWorkedHour } from '../hooks/fetch-ambassador-declaration-period/types/ambassador-candidate-worked-hour.interface';

export const getAmbassadorCandidateWorkedHoursByTier = (
    ambassadorWorkedHour: IAmbassadorWorkedHour,
    tier: number
): IAmbassadorCandidateWorkedHour[] => {
    return ambassadorWorkedHour.ambassadorCandidateWorkedHours.filter(
        (ambassadorCandidate) => ambassadorCandidate.tier === tier
    );
};
