import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useApi } from '../../../../../../helpers';
import { IProfessionGroup } from '../../../hooks';
import { IUpdateProfessionGroupVariables } from './types';

export const useUpdateProfessionGroup = (professionGroupId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<IProfessionGroup>, any, IUpdateProfessionGroupVariables>(
        ({ body }) => api.patch(`/profession-groups/${professionGroupId}`, body)
    );
};
