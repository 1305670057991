/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { formatNumber } from '../../../helpers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import {
    IHoursAmbassador,
    IHoursCandidate,
    IHoursAmbassadorHours,
    IHoursCandidateHours,
} from '../../../pages/dashboards/hooks/fetch-hours/types';

interface IProps {
    ambassador: IHoursAmbassador;
    year: number;
}

export const HoursAmbassadorTable = ({ ambassador, year }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { t } = useTranslation();

    const weeks = Array.from(
        { length: moment({ year, day: 1, month: 1 }).isoWeeksInYear() },
        (_value, index) => index + 1
    );

    const getSecondRowCellStyle = React.useCallback(
        (weekNumber: number) => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            if (ambassadorHour && ambassadorHour.isHoursAmountSatisfies) {
                return [styles.secondRowHourCells, styles.cellGreen];
            } else if (ambassadorHour && !ambassadorHour.isHoursAmountSatisfies) {
                return [styles.secondRowHourCells, styles.cellOrange];
            } else {
                return [styles.secondRowHourCells, styles.cellYellow];
            }
        },
        [
            ambassador.hours,
            styles.secondRowHourCells,
            styles.cellGreen,
            styles.cellOrange,
            styles.cellYellow,
        ]
    );

    const getSecondRowCellValue = React.useCallback(
        (weekNumber: number): string => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            return ambassadorHour ? formatNumber(ambassadorHour.amount) : formatNumber(0);
        },
        [ambassador.hours]
    );

    const getCandidateHourRowCellStyle = React.useCallback(
        (weekNumber: number, candidate: IHoursCandidate) => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            const candidateHour = candidate.hours[weekNumber as keyof IHoursCandidateHours];
            if (
                ambassadorHour &&
                candidateHour &&
                ambassadorHour.isHoursAmountSatisfies &&
                candidateHour.isHoursAmountSatisfies
            ) {
                return [styles.candidateRowHourCells, styles.cellGreen];
            } else if (
                ambassadorHour &&
                candidateHour &&
                (!ambassadorHour.isHoursAmountSatisfies || !candidateHour.isHoursAmountSatisfies)
            ) {
                return [styles.candidateRowHourCells, styles.cellOrange];
            } else {
                return [styles.candidateRowHourCells, styles.cellYellow];
            }
        },
        [
            ambassador.hours,
            styles.candidateRowHourCells,
            styles.cellGreen,
            styles.cellOrange,
            styles.cellYellow,
        ]
    );

    const getCandidateHourRowCellValue = React.useCallback(
        (weekNumber: number, candidate: IHoursCandidate): string => {
            const candidateHour = candidate.hours[weekNumber as keyof IHoursCandidateHours];
            return candidateHour ? formatNumber(candidateHour.amount) : formatNumber(0);
        },
        []
    );

    const getTotalHoursTierRowCellStyle = React.useCallback(
        (weekNumber: number, tier: number) => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            switch (tier) {
                case 1:
                    return ambassadorHour
                        ? styles.totalHoursFirstTierRowAmountCells
                        : [styles.totalHoursFirstTierRowAmountCells, styles.cellYellow];
                case 2:
                    return ambassadorHour
                        ? styles.totalHoursSecondTierRowAmountCells
                        : [styles.totalHoursSecondTierRowAmountCells, styles.cellYellow];
                case 3:
                    return ambassadorHour
                        ? styles.totalHoursThirdTierRowAmountCells
                        : [styles.totalHoursThirdTierRowAmountCells, styles.cellYellow];
                default:
                    return ambassadorHour
                        ? styles.totalHoursFirstTierRowAmountCells
                        : [styles.totalHoursFirstTierRowAmountCells, styles.cellYellow];
            }
        },
        [
            ambassador.hours,
            styles.totalHoursFirstTierRowAmountCells,
            styles.totalHoursSecondTierRowAmountCells,
            styles.totalHoursThirdTierRowAmountCells,
            styles.cellYellow,
        ]
    );

    const getTotalHoursTierRowCellValue = React.useCallback(
        (weekNumber: number, tier: number): string => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            switch (tier) {
                case 1:
                    return ambassadorHour
                        ? formatNumber(ambassadorHour.firstTierTotalAmountOfCandidateHours)
                        : formatNumber(0);
                case 2:
                    return ambassadorHour
                        ? formatNumber(ambassadorHour.secondTierTotalAmountOfCandidateHours)
                        : formatNumber(0);
                case 3:
                    return ambassadorHour
                        ? formatNumber(ambassadorHour.thirdTierTotalAmountOfCandidateHours)
                        : formatNumber(0);
                default:
                    return ambassadorHour
                        ? formatNumber(ambassadorHour.firstTierTotalAmountOfCandidateHours)
                        : formatNumber(0);
            }
        },
        [ambassador.hours]
    );

    const getStatusRowCellStyle = React.useCallback(
        (weekNumber: number) => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            return ambassadorHour
                ? styles.statusRowStatusCells
                : [styles.statusRowStatusCells, styles.cellYellow];
        },
        [ambassador.hours, styles.statusRowStatusCells, styles.cellYellow]
    );

    const getStatusRowCellValue = React.useCallback(
        (weekNumber: number): string => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            if (ambassadorHour && ambassadorHour.isProcessed) {
                return t('text.frozen');
            } else if (ambassadorHour && !ambassadorHour.isProcessed) {
                return t('text.draft');
            } else {
                return '';
            }
        },
        [ambassador.hours, t]
    );

    const getPaidFeeRowCellStyles = React.useCallback(
        (weekNumber: number) => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            return ambassadorHour
                ? styles.paidFeeRowAmountCells
                : [styles.paidFeeRowAmountCells, styles.cellYellow];
        },
        [ambassador.hours, styles.paidFeeRowAmountCells, styles.cellYellow]
    );

    const getPaidFeeRowCellValue = React.useCallback(
        (weekNumber: number): string => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            return ambassadorHour
                ? `€${formatNumber(ambassadorHour.paidFeeAmount)}`
                : `€${formatNumber(0)}`;
        },
        [ambassador.hours]
    );

    const getPaidFeeDateRowCellStyle = React.useCallback(
        (weekNumber: number) => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            return ambassadorHour
                ? styles.weekFeePaidRowWeekCells
                : [styles.weekFeePaidRowWeekCells, styles.cellYellow];
        },
        [ambassador.hours, styles.weekFeePaidRowWeekCells, styles.cellYellow]
    );

    const getPaidFeeDateRowCellValue = React.useCallback(
        (weekNumber: number): string => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            return ambassadorHour && ambassadorHour.dateFeePaid
                ? `wk ${moment(ambassadorHour.dateFeePaid).isoWeek()}`
                : '';
        },
        [ambassador.hours]
    );

    return (
        <div css={styles.root}>
            <div css={styles.wrapper}>
                <table css={styles.table}>
                    <tbody style={styles.tableBody}>
                        <tr css={styles.firstRow}>
                            <td css={styles.firstRowFirstCell}>{t('text.ambassador')}</td>
                            <td css={styles.firstRowSecondCell} colSpan={2}>
                                {t('text.status')}
                            </td>
                            {weeks.map((weekNumber) => (
                                <td
                                    key={weekNumber}
                                    css={styles.firstRowFirstRowHourCells}
                                >{`wk ${weekNumber}`}</td>
                            ))}
                        </tr>
                        <tr css={styles.secondRow}>
                            <td
                                css={styles.secondRowFirstCell}
                            >{`${ambassador.name} - ${ambassador.employeeNumber}`}</td>
                            <td css={styles.secondRowSecondCell} colSpan={2}>
                                {ambassador.isActive ? t('text.active') : t('text.inactive')}
                            </td>
                            {weeks.map((weekNumber) => (
                                <td key={weekNumber} css={getSecondRowCellStyle(weekNumber)}>
                                    {getSecondRowCellValue(weekNumber)}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.thirdRow}>
                            <td css={styles.thirdRowFirstCell}>{t('text.candidate')}</td>
                            <td css={styles.thirdRowSecondCell}>{t('text.status')}</td>
                            <td css={styles.thirdRowThirdCell}>{t('text.tier')}</td>
                            <td css={styles.thirdRowFourthCell} colSpan={weeks.length}>
                                {''}
                            </td>
                        </tr>
                        {ambassador.hoursCandidates.length < 1 && (
                            <tr css={styles.noCandidateRow}>
                                <td css={styles.noCandidateRowFirstCell} colSpan={3}>
                                    {t('text.noCandidates')}
                                </td>
                                <td
                                    css={styles.noCandidateRowSecondCell}
                                    colSpan={weeks.length}
                                ></td>
                            </tr>
                        )}
                        {ambassador.hoursCandidates.map((candidate, index) => (
                            <tr key={index} css={styles.candidateRow}>
                                <td css={styles.candidateRowFirstCell}>
                                    {candidate.employeeNumber
                                        ? `${candidate.name} - ${candidate.employeeNumber}`
                                        : candidate.name}
                                </td>
                                <td css={styles.candidateRowSecondCell}>
                                    {t(`candidateStatus.${candidate.status}`)}
                                </td>
                                <td css={styles.candidateRowThridCell}>{candidate.tier}</td>
                                {weeks.map((weekNumber) => (
                                    <td
                                        key={weekNumber}
                                        css={getCandidateHourRowCellStyle(weekNumber, candidate)}
                                    >
                                        {getCandidateHourRowCellValue(weekNumber, candidate)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr css={styles.firstEmptyRow}>
                            <td css={styles.firstEmptyRowFirstCell}>{t('text.totalHours')}</td>
                            <td css={styles.firstEmptyRowSecondCell} colSpan={2 + weeks.length}>
                                {''}
                            </td>
                        </tr>
                        <tr css={styles.totalHoursFirstTierRow}>
                            <td css={styles.totalHoursFirstTierRowFirstCell} colSpan={2}>
                                {t('text.totalHours')}
                            </td>
                            <td css={styles.totalHoursFirstTierRowSecondCell}>1</td>
                            {weeks.map((weekNumber) => (
                                <td
                                    key={weekNumber}
                                    css={getTotalHoursTierRowCellStyle(weekNumber, 1)}
                                >
                                    {getTotalHoursTierRowCellValue(weekNumber, 1)}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.totalHoursSecondTierRow}>
                            <td css={styles.totalHoursSecondTierRowFirstCell} colSpan={2}>
                                {t('text.totalHours')}
                            </td>
                            <td css={styles.totalHoursSecondTierRowSecondCell}>2</td>
                            {weeks.map((weekNumber) => (
                                <td
                                    key={weekNumber}
                                    css={getTotalHoursTierRowCellStyle(weekNumber, 2)}
                                >
                                    {getTotalHoursTierRowCellValue(weekNumber, 2)}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.totalHoursThirdTierRow}>
                            <td css={styles.totalHoursThirdTierRowFirstCell} colSpan={2}>
                                {t('text.totalHours')}
                            </td>
                            <td css={styles.totalHoursThirdTierRowSecondCell}>3</td>
                            {weeks.map((weekNumber) => (
                                <td
                                    key={weekNumber}
                                    css={getTotalHoursTierRowCellStyle(weekNumber, 3)}
                                >
                                    {getTotalHoursTierRowCellValue(weekNumber, 3)}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.statusRow}>
                            <td css={styles.statusRowFirstCell} colSpan={3}>
                                {t('text.statusHours')}
                            </td>
                            {weeks.map((weekNumber) => (
                                <td key={weekNumber} css={getStatusRowCellStyle(weekNumber)}>
                                    {getStatusRowCellValue(weekNumber)}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.secondEmptyRow}>
                            <td css={styles.secondEmptyRowFirstCell}>{t('text.paidFee')}</td>
                            <td css={styles.secondEmptyRowSecondCell} colSpan={2 + weeks.length}>
                                {''}
                            </td>
                        </tr>
                        <tr css={styles.paidFeeRow}>
                            <td css={styles.paidFeeRowFirstCell} colSpan={3}>
                                {t('text.paidFeeAmount')}
                            </td>
                            {weeks.map((weekNumber) => (
                                <td key={weekNumber} css={getPaidFeeRowCellStyles(weekNumber)}>
                                    {getPaidFeeRowCellValue(weekNumber)}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.weekFeePaidRow}>
                            <td css={styles.weekFeePaidRowFirstCell} colSpan={3}>
                                {t('text.weekFeePaid')}
                            </td>
                            {weeks.map((weekNumber) => (
                                <td key={weekNumber} css={getPaidFeeDateRowCellStyle(weekNumber)}>
                                    {getPaidFeeDateRowCellValue(weekNumber)}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
