import moment from 'moment';
import { ICandidate } from '../hooks';

export const isCandidateActive = (canidate: ICandidate): boolean => {
    let result = false;
    const year = moment().year();
    const week = moment().isoWeek();

    canidate.activityPeriods.forEach((activityPeriod) => {
        if (
            (!activityPeriod.endYear &&
                !activityPeriod.endWeek &&
                ((activityPeriod.startYear === year && activityPeriod.startWeek <= week) ||
                    activityPeriod.startYear < year)) ||
            (activityPeriod.endYear &&
                activityPeriod.endWeek &&
                ((activityPeriod.startYear === year && activityPeriod.startWeek <= week) ||
                    activityPeriod.startYear < year) &&
                ((activityPeriod.endYear === year && activityPeriod.endWeek >= week) ||
                    activityPeriod.endYear > year))
        ) {
            result = true;
        }
    });
    return result;
};
