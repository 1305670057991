/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useAcceptTerms } from './hooks';
import { Language, SocialMediaLink, routePath, colors, handleError } from '../../../helpers';
import { useHistory, Link } from 'react-router-dom';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { LanguageMenu, CheckboxInput, Button } from '../../../components';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import { English, Dutch } from '../contents';

export const AcceptTerms = () => {
    const widthBelow200px = useMediaQuery('(max-width: 200px)');
    const styles = useStyles(widthBelow200px);
    const history = useHistory();
    const { i18n, t } = useTranslation();

    const acceptTerms = useAcceptTerms();

    React.useEffect(() => {
        const timer = setTimeout(() => {
            localStorage.removeItem('lvid');
            history.push(routePath.login.create());
        }, 3 * 60 * 1000 - 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [history]);

    return (
        <div style={styles.root}>
            <div style={styles.header}>
                <h1 css={styles.headerText}>Oranjelink</h1>
                <LanguageMenu />
            </div>
            <Formik
                initialValues={
                    {
                        terms: false,
                    } as IFormValues
                }
                validate={({ terms }) => {
                    let errors: FormikErrors<IFormValues> = {};
                    if (!terms) {
                        errors.terms = t('form.error.needToAgreeWithGeneralTerms');
                    }
                    return errors;
                }}
                onSubmit={async ({ terms }, { setErrors }) => {
                    const token = localStorage.getItem('lvid');
                    if (token) {
                        try {
                            await acceptTerms.mutateAsync({
                                body: {
                                    token,
                                    areTermsAccepted: terms,
                                },
                            });
                            history.push(routePath.verifyLogin.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 404) {
                                    history.push(routePath.notFoundVerifyLoginError.create());
                                } else if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedVerifyLoginError.create());
                                } else if (status === 400) {
                                    setErrors({
                                        terms: t('form.error.needToAgreeWithGeneralTerms'),
                                    });
                                } else if (status === 500) {
                                    history.push(routePath.internalServerVerifyLoginError.create());
                                } else alert(statusText);
                            });
                        }
                    } else {
                        history.push(routePath.unauthorizedVerifyLoginError.create());
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form noValidate style={styles.form}>
                        <div style={styles.formTermsContainer}>
                            {i18n.language === Language.en && <English />}
                            {i18n.language === Language.nl && <Dutch />}
                            {i18n.language === Language.ro && <English />}
                            {i18n.language === Language.pl && <English />}
                            {i18n.language === Language.hr && <English />}
                            {i18n.language === Language.pt && <English />}
                            {i18n.language === Language.hu && <English />}
                        </div>
                        <Field
                            style={styles.formCheckboxField}
                            name="terms"
                            label={t('form.label.acceptGeneralTerms')}
                            component={CheckboxInput}
                        />
                        <Button
                            css={styles.formButton}
                            type="submit"
                            disabled={isSubmitting}
                            spinner={isSubmitting}
                            cs={{
                                color: colors.blue,
                            }}
                        >
                            {t('text.accept')}
                        </Button>
                        <Button
                            css={styles.formButton}
                            type="button"
                            disabled={isSubmitting}
                            spinner={isSubmitting}
                            cs={{
                                color: colors.blue,
                            }}
                            onClick={() => {
                                localStorage.removeItem('lvid');
                                history.push(routePath.login.create());
                            }}
                        >
                            {t('text.back')}
                        </Button>
                    </Form>
                )}
            </Formik>
            <div style={styles.footer}>
                <div style={styles.footerLinkBox}>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillFacebook />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillInstagram />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.LinkedIn}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillLinkedin />
                    </a>
                </div>
                <div style={styles.footerRightContainer}>
                    <div>
                        <Link css={styles.footerRightContainerLink} to={routePath.terms.create()}>
                            {t('text.generalTerms')}
                        </Link>
                        &nbsp;/&nbsp;
                        <Link
                            css={styles.footerRightContainerLink}
                            to={routePath.privacyPolicy.create()}
                        >
                            {t('text.privacyPolicy')}
                        </Link>
                    </div>
                    <p>
                        {`De Oranjegroep \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop`}
                    </p>
                </div>
            </div>
        </div>
    );
};
