/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle, IElementSxProps } from './types';
import { TextField, TextFieldProps, InputAdornment, Fade } from '@mui/material';
import { FieldProps } from 'formik';
import { Spinner } from '../../spinner/Spinner';
import { colors } from '../../../helpers';

interface IProps {
    spinner: boolean;
    icon?: JSX.Element;
    noPaste?: boolean;
    cs?: ICustomStyle;
    esx?: IElementSxProps;
    es?: IElementStyle;
    style?: React.CSSProperties;
    className?: string;
}

export const TextFieldInput = ({
    type,
    label,
    placeholder,
    autoFocus,
    disabled,
    cs,
    esx,
    es,
    spinner,
    icon,
    noPaste,
    style,
    className,
    field,
    form: { errors, touched, handleChange, handleBlur },
}: IProps & TextFieldProps & FieldProps) => {
    const styles = useStyles(type, field, errors, touched, cs);

    return (
        <TextField
            {...field}
            css={
                es && es.textField
                    ? [es.textField as Interpolation<Theme>, style as Interpolation<Theme>]
                    : (style as Interpolation<Theme>)
            }
            className={className}
            variant="standard"
            type={type}
            label={label}
            placeholder={placeholder}
            autoFocus={autoFocus}
            autoComplete="new-password"
            disabled={disabled ? disabled : false}
            fullWidth
            sx={esx && esx.textField ? { ...styles.textField, ...esx.textField } : styles.textField}
            inputProps={{
                role: 'presentation',
                autoComplete: 'new-password',
                spellCheck: false,
                'aria-live': 'polite',
                disabled: disabled ? disabled : false,
            }}
            error={Boolean(errors[field.name]) && Boolean(touched[field.name])}
            helperText={
                Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? errors[field.name]
                    : ' '
            }
            onChange={(event) => {
                handleChange(event);
            }}
            onBlur={(event) => {
                handleBlur(event);
            }}
            onPaste={(event) => {
                if (noPaste) {
                    event.preventDefault();
                }
            }}
            onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
            }}
            InputProps={
                icon
                    ? {
                          startAdornment: (
                              <InputAdornment
                                  position="start"
                                  css={
                                      es && es.startAdornment
                                          ? (es.startAdornment as Interpolation<Theme>)
                                          : undefined
                                  }
                                  sx={
                                      esx && esx.startAdornment
                                          ? { ...styles.startAdornment, ...esx.startAdornment }
                                          : styles.startAdornment
                                  }
                              >
                                  {icon}
                              </InputAdornment>
                          ),
                          endAdornment: (
                              <InputAdornment
                                  position="end"
                                  css={
                                      es && es.endAdornment
                                          ? (es.endAdornment as Interpolation<Theme>)
                                          : undefined
                                  }
                                  sx={
                                      esx && esx.endAdornment
                                          ? { ...styles.endAdornment, ...esx.endAdornment }
                                          : styles.endAdornment
                                  }
                              >
                                  <Fade in={spinner}>
                                      <Spinner
                                          variant="rond"
                                          cs={{
                                              color:
                                                  cs && cs.inputColor ? cs.inputColor : colors.blue,
                                              radius: cs && cs.fontSize ? cs.fontSize : '2rem',
                                          }}
                                      />
                                  </Fade>
                              </InputAdornment>
                          ),
                      }
                    : {
                          endAdornment: (
                              <InputAdornment
                                  position="end"
                                  css={
                                      es && es.endAdornment
                                          ? (es.endAdornment as Interpolation<Theme>)
                                          : undefined
                                  }
                                  sx={
                                      esx && esx.endAdornment
                                          ? { ...styles.endAdornment, ...esx.endAdornment }
                                          : styles.endAdornment
                                  }
                              >
                                  <Fade in={spinner}>
                                      <Spinner
                                          variant="rond"
                                          cs={{
                                              color:
                                                  cs && cs.inputColor ? cs.inputColor : colors.blue,
                                              radius: cs && cs.fontSize ? cs.fontSize : '2rem',
                                          }}
                                      />
                                  </Fade>
                              </InputAdornment>
                          ),
                      }
            }
            InputLabelProps={{ shrink: label ? true : false }}
        />
    );
};
