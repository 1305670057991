/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useProcessAmbassadorDeclarationPeriods } from './hooks';
import { IAmbassadorDeclarationPeriod } from '../hooks';
import { Cache, colors, handleError, routePath } from '../../../helpers';
import {
    useQueryClient,
    RefetchOptions,
    RefetchQueryFilters,
    QueryObserverResult,
} from 'react-query';
import { ISnackActionPayload } from '../../../helpers/reducers/snack/types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Button } from '../../../components';

interface IProps {
    isCreated: boolean;
    year: number;
    week: number;
    isPageSubmitting: boolean;
    setPageSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
    openSnack: (payload: ISnackActionPayload) => void;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<IAmbassadorDeclarationPeriod[], any>>;
}

export const Card = ({
    isCreated,
    year,
    week,
    isPageSubmitting,
    setPageSubmitting,
    openSnack,
    refetch,
}: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(isCreated, widthBelow600px);
    const history = useHistory();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const processPeriods = useProcessAmbassadorDeclarationPeriods();

    return (
        <div style={styles.root}>
            <p style={styles.year}>{year}</p>
            <p style={styles.week}>{t('text.week')}</p>
            <p style={styles.weekNumber}>{week}</p>
            {isCreated ? (
                <Button
                    style={styles.button}
                    spinner={processPeriods.isLoading}
                    disabled={isPageSubmitting}
                    cs={{ color: colors.blue, fontSize: '1rem', padding: '1rem 1rem' }}
                    onClick={() =>
                        history.push(
                            routePath.ambassadorDeclarationPeriod.create(`${year}-${week}`)
                        )
                    }
                >
                    {t('text.open')}
                </Button>
            ) : (
                <Button
                    style={styles.button}
                    spinner={processPeriods.isLoading}
                    disabled={isPageSubmitting}
                    cs={{ color: colors.blue, fontSize: '1rem', padding: '1rem 1rem' }}
                    onClick={async () => {
                        setPageSubmitting(true);
                        try {
                            const { data: period } = await processPeriods.mutateAsync({
                                body: {
                                    year,
                                    week,
                                },
                            });
                            queryClient.setQueriesData<IAmbassadorDeclarationPeriod[]>(
                                [Cache.ambassadorDeclarationPeriods, year],
                                (periods) => {
                                    if (periods) {
                                        return periods.concat(period);
                                    }
                                    return [];
                                }
                            );
                            setPageSubmitting(false);
                        } catch (e) {
                            handleError(e, async ({ status, statusText }) => {
                                if (status === 401 || status === 403) {
                                    setPageSubmitting(false);
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 400) {
                                    setPageSubmitting(false);
                                    openSnack({
                                        snackVariant: 'error',
                                        snackMessage: t('form.error.failedToCreatePeriod'),
                                    });
                                    await refetch();
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else {
                                    setPageSubmitting(false);
                                    alert(statusText);
                                }
                            });
                        }
                    }}
                >
                    {t('text.create')}
                </Button>
            )}
        </div>
    );
};
