/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { formatNumber } from '../../../helpers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import {
    IHoursAmbassador,
    IHoursCandidate,
    IHoursAmbassadorHours,
    IHoursCandidateHours,
} from '../../../pages/dashboards/hooks/fetch-hours/types';

interface IProps {
    ambassadors: IHoursAmbassador[];
    year: number;
}

export const HoursAmbassadorsTable = ({ ambassadors, year }: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { t } = useTranslation();

    const weeks = Array.from(
        { length: moment({ year, day: 1, month: 1 }).isoWeeksInYear() },
        (_value, index) => index + 1
    );

    const getSecondRowCellStyle = React.useCallback(
        (weekNumber: number, ambassador: IHoursAmbassador) => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            if (ambassadorHour && ambassadorHour.isHoursAmountSatisfies) {
                return [styles.secondRowHourCells, styles.cellGreen];
            } else if (ambassadorHour && !ambassadorHour.isHoursAmountSatisfies) {
                return [styles.secondRowHourCells, styles.cellOrange];
            } else {
                return [styles.secondRowHourCells, styles.cellYellow];
            }
        },
        [styles.secondRowHourCells, styles.cellGreen, styles.cellOrange, styles.cellYellow]
    );

    const getSecondRowCellValue = React.useCallback(
        (weekNumber: number, ambassador: IHoursAmbassador): string => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            return ambassadorHour ? formatNumber(ambassadorHour.amount) : formatNumber(0);
        },
        []
    );

    const getCandidateHourRowCellStyle = React.useCallback(
        (weekNumber: number, ambassador: IHoursAmbassador, candidate: IHoursCandidate) => {
            const ambassadorHour = ambassador.hours[weekNumber as keyof IHoursAmbassadorHours];
            const candidateHour = candidate.hours[weekNumber as keyof IHoursCandidateHours];
            if (
                ambassadorHour &&
                candidateHour &&
                ambassadorHour.isHoursAmountSatisfies &&
                candidateHour.isHoursAmountSatisfies
            ) {
                return [styles.candidateRowHourCells, styles.cellGreen];
            } else if (
                ambassadorHour &&
                candidateHour &&
                (!ambassadorHour.isHoursAmountSatisfies || !candidateHour.isHoursAmountSatisfies)
            ) {
                return [styles.candidateRowHourCells, styles.cellOrange];
            } else {
                return [styles.candidateRowHourCells, styles.cellYellow];
            }
        },
        [styles.candidateRowHourCells, styles.cellGreen, styles.cellOrange, styles.cellYellow]
    );

    const getCandidateHourRowCellValue = React.useCallback(
        (weekNumber: number, candidate: IHoursCandidate): string => {
            const candidateHour = candidate.hours[weekNumber as keyof IHoursCandidateHours];
            return candidateHour ? formatNumber(candidateHour.amount) : formatNumber(0);
        },
        []
    );

    const getTotalAmountsHoursCellStyle = React.useCallback(
        (tier: number, total: string) => {
            switch (tier) {
                case 1:
                    return total === '-1'
                        ? [styles.totalHoursFirstTierRowAmountCells, styles.cellYellow]
                        : styles.totalHoursFirstTierRowAmountCells;
                case 2:
                    return total === '-1'
                        ? [styles.totalHoursSecondTierRowAmountCells, styles.cellYellow]
                        : styles.totalHoursSecondTierRowAmountCells;
                case 3:
                    return total === '-1'
                        ? [styles.totalHoursThirdTierRowAmountCells, styles.cellYellow]
                        : styles.totalHoursThirdTierRowAmountCells;
            }
        },
        [
            styles.totalHoursFirstTierRowAmountCells,
            styles.totalHoursSecondTierRowAmountCells,
            styles.totalHoursThirdTierRowAmountCells,
            styles.cellYellow,
        ]
    );

    const getTotalAmountsHours = React.useCallback(
        (tier: number): string[] => {
            const result: string[] = [];
            for (let i = 1; i <= weeks.length; i++) {
                let total = -1;
                switch (tier) {
                    case 1:
                        ambassadors.forEach((ambassador) => {
                            const ambassadorHour =
                                ambassador.hours[i as keyof IHoursAmbassadorHours];
                            if (ambassadorHour) {
                                if (total === -1) {
                                    total = 0;
                                }
                                total += ambassadorHour.firstTierTotalAmountOfCandidateHours;
                            }
                        });
                        break;
                    case 2:
                        ambassadors.forEach((ambassador) => {
                            const ambassadorHour =
                                ambassador.hours[i as keyof IHoursAmbassadorHours];
                            if (ambassadorHour) {
                                if (total === -1) {
                                    total = 0;
                                }
                                total += ambassadorHour.secondTierTotalAmountOfCandidateHours;
                            }
                        });
                        break;
                    case 3:
                        ambassadors.forEach((ambassador) => {
                            const ambassadorHour =
                                ambassador.hours[i as keyof IHoursAmbassadorHours];
                            if (ambassadorHour) {
                                if (total === -1) {
                                    total = 0;
                                }
                                total += ambassadorHour.thirdTierTotalAmountOfCandidateHours;
                            }
                        });
                        break;
                }
                result.push(formatNumber(total));
            }
            return result;
        },
        [ambassadors, weeks.length]
    );

    const getStatusCellStyle = React.useCallback(
        (weekNumber: number) => {
            const ambassadorHour = ambassadors[0].hours[weekNumber as keyof IHoursAmbassadorHours];
            if (ambassadorHour && (ambassadorHour.isProcessed || !ambassadorHour.isProcessed)) {
                return styles.statusRowStatusCells;
            } else {
                return [styles.statusRowStatusCells, styles.cellYellow];
            }
        },
        [ambassadors, styles.statusRowStatusCells, styles.cellYellow]
    );

    const getStatus = React.useCallback(
        (weekNumber: number): string => {
            const ambassadorHour = ambassadors[0].hours[weekNumber as keyof IHoursAmbassadorHours];
            if (ambassadorHour && ambassadorHour.isProcessed) {
                return t('text.frozen');
            } else if (ambassadorHour && !ambassadorHour.isProcessed) {
                return t('text.draft');
            } else {
                return '';
            }
        },
        [ambassadors, t]
    );

    const getTotalAmountsPaidFeeCellStyle = React.useCallback(
        (amount: string) => {
            if (amount === '-1') {
                return [styles.paidFeeRowAmountCells, styles.cellYellow];
            } else {
                return styles.paidFeeRowAmountCells;
            }
        },
        [styles.paidFeeRowAmountCells, styles.cellYellow]
    );

    const getTotalAmountsPaidFee = React.useCallback((): string[] => {
        const result: string[] = [];
        for (let i = 1; i <= weeks.length; i++) {
            let total = -1;
            ambassadors.forEach((ambassador) => {
                const ambassadorHour = ambassador.hours[i as keyof IHoursAmbassadorHours];
                if (ambassadorHour) {
                    if (total === -1) {
                        total = 0;
                    }
                    total += ambassadorHour.paidFeeAmount;
                }
            });
            result.push(formatNumber(total));
        }
        return result;
    }, [ambassadors, weeks.length]);

    const getWeeksFeePaidCellStyle = React.useCallback(
        (week: string) => {
            if (week === '-1') {
                return [styles.weekFeePaidRowWeekCells, styles.cellYellow];
            } else {
                return styles.weekFeePaidRowWeekCells;
            }
        },
        [styles.weekFeePaidRowWeekCells, styles.cellYellow]
    );

    const getWeeksFeePaid = React.useCallback((): string[] => {
        const result: string[] = [];
        for (let i = 1; i <= weeks.length; i++) {
            let week = '';
            let isAnyAmbassadorHour = false;
            ambassadors.forEach((ambassador) => {
                const ambassadorHour = ambassador.hours[i as keyof IHoursAmbassadorHours];
                if (ambassadorHour) {
                    isAnyAmbassadorHour = true;
                    if (ambassadorHour.dateFeePaid) {
                        if (!week) {
                            week = `wk ${moment(
                                ambassadorHour.dateFeePaid,
                                'YYYY-MM-DD'
                            ).isoWeek()}`;
                        } else if (
                            moment(week, 'YYYY-MM-DD').isoWeek() >
                            moment(ambassadorHour.dateFeePaid, 'YYYY-MM-DD').isoWeek()
                        ) {
                            week = `wk ${moment(
                                ambassadorHour.dateFeePaid,
                                'YYYY-MM-DD'
                            ).isoWeek()}`;
                        }
                    }
                }
            });
            if (!isAnyAmbassadorHour) {
                week = '-1';
            }
            result.push(week);
        }
        return result;
    }, [ambassadors, weeks.length]);

    return (
        <div css={styles.root}>
            <div css={styles.wrapper}>
                <table css={styles.table}>
                    <tbody style={styles.tableBody}>
                        {ambassadors.map((ambassador, ambassadorindex) => (
                            <React.Fragment key={ambassadorindex}>
                                <tr css={styles.firstRow}>
                                    <td css={styles.firstRowFirstCell}>{t('text.ambassador')}</td>
                                    <td css={styles.firstRowSecondCell} colSpan={2}>
                                        {t('text.status')}
                                    </td>
                                    {weeks.map((weekNumber) => (
                                        <td
                                            key={weekNumber}
                                            css={styles.firstRowFirstRowHourCells}
                                        >{`wk ${weekNumber}`}</td>
                                    ))}
                                </tr>
                                <tr css={styles.secondRow}>
                                    <td
                                        css={styles.secondRowFirstCell}
                                    >{`${ambassador.name} - ${ambassador.employeeNumber}`}</td>
                                    <td css={styles.secondRowSecondCell} colSpan={2}>
                                        {ambassador.isActive
                                            ? t('text.active')
                                            : t('text.inactive')}
                                    </td>
                                    {weeks.map((weekNumber) => (
                                        <td
                                            key={weekNumber}
                                            css={getSecondRowCellStyle(weekNumber, ambassador)}
                                        >
                                            {getSecondRowCellValue(weekNumber, ambassador)}
                                        </td>
                                    ))}
                                </tr>
                                <tr css={styles.thirdRow}>
                                    <td css={styles.thirdRowFirstCell}>{t('text.candidate')}</td>
                                    <td css={styles.thirdRowSecondCell}>{t('text.status')}</td>
                                    <td css={styles.thirdRowThirdCell}>{t('text.tier')}</td>
                                    <td css={styles.thirdRowFourthCell} colSpan={weeks.length}>
                                        {''}
                                    </td>
                                </tr>
                                {ambassador.hoursCandidates.length < 1 && (
                                    <tr css={styles.noCandidateRow}>
                                        <td css={styles.noCandidateRowFirstCell} colSpan={3}>
                                            {t('text.noCandidates')}
                                        </td>
                                        <td
                                            css={styles.noCandidateRowSecondCell}
                                            colSpan={weeks.length}
                                        ></td>
                                    </tr>
                                )}
                                {ambassador.hoursCandidates.map((candidate, candidateIndex) => (
                                    <tr key={candidateIndex} css={styles.candidateRow}>
                                        <td css={styles.candidateRowFirstCell}>
                                            {candidate.employeeNumber
                                                ? `${candidate.name} - ${candidate.employeeNumber}`
                                                : candidate.name}
                                        </td>
                                        <td css={styles.candidateRowSecondCell}>
                                            {t(`candidateStatus.${candidate.status}`)}
                                        </td>
                                        <td css={styles.candidateRowThridCell}>{candidate.tier}</td>
                                        {weeks.map((weeknumber) => (
                                            <td
                                                key={weeknumber}
                                                css={getCandidateHourRowCellStyle(
                                                    weeknumber,
                                                    ambassador,
                                                    candidate
                                                )}
                                            >
                                                {getCandidateHourRowCellValue(
                                                    weeknumber,
                                                    candidate
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                        <tr css={styles.firstEmptyRow}>
                            <td css={styles.firstEmptyRowFirstCell}>{t('text.totalHours')}</td>
                            <td css={styles.firstEmptyRowSecondCell} colSpan={2 + weeks.length}>
                                {''}
                            </td>
                        </tr>
                        <tr css={styles.totalHoursFirstTierRow}>
                            <td css={styles.totalHoursFirstTierRowFirstCell} colSpan={2}>
                                {t('text.totalHours')}
                            </td>
                            <td css={styles.totalHoursFirstTierRowSecondCell}>1</td>
                            {getTotalAmountsHours(1).map((total, i) => (
                                <td key={i} css={getTotalAmountsHoursCellStyle(1, total)}>
                                    {total === '-1' ? formatNumber(0) : total}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.totalHoursSecondTierRow}>
                            <td css={styles.totalHoursSecondTierRowFirstCell} colSpan={2}>
                                {t('text.totalHours')}
                            </td>
                            <td css={styles.totalHoursSecondTierRowSecondCell}>2</td>
                            {getTotalAmountsHours(2).map((total, i) => (
                                <td key={i} css={getTotalAmountsHoursCellStyle(2, total)}>
                                    {total === '-1' ? formatNumber(0) : total}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.totalHoursThirdTierRow}>
                            <td css={styles.totalHoursThirdTierRowFirstCell} colSpan={2}>
                                {t('text.totalHours')}
                            </td>
                            <td css={styles.totalHoursThirdTierRowSecondCell}>3</td>
                            {getTotalAmountsHours(3).map((total, i) => (
                                <td key={i} css={getTotalAmountsHoursCellStyle(3, total)}>
                                    {total === '-1' ? formatNumber(0) : total}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.statusRow}>
                            <td css={styles.statusRowFirstCell} colSpan={3}>
                                {t('text.statusHours')}
                            </td>
                            {weeks.map((weekNumber) => (
                                <td key={weekNumber} css={getStatusCellStyle(weekNumber)}>
                                    {getStatus(weekNumber)}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.secondEmptyRow}>
                            <td css={styles.secondEmptyRowFirstCell}>{t('text.paidFee')}</td>
                            <td css={styles.secondEmptyRowSecondCell} colSpan={2 + weeks.length}>
                                {''}
                            </td>
                        </tr>
                        <tr css={styles.paidFeeRow}>
                            <td css={styles.paidFeeRowFirstCell} colSpan={3}>
                                {t('text.paidFeeAmount')}
                            </td>
                            {getTotalAmountsPaidFee().map((amount, i) => (
                                <td key={i} css={getTotalAmountsPaidFeeCellStyle(amount)}>
                                    {amount === '-1' ? `€${formatNumber(0)}` : `€${amount}`}
                                </td>
                            ))}
                        </tr>
                        <tr css={styles.weekFeePaidRow}>
                            <td css={styles.weekFeePaidRowFirstCell} colSpan={3}>
                                {t('text.weekFeePaid')}
                            </td>
                            {getWeeksFeePaid().map((week, i) => (
                                <td key={i} css={getWeeksFeePaidCellStyle(week)}>
                                    {week === '-1' ? '' : week}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
