import jwtDecode from 'jwt-decode';
import { ITokenPayload } from '../types';

export const isTokenExpired = (token: string): boolean => {
    try {
        const { exp } = jwtDecode<ITokenPayload>(token);
        if (Date.now() <= exp * 1000) return false;
        return true;
    } catch {
        return false;
    }
};
