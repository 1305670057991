/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchUser } from '../hooks';
import { useChangeEmailSubscription } from './hooks';
import {
    handleQueryError,
    IRouteParams,
    colors,
    handleError,
    useAuth,
    routePath,
} from '../../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { Page, SwitchInput, Button } from '../../../components';

export const ChangeEmailSubscription = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUser } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams<IRouteParams>();

    const { isLoading, data, error } = useFetchUser(id);
    const changeEmailSubscription = useChangeEmailSubscription(id);

    React.useEffect(() => {
        if (id !== currentUser!.userId) {
            history.push(routePath.unauthorizedError.create());
        }
    }, [id, history, currentUser]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page isPageSubmitting={changeEmailSubscription.isLoading} style={styles.root}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.emailSubscription')}</h1>
                {isLoading && (
                    <div style={styles.contentForm}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.buttonSkeleton}
                        />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            css={styles.buttonSkeleton}
                        />
                    </div>
                )}
                {data && (
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                isMailingSubscribed: data.isMailingSubscribed,
                                isNewsletterSubscribed: data.isNewsletterSubscribed,
                            } as IFormValues
                        }
                        onSubmit={async ({ isMailingSubscribed, isNewsletterSubscribed }) => {
                            try {
                                await changeEmailSubscription.mutateAsync({
                                    body: {
                                        isMailingSubscribed,
                                        isNewsletterSubscribed,
                                    },
                                });
                                history.push(routePath.user.create());
                            } catch (e) {
                                handleError(e, ({ status, statusText }) => {
                                    if (status === 404) {
                                        history.push(routePath.notFoundError.create());
                                    } else if (status === 401 || status === 403) {
                                        history.push(routePath.unauthorizedError.create());
                                    } else if (status === 500) {
                                        history.push(routePath.internalServerError.create());
                                    } else alert(statusText);
                                });
                            }
                        }}
                    >
                        {({ isSubmitting, setFieldValue, values }) => (
                            <Form noValidate style={styles.contentForm}>
                                <Field
                                    style={styles.formField}
                                    name="isMailingSubscribed"
                                    label={t('form.label.mailingSubscribed')}
                                    disabled={isSubmitting}
                                    component={SwitchInput}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                        checked: boolean
                                    ) => {
                                        setFieldValue('isMailingSubscribed', event.target.checked);
                                        if (!checked) {
                                            setFieldValue('isNewsletterSubscribed', false);
                                        }
                                    }}
                                />
                                <Field
                                    style={styles.formField}
                                    name="isNewsletterSubscribed"
                                    label={t('form.label.newsletterSubscribed')}
                                    disabled={isSubmitting}
                                    component={SwitchInput}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (values.isMailingSubscribed) {
                                            setFieldValue(
                                                'isNewsletterSubscribed',
                                                event.target.checked
                                            );
                                        }
                                    }}
                                />
                                <Button
                                    css={styles.formButton}
                                    type="submit"
                                    disabled={isSubmitting}
                                    spinner={isSubmitting}
                                    cs={{
                                        color: colors.blue,
                                    }}
                                >
                                    {t('text.update')}
                                </Button>
                                <Button
                                    css={styles.formButton}
                                    type="button"
                                    disabled={isSubmitting}
                                    spinner={isSubmitting}
                                    cs={{
                                        color: colors.blue,
                                    }}
                                    onClick={() => history.push(routePath.user.create())}
                                >
                                    {t('text.back')}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </Page>
    );
};
