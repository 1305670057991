/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchAmbassador, useUpdateAmbassador, useFetchDepartments } from './hooks';
import { IDepartment } from '../hooks';
import {
    handleQueryError,
    IRouteParams,
    colors,
    handleError,
    useAuth,
    Role,
    routePath,
} from '../../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, useMediaQuery } from '@mui/material';
import { Formik, Form, Field, FormikErrors } from 'formik';
import {
    Page,
    TextFieldInput,
    SelectInput,
    LanguageSelectInput,
    Button,
    SwitchInput,
} from '../../../components';

export const UpdateAmbassador = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles, getCurrentUser } = useAuth();
    const history = useHistory();
    const { id } = useParams<IRouteParams>();
    const { t, i18n } = useTranslation();

    const ambassadorResponse = useFetchAmbassador(id);
    const departmentsResponse = useFetchDepartments(currentUserHasRoles(Role.superAdmin));
    const updateAmbassador = useUpdateAmbassador(id);

    React.useEffect(() => {
        if (ambassadorResponse.data && ambassadorResponse.data.user.department) {
            if (
                currentUserHasRoles(Role.admin) &&
                getCurrentUser().departmentId !== ambassadorResponse.data.user.department.id &&
                ambassadorResponse.data.admin.user.id !== getCurrentUser().userId
            ) {
                history.push(routePath.unauthorizedError.create());
            }
        }
    }, [currentUserHasRoles, getCurrentUser, ambassadorResponse.data, history]);

    if (ambassadorResponse.error) {
        return handleQueryError(ambassadorResponse.error, history);
    }
    if (departmentsResponse.error) {
        return handleQueryError(departmentsResponse.error, history);
    }
    return (
        <Page
            isPageSubmitting={updateAmbassador.isLoading}
            title={t('text.updateAmbassador')}
            style={styles.root}
        >
            {ambassadorResponse.isLoading || departmentsResponse.isLoading ? (
                <div style={styles.formSkeleton}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.employeeSkeleton}
                    />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    {currentUserHasRoles(Role.superAdmin) ? (
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={styles.fieldSkeleton}
                        />
                    ) : null}
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            ) : null}
            {ambassadorResponse.data &&
            (!currentUserHasRoles(Role.superAdmin) ||
                (currentUserHasRoles(Role.superAdmin) && departmentsResponse.data)) ? (
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            firstName: ambassadorResponse.data.user.firstName,
                            lastName: ambassadorResponse.data.user.lastName,
                            language: ambassadorResponse.data.user.language,
                            isContractSigned: ambassadorResponse.data.isContractSigned,
                            department: ambassadorResponse.data.user.department
                                ? ambassadorResponse.data.user.department.id
                                : '',
                        } as IFormValues
                    }
                    validate={({ firstName, lastName, language, department }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!firstName.trim()) {
                            errors.firstName = t('form.error.requiredField');
                        } else if (firstName.trim().length > 127) {
                            errors.firstName = t('form.error.max127Chars');
                        }
                        if (!lastName.trim()) {
                            errors.lastName = t('form.error.requiredField');
                        } else if (lastName.trim().length > 127) {
                            errors.lastName = t('form.error.max127Chars');
                        }
                        if (currentUserHasRoles(Role.admin) && !department) {
                            errors.department = t('form.error.requiredField');
                        }
                        if (!language) {
                            errors.language = t('form.error.requiredField');
                        }
                        return errors;
                    }}
                    onSubmit={async ({
                        firstName,
                        lastName,
                        language,
                        isContractSigned,
                        department,
                    }) => {
                        try {
                            await updateAmbassador.mutateAsync({
                                body: {
                                    firstName: firstName.trim(),
                                    lastName: lastName.trim(),
                                    departmentId: currentUserHasRoles(Role.superAdmin)
                                        ? department
                                        : undefined,
                                    language,
                                    isContractSigned,
                                },
                            });
                            history.push(routePath.ambassadors.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form noValidate style={styles.form}>
                            <h2 style={styles.formEmployee}>
                                {`${ambassadorResponse.data.user.name} - ${ambassadorResponse.data.employeeNumber}`}
                            </h2>
                            <Field
                                style={styles.formField}
                                name="firstName"
                                type="text"
                                label={t('form.label.firstName')}
                                placeholder={t('form.placeholder.firstName')}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Field
                                style={styles.formField}
                                name="lastName"
                                type="text"
                                label={t('form.label.lastName')}
                                placeholder={t('form.placeholder.lastName')}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            {currentUserHasRoles(Role.superAdmin) ? (
                                <Field
                                    style={styles.formField}
                                    name="department"
                                    label={t('form.label.department')}
                                    items={departmentsResponse.data!.map((department) => {
                                        return {
                                            value: department.id,
                                            item: department[i18n.language as keyof IDepartment],
                                        };
                                    })}
                                    disabled={isSubmitting}
                                    component={SelectInput}
                                />
                            ) : null}
                            <Field
                                style={styles.formField}
                                name="language"
                                label={t('form.label.language')}
                                disabled={isSubmitting}
                                component={LanguageSelectInput}
                            />
                            <Field
                                style={styles.formField}
                                name="isContractSigned"
                                label={t('form.label.isAddendumSigned')}
                                disabled={isSubmitting}
                                component={SwitchInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.update')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.push(routePath.ambassadors.create())}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </Page>
    );
};
