/**@jsxImportSource @emotion/react */
import React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useStyle } from './style';
import { ICustomStyle, IElementStyle } from './types';

interface IProps {
    children?: React.ReactNode;
    to: string;
    cs: ICustomStyle;
    es?: IElementStyle;
    style?: React.CSSProperties;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const LinkButton = ({ children, to, cs, es, className, style, onClick }: IProps) => {
    const [coords, setCoords] = React.useState({ x: -1, y: -1 });
    const [isRippling, setIsRippling] = React.useState(false);
    const styles = useStyle(cs, coords);

    React.useLayoutEffect(() => {
        let timer: NodeJS.Timer;
        if (coords.x !== -1 && coords.y !== -1) {
            setIsRippling(true);
            timer = setTimeout(() => setIsRippling(false), 300);
        } else setIsRippling(false);
        return () => {
            clearTimeout(timer);
        };
    }, [coords]);

    React.useLayoutEffect(() => {
        if (!isRippling) setCoords({ x: -1, y: -1 });
    }, [isRippling]);

    return (
        <Link
            css={
                es && es.button
                    ? [
                          styles.button,
                          es.button as Interpolation<Theme>,
                          style as Interpolation<Theme>,
                      ]
                    : [styles.button, style as Interpolation<Theme>]
            }
            to={to}
            className={className}
            onClick={(event) => {
                const rect = event.currentTarget.getBoundingClientRect();
                setCoords({ x: event.clientX - rect.left, y: event.clientY - rect.top });
                if (onClick) onClick(event);
            }}
        >
            <span
                css={
                    es && es.buttonContent
                        ? [styles.buttonContent, es.buttonContent as Interpolation<Theme>]
                        : styles.buttonContent
                }
            >
                {children}
            </span>
            {isRippling ? (
                <span
                    css={
                        es && es.buttonRipple
                            ? [styles.buttonRipple, es.buttonRipple as Interpolation<Theme>]
                            : styles.buttonRipple
                    }
                />
            ) : null}
        </Link>
    );
};
