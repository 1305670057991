/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useEmailSubscription } from './hooks';
import { colors, handleError, routePath, SocialMediaLink } from '../../helpers';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { LanguageMenu, SwitchInput, Button } from '../../components';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';

export const EmailSubscription = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const widthBelow200px = useMediaQuery('(max-width: 200px)');
    const styles = useStyles(widthBelow600px, widthBelow200px);
    const history = useHistory();
    const { t } = useTranslation();

    const update = useEmailSubscription();

    return (
        <div style={styles.root}>
            <div style={styles.header}>
                <h1 css={styles.headerText}>Oranjelink</h1>
                <LanguageMenu />
            </div>
            <div style={styles.content}>
                <Formik
                    initialValues={
                        {
                            isMailingSubscribed: true,
                            isNewsletterSubscribed: true,
                        } as IFormValues
                    }
                    onSubmit={async ({ isMailingSubscribed, isNewsletterSubscribed }) => {
                        const token = localStorage.getItem('esvid');
                        if (token) {
                            try {
                                await update.mutateAsync({
                                    body: { token, isMailingSubscribed, isNewsletterSubscribed },
                                });
                                history.push(routePath.verifyEmailSubscription.create());
                            } catch (e) {
                                handleError(e, ({ status, statusText }) => {
                                    if (status === 404) {
                                        localStorage.removeItem('esvid');
                                        history.push(
                                            routePath.notFoundVerifyEmailSubscriptionError.create()
                                        );
                                    } else if (status === 401) {
                                        localStorage.removeItem('esvid');
                                        history.push(
                                            routePath.unauthorizedVerifyEmailSubscriptionError.create()
                                        );
                                    } else if (status === 500) {
                                        localStorage.removeItem('esvid');
                                        history.push(
                                            routePath.internalServerVerifyEmailSubscriptionError.create()
                                        );
                                    } else {
                                        alert(statusText);
                                    }
                                });
                            }
                        } else {
                            routePath.unauthorizedVerifyEmailSubscriptionError.create();
                        }
                    }}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form noValidate style={styles.contentForm}>
                            <h1 css={styles.formTitle}>{t('text.emailSubscription')}</h1>
                            <Field
                                style={styles.formField}
                                name="isMailingSubscribed"
                                label={t('form.label.mailingSubscribed')}
                                disabled={isSubmitting}
                                component={SwitchInput}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean
                                ) => {
                                    setFieldValue('isMailingSubscribed', event.target.checked);
                                    if (!checked) {
                                        setFieldValue('isNewsletterSubscribed', false);
                                    }
                                }}
                            />
                            <Field
                                style={styles.formField}
                                name="isNewsletterSubscribed"
                                label={t('form.label.newsletterSubscribed')}
                                disabled={isSubmitting}
                                component={SwitchInput}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (values.isMailingSubscribed) {
                                        setFieldValue(
                                            'isNewsletterSubscribed',
                                            event.target.checked
                                        );
                                    }
                                }}
                            />
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{ color: colors.blue }}
                            >
                                {t('text.update')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div style={styles.footer}>
                <div style={styles.footerLinkBox}>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillFacebook />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillInstagram />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.LinkedIn}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillLinkedin />
                    </a>
                </div>
                <div style={styles.footerRightContainer}>
                    <div>
                        <Link css={styles.footerRightContainerLink} to={routePath.terms.create()}>
                            {t('text.generalTerms')}
                        </Link>
                        &nbsp;/&nbsp;
                        <Link
                            css={styles.footerRightContainerLink}
                            to={routePath.privacyPolicy.create()}
                        >
                            {t('text.privacyPolicy')}
                        </Link>
                    </div>
                    <p>
                        {`De Oranjegroep \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop - Public Bèta`}
                    </p>
                </div>
            </div>
        </div>
    );
};
