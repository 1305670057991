import { ILoginVariables, ILoginResponse } from './types';
import { useApi } from '../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useLogin = () => {
    const api = useApi();

    return useMutation<AxiosResponse<ILoginResponse>, any, ILoginVariables>(({ body }) =>
        api.post('/login', body)
    );
};
