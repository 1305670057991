/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            position: 'fixed',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '6rem',
            background: colors.blue,
            boxShadow: `0 0.5rem 1rem ${colors.darkBoxShadow}`,
            zIndex: 1000,
        },
        panelWrapper: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            padding: '0 1rem 0 1rem',
        },
        panelWrapperLeftPanel: {
            display: 'flex',
            flex: 2,
            alignItems: 'center',
        },
        panelWrapperRightPanel: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '2rem',
        },
        rightPanelLngSelect: {
            marginRight: '4rem',
        },
        emptyFetcher: {
            height: '4px',
        },
    });
