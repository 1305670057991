import { AgGridReact } from 'ag-grid-react';
import { Workbook } from 'exceljs';

export const excelExport = (gridRef: React.RefObject<AgGridReact<any>>) => {
    if (gridRef.current) {
        const workbook = new Workbook();
        workbook.created = new Date();
        const worksheet = workbook.addWorksheet('Logs');
        worksheet.columns = [
            { header: 'Entity Type', key: 'entityType', width: 80 },
            { header: 'Entity', key: 'entityIdentifier', width: 80 },
            { header: 'Field', key: 'field', width: 80 },
            { header: 'Modification', key: 'modification', width: 30 },
            { header: 'Old Value', key: 'oldValue', width: 30 },
            { header: 'New Value', key: 'newValue', width: 30 },
            { header: 'Entity Id', key: 'entityId', width: 80 },
            { header: 'Modified by', key: 'modifiedBy', width: 30 },
            { header: 'Modified on', key: 'modifiedOn', width: 20 },
            {
                header: 'Modified at',
                key: 'modifiedAt',
                width: 20,
                style: { numFmt: 'hh:mm:ss' },
            },
        ];
        gridRef.current.api.forEachNodeAfterFilterAndSort(({ data }) =>
            worksheet.addRow({
                entityType: data.entityType,
                entityIdentifier: data.entityIdentifier,
                field: data.field,
                modification: data.modification,
                oldValue: data.oldValue,
                newValue: data.newValue,
                entityId: data.entityId,
                modifiedBy: data.modifiedBy,
                modifiedOn: new Date(
                    new Date(data.modifiedAt).getTime() -
                        new Date(data.modifiedAt).getTimezoneOffset() * 60 * 1000
                ),
                modifiedAt: new Date(
                    new Date(data.modifiedAt).getTime() -
                        new Date(data.modifiedAt).getTimezoneOffset() * 60 * 1000
                ),
            })
        );

        return workbook.xlsx.writeBuffer();
    }
};
