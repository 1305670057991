/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useAuth, Role } from '../../../../helpers';
import { useMediaQuery, Skeleton } from '@mui/material';

export const SkeletonForm = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles } = useAuth();

    return (
        <div style={styles.form}>
            <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
            <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
            <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
            <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
            {currentUserHasRoles(Role.superAdmin, Role.admin) ? (
                <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
            ) : null}
            <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
            <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
            <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
            {currentUserHasRoles(Role.superAdmin, Role.admin) ? (
                <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
            ) : null}
            <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
            <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
        </div>
    );
};
