import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useApi } from '../../../../../helpers';
import { ISetting } from '../types';
import { IUpdateSettingVariables } from './types';

export const useUpdateSetting = () => {
    const api = useApi();

    return useMutation<AxiosResponse<ISetting>, any, IUpdateSettingVariables>(({ body }) =>
        api.patch('settings', body)
    );
};
