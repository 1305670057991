import * as React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { history, PrivateRoute, Role, routePath } from './helpers';
import {
    Unavailable,
    AfasUnavailable,
    Unauthorized,
    GatewayTimeout,
    InternalServer,
    NotFound,
    Login,
    Vertify,
    EmailCheck,
    ResetPassword,
    EmailSubscription,
    Log,
    Settings,
    GeneralSetting,
    Departments,
    CreateDepartment,
    UpdateDepartment,
    Professions,
    CreateProfession,
    UpdateProfession,
    ProfessionGroups,
    CreateProfessionGroup,
    UpdateProfessionGroup,
    Tasks,
    TaskErrors,
    Newsletter,
    User,
    ChangeEmail,
    Changelanguage,
    ChangePassword,
    ChangeEmailSubscription,
    Admins,
    CreateAdmin,
    UpdateAdmin,
    ChangeEmailAdmin,
    ChangePasswordAdmin,
    Ambassadors,
    CreateAmbassador,
    UpdateAmbassador,
    ChangeEmailAmbassador,
    ChangePasswordAmbassador,
    AmbassadorActivityPeriods,
    CreateAmbassadorActivityPeriod,
    CloseAmbassadorActivityPeriod,
    AmbassadorEmployedPeriods,
    AmbassadorCandidates,
    Candidates,
    CreateCandidate,
    UpdateCandidate,
    UpdateInvitedCandidate,
    UpdateCandidateEmployeeNumber,
    UpdateCandidateInvitation,
    PromoteCandidate,
    InviteCreateCandidate,
    InviteCreateAmbassadorCandidate,
    CandidateActivityPeriods,
    CreateCandidateActivityPeriod,
    CloseCandidateActivityPeriod,
    Welcome,
    Registered,
    AmbassadorDeclarations,
    AmbassadorDeclarationPeriod,
    AmbassadorHoursDashboard,
    AmbassadorPipelineDashboard,
    SalesHoursDashboard,
    SalesPipeline,
    OranjelinkSalesPipeline,
    RecruitmentDashboard,
    ScoreboardDashboard,
    CandidatesDashboard,
    Help,
    Terms,
    AcceptTerms,
    PrivacyPolicy,
    Feedback,
} from './pages';

const {
    login,
    verifyLogin,
    verifyResetPassword,
    verifyEmailSubscription,
    emailSubscriptionEmailCheck,
    resetPasswordEmailCheck,
    emailSubscription,
    resetPassword,
    user,
    userChangeEmail,
    userChangeLanguage,
    userChangePassword,
    userChangeEmailSubscription,
    admins,
    createAdmin,
    updateAdmin,
    changeEmailAdmin,
    changePasswordAdmin,
    ambassadors,
    createAmbassador,
    updateAmbassador,
    changeEmailAmbassador,
    changePasswordAmbassador,
    ambassadorActivityPeriods,
    createAmbassadorActivityPeriod,
    closeAmbassadorActivityPeriod,
    ambassadorEmployedPeriods,
    ambassadorCandidates,
    candidates,
    createCandidate,
    updateCandidate,
    inviteCreateCandidate,
    inviteCreateAmbassadorCandidate,
    updateInvitedCandidate,
    promoteCandidate,
    updateCandidateEmployeeNumber,
    updateCandidateInvitation,
    candidateActivityPeriods,
    createCandidateActivityPeriod,
    closeCandidateActivityPeriod,
    unauthorizedError,
    unauthorizedVerifyLoginError,
    unauthorizedVerifyEmailSubscriptionError,
    unauthorizedVerifyResetPasswordError,
    internalServerError,
    internalServerVerifyLoginError,
    internalServerVerifyEmailSubscriptionError,
    internalServerVerifyResetPasswordError,
    unavailableError,
    afasUnavailableError,
    gatewayTimeoutError,
    notFoundVerifyLoginError,
    notFoundVerifyEmailSubscriptionError,
    notFoundVerifyResetPasswordError,
    logs,
    settings,
    generalSettings,
    departmentSettings,
    createDepartment,
    updateDepartment,
    professionsSettings,
    createProfession,
    updateProfession,
    professionGroupsSetting,
    createProfessionGroup,
    updateProfessionGroup,
    tasks,
    taskErrors,
    newsletter,
    welcome,
    registered,
    ambassadorDeclarations,
    ambassadorDeclarationPeriod,
    ambassadorHoursDashboard,
    salesHoursDashboard,
    ambassadorPipelineDashboard,
    salesPipelineDashboard,
    oranjelinkSalesPipelineDashboard,
    recruitmentDashboard,
    scoreboardDashboard,
    candidatesDashboard,
    help,
    terms,
    acceptTerms,
    privacyPolicy,
    feedback,
} = routePath;

export const Routes = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route path={login.path} exact component={Login} />
                <Route path={acceptTerms.path} exact component={AcceptTerms} />
                <Route path={verifyLogin.path} exact component={Vertify} />
                <Route path={verifyResetPassword.path} exact component={Vertify} />
                <Route path={verifyEmailSubscription.path} exact component={Vertify} />
                <Route path={resetPasswordEmailCheck.path} exact component={EmailCheck} />
                <Route path={emailSubscriptionEmailCheck.path} exact component={EmailCheck} />
                <Route path={emailSubscription.path} exact component={EmailSubscription} />
                <Route path={resetPassword.path} exact component={ResetPassword} />
                <PrivateRoute roles={[Role.superAdmin]} path={logs.path} exact component={Log} />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={settings.path}
                    exact
                    component={Settings}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={generalSettings.path}
                    exact
                    component={GeneralSetting}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={departmentSettings.path}
                    exact
                    component={Departments}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={createDepartment.path}
                    exact
                    component={CreateDepartment}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={updateDepartment.path}
                    exact
                    component={UpdateDepartment}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={professionsSettings.path}
                    exact
                    component={Professions}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={createProfession.path}
                    exact
                    component={CreateProfession}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={updateProfession.path}
                    exact
                    component={UpdateProfession}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={professionGroupsSetting.path}
                    exact
                    component={ProfessionGroups}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={createProfessionGroup.path}
                    exact
                    component={CreateProfessionGroup}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={updateProfessionGroup.path}
                    exact
                    component={UpdateProfessionGroup}
                />
                <PrivateRoute roles={[Role.superAdmin]} path={tasks.path} exact component={Tasks} />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={taskErrors.path}
                    exact
                    component={TaskErrors}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={newsletter.path}
                    exact
                    component={Newsletter}
                />
                <PrivateRoute path={user.path} exact component={User} />
                <PrivateRoute path={userChangeEmail.path} exact component={ChangeEmail} />
                <PrivateRoute path={userChangeLanguage.path} exact component={Changelanguage} />
                <PrivateRoute path={userChangePassword.path} exact component={ChangePassword} />
                <PrivateRoute
                    path={userChangeEmailSubscription.path}
                    exact
                    component={ChangeEmailSubscription}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={admins.path}
                    exact
                    component={Admins}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={createAdmin.path}
                    exact
                    component={CreateAdmin}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={updateAdmin.path}
                    exact
                    component={UpdateAdmin}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={changeEmailAdmin.path}
                    exact
                    component={ChangeEmailAdmin}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={changePasswordAdmin.path}
                    exact
                    component={ChangePasswordAdmin}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={ambassadors.path}
                    exact
                    component={Ambassadors}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={createAmbassador.path}
                    exact
                    component={CreateAmbassador}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={updateAmbassador.path}
                    exact
                    component={UpdateAmbassador}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={changeEmailAmbassador.path}
                    exact
                    component={ChangeEmailAmbassador}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={changePasswordAmbassador.path}
                    exact
                    component={ChangePasswordAmbassador}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={ambassadorActivityPeriods.path}
                    exact
                    component={AmbassadorActivityPeriods}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={createAmbassadorActivityPeriod.path}
                    exact
                    component={CreateAmbassadorActivityPeriod}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={closeAmbassadorActivityPeriod.path}
                    exact
                    component={CloseAmbassadorActivityPeriod}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={ambassadorEmployedPeriods.path}
                    exact
                    component={AmbassadorEmployedPeriods}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter]}
                    path={ambassadorCandidates.path}
                    exact
                    component={AmbassadorCandidates}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter]}
                    path={inviteCreateAmbassadorCandidate.path}
                    exact
                    component={InviteCreateAmbassadorCandidate}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.ambassador]}
                    path={candidates.path}
                    exact
                    component={Candidates}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={createCandidate.path}
                    exact
                    component={CreateCandidate}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.ambassador]}
                    path={updateCandidate.path}
                    exact
                    component={UpdateCandidate}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.ambassador, Role.recruitmentAgency]}
                    path={inviteCreateCandidate.path}
                    exact
                    component={InviteCreateCandidate}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={promoteCandidate.path}
                    exact
                    component={PromoteCandidate}
                />
                <Route
                    path={updateInvitedCandidate.path}
                    exact
                    component={UpdateInvitedCandidate}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter]}
                    path={updateCandidateEmployeeNumber.path}
                    exact
                    component={UpdateCandidateEmployeeNumber}
                />
                <PrivateRoute
                    roles={[
                        Role.superAdmin,
                        Role.admin,
                        Role.ambassador,
                        Role.recruitmentAgency,
                        Role.recruiter,
                    ]}
                    path={updateCandidateInvitation.path}
                    exact
                    component={UpdateCandidateInvitation}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={candidateActivityPeriods.path}
                    exact
                    component={CandidateActivityPeriods}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin]}
                    path={createCandidateActivityPeriod.path}
                    exact
                    component={CreateCandidateActivityPeriod}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={closeCandidateActivityPeriod.path}
                    exact
                    component={CloseCandidateActivityPeriod}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={ambassadorDeclarations.path}
                    exact
                    component={AmbassadorDeclarations}
                />
                <PrivateRoute
                    roles={[Role.superAdmin]}
                    path={ambassadorDeclarationPeriod.path}
                    exact
                    component={AmbassadorDeclarationPeriod}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter, Role.ambassador]}
                    path={ambassadorHoursDashboard.path}
                    exact
                    component={AmbassadorHoursDashboard}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter]}
                    path={salesHoursDashboard.path}
                    exact
                    component={SalesHoursDashboard}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter, Role.ambassador]}
                    path={ambassadorPipelineDashboard.path}
                    exact
                    component={AmbassadorPipelineDashboard}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter]}
                    path={salesPipelineDashboard.path}
                    exact
                    component={SalesPipeline}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter]}
                    path={oranjelinkSalesPipelineDashboard.path}
                    exact
                    component={OranjelinkSalesPipeline}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter]}
                    path={recruitmentDashboard.path}
                    exact
                    component={RecruitmentDashboard}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter, Role.ambassador]}
                    path={scoreboardDashboard.path}
                    exact
                    component={ScoreboardDashboard}
                />
                <PrivateRoute
                    roles={[Role.superAdmin, Role.admin, Role.recruiter]}
                    path={candidatesDashboard.path}
                    exact
                    component={CandidatesDashboard}
                />
                <PrivateRoute path={help.path} exact component={Help} />
                <PrivateRoute path={feedback.path} exact component={Feedback} />
                <Route path={terms.path} exact component={Terms} />
                <Route path={privacyPolicy.path} exact component={PrivacyPolicy} />
                <Route path={unauthorizedError.path} exact component={Unauthorized} />
                <Route path={unauthorizedVerifyLoginError.path} exact component={Unauthorized} />
                <Route
                    path={unauthorizedVerifyEmailSubscriptionError.path}
                    exact
                    component={Unauthorized}
                />
                <Route
                    path={unauthorizedVerifyResetPasswordError.path}
                    exact
                    component={Unauthorized}
                />
                <Route path={internalServerError.path} exact component={InternalServer} />
                <Route
                    path={internalServerVerifyLoginError.path}
                    exact
                    component={InternalServer}
                />
                <Route
                    path={internalServerVerifyEmailSubscriptionError.path}
                    exact
                    component={InternalServer}
                />
                <Route
                    path={internalServerVerifyResetPasswordError.path}
                    exact
                    component={InternalServer}
                />
                <Route path={unavailableError.path} exact component={Unavailable} />
                <Route path={afasUnavailableError.path} exact component={AfasUnavailable} />
                <Route path={gatewayTimeoutError.path} exact component={GatewayTimeout} />
                <Route path={notFoundVerifyLoginError.path} exact component={NotFound} />
                <Route
                    path={notFoundVerifyEmailSubscriptionError.path}
                    exact
                    component={NotFound}
                />
                <Route path={notFoundVerifyResetPasswordError.path} exact component={NotFound} />
                <Route path={welcome.path} exact component={Welcome} />
                <Route path={registered.path} exact component={Registered} />
                <Route path="*" exact component={NotFound} />
            </Switch>
        </Router>
    );
};
