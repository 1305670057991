/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IProfession } from '../../hooks';
import { IContext } from './types';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams } from 'ag-grid-community';
import { Tooltip, IconButton } from '@mui/material';
import { BiEdit } from 'react-icons/bi';

export const UpdateCell = ({
    data,
    context: { handleUpdateClick },
}: ICellRendererParams<IProfession, any, IContext>) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div style={styles.cell}>
            <Tooltip arrow title={<h1>{t('text.update')}</h1>}>
                <IconButton
                    onClick={() => {
                        if (data) {
                            return handleUpdateClick(data.id);
                        }
                    }}
                >
                    <BiEdit style={styles.button} />
                </IconButton>
            </Tooltip>
        </div>
    );
};
