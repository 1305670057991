/**@jsxImportSource @emotion/react */
import { colors, designStyles } from '../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '70vw',
            width: 'fit-content',
            borderRadius: '1rem',
            overflow: 'hidden',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            background: colors.blue,
            padding: '1.2rem',
        },
        headerTitle: {
            color: colors.white,
            marginRight: '3rem',
            flex: 2,
            userSelect: 'none',
        },
        headerCloseButton: {
            marginRight: '0.2rem',
            fontSize: '1.5rem',
            color: colors.white,
            transition: 'all 0.2s',
            '&:hover': {
                color: colors.orange,
                transform: 'scale(1.2)',
            },
        },
        content: {
            padding: '3rem',
            background: colors.white,
            display: 'flex',
            justifyContent: 'center',
        },
        contentText: {
            color: colors.blue,
            fontSize: '1.5rem',
            fontWeight: 600,
            userSelect: 'none',
        },
        actions: {
            display: 'flex',
            justifyContent: 'center',
            padding: '1.5rem',
            background: colors.white,
        },
        actionsYesButton: {
            marginRight: '0.5rem',
        },
    });
