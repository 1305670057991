import { ICandidateOwner } from './types';
import { Cache, useApi } from '../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchCandidateOwners = () => {
    const api = useApi();

    return useQuery<ICandidateOwner[], any>(Cache.candidateOwners, () =>
        api.get('/candidate-owners').then((res) => res.data)
    );
};
