/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../../../../helpers';

export const useStyles = () =>
    designStyles({
        panel: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            height: '100%',
            padding: '1rem 1rem 0 1rem',
            userSelect: 'none',
            color: colors.blue,
            fontSize: '1.6rem',
        },
        panelText: {
            display: 'inline-block',
            fontWeight: 'bold',
        },
        panelDataText: {
            display: 'inline-block',
            fontStyle: 'italic',
            userSelect: 'text',
        },
        panelBooleanBox: {
            display: 'flex',
            alignItems: 'center',
        },
        panelBooleanIcon: {
            marginLeft: '0.2rem',
            fontSize: '1.6rem',
        },
    });
