/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './styles';
import { IDepartment, useFetchDepartments, useFetchPipeline } from '../hooks';
import { handleQueryError } from '../../../helpers';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import {
    Page,
    NumberSelectInput,
    PipelineDepartmentTable,
    PipelineAmbassadorsTable,
    PipelineCandidatesTable,
} from '../../../components';
import { IPipelineDepartmentData } from '../../../components/tables/pipeline-department-table/types';
import { ISelectItem } from '../../../components/inputs/number-select/types';
import { DepartmentComboBoxInput } from '../department-combo-box/DepartmentComboBox';

export const SalesPipeline = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { t } = useTranslation();
    const [year, setYear] = React.useState(moment().year());
    const [department, setDepartment] = React.useState<IDepartment | null>(null);

    const departmentsResponse = useFetchDepartments();

    React.useEffect(() => {
        if (departmentsResponse.data && departmentsResponse.data.length > 0) {
            setDepartment(departmentsResponse.data[0]);
        }
    }, [departmentsResponse.data]);

    const pipelineResponse = useFetchPipeline(!!department?.id, year, '', department?.id);

    const getYears = React.useCallback((): ISelectItem[] => {
        const years: ISelectItem[] = [];
        for (let i = 20234; i <= moment().year(); i++) {
            years.push({
                value: i,
                item: i.toString(),
            });
        }
        return years;
    }, []);

    const getPipelineDepartments = React.useCallback(() => {
        const result: IPipelineDepartmentData[] = [];
        if (department !== null && pipelineResponse.data) {
            let workedCandidateAmount = 0;
            let invitationAmount = 0;
            let registrationAmount = 0;
            let rejectedAmount = 0;
            let firstTierCandidateHours = 0;
            let secondTierCandidateHours = 0;
            let thirdTierCandidateHours = 0;
            let paidFee = 0;

            pipelineResponse.data.pipelineAmbassadors.forEach((ambassador) => {
                workedCandidateAmount += ambassador.wrokedCandidateAmount;
                invitationAmount += ambassador.invitationAmount;
                registrationAmount += ambassador.registeredAmount;
                rejectedAmount += ambassador.rejectedAmount;
                firstTierCandidateHours += ambassador.firstTierCandidateHours
                    ? ambassador.firstTierCandidateHours
                    : 0;
                secondTierCandidateHours += ambassador.secondTierCandidateHours
                    ? ambassador.secondTierCandidateHours
                    : 0;
                thirdTierCandidateHours += ambassador.thirdTierCandidateHours
                    ? ambassador.thirdTierCandidateHours
                    : 0;
                paidFee += ambassador.paidFee ? ambassador.paidFee : 0;
            });

            result.push({
                department,
                ambassadorAmount: pipelineResponse.data.pipelineAmbassadors.length,
                workedCandidateAmount,
                invitationAmount,
                registrationAmount,
                rejectedAmount,
                firstTierCandidateHours,
                secondTierCandidateHours,
                thirdTierCandidateHours,
                paidFee,
            });
        }
        return result;
    }, [department, pipelineResponse.data]);

    if (departmentsResponse.error) {
        return handleQueryError(departmentsResponse.error, history);
    }
    if (pipelineResponse.error) {
        return handleQueryError(pipelineResponse.error, history);
    }
    return (
        <Page style={styles.root} title={t('text.salesPipeline')}>
            <div style={styles.filters}>
                {departmentsResponse.isLoading ? (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.departmentSkeleton}
                    />
                ) : null}
                {departmentsResponse.data ? (
                    <DepartmentComboBoxInput
                        style={styles.filtersDepartment}
                        label={t('form.label.department')}
                        placeholder={t('form.placeholder.department')}
                        options={departmentsResponse.data}
                        value={department}
                        setDepartment={setDepartment}
                    />
                ) : null}
                <NumberSelectInput
                    css={styles.filtersNumberSelect}
                    label={t('form.label.year')}
                    value={year}
                    setValue={setYear}
                    items={getYears()}
                    cs={{
                        fontSize: '1.5rem',
                    }}
                />
            </div>
            {departmentsResponse.isLoading || pipelineResponse.isLoading ? (
                <React.Fragment>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.departmentGridSkeleton}
                    />
                    <Skeleton variant="rectangular" animation="wave" style={styles.gridSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.gridSkeleton} />
                </React.Fragment>
            ) : null}
            {pipelineResponse.data && pipelineResponse.data.pipelineAmbassadors.length > 0 ? (
                <React.Fragment>
                    <PipelineDepartmentTable
                        departments={getPipelineDepartments()}
                        maxHeight="55vh"
                    />
                    <PipelineAmbassadorsTable
                        ambassadors={pipelineResponse.data.pipelineAmbassadors}
                        maxHeight="21.5vh"
                    />
                    <PipelineCandidatesTable
                        candidates={pipelineResponse.data.pipelineCandidates}
                        maxHeight="21.5vh"
                    />
                </React.Fragment>
            ) : null}
            {department === null ||
            (pipelineResponse.data && pipelineResponse.data.pipelineAmbassadors.length === 0) ? (
                <div style={styles.noDataContainer}>
                    <p style={styles.noData}>{t('text.noDataToShow')}</p>
                </div>
            ) : null}
        </Page>
    );
};
