/**@jsxImportSource @emotion/react */
import { Interpolation, Theme } from '@emotion/react';
import { Language } from '../../../../helpers';
import netherland from '../../../../assets/netherlands.svg';
import england from '../../../../assets/england.svg';
import romania from '../../../../assets/romania.svg';
import poland from '../../../../assets/poland.svg';
import croatia from '../../../../assets/croatia.svg';
import portugal from '../../../../assets/portugal.svg';
import hungary from '../../../../assets/hungary.svg';

export const getFlag = (lng: Language, css: Interpolation<Theme>) => {
    switch (lng) {
        case Language.nl:
            return <img css={css} src={netherland} alt="fg" />;
        case Language.en:
            return <img css={css} src={england} alt="fg" />;
        case Language.ro:
            return <img css={css} src={romania} alt="fg" />;
        case Language.pl:
            return <img css={css} src={poland} alt="fg" />;
        case Language.hr:
            return <img css={css} src={croatia} alt="fg" />;
        case Language.pt:
            return <img css={css} src={portugal} alt="fg" />;
        case Language.hu:
            return <img css={css} src={hungary} alt="fg" />;
    }
};
