import { IProfession } from '../types';
import { useApi, Cache } from '../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchProfessions = () => {
    const api = useApi();

    return useQuery<IProfession[], any>(Cache.professions, ({ signal }) =>
        api.get('/professions', { signal }).then((res) => res.data)
    );
};
