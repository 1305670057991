/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ICustomStyle, IElementSxProps, IElementStyle } from './types';
import { ThemeProvider } from '@mui/material/styles';
import MUIRichTextEditor from 'mui-rte';
import { FormHelperText } from '@mui/material';
import { FieldProps } from 'formik';
import { stateToHTML } from 'draft-js-export-html';

interface IProps {
    label: string;
    controls: string[];
    placeholder: string;
    cs?: ICustomStyle;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
}

export const RichTextEditorInput = ({
    label,
    controls,
    placeholder,
    cs,
    es,
    esx,
    field,
    style,
    form: { setFieldTouched, setFieldValue, errors, touched },
}: IProps & FieldProps) => {
    const [focused, setFocused] = React.useState(false);
    const styles = useStyles(focused, cs, esx);

    return (
        <div
            css={
                es && es.root
                    ? [styles.root, es.root as Interpolation<Theme>, style as Interpolation<Theme>]
                    : [styles.root, style as Interpolation<Theme>]
            }
        >
            <p
                css={
                    es && es.label ? [styles.label, es.label as Interpolation<Theme>] : styles.label
                }
            >
                {label}
            </p>
            <ThemeProvider theme={styles.theme}>
                <MUIRichTextEditor
                    id={field.name}
                    label={placeholder}
                    controls={controls}
                    onChange={(state) => {
                        //To validate empty:
                        //console.log(state.getCurrentContent().getPlainText().trim());
                        //setFieldValue(field.name, state);
                        setFieldValue(field.name, stateToHTML(state.getCurrentContent()));
                    }}
                    onBlur={() => {
                        setFieldTouched(field.name, true);
                        setFocused(false);
                    }}
                    onFocus={() => {
                        setFocused(true);
                        setFieldTouched(field.name, true);
                    }}
                />
                <FormHelperText
                    css={es && es.helperText ? (es.helperText as Interpolation<Theme>) : undefined}
                    sx={
                        esx && esx.helperText
                            ? { ...styles.helperText, ...esx.helperText }
                            : styles.helperText
                    }
                >
                    {Boolean(errors[field.name]) && Boolean(touched[field.name])
                        ? errors[field.name]
                        : ' '}
                </FormHelperText>
            </ThemeProvider>
        </div>
    );
};
