/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { colors } from '../../helpers';

export const useStyles = (open: boolean, noBlur: boolean) => ({
    root: css({
        display: open ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        position: 'fixed',
        backgroundColor: noBlur ? colors.ghostWhite : colors.blurBackground,
        backdropFilter: noBlur ? 'none' : 'blur(10px)',
        zIndex: 9999,
    }),
});
