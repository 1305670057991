import { ICandidateActivityPeriod } from '../../hooks';

export const hasOpenPeriod = (activityPeriods: ICandidateActivityPeriod[]): boolean => {
    let result = false;
    activityPeriods.forEach((activityPeriod) => {
        if (!activityPeriod.endYear && !activityPeriod.endWeek) {
            result = true;
        }
    });
    return result;
};
