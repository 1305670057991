export const localTextPL = {
    selectAll: '(Zaznacz wszystko)',
    selectAllSearchResults: '(Zaznacz wszystkie wyniki wyszukiwania)',
    searchOoo: 'Szukać...',
    blanks: '(Puste miejsca)',
    noMatches: 'Brak dopasowań',
    filterOoo: 'Filtr...',
    equals: 'Equals',
    notEqual: 'Nie równa się',
    empty: 'Wybierz jedną z opcji',
    lessThan: 'Mniej niż',
    greaterThan: 'Większe niż',
    lessThanOrEqual: 'Mniejsze lub równe',
    greaterThanOrEqual: 'Większe lub równe',
    inRange: 'W zasięgu',
    inRangeStart: 'z',
    inRangeEnd: 'do',
    contains: 'Contains',
    notContains: 'Nie zawiera',
    startsWith: 'Zaczyna się od',
    endsWith: 'Kończy się na',
    dateFormatOoo: 'rrrr-mm-dd',
    andCondition: 'I',
    orCondition: 'LUB',
    applyFilter: 'Zastosować',
    resetFilter: 'Resetować',
    clearFilter: 'Jasny',
    cancelFilter: 'Anuluj',
    textFilter: 'Filtr tekstowy',
    numberFilter: 'Filtr liczb',
    dateFilter: 'Filtr daty',
    setFilter: 'Ustaw filtr',
    columns: 'Kolumny',
    filters: 'Filtry',
    pivotMode: 'Tryb przestawny',
    groups: 'Grupy wierszy',
    rowGroupColumnsEmptyMessage: 'Przeciągnij tutaj, aby ustawić grupy wierszy',
    values: 'Wartości',
    valueColumnsEmptyMessage: 'Przeciągnij tutaj, aby zagregować',
    pivots: 'Etykiety kolumn',
    pivotColumnsEmptyMessage: 'Przeciągnij tutaj, aby ustawić etykiety kolumn',
    group: 'Grupa',
    loadingOoo: 'Ładowania...',
    noRowsToShow: 'Brak wierszy do pokazania',
    enabled: 'Włączone',
    pinColumn: 'Kolumna sworzniowa',
    pinLeft: 'Szpilka w lewo',
    pinRight: 'Przypnij w prawo',
    noPin: 'Brak szpilki',
    valueAggregation: 'Agregacja wartości',
    autosizeThiscolumn: 'Automatyczne dopasowywanie rozmiaru tej kolumny',
    autosizeAllColumns: 'Automatyczne dopasowywanie rozmiaru wszystkich kolumn',
    groupBy: 'Grupuj wg:',
    ungroupBy: 'Rozgrupuj według',
    resetColumns: 'Resetowanie kolumn',
    expandAll: 'Rozwiń wszystko',
    collapseAll: 'Zamknij wszystko',
    copy: 'Kopiować',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Kopiuj z nagłówkami',
    paste: 'Wklejać',
    ctrlV: 'Ctrl+V',
    export: 'Eksport',
    csvExport: 'Eksport CSV',
    excelExport: 'Eksport do Excela',
    sum: 'Suma',
    min: 'Min',
    max: 'Max',
    none: 'Żaden',
    count: 'Hrabia',
    avg: 'Średnia',
    filteredRows: 'Filtrowane',
    selectedRows: 'Wybrany',
    totalRows: 'Łączna liczba wierszy',
    totalAndFilteredRows: 'Wierszy',
    more: 'Więcej',
    to: 'do',
    of: 'z',
    page: 'Strona',
    nextPage: 'Następna strona',
    lastPage: 'Ostatnia strona',
    firstPage: 'Pierwsza strona',
    previousPage: 'Poprzednia strona',
    pivotColumnGroupTotals: 'Łączny',
    pivotChartAndPivotMode: 'Wykres przestawny i tryb przestawny',
    pivotChart: 'Wykres przestawny',
    chartRange: 'Zakres wykresu',
    columnChart: 'Kolumna',
    groupedColumn: 'Zgrupowane',
    stackedColumn: 'Stosowy',
    normalizedColumn: '100% skumulowane',
    barChart: 'Bar',
    groupedBar: 'Zgrupowane',
    stackedBar: 'Stosowy',
    normalizedBar: '100% skumulowane',
    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Pączek',
    line: 'Linia',
    xyChart: 'X Y (Punktowy)',
    scatter: 'Rozrzucać',
    bubble: 'Pęcherzyk',
    areaChart: 'Obszar',
    area: 'Obszar',
    stackedArea: 'Stosowy',
    normalizedArea: '100% skumulowane',
    histogramChart: 'Histogram',
    pivotChartTitle: 'Wykres przestawny',
    rangeChartTitle: 'Wykres zakresowy',
    settings: 'Ustawienia',
    data: 'Dane',
    format: 'Format',
    categories: 'Kategorie',
    defaultCategory: '(Brak)',
    series: 'Seria',
    xyValues: 'Wartości X, Y',
    paired: 'Tryb sparowany',
    axis: 'Oś',
    navigator: 'Nawigator',
    color: 'Kolor',
    thickness: 'Grubość',
    xType: 'Typ X',
    automatic: 'Automatyczne',
    category: 'Kategoria',
    number: 'Numer',
    time: 'Godzina',
    xRotation: 'Obrót X',
    yRotation: 'Obrót Y',
    ticks: 'Kleszcze',
    width: 'Szerokość',
    height: 'Wysokość',
    length: 'Długość',
    padding: 'Dopełnienie',
    spacing: 'Odstępy',
    chart: 'Wykres',
    title: 'Tytuł',
    titlePlaceholder: 'Tytuł wykresu - kliknij dwukrotnie, aby edytować',
    background: 'Tło',
    font: 'Czcionka',
    top: 'Do góry',
    right: 'Prawy',
    bottom: 'Dno',
    left: 'Lewy',
    labels: 'Etykiety',
    size: 'Rozmiar',
    minSize: 'Minimalny rozmiar',
    maxSize: 'Maksymalny rozmiar',
    legend: 'Legenda',
    position: 'Pozycja',
    markerSize: 'Rozmiar znacznika',
    markerStroke: 'Obrys znacznika',
    markerPadding: 'Dopełnienie znacznika',
    itemSpacing: 'Odstępy między przedmiotami',
    itemPaddingX: 'Dopełnienie przedmiotu X',
    itemPaddingY: 'Dopełnienie elementu Y',
    layoutHorizontalSpacing: 'Odstępy poziome',
    layoutVerticalSpacing: 'Odstępy pionowe',
    strokeWidth: 'Szerokość obrysu',
    offset: 'Przesunięcie',
    offsets: 'Przesunięcia',
    tooltips: 'Podpowiedzi',
    callout: 'Objaśnienie',
    markers: 'Znaczniki',
    shadow: 'Cień',
    blur: 'Zacierać',
    xOffset: 'Odsunięcie X',
    yOffset: 'Odsunięcie Y',
    lineWidth: 'Szerokość linii',
    normal: 'Normalny',
    bold: 'Śmiały',
    italic: 'Kursywa',
    boldItalic: 'Pogrubiona kursywa',
    predefined: 'Wstępnie zdefiniowanych',
    fillOpacity: 'Krycie wypełnienia',
    strokeOpacity: 'Krycie linii',
    histogramBinCount: 'Liczba pojemników',
    columnGroup: 'Kolumna',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Linia',
    scatterGroup: 'X Y (Punktowy)',
    areaGroup: 'Obszar',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Zgrupowane',
    stackedColumnTooltip: 'Stosowy',
    normalizedColumnTooltip: '100% skumulowane',
    groupedBarTooltip: 'Zgrupowane',
    stackedBarTooltip: 'Stosowy',
    normalizedBarTooltip: '100% skumulowane',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Pączek',
    lineTooltip: 'Linia',
    groupedAreaTooltip: 'Obszar',
    stackedAreaTooltip: 'Stosowy',
    normalizedAreaTooltip: '100% skumulowane',
    scatterTooltip: 'Rozrzucać',
    bubbleTooltip: 'Pęcherzyk',
    histogramTooltip: 'Histogram',
    noDataToChart: 'Brak dostępnych danych do wykreślenia.',
    pivotChartRequiresPivotMode: 'Wykres przestawny wymaga włączonego trybu przestawnego.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Połączony z siecią',
    chartUnlinkToolbarTooltip: 'Odłączony od siatki',
    chartDownloadToolbarTooltip: 'Pobierz wykres',
    ariaHidden: 'ukryty',
    ariaVisible: 'widoczny',
    ariaChecked: 'Sprawdzane',
    ariaUnchecked: 'Niezaznaczone',
    ariaIndeterminate: 'Nieokreślony',
    ariaColumnSelectAll: 'Przełącz opcję Zaznacz wszystkie kolumny',
    ariaInputEditor: 'Edytor danych wejściowych',
    ariaDateFilterInput: 'Wejście filtra daty',
    ariaFilterInput: 'Wejście filtra',
    ariaFilterColumnsInput: 'Filtruj dane wejściowe kolumn',
    ariaFilterValue: 'Wartość filtra',
    ariaFilterFromValue: 'Filtruj według wartości',
    ariaFilterToValue: 'Filtruj według wartości',
    ariaFilteringOperator: 'Operator filtrowania',
    ariaColumn: 'Kolumna',
    ariaColumnGroup: 'Grupa kolumn',
    ariaRowSelect: 'Naciśnij SPACJĘ, aby zaznaczyć ten wiersz',
    ariaRowDeselect: 'Naciśnij SPACJA, aby usunąć zaznaczenie tego wiersza',
    ariaRowToggleSelection: 'Naciśnij spację, aby przełączyć wybór wiersza',
    ariaRowSelectAll: 'Naciśnij spację, aby przełączyć wybór wszystkich wierszy',
    ariaToggleVisibility: 'Naciśnij SPACJĘ, aby przełączyć widoczność',
    ariaSearch: 'Szukać',
    ariaSearchFilterValues: 'Wyszukaj wartości filtru',
    ariaLabelColumnMenu: 'Menu kolumnowe',
    ariaLabelCellEditor: 'Edytor komórek',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Wybierz pole',
    ariaLabelTooltip: 'Podpowiedź',
    ariaLabelContextMenu: 'Menu kontekstowe',
    ariaLabelSubMenu: 'Podmenu',
    ariaLabelAggregationFunction: 'Funkcja agregacji',
};
