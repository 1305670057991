import { useQuery } from 'react-query';
import { Cache, useApi } from '../../../../helpers';
import { IScoreboardAmbassador } from './types';

export const useFetchScoreboard = (year: number) => {
    const api = useApi();

    return useQuery<IScoreboardAmbassador[], any>([Cache.dashboardScoreboard, year], ({ signal }) =>
        api.get(`/dashboards/scoreboard/${year}`, { signal }).then((res) => res.data)
    );
};
