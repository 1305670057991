/**@jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';

export const ripple = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(10);
        opacity: 0.375;
    }
    100% {
        transform: scale(35);
        opacity: 0;
    }
`;
