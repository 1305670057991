import * as React from 'react';
import { useStyles } from './style';
import { IAmbassador } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

interface IProps {
    ambassador: IAmbassador;
}

export const InfoPanel = ({ ambassador }: IProps) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div style={styles.panel}>
            <div>
                <p style={styles.panelText}>{t('text.createdBy')}:&nbsp;</p>
                <p style={styles.panelDataText}>{ambassador.createdBy}</p>
            </div>
            <div>
                <p style={styles.panelText}>{t('text.createdOn')}:&nbsp;</p>
                <p style={styles.panelDataText}>
                    {new Date(ambassador.createdAt).toLocaleDateString()}
                </p>
            </div>
            <div>
                <p style={styles.panelText}>{t('text.modifiedBy')}:&nbsp;</p>
                <p style={styles.panelDataText}>{ambassador.lastModifiedBy}</p>
            </div>
            <div>
                <p style={styles.panelText}>{t('text.modifiedOn')}:&nbsp;</p>
                <p style={styles.panelDataText}>
                    {new Date(ambassador.lastModifiedAt).toLocaleDateString()}
                </p>
            </div>
        </div>
    );
};
