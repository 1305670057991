export const colors = {
    white: '#ffffff',
    black: '#000000',
    ghostWhite: '#f8f8ff',
    gray: '#808080',
    lightGrey: '#d3d3d3',
    orange: '#ff7030',
    dashboardOrange: '#F7D8CB',
    dashboardYellow: '#F6F7CB',
    blue: '#2a5dc4',
    blueRGB: 'rgb(42, 93, 196)',
    red: '#b71c1c',
    green: '#3CB371',
    dashboardGreen: '#C4EBD5',
    blurBackground: 'rgba(0, 0, 0, 0.6)',
    buttonTextColor: '#ffffff',
    darkBoxShadow: 'rgba(0, 0, 0, 0.2)',
    lightBoxShadow: 'rgba(255, 255, 255, 0.2)',
    snackSuccessBackground: '#008000',
    snackErrorBackground: '#b22222',
    globeBackground: 'rgba(0, 0, 0, 0.1)',
    resendQROnHover: 'rgba(42, 93, 168, 0.2)',
    gridHeaderBackground: '#f8f8f8',
    disabledFieldTextColor: 'rgba(0, 0, 0, 0.38)',
    backgroundColor: 'linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
};
