/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = () =>
    designStyles({
        closeButton: {
            position: 'absolute',
            top: '2rem',
            right: '2rem',
            fontSize: '3rem',
            color: colors.white,
            transition: 'all 0.2s',
            '&:hover': {
                color: colors.orange,
                transform: 'scale(1.2)',
            },
        },
        image: {
            width: '90vw',
        },
    });
