/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IAmbassador, useFetchAmbassadors, useFetchPipeline } from '../hooks';
import { Role, handleQueryError, useAuth } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMediaQuery, Skeleton } from '@mui/material';
import {
    Page,
    NumberSelectInput,
    PipelineAmbassadorsTable,
    PipelineCandidatesTable,
} from '../../../components';
import { ISelectItem } from '../../../components/inputs/number-select/types';
import { AmbassadorComboBoxInput } from '../ambassador-combo-box/AmbassadorComboBox';

export const AmbassadorPipelineDashboard = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const [year, setYear] = React.useState(moment().year());
    const [ambassador, setAmbassador] = React.useState<IAmbassador | null>(null);

    const ambassadorsResponse = useFetchAmbassadors(!currentUserHasRoles(Role.ambassador));

    React.useEffect(() => {
        if (ambassadorsResponse.data && ambassadorsResponse.data.length > 0) {
            setAmbassador(ambassadorsResponse.data[0]);
        }
    }, [ambassadorsResponse.data]);

    const pipelineResponse = useFetchPipeline(
        currentUserHasRoles(Role.ambassador) ? true : !!ambassador?.id,
        year,
        ambassador?.id,
        ''
    );

    const getYears = React.useCallback((): ISelectItem[] => {
        const years: ISelectItem[] = [];
        for (let i = 2024; i <= moment().year(); i++) {
            years.push({
                value: i,
                item: i.toString(),
            });
        }
        return years;
    }, []);

    if (ambassadorsResponse.error) {
        return handleQueryError(ambassadorsResponse.error, history);
    }
    if (pipelineResponse.error) {
        return handleQueryError(pipelineResponse.error, history);
    }
    return (
        <Page style={styles.root} title={t('text.ambassadorPipeline')}>
            <div style={styles.filters}>
                {!currentUserHasRoles(Role.ambassador) && ambassadorsResponse.data ? (
                    <AmbassadorComboBoxInput
                        style={styles.filtersAmbassador}
                        label={t('form.label.ambassador')}
                        placeholder={t('form.placeholder.ambassador')}
                        options={ambassadorsResponse.data}
                        value={ambassador}
                        setAmbassador={setAmbassador}
                    />
                ) : !currentUserHasRoles(Role.ambassador) ? (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.ambassadorSkeleton}
                    />
                ) : null}
                <NumberSelectInput
                    css={styles.filtersNumberSelect}
                    label={t('form.label.year')}
                    value={year}
                    setValue={setYear}
                    items={getYears()}
                    cs={{
                        fontSize: '1.5rem',
                    }}
                />
            </div>
            {ambassadorsResponse.isLoading || pipelineResponse.isLoading ? (
                <React.Fragment>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.ambassadorGridSkeleton}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.candidateGridSkeleton}
                    />
                </React.Fragment>
            ) : null}
            {pipelineResponse.data && pipelineResponse.data.pipelineAmbassadors.length > 0 ? (
                <React.Fragment>
                    <PipelineAmbassadorsTable
                        ambassadors={pipelineResponse.data.pipelineAmbassadors}
                        maxHeight="55vh"
                    />
                    <PipelineCandidatesTable
                        candidates={pipelineResponse.data.pipelineCandidates}
                        maxHeight="45vh"
                    />
                </React.Fragment>
            ) : null}
            {(currentUserHasRoles(Role.ambassador) &&
                pipelineResponse.data &&
                pipelineResponse.data.pipelineAmbassadors.length === 0) ||
            (!currentUserHasRoles(Role.ambassador) &&
                (ambassador === null ||
                    (pipelineResponse.data &&
                        pipelineResponse.data.pipelineAmbassadors.length === 0))) ? (
                <div style={styles.noDataContainer}>
                    <p style={styles.noData}>{t('text.noDataToShow')}</p>
                </div>
            ) : null}
        </Page>
    );
};
