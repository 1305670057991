import { IAmbassadorWorkedHour } from '../hooks/fetch-ambassador-declaration-period/types/ambassador-worked-hour.interface';
import { IAmbassadorAfasDeclaration } from '../hooks/fetch-ambassador-declaration-period/types/ambassador-afas-declaration.interface';

export const getAmbassadorDeclaration = (
    ambassadorWorkedHour: IAmbassadorWorkedHour,
    ambassadorAfasDeclarations: IAmbassadorAfasDeclaration[]
): string => {
    let result = 0;
    if (
        ambassadorWorkedHour.isActive &&
        ambassadorWorkedHour.amount >= ambassadorWorkedHour.minWorkedHourPerWeekForFee
    ) {
        ambassadorAfasDeclarations
            .filter(
                (afasDeclaration) =>
                    afasDeclaration.ambassador.id === ambassadorWorkedHour.ambassador.id
            )
            .forEach((afasDeclaration) => {
                result += afasDeclaration.amount * afasDeclaration.fee;
            });
    }

    return result === 0 ? '0.00' : result.toFixed(2);
};
