/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchCandidate } from './hooks';
import {
    handleQueryError,
    IRouteParams,
    colors,
    useAuth,
    Role,
    routePath,
    useSnack,
    useDeleteModal,
} from '../../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton, Tooltip, IconButton } from '@mui/material';
import { Page, Button, Snack } from '../../../components';
import { AiOutlinePlus } from 'react-icons/ai';
import { ActivityPeriods } from './activity-periods/ActivityPeriods';
import { DeleteModal } from './activity-periods/delete-modal/DeleteModal';

export const CandidateActivityPeriods = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles, getCurrentUser } = useAuth();
    const history = useHistory();
    const { id } = useParams<IRouteParams>();
    const { t } = useTranslation();
    const { isSnackOpen, snackVariant, snackMessage, closeSnack, openSnack } = useSnack();
    const { isDeleteOpen, itemToDeleteId, openDelete, closeDelete } = useDeleteModal();

    const { isLoading, data, error } = useFetchCandidate(id);

    React.useEffect(() => {
        if (data) {
            if (
                currentUserHasRoles(Role.admin) &&
                ((data.ambassador &&
                    data.ambassador.admin.user.id !== getCurrentUser().userId &&
                    data.user.department!.id !== getCurrentUser().departmentId) ||
                    (data.recruitmentAgency &&
                        data.recruitmentAgency.admin.user.id !== getCurrentUser().userId))
            ) {
                history.push(routePath.unauthorizedError.create());
            } else if (
                currentUserHasRoles(Role.ambassador) &&
                data.ambassador &&
                data.ambassador.user.id !== getCurrentUser().userId
            ) {
                history.push(routePath.unauthorizedError.create());
            } else if (
                currentUserHasRoles(Role.recruitmentAgency) &&
                data.recruitmentAgency &&
                data.recruitmentAgency.user.id !== getCurrentUser().userId
            ) {
                history.push(routePath.unauthorizedError.create());
            }
        }
    }, [currentUserHasRoles, getCurrentUser, history, data]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page style={styles.root} title={t('text.candidateActivity')}>
            {isSnackOpen ? (
                <Snack
                    open={isSnackOpen}
                    variant={snackVariant}
                    message={snackMessage}
                    closeSnack={closeSnack}
                    cs={{
                        top: '8rem',
                    }}
                />
            ) : null}
            {isDeleteOpen ? (
                <DeleteModal
                    open={isDeleteOpen}
                    itemToDeleteId={itemToDeleteId}
                    openSnack={openSnack}
                    closeModal={closeDelete}
                />
            ) : null}
            {isLoading ? (
                <React.Fragment>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.candidateSkeleton}
                    />
                    <Skeleton variant="rectangular" animation="wave" style={styles.tableSkeleton} />
                </React.Fragment>
            ) : null}
            {data ? (
                <React.Fragment>
                    <h2 style={styles.candidate}>
                        {data.employeeNumber
                            ? `${data.user.firstName} ${data.user.lastName} - ${data.employeeNumber}`
                            : `${data.user.firstName} ${data.user.lastName}`}
                    </h2>
                    <div style={styles.actionBar}>
                        <Tooltip
                            arrow
                            placement="left"
                            title={<h1>{t('text.createActivityPeriod')}</h1>}
                        >
                            <IconButton
                                size="large"
                                style={styles.actionBarCreateIcon}
                                onClick={() =>
                                    history.push(
                                        routePath.createCandidateActivityPeriod.create(data.id)
                                    )
                                }
                            >
                                <AiOutlinePlus />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ActivityPeriods data={data.activityPeriods} openDelete={openDelete} />
                </React.Fragment>
            ) : null}
            <Button
                style={styles.button}
                onClick={() => history.push(routePath.candidates.create())}
                cs={{
                    color: colors.blue,
                    padding: '1rem 1rem',
                    fontSize: '1rem',
                }}
            >
                {t('text.back')}
            </Button>
        </Page>
    );
};
