/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { LightenDarkenColor, colors } from '../../helpers';
import { ICustomStyle } from './types';

export const useStyles = (
    panel: 'first' | 'second',
    { width, margin, contentHeight }: ICustomStyle
) => ({
    root: css({
        width: width,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        boxShadow: `5px 5px 8px 5px ${colors.darkBoxShadow}`,
        margin: margin,
    }),
    toggleButtonBox: css({
        width: '100%',
        height: '4rem',
        display: 'flex',
        borderBottom: `solid 1px ${colors.blue}`,
    }),
    button: css({
        textTransform: 'uppercase',
        textDecoration: 'none',
        border: 'none',
        userSelect: 'none',
        width: '50%',
        height: '100%',
        backgroundColor: colors.white,
        color: colors.blue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'inherit',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        cursor: 'pointer',
        transition: 'all 0.4s',
        '&:hover': {
            backgroundColor: LightenDarkenColor(colors.white, -20),
        },
    }),
    activeButton: css({
        textTransform: 'uppercase',
        textDecoration: 'none',
        border: 'none',
        userSelect: 'none',
        width: '50%',
        height: '100%',
        backgroundColor: colors.blue,
        color: colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'inherit',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        cursor: 'pointer',
        transition: 'all 0.4s',
        '&:hover': {
            backgroundColor: LightenDarkenColor(colors.blue, 20),
        },
    }),
    content: css({
        display: 'flex',
        height: contentHeight,
        backgroundColor: colors.white,
    }),
    firstPanel: css({
        width: panel === 'first' ? '100%' : 0,
        order: panel === 'first' ? 0 : 1,
        overflow: 'hidden',
        transition: 'all 0.1s',
    }),
    secondPanel: css({
        width: panel === 'second' ? '100%' : 0,
        order: panel === 'second' ? 0 : 1,
        overflow: 'hidden',
        transition: 'all 0.1s',
    }),
});
