import { useQuery } from 'react-query';
import { IActivityPeriodAmbassador, IAmbassadorActivityPeriod } from '../../../hooks/types';
import { Cache, useApi } from '../../../../../../../helpers';

export const useFetchActivityPeriod = (activityPeriodId: string) => {
    const api = useApi();

    return useQuery<
        IActivityPeriodAmbassador & { ambassadorActivityPeriod: IAmbassadorActivityPeriod },
        any
    >([Cache.ambassadorActivityPeriod, activityPeriodId], ({ signal }) =>
        api
            .get(`/ambassador-activity-periods/${activityPeriodId}`, { signal })
            .then((res) => res.data)
    );
};
