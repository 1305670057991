import { IUpdateProfessionVariables } from './types';
import { IProfession } from '../../../hooks';
import { useApi } from '../../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useUpdateProfession = (professionId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<IProfession>, any, IUpdateProfessionVariables>(({ body }) =>
        api.patch(`/professions/${professionId}`, body)
    );
};
