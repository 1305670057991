/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchDepartment, useUpdateDepartment } from './hooks';
import { IRouteParams, handleError, handleQueryError, routePath } from '../../../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Page } from '../../../../components';
import { DepartmentForm } from '../department-form/DepartmentForm';

export const UpdateDepartment = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { id } = useParams<IRouteParams>();
    const { t } = useTranslation();

    console.log(id);

    const { isLoading, data, error } = useFetchDepartment(id);
    const update = useUpdateDepartment(id);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            isPageSubmitting={update.isLoading}
            title={t('text.updateDepartment')}
            style={styles.root}
        >
            {isLoading ? (
                <div style={styles.formSkeleton}>
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            ) : null}
            {data ? (
                <DepartmentForm
                    mutationType="update"
                    initialValues={{
                        en: data.en,
                        nl: data.nl,
                        ro: data.ro,
                        pl: data.pl,
                        hr: data.hr,
                        pt: data.pt,
                        hu: data.hu,
                        email: data.email,
                        confirmEmail: data.email,
                    }}
                    onSubmit={async ({ en, nl, ro, pl, hr, pt, hu, email }, { setErrors }) => {
                        try {
                            await update.mutateAsync({
                                body: {
                                    en: en.trim(),
                                    nl: nl.trim(),
                                    ro: ro.trim(),
                                    pl: pl.trim(),
                                    hr: hr.trim(),
                                    pt: pt.trim(),
                                    hu: hu.trim(),
                                    email: email.trim(),
                                },
                            });
                            history.push(routePath.departmentSettings.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText, data }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 400) {
                                    if (data.message === 'Department is duplicated') {
                                        setErrors({
                                            en: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                            nl: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                            ro: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                            pl: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                            hr: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                            pt: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                            hu: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                        });
                                    } else if (data.message === 'Email is already registered') {
                                        setErrors({
                                            email: t('form.error.emailIsRegistered'),
                                            confirmEmail: t('form.error.emailIsRegistered'),
                                        });
                                    } else {
                                        alert(statusText);
                                    }
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                />
            ) : null}
        </Page>
    );
};
