/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = (isCreated: boolean, widthBelow600px: boolean) =>
    designStyles({
        root: {
            width: '15rem',
            height: '17.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '0.5rem',
            overflow: 'hidden',
            boxShadow: `5px 5px 8px 5px ${colors.darkBoxShadow}`,
            margin: widthBelow600px ? '3rem 0' : '3rem',
            color: isCreated ? colors.blue : colors.gray,
            fontFamily: 'inherit',
            padding: '1.5rem',
            backgroundColor: colors.white,
            border: isCreated ? `1px solid ${colors.blue}` : 'none',
            userSelect: 'none',
        },
        year: {
            fontSize: '1.25rem',
            alignSelf: 'end',
        },
        week: {
            fontSize: '2rem',
            fontWeight: 'bold',
        },
        weekNumber: {
            fontSize: '3rem',
            fontWeight: 'bold',
        },
        button: {
            marginTop: '2rem',
            width: '70%',
        },
    });
