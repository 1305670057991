/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useCreateDepartment } from './hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../../components';
import { DepartmentForm } from '../department-form/DepartmentForm';
import { handleError, routePath } from '../../../../helpers';

export const CreateDepartment = () => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const create = useCreateDepartment();

    return (
        <Page
            isPageSubmitting={create.isLoading}
            title={t('text.createDepartment')}
            style={styles.root}
        >
            <DepartmentForm
                mutationType="create"
                initialValues={{
                    nl: '',
                    en: '',
                    ro: '',
                    pl: '',
                    hr: '',
                    pt: '',
                    hu: '',
                    email: '',
                    confirmEmail: '',
                }}
                onSubmit={async ({ en, nl, ro, pl, hr, pt, hu, email }, { setErrors }) => {
                    try {
                        await create.mutateAsync({
                            body: {
                                en: en.trim(),
                                nl: nl.trim(),
                                ro: ro.trim(),
                                pl: pl.trim(),
                                hr: hr.trim(),
                                pt: pt.trim(),
                                hu: hu.trim(),
                                email: email.trim(),
                            },
                        });
                        history.push(routePath.departmentSettings.create());
                    } catch (e) {
                        handleError(e, ({ status, statusText, data }) => {
                            if (status === 401 || status === 403) {
                                history.push(routePath.unauthorizedError.create());
                            } else if (status === 400) {
                                if (data.message === 'Department is duplicated') {
                                    setErrors({
                                        en: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                        nl: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                        ro: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                        pl: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                        hr: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                        pt: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                        hu: t('form.error.oneOfTheDepartmentsIsRegistered'),
                                    });
                                } else if (data.message === 'Email is already registered') {
                                    setErrors({
                                        email: t('form.error.emailIsRegistered'),
                                        confirmEmail: t('form.error.emailIsRegistered'),
                                    });
                                } else {
                                    alert(statusText);
                                }
                            } else if (status === 500) {
                                history.push(routePath.internalServerError.create());
                            } else alert(statusText);
                        });
                    }
                }}
            />
        </Page>
    );
};
