import * as React from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { useAuth } from '../auth-context/AuthContext';
import { Role } from '../shared-types/role.enum';

interface IProps {
    roles?: Role[];
}

export const PrivateRoute = ({ roles, ...rest }: IProps & RouteProps) => {
    const { currentUser } = useAuth();

    if (currentUser && currentUser.areTermsAccepted) {
        if (roles) {
            let authorized = false;
            for (let i = 0; i < currentUser.roles.length; i++) {
                const role = currentUser.roles[i];
                if (roles.includes(role)) {
                    authorized = true;
                    break;
                }
            }
            return authorized ? <Route {...rest} /> : <Redirect to={{ pathname: '/401' }} />;
        }
        return <Route {...rest} />;
    }
    return <Redirect to={{ pathname: '/401' }} />;
};
