/**@jsxImportSource @emotion/react */
import { designStyles } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        cardSkeleton: {
            width: '15rem',
            height: '17.5rem',
            margin: widthBelow600px ? '3rem 0' : '3rem',
        },
    });
