/**@jsxImportSource @emotion/react */
import { colors, designStyles } from '../../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '90vw',
            height: '45vh',
            maxHeight: '45vh',
            margin: '2rem 0 4rem 0',
            background: colors.white,
            boxShadow: `5px 5px 8px 5px ${colors.darkBoxShadow}`,
            borderRadius: '0.5rem',
            overflow: 'hidden',
        },
        title: {
            background: colors.blue,
            color: colors.white,
            width: '100%',
            height: '5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            userSelect: 'none',
        },
        wrapper: {
            overflow: 'auto',
            width: '100%',
            height: '100%',
            position: 'relative',
        },
        table: {
            width: '100%',
            borderSpacing: 0,
        },
        tableHeader: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            background: colors.blue,
            color: colors.white,
            userSelect: 'none',
        },
        tableHeaderSerialNumberCell: {
            minWidth: '10rem',
            width: '10%',
            textAlign: 'center',
            padding: '1rem 0 1rem 0',
        },
        tableHeaderEmployeeNumberCell: {
            minWidth: '10rem',
            width: '20%',
            textAlign: 'center',
            padding: '1rem 0 1rem 0',
        },
        tableHeaderDateCell: {
            minWidth: '20rem',
            width: '25%',
            textAlign: 'center',
            padding: '1rem 0 1rem 0',
        },
        tableHeaderActionCell: {
            minWidth: '10rem',
            width: '20%',
            textAlign: 'center',
            padding: '1rem 0 1rem 0',
        },
        tableRow: {
            fontSize: '1.5rem',
            color: colors.blue,
        },
        tableRowSerialNumberCell: {
            minWidth: '10rem',
            width: '10%',
            textAlign: 'center',
            padding: '0.5rem 0 0.5rem 0',
        },
        tableRowEmployeeNumberCell: {
            minWidth: '10rem',
            width: '20%',
            textAlign: 'center',
            padding: '0.5rem 0 0.5rem 0',
        },
        tableRowDateCell: {
            minWidth: '20rem',
            width: '25%',
            textAlign: 'center',
            padding: '0.5rem 0 0.5rem 0',
        },
        tableRowActionCell: {
            minWidth: '10rem',
            width: '20%',
            textAlign: 'center',
            padding: '0.5rem 0 0.5rem 0',
        },
        tableNoDataRow: {
            position: 'absolute',
            height: '90%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        tableRowNoDataCell: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: colors.blue,
            userSelect: 'none',
        },
    });
