/**@jsxImportSource @emotion/react */
import { designStyles } from '../../../../../helpers';

export const useStyles = () =>
    designStyles({
        skeleton: {
            margin: '4rem 0 4rem 0',
            width: '90vw',
            height: '50vh',
        },
    });
