import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { ICloseActivityPeriodVariables } from './types';
import { ICandidateActivityPeriod } from '../fetch-activity-period/types';
import { useApi } from '../../../../../../../helpers';

export const useCloseActivityPeriod = (activityPeriodId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<ICandidateActivityPeriod>, any, ICloseActivityPeriodVariables>(
        ({ body }) => api.patch(`/candidate-activity-periods/${activityPeriodId}/end-date`, body)
    );
};
