/**@jsxImportSource @emotion/react */
import { designStyles } from '../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    });
