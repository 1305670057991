/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchCandidate, useUpdateCandidateEmployeeNumber } from './hooks';
import { IRouteParams, colors, handleError, handleQueryError, routePath } from '../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { Page, Button } from '../../../components';
import { useMediaQuery, Skeleton } from '@mui/material';
import { AfasEmployeeComboBox } from '../afas-employee-combo-box/AfasEmployeeComboBox';

export const UpdateCandidateEmployeeNumber = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchCandidate(id);
    const updateCandidateEmployeeNumber = useUpdateCandidateEmployeeNumber(id);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page isPageSubmitting={updateCandidateEmployeeNumber.isLoading} style={styles.root}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.afasLink')}</h1>
                {isLoading ? (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        css={styles.candidateNameSkeleton}
                    />
                ) : null}
                {data ? (
                    <p css={styles.contentCandidateName}>{`${t('text.candidate')}: ${
                        data.user.firstName
                    } ${data.user.lastName}`}</p>
                ) : null}
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            afasEmployee: null,
                        } as IFormValues
                    }
                    validate={({ afasEmployee }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!afasEmployee) {
                            errors.afasEmployee = t('form.error.requiredField');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ afasEmployee }, { setErrors }) => {
                        try {
                            await updateCandidateEmployeeNumber.mutateAsync({
                                body: {
                                    employeeNumber: afasEmployee!.id.trim(),
                                },
                            });
                            history.push(routePath.candidates.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText, data }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 400) {
                                    if (data.message === 'Employee number is already registered') {
                                        setErrors({
                                            afasEmployee: t('form.error.candidateIsRegistered'),
                                        });
                                    } else if (
                                        data.message === 'Candidate has already an employee number'
                                    ) {
                                        setErrors({
                                            afasEmployee: t(
                                                'form.error.candidateHasEmployeeNumber'
                                            ),
                                        });
                                    } else if (data.message === 'Terms are not accepted') {
                                        setErrors({
                                            afasEmployee: t('form.error.termsNotAccepted'),
                                        });
                                    } else {
                                        alert(statusText);
                                    }
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form style={styles.contentForm} noValidate>
                            <Field
                                style={styles.formField}
                                name="afasEmployee"
                                label={t('form.label.employeeNumber')}
                                placeholder={t('form.placeholder.employeeNumber')}
                                fontSize="2rem"
                                inputColor={colors.blue}
                                textColor={colors.blue}
                                disabled={isSubmitting}
                                component={AfasEmployeeComboBox}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.link')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.push(routePath.candidates.create())}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Page>
    );
};
