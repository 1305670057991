/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';

interface IProps {
    titleEffect?: boolean;
}

export const English = ({ titleEffect }: IProps) => {
    const styles = useStyles(titleEffect);

    return (
        <div style={styles.root}>
            <h3 css={styles.titel}>General terms and conditions ORANJELINK portal</h3>
            <p style={styles.bottomSpaceParagraph}>
                The ORANJELINK Portal (hereinafter: Portal) is a service provided by Oranjegroep
                Holding B.V. on behalf of Oranjegroep. With regard to the use of the Portal, these
                specific General Terms and Conditions of ORANJELINK Portal (hereinafter: General
                Terms and Conditions) apply in the context of the so-called Ambassador Arrangement
                (hereinafter: Arrangement). The General Terms and Conditions are supplemented to the
                extent that an addendum to the employment law agreement or assignment agreement
                (hereinafter: Addendum) has been agreed with the User of the Portal. In the event of
                any conflict between the General Terms and Conditions and the Addendum, the Addendum
                shall prevail.
            </p>
            <div style={styles.mainSubTitle}>
                <p style={styles.mainSubTitelFirstParagraph}>A.</p>
                <p style={styles.mainSubTitelSecondParagraph}>Agree</p>
            </div>
            <p style={styles.bottomSpaceParagraph}>
                Please read these Terms and Conditions carefully. By registering in the Portal you
                declare to be bound by the General Terms and Conditions as amended from time to time
                and you qualify as a User within the meaning of these terms and conditions.
            </p>
            <div style={styles.mainSubTitle}>
                <p style={styles.mainSubTitelFirstParagraph}>B.</p>
                <p style={styles.mainSubTitelSecondParagraph}>General user rights</p>
            </div>
            <p style={styles.bottomSpaceParagraph}>
                Oranjegroep has developed the Portal with the aim of providing User with certain
                reporting and related information services (hereinafter individually referred to as
                a 'Service' and collectively the 'Services'). The content of the Portal may change.
                Oranjegroep would like to point out that certain Services may not be available in
                certain jurisdictions. Oranjegroep may, at its sole discretion, grant the User
                access to all or certain specific Services. User agrees to use these Services solely
                for its (internal) business purposes and in accordance with the terms and
                conditions.
            </p>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>1.</p>
                <p style={styles.subTitelSecondParagraph}>Definitions</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep: Oranjegroep Holding BV and/or its affiliated parties. In
                            relation to any legal entity, an affiliated party means any entity that
                            is directly or indirectly controlled by that legal entity, any entity
                            that directly or indirectly controls the legal entity or any entity that
                            is directly or indirectly under common control with the legal entity.
                            Control over an entity or legal entity means ownership of a majority of
                            the votes for the entity or legal entity.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            User: any natural person or legal entity who is or wishes to be in
                            contact with Oranjegroep via the Portal. Where the term User is used in
                            these terms and conditions, this means, if and to the extent applicable,
                            User and/or End User.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Legal representative: statutory director(s) of a company. This does not
                            include agents/authorized representatives.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            Services: all forms and downloads and other data made available via the
                            Portal. Where the term Service is used in these terms and conditions, it
                            refers to product and/or service.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            Account: access to various secure services; A User gains access by
                            entering an access code and associated password.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            Portal: Secure online environment in which Users and End Users can share
                            data and documents with Oranjegroep.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            End User: a User who qualifies as a Legal Representative and assigns a
                            natural person to create an Account in the Portal.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>1.8</td>
                        <td style={styles.textTableRowSecondCell}>
                            User reference: persons to whom Oranjegroep has provided an Account,
                            which provides secure access to the site.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>2.</p>
                <p style={styles.subTitelSecondParagraph}>Access to the Services</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep may, in its sole discretion, from time to time decide to
                            withdraw certain Services in order to change the functionality of the
                            Services or to expand or limit the User's access to the Services.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep can also offer the User new Services or expand existing
                            Services as they become available on the Portal. Oranjegroep is not
                            obliged to inform the User about any (material) change in the Services
                            offered or any withdrawal or extension of the Services via the Portal.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User is not entitled to demand specific Services withdrawn by
                            Oranjegroep.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            The Portal gives the User the opportunity to submit data and documents
                            to Oranjegroep electronically and in a secure manner.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            Users log in with their Portal account. The Portal is only accessible to
                            persons to whom Oranjegroep has given a User Reference has provided,
                            which provides secure access to the site. Paragraphs 6 to 9 only apply
                            to use by third parties.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User informs Oranjegroep about the persons (within the User's
                            organization) to whom access to the Portal may be granted (hereinafter:
                            End User).
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            A User who is also a legal representative is authorized to appoint one
                            or more End Users to use the Portal. The director of a company
                            guarantees that End Users comply with these conditions and is liable for
                            the consequences of non-compliance therewith. The director of a company
                            is obliged to remove an End User when an End User is no longer
                            authorized to act on behalf of the company.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.8</td>
                        <td style={styles.textTableRowSecondCell}>
                            User hereby declares and warrants that: <br />
                            (i) the End User(s) is/are authorized to access and use the Portal and
                            the Services on behalf of the User, that <br />
                            (ii) the End User(s) has/have experience and knowledge with regard to
                            the matters for which they will use the Portal and the Services and that{' '}
                            <br /> (iii) User is responsible for the acts and omissions of each End
                            User.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>2.9</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User agrees that anyone who accesses the Portal using an assignment
                            provided by Oranjegroep to an End User associated with the User must be
                            considered an End User acting on behalf of the User. The User and all
                            End Users must treat the User references of the End Users as strictly
                            confidential and do everything reasonably within their power to ensure
                            that confidentiality.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>3.</p>
                <p style={styles.subTitelSecondParagraph}>
                    Use of the accounts, access codes and passwords
                </p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            User acknowledges and agrees that any request to provide access to the
                            Portal to a User or End User may be approved or rejected by Oranjegroep
                            in its sole discretion and that no reason is required for such rejection
                            or approval. Oranjegroep is entitled to change, block or delete an
                            account at any time. Oranjegroep is not obliged to inform the User or
                            End User about this.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User is fully responsible for the use and management of her
                            account(s), access code(s) and password(s) by or on his behalf. The User
                            will keep the password(s) completely confidential and ensure that they
                            are not used by unauthorized third parties or for other purposes. The
                            User will also be careful in using the access code(s).
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            User will (i) always follow all reasonable instructions from
                            Oranjegroep; (ii) not disrupt the normal operation or integrity of the
                            Portal; (iii) not modify the information contained in the Portal, nor
                            restrict or otherwise affect access to the Portal; (iv) prevent
                            inconvenience to other users of the portal; and (v) ensure that any
                            information it communicates through the portal does not infringe the
                            rights of third parties or contain viruses or worms.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep reserves the right to trace every action in the Portal, such
                            as logging in, reading, downloading, uploading and sending electronic
                            forms, to the account with which the User logs in.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            If the User no longer has access to the Portal, Oranjegroep will do what
                            it can to restore this access within a reasonable period.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            As soon as the User knows or suspects that an access code or password is
                            or may be known to someone else who is not authorized, he must
                            immediately report this to Oranjegroep.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep reserves the right to revoke the User Credentials of an End
                            User, to terminate an End User's access and to take any further measures
                            that Oranjegroep deems appropriate after improper use or for any other
                            reason.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.8</td>
                        <td style={styles.textTableRowSecondCell}>
                            A request to change the rights and authorities of End Users or to block
                            or unblock an End User must be submitted by the User via the digital
                            reporting form available in the Portal.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>3.9</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep accepts no liability for damage suffered by the User as a
                            result of or in connection with loss, theft or misuse of a User
                            Reference or the inability for a User to access the Portal due to loss,
                            theft or misuse. of a User Reference or blocking or unblocking a User
                            Reference.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>4.</p>
                <p style={styles.subTitelSecondParagraph}>Data exchange</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User declares that he will immediately inform Oranjegroep of any
                            changes to the data of the User and/or End Users (including residential
                            and/or business address, personal data, financial data, authority and
                            status with regard to End Users) by means of the digital reporting form
                            or by sending an e-mail to info@oranjegroep.nl.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            User declares that the information he submits upon registration, or at
                            any time thereafter, is true, accurate and complete. Oranjegroep may
                            assume that such information is true, accurate and complete and is not
                            required to verify such information unless, at the time Oranjegroep
                            views the information, there is reasonable doubt as to the veracity,
                            accuracy or completeness of the information submitted .
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep ensures to the best of its ability that the data exchange
                            via the Portal takes place in a sufficiently reliable manner, given the
                            nature and content of the data exchange and its purpose.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            The data provided by the User is used by Oranjegroep for the execution
                            of the agreement with the User (the underlying and future employment law
                            agreement(s) and/or assignment agreement(s)).
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>4.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            The user remains independently responsible at all times for compliance
                            with its (legal) obligations.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>5.</p>
                <p style={styles.subTitelSecondParagraph}>Technical facilities</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>5.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User will provide, at his own expense and risk, the necessary
                            technical facilities, such as hardware and software, that are required
                            to use the Portal. The hardware and software used by the User is
                            protected against unauthorized use according to common insights.
                            Hardware and software may also not contain malicious software.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>5.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User is responsible and must provide all software, telephone and
                            internet connections and other equipment that is/are necessary for the
                            User's access to and use of the Portal and the Services. User is also
                            responsible for all relevant licenses and/or permissions required for
                            this purpose.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>6.</p>
                <p style={styles.subTitelSecondParagraph}>Intellectual Property Rights</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep Holding BV, all rights reserved.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            All claims, ownership rights and intellectual property rights (including
                            but not limited to copyright, patent rights, database rights and
                            trademark rights) relating to the Portal, as well as all information
                            transferred to or through the Portal, any linked domain, software and
                            the content placed on it by or on behalf of Oranjegroep are vested in
                            and remain in the hands of Oranjegroep or its licensors.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User may use the Portal and any linked domain, software and the
                            content placed thereon by or on behalf of Oranjegroep only, on a
                            non-exclusive basis, for the purposes stated in these terms and
                            conditions.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            All intellectual property rights (including but not limited to
                            copyright, patents, databases and trademark rights) relating to any
                            information exchanged by or on behalf of the User belong to the User.
                            Oranjegroep uses this information exclusively for the purposes stated in
                            these conditions.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User is not permitted to transfer any research provided to the User
                            via the Services to third parties, to copy or adapt it or to
                            commercially exploit it in any way.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User is not permitted to reproduce, distribute, transmit, make
                            public or transfer in any other way, commercially exploit or use in
                            another product the following items: data, information, know-how, ideas,
                            communications or content received or accessed through or in connection
                            with the Portal or Services.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>6.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            The above does not lead to the transfer of intellectual property rights.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>7.</p>
                <p style={styles.subTitelSecondParagraph}>Costs</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>7.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            There are currently no costs associated with the use of the Portal and
                            the Services. Oranjegroep may change the General Terms and Conditions at
                            any time with immediate effect and without prior notice.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>7.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User agrees to charging the costs of a service as soon as the user
                            requests it.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>8.</p>
                <p style={styles.subTitelSecondParagraph}>
                    Availability, maintenance and management of the portal
                </p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>8.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep does everything reasonably possible to make and keep the
                            portal continuously available. Oranjegroep does not guarantee that the
                            Portal, its content and related services will be uninterrupted and fully
                            available.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>8.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep may temporarily decommission the Portal in whole or in part
                            without notice if it deems this necessary, for example in connection
                            with a change to be implemented or in connection with other maintenance
                            and administrative activities. Oranjegroep strives, as far as reasonably
                            possible, to inform the user in advance about the times and duration of
                            this, insofar as this has significant consequences for the User.
                            Oranjegroep accepts no liability for the harmful consequences that this
                            (temporary) decommissioning could have for the User.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>9.</p>
                <p style={styles.subTitelSecondParagraph}>Liability and Warranties</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep ensures to the best of its ability that the data exchange
                            via the portal takes place as reliably as possible and that the
                            information on the portal is up to date. Although the Portal is managed
                            with the utmost care, Oranjegroep cannot guarantee that the portal will
                            always be available, that the available information is up to date and
                            that data will always arrive at Oranjegroep undamaged. When exchanging
                            data, errors in the data exchange can occur, such as the data not being
                            transmitted or not being transmitted in full. Oranjegroep excludes any
                            liability for errors and damage caused by the use of the Portal.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep will handle the data that the user sends via the Portal with
                            the utmost care. If it is clear to Oranjegroep that electronic data has
                            been damaged, it will inform the User about this. If the User is obliged
                            to also send a paper version of the data when using a service and the
                            electronic data has been damaged via the Portal, the paper version will
                            be used for the treatment of that data. Without prejudice to the above,
                            Oranjegroep is in any case not liable for damage of any kind that the
                            user suffers as a result of an act or omission by the User in violation
                            of these conditions:
                            <br /> • theft, loss and/or misuse of account data;
                            <br /> • information from third party websites linked to the Portal; or
                            <br /> • failure to follow instructions given by the Oranjegroep.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            Any (reporting) functions available on the Portal are made available
                            solely for reasons of convenience. Oranjegroep does not guarantee the
                            immediate updating of information regarding a User's outstanding
                            contracts and positions, nor does it guarantee the completeness or
                            accuracy of such information. The reporting on the Portal regarding
                            (reimbursement) balances does not constitute a guarantee with regard to
                            the reimbursement for the User. Valuations indicated on the Portal are
                            indicative only. Oranjegroep expressly disclaims any liability for any
                            use for which the User may use compensation provided on the Portal. Such
                            valuations concern the estimate by Oranjegroep based on estimates that
                            are relevant in the eyes of Oranjegroep. Fees will be calculated in
                            accordance with Oranjegroep's policies regarding such calculations and
                            in accordance with Oranjegroep's discretion as best business practices.
                            They do not contain any conditions on the basis of which Oranjegroep
                            would enter into new arrangements or settle existing arrangements.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.4</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep disclaims any express, statutory or implied warranty with
                            regard to the Portal and all results obtained through the use of the
                            Portal. Oranjegroep makes no representations regarding the accuracy,
                            correctness or completeness of the information and material on the
                            Portal, nor regarding the suitability or soundness of the information
                            and material for a particular purpose or use.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.5</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep, any other company within Oranjegroep (including
                            subsidiaries and holding companies) or directors, executives, employees,
                            agents or subcontractors of Oranjegroep or any other company within
                            Oranjegroep is/are not liable on any contractual or non-contractual
                            grounds whatsoever (including but not limited to the Addendum with User)
                            for any damage suffered by the User as a result of the access to or use
                            of the Portal or the inability to access or use the Portal, regardless
                            of whether this concerns direct or indirect damage, or for any claim or
                            demand from third parties, unless there is intentional misconduct or
                            gross negligence.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.6</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep is under no circumstances liable for any disruption of the
                            data provided on the Portal due to technical interference (i.e. errors
                            in transmission, technical defects, interruptions, interventions by
                            third parties, etc. ).
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.7</td>
                        <td style={styles.textTableRowSecondCell}>
                            The User acknowledges that Oranjegroep provides no guarantees with
                            regard to the availability, security or performance of the Portal or the
                            Services offered on it. Oranjegroep will use all reasonable means to
                            ensure the correct operation and performance of the Portal at all times.
                            As a result, Oranjegroep is not liable for any direct or indirect damage
                            that the User may suffer (including damage due to claims or demands from
                            third parties) as a result of or in connection with the following
                            matters: (i) interruptions or malfunctions in the Services, (ii) in
                            particular in the event of interruption for maintenance or repair of the
                            computer system or technical defect in the computer system, servers or
                            communication lines, (iii) theft, loss, destruction or modification of
                            data, software or computer equipment as a result of unlawful access to
                            the computer systems of Oranjegroep or those of the User or as a result
                            of a virus originating from the Portal, the internet or the computer
                            system or a server of Oranjegroep or a User, (iv) failure to execute
                            assignments, errors, delays or interruptions in the transmission of data
                            or, in general, problems of any kind in the transmission and receipt of
                            orders.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>9.8</td>
                        <td style={styles.textTableRowSecondCell}>
                            In cases where Oranjegroep is held liable for damage or loss, its
                            liability is limited to the actual direct damage suffered by the User.
                            Oranjegroep is not responsible for the failure to realize an expected
                            compensation.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>10.</p>
                <p style={styles.subTitelSecondParagraph}>Confidentiality and Security</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>10.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Except for the legally required publication of data, Oranjegroep may not
                            make public the data and information it receives via the Portal. These
                            fall under the confidentiality provisions of the (supervision)
                            legislation relevant to Oranjegroep.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>10.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep takes appropriate measures to the best of its ability to
                            protect the Portal and the exchanged information as much as possible
                            against loss or unlawful use.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>11.</p>
                <p style={styles.subTitelSecondParagraph}>Privacy</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>11.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep and the User comply with all legal regulations regarding the
                            processing of personal data. Oranjegroep and the User will immediately
                            provide each other with all relevant information if requested.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>11.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            The Privacy statement of Oranjegroep is available on its website.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>11.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            The user indemnifies Oranjegroep against all claims from third parties
                            brought against Oranjegroep due to a violation of the General Data
                            Protection Regulation and/or other legislation relating to the
                            processing and/or storage of personal data that cannot be attributed to
                            Oranjegroep.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>12.</p>
                <p style={styles.subTitelSecondParagraph}>Disputes and competent court</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>12.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Dutch law applies to these conditions.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>12.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            A legal dispute in connection with or due to these conditions can only
                            be submitted to a (subdistrict) judge in Rotterdam.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>13.</p>
                <p style={styles.subTitelSecondParagraph}>Force majeure</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>13.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            Oranjegroep or the User cannot be forced to fulfill its obligations if
                            it is unable to do so as a result of force majeure.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>13.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Force majeure on the part of Oranjegroep also includes the
                            malfunctioning of mains voltage, telecommunications or network
                            facilities and a non-attributable shortcoming of third parties engaged
                            by Oranjegroep.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.subTitle}>
                <p style={styles.subTitelFirstParagraph}>14.</p>
                <p style={styles.subTitelSecondParagraph}>Other provisions</p>
            </div>
            <table style={styles.textTable}>
                <tbody>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>14.1</td>
                        <td style={styles.textTableRowSecondCell}>
                            These conditions can be adjusted. The User is assumed to be familiar
                            with the current conditions for using the Portal.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>14.2</td>
                        <td style={styles.textTableRowSecondCell}>
                            Any purchasing, delivery or other general terms and conditions of the
                            User are expressly not applicable.
                        </td>
                    </tr>
                    <tr style={styles.textTableRow}>
                        <td style={styles.textTableRowFirstCell}>14.3</td>
                        <td style={styles.textTableRowSecondCell}>
                            The rights and obligations arising from these conditions cannot be
                            transferred by the User to third parties without the written permission
                            of Oranjegroep.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
