/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../../helpers';

export const useStyles = () =>
    designStyles({
        gridContainer: {
            margin: '4rem 0 4rem 0',
            overflow: 'hidden',
            borderRadius: '1rem',
            boxShadow: `5px 10px 8px 10px ${colors.darkBoxShadow}`,
        },
        gridHeader: {
            background: colors.gridHeaderBackground,
            borderStyle: 'solid',
            borderColor: '#babfc7',
            borderWidth: '1px 1px 0 1px',
            color: colors.blue,
            padding: '0.5rem',
            userSelect: 'none',
            display: 'flex',
            alignItems: 'center',
        },
        gridHeaderText: {
            marginLeft: '1rem',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            flex: 1,
        },
        gridHeaderExportButton: {
            fontSize: '1.5rem',
            color: colors.green,
            padding: '1rem',
        },
        gridHeaderResizeButton: {
            fontSize: '1.5rem',
            color: colors.blue,
            padding: '1rem',
        },
        gridBox: {
            width: '90vw',
            height: '70vh',
        },
    });
