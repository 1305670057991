/**@jsxImportSource @emotion/react */
import { designStyles } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
        },
        button: {
            width: widthBelow600px ? '30rem' : '40rem',
            marginBottom: '0.8rem',
        },
        gridSkeleton: {
            margin: '5rem 0 2rem 0',
            width: '90vw',
            height: '60vh',
            borderRadius: '1rem',
        },
        buttonSkeleton: {
            width: widthBelow600px ? '30rem' : '40rem',
            height: '3rem',
            marginBottom: '0.8rem',
        },
    });
