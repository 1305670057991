/**@jsxImportSource @emotion/react */
import { designStyles, colors, LightenDarkenColor } from '../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            margin: 0,
            padding: '6rem 0 0 0',
            background: colors.backgroundColor,
        },
        title: {
            alignSelf: 'center',
            color: colors.blue,
            userSelect: 'none',
            marginTop: '4rem',
            transition: 'all 0.2s',
            '&:hover': {
                transform: 'skewY(2deg) skewx(15deg) scale(1.1)',
                textShadow: `0.5rem 1rem 2rem ${colors.darkBoxShadow}`,
            },
        },
        content: {
            flex: 2,
        },
        footer: {
            display: 'flex',
            color: colors.buttonTextColor,
            userSelect: 'none',
            background: colors.blue,
            clipPath: 'polygon(10% 0, 100% 50%, 100% 100%, 0 100%, 0 0)',
        },
        footerLinkBox: {
            flex: 3,
            fontSize: '3rem',
            padding: '2rem',
        },
        footerVersion: {
            fontSize: '1rem',
        },
        linkBoxLink: {
            display: 'inline-flex',
            transition: 'all 0.2s',
            color: colors.buttonTextColor,
            '&:link': {
                color: colors.buttonTextColor,
            },
            '&:visited': {
                color: colors.buttonTextColor,
            },
            '&:active': {
                color: colors.buttonTextColor,
                outline: 'none',
                transform: 'translateY(-1px)',
                boxShadow: `0 1rem 2rem ${colors.lightBoxShadow}`,
            },
            '&:focus': {
                color: colors.buttonTextColor,
                outline: 'none',
                transform: 'translateY(-1px)',
                boxShadow: `0 1rem 2rem ${colors.lightBoxShadow}`,
            },
            '&:hover': {
                transform: 'translateY(-0.3rem)',
                boxShadow: `0 1rem 2rem ${colors.lightBoxShadow}`,
                color: colors.buttonTextColor,
            },
            '&:focus-visible': {
                outline: 'none',
            },
            '&:not(lastChild)': {
                marginRight: '1rem',
            },
        },
        footerRightContainer: {
            fontWeight: 'bold',
            fontSize: '1.2rem',
            alignSelf: 'flex-end',
            margin: '0 1rem 0.5rem 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        footerRightContainerLink: {
            color: colors.white,
            fontFamily: 'inherit',
            textDecoration: 'none',
            transition: 'all 0.3s',
            '&:hover': {
                color: LightenDarkenColor(colors.white, -80),
            },
        },
    });
