/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IPayload as IDeleteModalPayload } from '../../../helpers/reducers/delete/types';
import { IPayload as IResendQRModalPayload } from '../../../helpers/reducers/resend-qr-code/types';
import { IAmbassador } from '../hooks';
import { useMediaQuery } from '@mui/material';
import { Search } from '../../../components';
import { AmbassadorCard } from './card/AmbassadorCard';

interface IProps {
    data: IAmbassador[];
    isLoading: boolean;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    openDelete: (payload: IDeleteModalPayload) => void;
    openResendQR: (payload: IResendQRModalPayload) => void;
}

export const CardView = ({
    data,
    isLoading,
    search,
    setSearch,
    openDelete,
    openResendQR,
}: IProps) => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);

    return (
        <React.Fragment>
            <Search
                style={styles.search}
                disabled={isLoading}
                search={search}
                setSearch={setSearch}
            />
            <div style={styles.content}>
                {data.map((ambassador, index) => (
                    <AmbassadorCard
                        ambassador={ambassador}
                        openDelete={openDelete}
                        openResendQR={openResendQR}
                        key={index}
                    />
                ))}
            </div>
        </React.Fragment>
    );
};
