import { useQuery } from 'react-query';
import { Cache, useApi } from '../../../../helpers';
import { IPipeline } from './types';

export const useFetchPipeline = (
    enabled: boolean,
    year: number,
    ambassadorId: string | undefined,
    departmentId: string | undefined
) => {
    const api = useApi();

    return useQuery<IPipeline, any>(
        [Cache.dashboardPipeline, year, ambassadorId, departmentId],
        ({ signal }) =>
            api
                .get(
                    `/dashboards/pipeline/${year}?ambassadorId=${ambassadorId}&departmentId=${departmentId}`,
                    { signal }
                )
                .then((res) => res.data),
        {
            enabled,
            refetchOnWindowFocus: false,
        }
    );
};
