import { ICandidate } from '../../../hooks';
import { Cache, useApi } from '../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchCandidatesByAmbassador = (search: string, ambassadorId: string) => {
    const api = useApi();

    return useQuery<ICandidate[], any>([Cache.candidates, search, ambassadorId], () =>
        api.get(`/candidates/ambassador/${ambassadorId}?search=${search}`).then((res) => res.data)
    );
};
