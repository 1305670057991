import * as React from 'react';
import { useStyles } from './style';
import { useFetchTask } from './hooks';
import { IRouteParams, colors, handleQueryError, routePath } from '../../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { Page, Button } from '../../../../components';

export const TaskErrors = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { id } = useParams<IRouteParams>();
    const { t } = useTranslation();

    const { data, isLoading, error } = useFetchTask(id);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page style={styles.root} title={t('text.taskErrors')}>
            {isLoading && (
                <React.Fragment>
                    <Skeleton variant="rectangular" animation="wave" style={styles.taskSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.errorSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.errorSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.errorSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.errorSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.errorSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.errorSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.errorSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.errorSkeleton} />
                </React.Fragment>
            )}
            {data && (
                <React.Fragment>
                    <p style={styles.task}>
                        {`${t(`task.${data.taskType}`)} - ${new Date(
                            data.createdAt
                        ).toLocaleDateString()}`}
                    </p>
                    {data.errors.map((error, index) => (
                        <div key={index} style={styles.errorContainer}>
                            {error.errorMessage}
                        </div>
                    ))}
                </React.Fragment>
            )}
            <Button
                style={styles.button}
                cs={{
                    color: colors.blue,
                    padding: '1rem 1rem',
                    fontSize: '1rem',
                }}
                onClick={() => history.push(routePath.tasks.create())}
            >
                {t('text.back')}
            </Button>
        </Page>
    );
};
