/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IProfession } from '../../../pages/candidates/hooks';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '../../backdrop/Backdrop';
import { AiOutlineClose } from 'react-icons/ai';

interface IProps {
    open: boolean;
    candidateName: string;
    professions: IProfession[];
    closeModal: () => void;
}

export const ProfessionsModal = ({ open, candidateName, professions, closeModal }: IProps) => {
    const styles = useStyles();
    const { i18n, t } = useTranslation();

    return (
        <Backdrop open={open}>
            <div style={styles.root}>
                <div style={styles.header}>
                    <h2 style={styles.headerTitle}>{`${t(
                        'text.professions'
                    )} - ${candidateName}`}</h2>
                    <AiOutlineClose css={styles.headerCloseButton} onClick={closeModal} />
                </div>
                <div style={styles.content}>
                    <ul>
                        {professions.map((profession, index) => (
                            <li key={index} css={styles.listItem}>
                                {profession[i18n.language.toString() as keyof IProfession]}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Backdrop>
    );
};
