/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../../../helpers';

export const useStyles = () =>
    designStyles({
        root: {
            width: '100%',
            textAlign: 'center',
        },
        button: {
            fontSize: '2rem',
            color: colors.red,
        },
    });
