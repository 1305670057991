/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IElementStyle } from './types';
import { Interpolation, Theme } from '@emotion/react';
import { View, setViewLocalStorage } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { MdViewModule, MdViewList } from 'react-icons/md';

interface IProps {
    view: View;
    setView: React.Dispatch<React.SetStateAction<View>>;
    es?: IElementStyle;
    style?: React.CSSProperties;
    className?: string;
}

export const ViewToggleButton = ({ view, setView, es, style, className }: IProps) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div
            css={
                es && es.root
                    ? [styles.root, es.root as Interpolation<Theme>, style as Interpolation<Theme>]
                    : [styles.root, style as Interpolation<Theme>]
            }
            className={className}
        >
            <Tooltip arrow title={<h1>{t('text.cardView')}</h1>}>
                <button
                    css={
                        view === View.Card
                            ? es && es.activeButton
                                ? [styles.activeButton, es.activeButton as Interpolation<Theme>]
                                : styles.activeButton
                            : es && es.button
                            ? [styles.button, es.button as Interpolation<Theme>]
                            : styles.button
                    }
                    onClick={() => {
                        setViewLocalStorage(View.Card);
                        setView(View.Card);
                    }}
                >
                    <MdViewModule />
                </button>
            </Tooltip>
            <Tooltip
                arrow
                title={<h1>{t('text.gridView')}</h1>}
                onClick={() => {
                    setViewLocalStorage(View.Grid);
                    setView(View.Grid);
                }}
            >
                <button
                    css={
                        view === View.Grid
                            ? es && es.activeButton
                                ? [styles.activeButton, es.activeButton as Interpolation<Theme>]
                                : styles.activeButton
                            : es && es.button
                            ? [styles.button, es.button as Interpolation<Theme>]
                            : styles.button
                    }
                >
                    <MdViewList />
                </button>
            </Tooltip>
        </div>
    );
};
