/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { ICandidate, IDepartment } from '../../../../hooks';
import { Role, isoDateStringToLocaleDateString, useAuth } from '../../../../../../helpers';
import { isCandidateActive } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { FcApproval, FcCancel } from 'react-icons/fc';

interface IProps {
    candidate: ICandidate;
}

export const MainPanel = ({ candidate }: IProps) => {
    const styles = useStyles();
    const { currentUserHasRoles } = useAuth();
    const { t, i18n } = useTranslation();

    return (
        <div style={styles.panel}>
            <p
                style={styles.panelText}
            >{`${candidate.user.firstName} ${candidate.user.lastName}`}</p>
            {candidate.user.email ? (
                <p style={styles.panelEmailText}>{candidate.user.email}</p>
            ) : (
                <p style={styles.panelText}>{t('text.noEmail')}</p>
            )}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                candidate.employeeNumber ? (
                    <div>
                        <p style={styles.panelText}>{t('text.employeeNumber')}:&nbsp;</p>
                        <p style={styles.panelDataText}>{candidate.employeeNumber}</p>
                    </div>
                ) : (
                    <div style={styles.panelBooleanBox}>
                        <p style={styles.panelText}>{t('text.employeeNumber')}:&nbsp;</p>
                        <FcCancel style={styles.panelBooleanIcon} />
                    </div>
                )
            ) : null}
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                <div>
                    <p style={styles.panelText}>{t('text.appliedBy')}:&nbsp;</p>
                    <p style={styles.panelDataText}>
                        {candidate.ambassador
                            ? candidate.ambassador.user.name
                            : candidate.recruitmentAgency
                            ? candidate.recruitmentAgency.user.name
                            : ''}
                    </p>
                </div>
            ) : null}
            <div>
                <p style={styles.panelText}>{t('text.status')}:&nbsp;</p>
                <p style={styles.panelDataText}>{t(`candidateStatus.${candidate.status}`)}</p>
            </div>
            <div>
                <p style={styles.panelText}>{t('text.department')}:&nbsp;</p>
                <p style={styles.panelDataText}>
                    {candidate.user.department![i18n.language as keyof IDepartment]}
                </p>
            </div>
            {currentUserHasRoles(Role.superAdmin, Role.admin, Role.recruiter) ? (
                candidate.isPolicyAccepted ? (
                    <div>
                        <p style={styles.panelText}>{t('text.privacyPolicy')}:&nbsp;</p>
                        <FcApproval style={styles.panelBooleanIcon} />
                    </div>
                ) : (
                    <div style={styles.panelBooleanBox}>
                        <p style={styles.panelText}>{t('text.privacyPolicy')}:&nbsp;</p>
                        <FcCancel style={styles.panelBooleanIcon} />
                    </div>
                )
            ) : null}
            {candidate.user.birthdate ? (
                <div>
                    <p style={styles.panelText}>{t('text.birthdate')}:&nbsp;</p>
                    <p style={styles.panelDataText}>
                        {isoDateStringToLocaleDateString(candidate.user.birthdate)}
                    </p>
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.birthdate')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            {candidate.user.phoneNumber ? (
                <div>
                    <p style={styles.panelText}>{t('text.phoneNumber')}:&nbsp;</p>
                    <p style={styles.panelDataText}>{candidate.user.phoneNumber}</p>
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.phoneNumber')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            <div style={styles.panelBooleanBox}>
                <p style={styles.panelText}>{t('text.active')}:&nbsp;</p>
                {isCandidateActive(candidate) ? (
                    <FcApproval style={styles.panelBooleanIcon} />
                ) : (
                    <FcCancel style={styles.panelBooleanIcon} />
                )}
            </div>
        </div>
    );
};
