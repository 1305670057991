/**@jsxImportSource @emotion/react */
import { colors, designStyles } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        filters: {
            width: '90vw',
            marginTop: '4rem',
            display: 'flex',
            flexDirection: widthBelow600px ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        filtersNumberSelect: {
            marginTop: widthBelow600px ? '2.5rem' : 0,
            width: '11rem',
        },
        filtersAmbassador: {
            width: widthBelow600px ? '30rem' : '40rem',
        },
        ambassadorSkeleton: {
            width: widthBelow600px ? '30rem' : '40rem',
            height: '5rem',
        },
        ambassadorGridSkeleton: {
            marginTop: '4rem',
            width: '90vW',
            height: '8vh',
        },
        candidateGridSkeleton: {
            marginTop: '4rem',
            width: '90vW',
            height: '45vh',
        },
        noDataContainer: {
            marginTop: '10rem',
            width: '80%',
        },
        noData: {
            color: colors.blue,
            textAlign: 'center',
            userSelect: 'none',
            backgroundColor: colors.white,
            padding: '10rem 5rem',
            borderRadius: '0.5rem',
            boxShadow: `5px 5px 8px 5px ${colors.darkBoxShadow}`,
            fontSize: '2rem',
            fontStyle: 'italic',
        },
    });
