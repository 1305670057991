/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { ICustomStyle } from './types';
import { LightenDarkenColor, colors } from '../../../helpers';
import { ripple } from '../../keyframes';

export const useStyle = (
    {
        color,
        fontSize,
        fontWeight,
        onHoverColor,
        textColor,
        scaleEffect,
        padding,
        boxShadowColor,
        borderRadius,
    }: ICustomStyle,
    coords: { x: number; y: number }
) => ({
    button: css({
        textTransform: 'uppercase',
        textDecoration: 'none',
        marginTop: '0.5rem',
        padding: padding ? padding : '1.5rem 4rem',
        display: 'inline-block',
        borderRadius: borderRadius ? borderRadius : '10rem',
        transition: 'all 0.2s',
        position: 'relative',
        fontSize: fontSize ? fontSize : '1rem',
        fontWeight: fontWeight ? fontWeight : 'bold',
        fontFamily: 'inherit',
        border: 'none',
        cursor: 'pointer',
        userSelect: 'none',
        backgroundColor: color,
        color: textColor ? textColor : colors.buttonTextColor,
        textAlign: 'center',
        overflow: 'hidden',
        '&:link': {
            textTransform: 'uppercase',
            textDecoration: 'none',
            marginTop: '0.5rem',
            padding: padding ? padding : '1.5rem 4rem',
            display: 'inline-block',
            borderRadius: borderRadius ? borderRadius : '10rem',
            transition: 'all 0.2s',
            position: 'relative',
            fontSize: fontSize ? fontSize : '1rem',
            fontWeight: fontWeight ? fontWeight : 'bold',
            fontFamily: 'inherit',
            border: 'none',
            cursor: 'pointer',
            userSelect: 'none',
            backgroundColor: color,
            color: textColor ? textColor : colors.buttonTextColor,
            textAlign: 'center',
        },
        '&:visited': {
            textTransform: 'uppercase',
            textDecoration: 'none',
            marginTop: '0.5rem',
            padding: padding ? padding : '1.5rem 4rem',
            display: 'inline-block',
            borderRadius: borderRadius ? borderRadius : '10rem',
            transition: 'all 0.2s',
            position: 'relative',
            fontSize: fontSize ? fontSize : '1rem',
            fontWeight: fontWeight ? fontWeight : 'bold',
            fontFamily: 'inherit',
            border: 'none',
            cursor: 'pointer',
            userSelect: 'none',
            backgroundColor: color,
            color: textColor ? textColor : colors.buttonTextColor,
            textAlign: 'center',
        },
        '&::after': {
            content: "''",
            display: 'inline-block',
            height: '100%',
            width: '100%',
            borderRadius: borderRadius ? borderRadius : '10rem',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            transition: 'all 0.4s',
        },
        '&:hover': {
            transform: 'translateY(-0.3rem)',
            boxShadow: boxShadowColor
                ? `0 1rem 2rem ${boxShadowColor}`
                : `0 1rem 2rem ${colors.darkBoxShadow}`,
            filter: onHoverColor ? 'none' : 'brightness(0.85)',
            backgroundColor: onHoverColor ? onHoverColor : color,
        },
        '&:hover::after': {
            transform: scaleEffect ? 'scaleX(1.4) scaleY(1.6);' : 'none',
            opacity: 0,
        },
        '&:focus-visible': {
            outline: 'none',
        },
        '&:active': {
            outline: 'none',
            transform: 'translateY(-1px)',
            boxShadow: boxShadowColor
                ? `'0 0.5rem 1rem ${boxShadowColor}'`
                : `0 0.5rem 1rem ${colors.darkBoxShadow}`,
        },
        '&:focus': {
            outline: 'none',
            transform: 'translateY(-1px)',
            boxShadow: boxShadowColor
                ? `'0 0.5rem 1rem ${boxShadowColor}'`
                : `0 0.5rem 1rem ${colors.darkBoxShadow}`,
        },
    }),
    buttonContent: css({
        position: 'relative',
        zIndex: 2,
    }),
    buttonRipple: css({
        width: `calc(5 * ${fontSize})`,
        height: `calc(5 * ${fontSize})`,
        position: 'absolute',
        left: coords.x,
        top: coords.y,
        background: LightenDarkenColor(color, 100),
        display: 'block',
        content: "''",
        borderRadius: '100%',
        opacity: 1,
        animation: `0.9s ease 1 forwards ${ripple}`,
    }),
});
