/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle } from './types';
import { Spinner } from '../../spinner/Spinner';

interface IProps {
    children?: React.ReactNode;
    cs: ICustomStyle;
    es?: IElementStyle;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    spinner?: boolean;
    style?: React.CSSProperties;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button = ({
    children,
    cs,
    es,
    type,
    disabled,
    spinner,
    className,
    style,
    onClick,
}: IProps) => {
    const [coords, setCoords] = React.useState({ x: -1, y: -1 });
    const [isRippling, setIsRippling] = React.useState(false);
    const styles = useStyles(cs, disabled ? true : false, spinner ? true : false, coords);

    React.useLayoutEffect(() => {
        let timer: NodeJS.Timer;
        if (coords.x !== -1 && coords.y !== -1) {
            setIsRippling(true);
            timer = setTimeout(() => setIsRippling(false), 300);
        } else setIsRippling(false);
        return () => {
            clearTimeout(timer);
        };
    }, [coords]);

    React.useLayoutEffect(() => {
        if (!isRippling) setCoords({ x: -1, y: -1 });
    }, [isRippling]);

    return (
        <button
            css={
                es && es.button
                    ? [
                          styles.button,
                          es.button as Interpolation<Theme>,
                          style as Interpolation<Theme>,
                      ]
                    : [styles.button, style as Interpolation<Theme>]
            }
            className={className}
            type={type}
            disabled={disabled}
            onClick={(event) => {
                const rect = event.currentTarget.getBoundingClientRect();
                setCoords({ x: event.clientX - rect.left, y: event.clientY - rect.top });
                if (onClick) onClick(event);
            }}
        >
            <span
                css={
                    es && es.buttonContent
                        ? [styles.buttonContent, es.buttonContent as Interpolation<Theme>]
                        : styles.buttonContent
                }
            >
                {children}
                {disabled && spinner ? (
                    <span
                        css={
                            es && es.buttonSpinner
                                ? [styles.buttonSpinner, es.buttonSpinner as Interpolation<Theme>]
                                : styles.buttonSpinner
                        }
                    >
                        <Spinner
                            className="loader"
                            variant="rond"
                            cs={{
                                color: cs.textColor ? cs.textColor : 'white',
                                thickness: '0.2rem',
                                radius: cs.fontSize ? `calc(${cs.fontSize} * 1.8)` : '2.6rem',
                            }}
                        />
                    </span>
                ) : null}
                {isRippling ? (
                    <span
                        css={
                            es && es.buttonRipple
                                ? [styles.buttonRipple, es.buttonRipple as Interpolation<Theme>]
                                : styles.buttonRipple
                        }
                    />
                ) : null}
            </span>
        </button>
    );
};
