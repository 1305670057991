/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useMediaQuery, Skeleton } from '@mui/material';

export const CardSkeleton = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);

    return <Skeleton variant="rectangular" animation="wave" style={styles.cardSkeleton} />;
};
