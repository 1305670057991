/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchAmbassadorActivityPeriods } from './hooks';
import {
    IRouteParams,
    Role,
    handleQueryError,
    routePath,
    useAuth,
    useDeleteModal,
    useSnack,
    colors,
} from '../../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page, Button, Snack } from '../../../../components';
import { useMediaQuery, Skeleton, Tooltip, IconButton } from '@mui/material';
import { AiOutlinePlus } from 'react-icons/ai';
import { Grid } from './grid/Grid';
import { GridSkeleton } from './grid-skeleton/GridSkeleton';
import { DeleteModal } from './delete-modal/DeleteModal';

export const AmbassadorActivityPeriods = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles, getCurrentUser } = useAuth();
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { isSnackOpen, snackVariant, snackMessage, closeSnack, openSnack } = useSnack();
    const { isDeleteOpen, itemToDeleteId, openDelete, closeDelete } = useDeleteModal();

    const { data, isLoading, error } = useFetchAmbassadorActivityPeriods(id);

    React.useEffect(() => {
        if (data) {
            if (
                currentUserHasRoles(Role.admin) &&
                getCurrentUser().departmentId !== data.ambassadorDepartmentId &&
                getCurrentUser().userId !== data.ambassadorAdminUserId
            ) {
                history.push(routePath.unauthorizedError.create());
            }
        }
    }, [currentUserHasRoles, getCurrentUser, data, history]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page title={t('text.activityPeriods')} style={styles.root}>
            {isSnackOpen && (
                <Snack
                    open={isSnackOpen}
                    variant={snackVariant}
                    message={snackMessage}
                    closeSnack={closeSnack}
                    cs={{
                        top: '8rem',
                    }}
                />
            )}
            {isLoading && (
                <React.Fragment>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.ambassadorSkeleton}
                    />
                    <GridSkeleton />
                </React.Fragment>
            )}
            {data && (
                <React.Fragment>
                    {isDeleteOpen && (
                        <DeleteModal
                            open={isDeleteOpen}
                            ambassadorId={data.id}
                            itemToDeleteId={itemToDeleteId}
                            openSnack={openSnack}
                            closeModal={closeDelete}
                        />
                    )}
                    <div style={styles.header}>
                        <h2 style={styles.headerAmbassador}>{data.ambassador}</h2>
                        <Tooltip
                            style={{ placeSelf: 'flex-end' }}
                            arrow
                            placement="left"
                            title={<h1>{t('text.createActivityPeriod')}</h1>}
                        >
                            <IconButton
                                size="large"
                                style={styles.headerCreateButton}
                                onClick={() =>
                                    history.push(
                                        routePath.createAmbassadorActivityPeriod.create(data.id)
                                    )
                                }
                            >
                                <AiOutlinePlus />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Grid
                        activityPeriods={data.ambassadorActivityPeriods}
                        lng={i18n.language}
                        openDelete={openDelete}
                    />
                </React.Fragment>
            )}
            <Button
                style={styles.button}
                onClick={() => history.push(routePath.ambassadors.create())}
                cs={{
                    color: colors.blue,
                    padding: '1rem 1rem',
                    fontSize: '1.25rem',
                }}
            >
                {t('text.back')}
            </Button>
        </Page>
    );
};
