/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { Skeleton } from '@mui/material';

export const GridSkeleton = () => {
    const styles = useStyles();

    return <Skeleton variant="rectangular" animation="wave" style={styles.skeleton} />;
};
