import * as React from 'react';
import { ISnackActionPayload, IState, TAction } from './types';

const snackReducer = (state: IState, action: TAction): IState => {
    switch (action.type) {
        case 'OPEN_SNACK':
            return {
                isSnackOpen: true,
                snackMessage: action.payload!.snackMessage,
                snackVariant: action.payload!.snackVariant,
            };
        case 'CLOSE_SNACK':
            return {
                ...state,
                isSnackOpen: false,
                snackMessage: '',
            };
        default:
            return state;
    }
};

export const useSnack = () => {
    const [{ isSnackOpen, snackVariant, snackMessage }, dispatch] = React.useReducer(snackReducer, {
        isSnackOpen: false,
        snackVariant: 'success',
        snackMessage: '',
    });
    return {
        isSnackOpen,
        snackVariant,
        snackMessage,
        openSnack: (payload: ISnackActionPayload) => dispatch({ type: 'OPEN_SNACK', payload }),
        closeSnack: () => dispatch({ type: 'CLOSE_SNACK' }),
    };
};
