/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { SocialMediaLink, routePath } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { Navigation } from '../navigation/Navigation';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import { Link } from 'react-router-dom';

interface IProps {
    children?: React.ReactNode;
    title?: string;
    isPageSubmitting?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const Page = ({ children, title, isPageSubmitting, className, style }: IProps) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Navigation isPageSubmitting={isPageSubmitting ? isPageSubmitting : false} />
            <div style={styles.root}>
                {title ? <h1 css={styles.title}>{title}</h1> : null}
                <div css={[styles.content, style as Interpolation<Theme>]} className={className}>
                    {children}
                </div>
                <div style={styles.footer}>
                    <div style={styles.footerLinkBox}>
                        <a
                            css={styles.linkBoxLink}
                            href={SocialMediaLink.Facebook}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <AiFillFacebook />
                        </a>
                        <a
                            css={styles.linkBoxLink}
                            href={SocialMediaLink.Instagram}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <AiFillInstagram />
                        </a>
                        <a
                            css={styles.linkBoxLink}
                            href={SocialMediaLink.LinkedIn}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <AiFillLinkedin />
                        </a>
                    </div>
                    <div style={styles.footerRightContainer}>
                        <div>
                            <Link
                                css={styles.footerRightContainerLink}
                                to={routePath.terms.create()}
                            >
                                {t('text.generalTerms')}
                            </Link>
                            &nbsp;/&nbsp;
                            <Link
                                css={styles.footerRightContainerLink}
                                to={routePath.privacyPolicy.create()}
                            >
                                {t('text.privacyPolicy')}
                            </Link>
                        </div>
                        <p>
                            {`De Oranjegroep \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop - Public Bèta`}
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
