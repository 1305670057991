/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchAmbassadors, useFetchHours, IAmbassador, useFetchAmbassador } from '../hooks';
import { Role, handleQueryError, useAuth } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { NumberSelectInput, Page, HoursAmbassadorTable } from '../../../components';
import { ISelectItem } from '../../../components/inputs/number-select/types';
import { AmbassadorComboBoxInput } from '../ambassador-combo-box/AmbassadorComboBox';

export const AmbassadorHoursDashboard = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { currentUserHasRoles, getCurrentUser } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const [year, setYear] = React.useState(moment().year());
    const [ambassador, setAmbassador] = React.useState<IAmbassador | null>(null);

    const ambassadorsResponse = useFetchAmbassadors(!currentUserHasRoles(Role.ambassador));
    const ambassadorResponse = useFetchAmbassador(
        getCurrentUser().userId,
        currentUserHasRoles(Role.ambassador)
    );

    React.useEffect(() => {
        if (ambassadorsResponse.data && ambassadorsResponse.data.length > 0) {
            setAmbassador(ambassadorsResponse.data[0]);
        }
    }, [ambassadorsResponse.data]);

    const hoursResponse = useFetchHours(
        currentUserHasRoles(Role.ambassador) ? true : !!ambassador?.id,
        year,
        ambassador?.id,
        ''
    );

    const getYears = React.useCallback((): ISelectItem[] => {
        const years: ISelectItem[] = [];
        for (let i = 2024; i <= moment().year(); i++) {
            years.push({
                value: i,
                item: i.toString(),
            });
        }
        return years;
    }, []);

    if (ambassadorsResponse.error) {
        return handleQueryError(ambassadorsResponse.error, history);
    }
    if (hoursResponse.error) {
        return handleQueryError(hoursResponse.error, history);
    }
    return (
        <Page style={styles.root} title={t('text.ambassadorHours')}>
            <div style={styles.filters}>
                {!currentUserHasRoles(Role.ambassador) && ambassadorsResponse.data ? (
                    <AmbassadorComboBoxInput
                        style={styles.filtersAmbassador}
                        label={t('form.label.ambassador')}
                        placeholder={t('form.placeholder.ambassador')}
                        options={ambassadorsResponse.data}
                        value={ambassador}
                        setAmbassador={setAmbassador}
                    />
                ) : !currentUserHasRoles(Role.ambassador) ? (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.ambassadorSkeleton}
                    />
                ) : null}
                <NumberSelectInput
                    css={styles.filtersNumberSelect}
                    label={t('form.label.year')}
                    value={year}
                    setValue={setYear}
                    items={getYears()}
                    cs={{
                        fontSize: '1.5rem',
                    }}
                />
            </div>
            {ambassadorResponse.isLoading ||
            ambassadorsResponse.isLoading ||
            hoursResponse.isLoading ? (
                <Skeleton variant="rectangular" animation="wave" style={styles.gridSkeleton} />
            ) : null}
            {hoursResponse.data && hoursResponse.data.length > 0 ? (
                <HoursAmbassadorTable ambassador={hoursResponse.data[0]} year={year} />
            ) : null}
            {(currentUserHasRoles(Role.ambassador) &&
                hoursResponse.data &&
                hoursResponse.data.length === 0) ||
            (!currentUserHasRoles(Role.ambassador) &&
                (ambassador === null ||
                    (hoursResponse.data && hoursResponse.data.length === 0))) ? (
                <div style={styles.noDataContainer}>
                    <p style={styles.noData}>{t('text.noDataToShow')}</p>
                </div>
            ) : null}
        </Page>
    );
};
