import { useQuery } from 'react-query';
import { IAmbassadorDeclarationPeriod } from './types';
import { useApi, Cache } from '../../../../../helpers';

export const useAmbassadorDeclarationPeriod = (year: number, week: number) => {
    const api = useApi();

    return useQuery<IAmbassadorDeclarationPeriod, any>([Cache.workedHours, year, week], () =>
        api.get(`ambassador-declaration-periods?year=${year}&week=${week}`).then((res) => res.data)
    );
};
