import { IRejectCandidateVariables } from './types';
import { useMutation } from 'react-query';
import { useApi } from '../../../../helpers';
import { AxiosResponse } from 'axios';
import { ICandidate } from '../../hooks';

export const useRejectCandidate = () => {
    const api = useApi();

    return useMutation<AxiosResponse<ICandidate>, any, IRejectCandidateVariables>(
        ({ candidateId }) => api.patch(`/candidates/${candidateId}/reject`)
    );
};
