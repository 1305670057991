import { IChangeEmailVariables } from './types';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useChangeEmail = (userId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<void>, any, IChangeEmailVariables>(({ body }) =>
        api.patch(`/users/${userId}/email`, body)
    );
};
