export const localTextHU = {
    selectAll: '(Összes kijelölése)',
    selectAllSearchResults: '(Az összes keresési eredményt kijelölése)',
    searchOoo: 'Keresés...',
    blanks: '(Üresek)',
    noMatches: 'Nincs egyezés',
    filterOoo: 'Szűrő...',
    equals: 'Egyenlő',
    notEqual: 'Nem egyenlő',
    empty: 'Válasszon egyet',
    lessThan: 'Kevesebb, mint',
    greaterThan: 'Nagyobb, mint',
    lessThanOrEqual: 'Kisebb vagy egyenlő',
    greaterThanOrEqual: 'Nagyobb vagy egyenlő',
    inRange: 'Sorozaton belül',
    inRangeStart: 'tól',
    inRangeEnd: 'hoz',
    contains: 'Tartalmaz',
    notContains: 'Nem tartalmaz',
    startsWith: 'Ezzel kezdődik',
    endsWith: 'Ezzel végződik',
    dateFormatOoo: 'éééé-hh-nn',
    andCondition: 'ÉS',
    orCondition: 'VAGY',
    applyFilter: 'Alkalmaz',
    resetFilter: 'Visszaállítás',
    clearFilter: 'Kiürít',
    cancelFilter: 'Érvénytelenít',
    textFilter: 'Szövegszűrő',
    numberFilter: 'Szám szűrő',
    dateFilter: 'Dátum szűrő',
    setFilter: 'Szűrő beállítása',
    columns: 'Oszlopok',
    filters: 'Szűrők',
    pivotMode: 'Pivot mód',
    groups: 'Sorcsoportok',
    rowGroupColumnsEmptyMessage: 'Húzza ide a sorcsoportok beállításához',
    values: 'Értékek',
    valueColumnsEmptyMessage: 'Húzás ide az összesítéshez',
    pivots: 'Oszlopfeliratok',
    pivotColumnsEmptyMessage: 'Húzza ide az oszlopfeliratok beállításához',
    group: 'Csoport',
    loadingOoo: 'Betöltés…',
    noRowsToShow: 'Nincs megjeleníthető sor',
    enabled: 'Engedélyezve',
    pinColumn: 'Oszlop rögzítése',
    pinLeft: 'Rögzítés balra',
    pinRight: 'Rögzítés jobbra',
    noPin: 'Nincs pin',
    valueAggregation: 'Értékösszesítés',
    autosizeThiscolumn: 'Az oszlop automatikus méretezése',
    autosizeAllColumns: 'Az összes oszlop automatikus méretezése',
    groupBy: 'Csoportosítási szempont',
    ungroupBy: 'Csoportbontás',
    resetColumns: 'Oszlopok visszaállítása',
    expandAll: 'Összes kinyitása',
    collapseAll: 'Összes bezárása',
    copy: 'Másol',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Másolás fejlécekkel',
    paste: 'Beillesztés',
    ctrlV: 'Ctrl+V',
    export: 'Exportálás',
    csvExport: 'CSV-exportálás',
    excelExport: 'Excel-exportálás',
    sum: 'Összeg',
    min: 'Min',
    max: 'Max',
    none: 'Egyik sem',
    count: 'Számlál',
    avg: 'Átlagos',
    filteredRows: 'Szűrt',
    selectedRows: 'Kiválasztott',
    totalRows: 'Összes sor',
    totalAndFilteredRows: 'Sorok',
    more: 'Több',
    to: 'hoz',
    of: 'ból',
    page: 'Oldal',
    nextPage: 'Következő oldal',
    lastPage: 'Utolsó oldal',
    firstPage: 'Első oldal',
    previousPage: 'Előző oldal',
    pivotColumnGroupTotals: 'Minden',
    pivotChartAndPivotMode: 'Kimutatási diagram & Pivot mód',
    pivotChart: 'Kimutatási diagram',
    chartRange: 'Diagram tartomány',
    columnChart: 'Oszlop',
    groupedColumn: 'Csoportosított',
    stackedColumn: 'Halmozott',
    normalizedColumn: '100% halmozott',
    barChart: 'Ütem',
    groupedBar: 'Csoportosított',
    stackedBar: 'Halmozott',
    normalizedBar: '100% halmozott',
    pieChart: 'Torta dia',
    pie: 'Torta',
    doughnut: 'Fánk',
    line: 'Vonal',
    xyChart: 'X Y (szórás)',
    scatter: 'Hint',
    bubble: 'Buborék',
    areaChart: 'Terület',
    area: 'Terület',
    stackedArea: 'Halmozott',
    normalizedArea: '100% halmozott',
    histogramChart: 'Hisztogram',
    pivotChartTitle: 'Kimutatási diagram',
    rangeChartTitle: 'Tartománydiagram',
    settings: 'Beállítások',
    data: 'Adat',
    format: 'Formátum',
    categories: 'Kategóriák',
    defaultCategory: '(Nincs)',
    series: 'Sorozatok',
    xyValues: 'X Y értékek',
    paired: 'Párosított mód',
    axis: 'Tengely',
    navigator: 'Navigátor',
    color: 'Szín',
    thickness: 'Vastagság',
    xType: 'X típus',
    automatic: 'Automatikus',
    category: 'Kategória',
    number: 'Szám',
    time: 'Idő',
    xRotation: 'X elforgatás',
    yRotation: 'Y Forgatás',
    ticks: 'Pipa',
    width: 'Szélesség',
    height: 'Magasság',
    length: 'Hosszúság',
    padding: 'Belső térköz',
    spacing: 'Térköz',
    chart: 'Diagram',
    title: 'Cím',
    titlePlaceholder: 'Diagram címe - dupla kattintás a szerkesztéshez',
    background: 'Háttér',
    font: 'Betűtipus',
    top: 'Felül',
    right: 'Jobb',
    bottom: 'Alul',
    left: 'Balra',
    labels: 'Címkék',
    size: 'Méret',
    minSize: 'Minimális méret',
    maxSize: 'Maximális méret',
    legend: 'Tábázat cimkék',
    position: 'Pozíció',
    markerSize: 'Jelölő mérete',
    markerStroke: 'Jelölő körvonal',
    markerPadding: 'Jelölő kitöltés',
    itemSpacing: 'Elemek térköze',
    itemPaddingX: 'Elem kitöltése X',
    itemPaddingY: 'Elem kitöltése Y',
    layoutHorizontalSpacing: 'Vízszintes térköz',
    layoutVerticalSpacing: 'Függőleges térköz',
    strokeWidth: 'Körvonal szélessége',
    offset: 'Eltolás',
    offsets: 'Eltolások',
    tooltips: 'Magyarázat',
    callout: 'Ábrafelirat',
    markers: 'Markerek',
    shadow: 'Árnyék',
    blur: 'Elhomályosít',
    xOffset: 'X eltolás',
    yOffset: 'Y eltolás',
    lineWidth: 'Vonalvastagság',
    normal: 'Normális',
    bold: 'Félkövér',
    italic: 'Dőlt',
    boldItalic: 'Félkövér dőlt',
    predefined: 'Előre definiált',
    fillOpacity: 'Kitöltési opacitás',
    strokeOpacity: 'Vonal opacitása',
    histogramBinCount: 'Kukák száma',
    columnGroup: 'Oszlop',
    barGroup: 'Ütem',
    pieGroup: 'Torta',
    lineGroup: 'Vonal',
    scatterGroup: 'X Y (szórás)',
    areaGroup: 'Terület',
    histogramGroup: 'Hisztogram',
    groupedColumnTooltip: 'Csoportosított',
    stackedColumnTooltip: 'Halmozott',
    normalizedColumnTooltip: '100% halmozott',
    groupedBarTooltip: 'Csoportosított',
    stackedBarTooltip: 'Halmozott',
    normalizedBarTooltip: '100% halmozott',
    pieTooltip: 'Torta',
    doughnutTooltip: 'Fánk',
    lineTooltip: 'Vonal',
    groupedAreaTooltip: 'Terület',
    stackedAreaTooltip: 'Halmozott',
    normalizedAreaTooltip: '100% halmozott',
    scatterTooltip: 'Hint',
    bubbleTooltip: 'Buborék',
    histogramTooltip: 'Hisztogram',
    noDataToChart: 'Nem áll rendelkezésre ábrázolható adat.',
    pivotChartRequiresPivotMode: 'A kimutatásdiagramhoz engedélyezni kell a pivot módot.',
    chartSettingsToolbarTooltip: 'Menü',
    chartLinkToolbarTooltip: 'Rácshoz kapcsolva',
    chartUnlinkToolbarTooltip: 'Leválasztva a rácsról',
    chartDownloadToolbarTooltip: 'Diagram letöltése',
    ariaHidden: 'rejtett',
    ariaVisible: 'látható',
    ariaChecked: 'Kijelölt',
    ariaUnchecked: 'ellenőrizetlen',
    ariaIndeterminate: 'határozatlan',
    ariaColumnSelectAll: 'Az összes oszlop kijelölése kapcsoló',
    ariaInputEditor: 'Beviteli szerkesztő',
    ariaDateFilterInput: 'Dátumszűrő bemenete',
    ariaFilterInput: 'Szűrő bemenet',
    ariaFilterColumnsInput: 'Oszlopok szűrése Bemenet',
    ariaFilterValue: 'Szűrési érték',
    ariaFilterFromValue: 'Szűrés értékből',
    ariaFilterToValue: 'Szűrés értékre',
    ariaFilteringOperator: 'Szűrési operátor',
    ariaColumn: 'Oszlop',
    ariaColumnGroup: 'Oszlopcsoport',
    ariaRowSelect: 'Nyomja le a SZÓKÖZ billentyűt a sor kiválasztásához',
    ariaRowDeselect: 'Nyomja le a SZÓKÖZ billentyűt a sor kijelölésének megszüntetéséhez',
    ariaRowToggleSelection: 'Nyomja meg a szóköz billentyűt a sorkijelölés váltásához',
    ariaRowSelectAll: 'Nyomja meg a szóköz billentyűt az összes sor kijelölésének váltásához',
    ariaToggleVisibility: 'Nyomja meg a SPACE billentyűt a láthatóság váltásához',
    ariaSearch: 'Keresés',
    ariaSearchFilterValues: 'Keresési szűrő értékei',
    ariaLabelColumnMenu: 'Oszlop menü',
    ariaLabelCellEditor: 'Cellaszerkesztő',
    ariaLabelDialog: 'Párbeszéd',
    ariaLabelSelectField: 'Mező kiválasztása',
    ariaLabelTooltip: 'Magyarázat',
    ariaLabelContextMenu: 'Helyi menü',
    ariaLabelSubMenu: 'Almenü',
    ariaLabelAggregationFunction: 'Aggregációs függvény',
};
