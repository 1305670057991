import * as React from 'react';
import { useFetchProfessionGroups, IProfessionGroup } from '../../../profession-groups/hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { handleQueryError } from '../../../../../helpers';
import { ComboBoxInput } from '../../../../../components';

export const ProfessionGroupComboBox = (props: any) => {
    const history = useHistory();
    const { i18n } = useTranslation();

    const { isLoading, data, error } = useFetchProfessionGroups();

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <ComboBoxInput
            {...props}
            options={data}
            isLoading={isLoading}
            getOptionLabel={(data) => {
                const professionGroup = data as IProfessionGroup;
                return professionGroup[i18n.language as keyof IProfessionGroup];
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};
