/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { Skeleton } from '@mui/material';

interface IProps {
    shorter?: boolean;
}

export const GridSkeleton = ({ shorter }: IProps) => {
    const styles = useStyles(shorter ? true : false);

    return <Skeleton variant="rectangular" animation="wave" style={styles.skeleton} />;
};
