import { IAmbassador } from '../../../../../ambassadors/hooks';
import { Cache, useApi } from '../../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchAmbassador = (ambassadorId: string) => {
    const api = useApi();

    return useQuery<IAmbassador, any>([Cache.ambassadors, ambassadorId], () =>
        api.get(`/ambassadors/${ambassadorId}`).then((res) => res.data)
    );
};
