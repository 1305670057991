/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IDepartment } from '../hooks';
import { localText, routePath } from '../../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
//import { saveAs } from 'file-saver';
import { Tooltip, IconButton } from '@mui/material';
import { SiMicrosoftexcel } from 'react-icons/si';
import { GiResize } from 'react-icons/gi';
import { UpdateCell } from './update-cell/UpdateCell';
import { GridSkeleton } from '../grid-skeleton/GridSkeleton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../../../helpers/ag-grid/ag-grid.css';

interface IProps {
    professions: IDepartment[];
    lng: string;
}

export const Grid = ({ professions, lng }: IProps) => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const gridRef = React.createRef<AgGridReact>();
    const [destroyed, setDestroyed] = React.useState(false);

    const columnDefs = React.useMemo(
        (): ColDef[] => [
            {
                colId: 'english',
                headerName: t('language.english'),
                field: 'en',
                sortable: true,
                filter: true,
                resizable: true,
                width: 350,
                maxWidth: 700,
            },
            {
                colId: 'dutch',
                headerName: t('language.dutch'),
                field: 'nl',
                sortable: true,
                filter: true,
                resizable: true,
                width: 350,
                maxWidth: 700,
            },
            {
                colId: 'romanian',
                headerName: t('language.romanian'),
                field: 'ro',
                sortable: true,
                filter: true,
                resizable: true,
                width: 350,
                maxWidth: 700,
            },
            {
                colId: 'polish',
                headerName: t('language.polish'),
                field: 'pl',
                sortable: true,
                filter: true,
                resizable: true,
                width: 350,
                maxWidth: 700,
            },
            {
                colId: 'croatian',
                headerName: t('language.croatian'),
                field: 'hr',
                sortable: true,
                filter: true,
                resizable: true,
                width: 350,
                maxWidth: 700,
            },
            {
                colId: 'portuguese',
                headerName: t('language.portuguese'),
                field: 'pt',
                sortable: true,
                filter: true,
                resizable: true,
                width: 350,
                maxWidth: 700,
            },
            {
                colId: 'hungarian',
                headerName: t('language.hungarian'),
                field: 'hu',
                sortable: true,
                filter: true,
                resizable: true,
                width: 350,
                maxWidth: 700,
            },
            {
                colId: 'email',
                headerName: t('text.email'),
                field: 'email',
                sortable: true,
                filter: true,
                resizable: true,
                width: 300,
                maxWidth: 600,
            },
            {
                colId: 'update',
                headerName: t('text.update'),
                cellRenderer: UpdateCell,
                suppressMovable: true,
                lockPosition: 'right',
                sortable: false,
                resizable: false,
                width: 150,
            },
        ],
        [t]
    );

    React.useEffect(() => {
        setDestroyed(true);
        const timer = setTimeout(() => setDestroyed(false));
        return () => {
            clearTimeout(timer);
        };
    }, [lng, setDestroyed]);

    if (destroyed) return <GridSkeleton />;
    return (
        <div style={styles.gridContainer}>
            <div style={styles.gridHeader}>
                <p style={styles.gridHeaderText}>{t('text.departments')}</p>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.resize')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderResizeButton}
                        onClick={() => {
                            const allColumnIds: string[] = [];
                            if (gridRef.current) {
                                gridRef.current.api.getColumns()!.forEach((column) => {
                                    allColumnIds.push(column.getId());
                                });

                                gridRef.current.api.autoSizeColumns(allColumnIds);
                            }
                        }}
                    >
                        <GiResize />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow placement="bottom" title={<h1>{t('text.exportToExcel')}</h1>}>
                    <IconButton
                        style={styles.gridHeaderExportButton}
                        onClick={async () => {
                            /* try {
                                const buffer = await excelExport(gridRef);
                                if (buffer) {
                                    saveAs(new Blob([buffer]), 'logs.xlsx');
                                }
                            } catch (e) {
                                alert(t('text.failedToExport'));
                            } */
                        }}
                    >
                        <SiMicrosoftexcel />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="ag-theme-quartz" style={styles.gridBox}>
                <React.StrictMode>
                    <AgGridReact<IDepartment>
                        ref={gridRef}
                        rowData={professions}
                        columnDefs={columnDefs}
                        autoSizeStrategy={{
                            type: 'fitCellContents',
                        }}
                        suppressDragLeaveHidesColumns={true}
                        localeText={localText[lng]}
                        rowSelection="single"
                        rowBuffer={50}
                        context={{
                            handleUpdateClick: (departmentId: string) => {
                                history.push(routePath.updateDepartment.create(departmentId));
                            },
                        }}
                    />
                </React.StrictMode>
            </div>
        </div>
    );
};
