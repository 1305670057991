import * as React from 'react';
import { useRefreshToken } from './helpers';
import { Routes } from './Routes';
import { Loader } from './components';

export const App = () => {
    const loading = useRefreshToken();

    if (loading) return <Loader open={loading} noBlur />;
    return (
        <React.Suspense fallback={<Loader open={true} noBlur />}>
            <Routes />
        </React.Suspense>
    );
};

export default App;
