import { useQuery } from 'react-query';
import { IAmbassadorDeclarationPeriod } from './types';
import { Cache, useApi } from '../../../../helpers';

export const useFetchAmbassadorDeclarationPeriods = (year: number) => {
    const api = useApi();

    return useQuery<IAmbassadorDeclarationPeriod[], any>(
        [Cache.ambassadorDeclarationPeriods, year],
        () => api.get(`/ambassador-declaration-periods/${year}/year`).then((res) => res.data)
    );
};
