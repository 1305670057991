/**@jsxImportSource @emotion/react */
import React from 'react';
import { useStyles } from './style';
import { IScoreboardData } from './types';
import { useFetchScoreboard } from '../hooks';
import { handleQueryError } from '../../../helpers';
import { useMediaQuery } from '@mui/material';
import { ISelectItem } from '../../../components/inputs/number-select/types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Page, NumberSelectInput } from '../../../components';
import { Grid } from './grid/Grid';
import { GridSkeleton } from './grid-skeleton/GridSkeleton';

export const ScoreboardDashboard = () => {
    const widthBelow1000px = useMediaQuery('(max-width: 1000px)');
    const styles = useStyles(widthBelow1000px);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [year, setYear] = React.useState(moment().year());

    const { data, isLoading, error } = useFetchScoreboard(year);

    const getYears = React.useCallback((): ISelectItem[] => {
        const years: ISelectItem[] = [];
        for (let i = 2024; i <= moment().year(); i++) {
            years.push({
                value: i,
                item: i.toString(),
            });
        }
        return years;
    }, []);

    const getCandidatesNumber = React.useCallback((): IScoreboardData[] => {
        if (data) {
            return data
                .map((ambassador) => ({
                    ambassadorName: ambassador.name,
                    ambassadorEmployeeNumber: ambassador.employeeNumber,
                    amount: ambassador.candidateNumber,
                    department: ambassador.department,
                }))
                .sort((a, b) => b.amount - a.amount);
        }
        return [];
    }, [data]);

    const getCandidatesHours = React.useCallback((): IScoreboardData[] => {
        if (data) {
            return data
                .map((ambassador) => ({
                    ambassadorName: ambassador.name,
                    ambassadorEmployeeNumber: ambassador.employeeNumber,
                    amount: ambassador.candidateHours ? ambassador.candidateHours : 0,
                    department: ambassador.department,
                }))
                .sort((a, b) => b.amount - a.amount);
        }
        return [];
    }, [data]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page style={styles.root} title={t('text.scoreboard')}>
            <div style={styles.filter}>
                <NumberSelectInput
                    css={styles.filterNumberSelect}
                    label={t('form.label.year')}
                    value={year}
                    setValue={setYear}
                    items={getYears()}
                    cs={{
                        fontSize: '1.5rem',
                    }}
                />
            </div>
            {isLoading && (
                <div style={styles.gridsContainer}>
                    <div style={styles.gridContainer}>
                        <GridSkeleton />
                    </div>
                    <div style={styles.gridContainer}>
                        <GridSkeleton />
                    </div>
                </div>
            )}
            {data && (
                <div style={styles.gridsContainer}>
                    <div style={styles.gridContainer}>
                        <Grid
                            label={t('text.newCandidates')}
                            amountLabel={t('text.candidates')}
                            data={getCandidatesNumber()}
                            lng={i18n.language}
                        />
                    </div>
                    <div style={styles.gridContainer}>
                        <Grid
                            label={t('text.mostHours')}
                            amountLabel={t('text.hours')}
                            data={getCandidatesHours()}
                            lng={i18n.language}
                        />
                    </div>
                </div>
            )}
        </Page>
    );
};
