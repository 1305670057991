/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { ICustomStyle, IElementStyle, IElementSxProps } from './types';
import { useStyles } from './style';
import { getFlag } from './helpers';
import { Language } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import netherland from '../../../assets/netherlands.svg';
import england from '../../../assets/england.svg';
import romania from '../../../assets/romania.svg';
import poland from '../../../assets/poland.svg';
import croatia from '../../../assets/croatia.svg';
import portugal from '../../../assets/portugal.svg';
import hungary from '../../../assets/hungary.svg';

interface IProps {
    cs?: ICustomStyle;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
}

export const LanguageMenu = ({ cs, es, esx, style, className }: IProps) => {
    const styles = useStyles(cs);
    const { t, i18n } = useTranslation();
    const [lng, setLng] = React.useState<Language>(i18n.language as Language);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleOnClick = (lng: Language) => {
        i18n.changeLanguage(lng);
        setLng(lng);
        setAnchorEl(null);
    };

    return (
        <div
            css={
                es && es.root
                    ? [es.root as Interpolation<Theme>, style as Interpolation<Theme>]
                    : (style as Interpolation<Theme>)
            }
            className={className}
        >
            <Tooltip arrow title={<h1>{t('text.language')}</h1>}>
                <IconButton
                    css={es && es.menuItem ? (es.menuItem as Interpolation<Theme>) : undefined}
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    {getFlag(lng, styles.selectedItemFlag)}
                </IconButton>
            </Tooltip>
            <Menu
                css={es && es.menu ? (es.menu as Interpolation<Theme>) : undefined}
                sx={esx && esx.menu ? esx.menu : undefined}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                    onClick={() => handleOnClick(Language.nl)}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.menuItemflag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.menuItemflag
                        }
                        src={netherland}
                        alt="fg"
                    />
                    {t('language.dutch')}
                </MenuItem>
                <MenuItem
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                    onClick={() => handleOnClick(Language.en)}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.menuItemflag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.menuItemflag
                        }
                        src={england}
                        alt="fg"
                    />
                    {t('language.english')}
                </MenuItem>
                <MenuItem
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                    onClick={() => handleOnClick(Language.ro)}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.menuItemflag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.menuItemflag
                        }
                        src={romania}
                        alt="fg"
                    />
                    {t('language.romanian')}
                </MenuItem>
                <MenuItem
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                    onClick={() => handleOnClick(Language.pl)}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.menuItemflag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.menuItemflag
                        }
                        src={poland}
                        alt="fg"
                    />
                    {t('language.polish')}
                </MenuItem>
                <MenuItem
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                    onClick={() => handleOnClick(Language.hr)}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.menuItemflag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.menuItemflag
                        }
                        src={croatia}
                        alt="fg"
                    />
                    {t('language.croatian')}
                </MenuItem>
                <MenuItem
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                    onClick={() => handleOnClick(Language.pt)}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.menuItemflag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.menuItemflag
                        }
                        src={portugal}
                        alt="fg"
                    />
                    {t('language.portuguese')}
                </MenuItem>
                <MenuItem
                    css={
                        es && es.menuItem
                            ? [styles.menuItem, es.menuItem as Interpolation<Theme>]
                            : styles.menuItem
                    }
                    sx={esx && esx.menuItem ? esx.menuItem : undefined}
                    onClick={() => handleOnClick(Language.hu)}
                >
                    <img
                        css={
                            es && es.menuItemFlag
                                ? [styles.menuItemflag, es.menuItemFlag as Interpolation<Theme>]
                                : styles.menuItemflag
                        }
                        src={hungary}
                        alt="fg"
                    />
                    {t('language.hungarian')}
                </MenuItem>
            </Menu>
        </div>
    );
};
