/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ICustomStyle } from './types';

export const useStyles = (cs?: ICustomStyle) => ({
    selectedItemFlag: css({
        height: cs && cs.size ? cs.size : '2rem',
    }),
    menuItem: css({
        fontFamily: 'inherit',
        fontSize: '1.5rem',
        padding: '0.6rem 1.5rem !important',
        minHeight: '5rem',
    }),
    menuItemflag: css({
        height: '1.2rem',
        marginRight: '0.5rem',
    }),
});
