export const localTextNL = {
    selectAll: '(Selecteer Alles)',
    selectAllSearchResults: '(Selecteer Alle Zoekresultaten)',
    searchOoo: 'Zoeken...',
    blanks: "(Blanco's)",
    noMatches: 'Geen match gevonden',
    filterOoo: 'Filter...',
    equals: 'Gelijk',
    notEqual: 'Niet gelijk',
    empty: 'Kies een',
    lessThan: 'Minder dan',
    greaterThan: 'Groter dan',
    lessThanOrEqual: 'Minder dan of gelijk aan',
    greaterThanOrEqual: 'Groter dan of gelijk aan',
    inRange: 'Binnen bereik',
    inRangeStart: 'van',
    inRangeEnd: 'tot',
    contains: 'Bevat',
    notContains: 'Bevat niet',
    startsWith: 'Begint met',
    endsWith: 'Eindigt met',
    dateFormatOoo: 'dd-mm-yyyy',
    andCondition: 'EN',
    orCondition: 'OF',
    applyFilter: 'Instellen',
    resetFilter: 'Resetten',
    clearFilter: 'Opheffen',
    cancelFilter: 'Annuleren',
    textFilter: 'Tekst filter',
    numberFilter: 'Nummer filter',
    dateFilter: 'Datum filter',
    setFilter: 'Filter instellen',
    columns: 'Kolommen',
    filters: 'Filters',
    pivotMode: 'Draaimodus',
    groups: 'Rijgroepen',
    rowGroupColumnsEmptyMessage: 'Sleep hierheen om rijgroepen in te stellen',
    values: 'Waarden',
    valueColumnsEmptyMessage: 'Sleep hier om te aggregeren',
    pivots: 'Kolomlabels',
    pivotColumnsEmptyMessage: 'Sleep hierheen om kolomlabels in te stellen',
    group: 'Groep',
    loadingOoo: 'Bezig met laden...',
    noRowsToShow: 'Geen rijen om weer te geven',
    enabled: 'Ingeschakeld',
    pinColumn: 'Kolom vastzetten',
    pinLeft: 'Vastzetten links',
    pinRight: 'Vastzetten rechts',
    noPin: 'Geen pin',
    valueAggregation: 'Waarde aggregatie',
    autosizeThiscolumn: 'Grootte van deze kolom aanpassen',
    autosizeAllColumns: 'Grootte van alle kolommen aanpassen',
    groupBy: 'Groeperen op',
    ungroupBy: 'Groepering opheffen op',
    resetColumns: 'Kolommen resetten',
    expandAll: 'Alles uitvouwen',
    collapseAll: 'Sluit alles',
    copy: 'Kopiëren',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Kopiëren met kopteksten',
    paste: 'Plakken',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',
    sum: 'Som',
    min: 'Min',
    max: 'Max',
    none: 'Geen',
    count: 'Aantal',
    avg: 'Gemiddeld',
    filteredRows: 'Gefilterd',
    selectedRows: 'Geselecteerd',
    totalRows: 'Totaal aantal rijen',
    totalAndFilteredRows: 'Rijen',
    more: 'Meer',
    to: 'Tot',
    of: 'Van',
    page: 'Pagina',
    nextPage: 'Volgende pagina',
    lastPage: 'Laatste pagina',
    firstPage: 'Eerste pagina',
    previousPage: 'Vorige pagina',
    pivotColumnGroupTotals: 'Totaal',
    pivotChartAndPivotMode: 'Draaigrafiek & draaimodus',
    pivotChart: 'Draaigrafiek',
    chartRange: 'Grafiekbereik',
    columnChart: 'Kolom',
    groupedColumn: 'Gegroepeerd',
    stackedColumn: 'Gestapeld',
    normalizedColumn: '100% gestapeld',
    barChart: 'Bar',
    groupedBar: 'Gegroepeerd',
    stackedBar: 'Gestapeld',
    normalizedBar: '100% gestapeld',
    pieChart: 'Taart grafiek',
    pie: 'Taart',
    doughnut: 'Donut',
    line: 'Lijn',
    xyChart: 'X Y (spreiding)',
    scatter: 'Spreiding',
    bubble: 'Bubbel',
    areaChart: 'Gebied',
    area: 'Gebied',
    stackedArea: 'Gestapeld',
    normalizedArea: '100% gestapeld',
    histogramChart: 'Histogram',
    pivotChartTitle: 'Draaigrafiek titel',
    rangeChartTitle: 'Bereik van grafiek titel',
    settings: 'Instellingen',
    data: 'Data',
    format: 'Formaat',
    categories: 'Categorieën',
    defaultCategory: '(Geen)',
    series: 'Series',
    xyValues: 'X Y Waarden',
    paired: 'Gekoppelde modus',
    axis: 'As',
    navigator: 'Navigator',
    color: 'Kleur',
    thickness: 'Dikte',
    xType: 'X Type',
    automatic: 'Automatisch',
    category: 'Categorie',
    number: 'Nummer',
    time: 'Tijd',
    xRotation: 'X rotatie',
    yRotation: 'Y rotatie',
    ticks: 'Teken',
    width: 'Breedte',
    height: 'Hoogte',
    length: 'Lengte',
    padding: 'Opvulling',
    spacing: 'Spatiëring',
    chart: 'Grafiek',
    title: 'Titel',
    titlePlaceholder: 'Grafiektitel - dubbelklik om te bewerken',
    background: 'Achtergrond',
    font: 'Lettertype',
    top: 'Bovenkant',
    right: 'Rechts',
    bottom: 'Onderkant',
    left: 'Links',
    labels: 'Etiketten',
    size: 'Grootte',
    minSize: 'Minimale grootte',
    maxSize: 'Maximum grootte',
    legend: 'Legenda',
    position: 'Positie',
    markerSize: 'Markeringsgrootte',
    markerStroke: 'Markeringsslag',
    markerPadding: 'Markering opvulling',
    itemSpacing: 'Artikelafstand',
    itemPaddingX: 'Item opvulling X',
    itemPaddingY: 'Item opvulling Y',
    layoutHorizontalSpacing: 'Horizontale afstand',
    layoutVerticalSpacing: 'Verticale afstand',
    strokeWidth: 'Streekbreedte',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Schaduw',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Lijnbreedte',
    normal: 'Normaal',
    bold: 'Vet',
    italic: 'Cursief',
    boldItalic: 'Vet cursief',
    predefined: 'Voorgedefinieerd',
    fillOpacity: 'Dekking vullen',
    strokeOpacity: 'Lijndekking',
    histogramBinCount: 'Verzamel aantal',
    columnGroup: 'Kolom',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Lijn',
    scatterGroup: 'X Y (spreiding)',
    areaGroup: 'Gebied',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Gegroepeerd',
    stackedColumnTooltip: 'Gestapeld',
    normalizedColumnTooltip: '100% gestapeld',
    groupedBarTooltip: 'Gegroepeerd',
    stackedBarTooltip: 'Gestapeld',
    normalizedBarTooltip: '100% gestapeld',
    pieTooltip: 'Taart',
    doughnutTooltip: 'Donut',
    lineTooltip: 'Lijn',
    groupedAreaTooltip: 'Gebied',
    stackedAreaTooltip: 'Gestapeld',
    normalizedAreaTooltip: '100% Gestapeld',
    scatterTooltip: 'Spreiding',
    bubbleTooltip: 'Bubbel',
    histogramTooltip: 'Histogram',
    noDataToChart: 'Geen gegevens beschikbaar om in grafiek te brengen.',
    pivotChartRequiresPivotMode: 'Draaigrafiek vereist dat de draaimodus is ingeschakeld.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Gekoppeld aan grid',
    chartUnlinkToolbarTooltip: 'Losgekoppeld van grid',
    chartDownloadToolbarTooltip: 'Grafiek downloaden',
    ariaHidden: 'Verborgen',
    ariaVisible: 'Zichtbaar',
    ariaChecked: 'Checked',
    ariaUnchecked: 'Unchecked',
    ariaIndeterminate: 'Onbepaald',
    ariaColumnSelectAll: 'Alle kolommen selecteren',
    ariaInputEditor: 'Invoereditor',
    ariaDateFilterInput: 'Invoer datum filter',
    ariaFilterInput: 'Filter invoer',
    ariaFilterColumnsInput: 'Invoer kolommen filter',
    ariaFilterValue: 'Filterwaarde',
    ariaFilterFromValue: 'Filteren vanaf waarde',
    ariaFilterToValue: 'Filteren tot waarde',
    ariaFilteringOperator: 'Filteroperator',
    ariaColumn: 'Kolom',
    ariaColumnGroup: 'Kolom groep',
    ariaRowSelect: 'Druk op SPATIE om deze rij te selecteren',
    ariaRowDeselect: 'Druk op SPATIE om deze rij te deselecteren',
    ariaRowToggleSelection: 'Druk op SPATIE om rijselectie te wisselen',
    ariaRowSelectAll: 'Druk op SPATIE om alle rijen te selecteren',
    ariaToggleVisibility: 'Druk op SPATIE om te schakelen tussen zichtbaarheid',
    ariaSearch: 'Zoeken',
    ariaSearchFilterValues: 'Zoek filterwaarden',
    ariaLabelColumnMenu: 'Kolom menu',
    ariaLabelCellEditor: 'Cel editor',
    ariaLabelDialog: 'Dialoog',
    ariaLabelSelectField: 'Selecteer veld',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context menu',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'Aggregatiefunctie',
};
