import { IUpdateAmbassadorVariables } from './types';
import { IAmbassador } from '../../../hooks';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useUpdateAmbassador = (ambassadorId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<IAmbassador>, any, IUpdateAmbassadorVariables>(({ body }) =>
        api.patch(`/ambassadors/${ambassadorId}`, body)
    );
};
