/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle, IElementSxProps } from './types';
import { TextField, TextFieldProps, InputAdornment } from '@mui/material';
import { FieldProps } from 'formik';

interface IProps {
    min?: number;
    max?: number;
    step?: number;
    unit?: string;
    cs?: ICustomStyle;
    es?: IElementStyle;
    esx?: IElementSxProps;
    style?: React.CSSProperties;
    className?: string;
}

export const NumberFieldInput = ({
    label,
    placeholder,
    min,
    max,
    step,
    disabled,
    unit,
    cs,
    es,
    esx,
    style,
    className,
    field,
    onChange,
    form: { errors, touched, handleChange, handleBlur },
}: IProps & TextFieldProps & FieldProps) => {
    const styles = useStyles(field, errors, touched, cs);

    return (
        <TextField
            {...field}
            css={
                es && es.textField
                    ? [es.textField as Interpolation<Theme>, style as Interpolation<Theme>]
                    : (style as Interpolation<Theme>)
            }
            sx={esx && esx.textField ? { ...styles.textField, ...esx.textField } : styles.textField}
            className={className}
            variant="standard"
            type="number"
            label={label}
            placeholder={placeholder}
            autoComplete="new-password"
            disabled={disabled ? disabled : false}
            fullWidth
            inputProps={{
                min,
                max,
                step,
                autoComplete: 'new-password',
                role: 'presentation',
                spellCheck: false,
                'aria-live': 'polite',
                disabled: disabled ? disabled : false,
            }}
            error={Boolean(errors[field.name]) && Boolean(touched[field.name])}
            helperText={
                Boolean(errors[field.name]) && Boolean(touched[field.name])
                    ? errors[field.name]
                    : ' '
            }
            onChange={(event) => {
                if (onChange) {
                    onChange(event);
                }
                handleChange(event);
            }}
            onBlur={(event) => {
                handleBlur(event);
            }}
            onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
            }}
            InputProps={
                unit
                    ? {
                          startAdornment: (
                              <InputAdornment
                                  position="start"
                                  css={
                                      es && es.startAdornment
                                          ? (es.startAdornment as Interpolation<Theme>)
                                          : undefined
                                  }
                                  sx={esx && esx.startAdornment ? esx.startAdornment : undefined}
                              >
                                  {unit}
                              </InputAdornment>
                          ),
                      }
                    : {}
            }
            InputLabelProps={
                unit
                    ? {
                          shrink: true,
                      }
                    : {}
            }
        />
    );
};
