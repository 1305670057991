import { IUpdateCandidateEmployeeNumberVariables } from './types';
import { ICandidate } from '../../../hooks';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useUpdateCandidateEmployeeNumber = (candidateId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<ICandidate>, any, IUpdateCandidateEmployeeNumberVariables>(
        ({ body }) => api.patch(`/candidates/${candidateId}/employee-number`, body)
    );
};
