import { colors } from '../../../helpers';

export const globePoints = [
    {
        lat: 51.9106641,
        lng: 4.4769407,
        size: 0.08,
        radius: 0.5,
        color: colors.orange,
        label: 'Rotterdam',
    },
    {
        lat: 45.6523093,
        lng: 25.6102746,
        size: 0.1,
        radius: 0.5,
        color: colors.orange,
        label: 'Brasov',
    },
    {
        lat: 38.7077507,
        lng: -9.1365919,
        size: 0.08,
        radius: 0.5,
        color: colors.orange,
        label: 'Lisboa',
    },
    {
        lat: 50.6787929,
        lng: 17.9298844,
        size: 0.08,
        radius: 0.5,
        color: colors.orange,
        label: 'Opole',
    },
    {
        lat: 45.812945,
        lng: 15.977938,
        size: 0.08,
        radius: 0.5,
        color: colors.orange,
        label: 'Zagreb',
    },
];
