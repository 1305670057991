/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';
import { ICustomStyle, IElementStyle } from './types';
import { AiOutlineExclamationCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';

interface IProps {
    open: boolean;
    variant: 'success' | 'error';
    message: string;
    cs: ICustomStyle;
    es?: IElementStyle;
    style?: React.CSSProperties;
    className?: string;
    closeSnack: () => void;
}

export const Snack = ({ open, variant, message, cs, es, closeSnack, className, style }: IProps) => {
    const styles = useStyles(variant, cs);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            closeSnack();
        }, 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [closeSnack]);

    if (open) {
        return (
            <div
                css={
                    es && es.root
                        ? [
                              styles.root,
                              es.root as Interpolation<Theme>,
                              style as Interpolation<Theme>,
                          ]
                        : [styles.root, style as Interpolation<Theme>]
                }
                className={className}
            >
                <div
                    css={
                        es && es.icon ? [styles.icon, es.icon as Interpolation<Theme>] : styles.icon
                    }
                >
                    {variant === 'success' ? (
                        <AiOutlineCheckCircle />
                    ) : (
                        <AiOutlineExclamationCircle />
                    )}
                </div>
                <div
                    css={
                        es && es.message
                            ? [styles.message, es.message as Interpolation<Theme>]
                            : styles.message
                    }
                >
                    <p>{message}</p>
                </div>
                <div
                    css={
                        es && es.closeIcon
                            ? [styles.closeIcon, es.closeIcon as Interpolation<Theme>]
                            : styles.closeIcon
                    }
                >
                    <RiCloseLine onClick={closeSnack} />
                </div>
            </div>
        );
    }
    return null;
};
