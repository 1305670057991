/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchPipeline, useFetchDepartments } from '../hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Skeleton } from '@mui/material';
import {
    Page,
    NumberSelectInput,
    PipelineOranjegroepTable,
    PipelineDepartmentTable,
    PipelineAmbassadorsTable,
} from '../../../components';
import { IPipelineDepartmentData } from '../../../components/tables/pipeline-department-table/types';
import { IPipelineOranjegroepData } from '../../../components/tables/pipeline-oranjegroep-table/types';
import { ISelectItem } from '../../../components/inputs/number-select/types';
import { handleQueryError } from '../../../helpers';

export const OranjelinkSalesPipeline = () => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [year, setYear] = React.useState(moment().year());

    const departmentsResponse = useFetchDepartments();
    const pipelineResponse = useFetchPipeline(true, year, '', '');

    const getYears = React.useCallback((): ISelectItem[] => {
        const years: ISelectItem[] = [];
        for (let i = 2024; i <= moment().year(); i++) {
            years.push({
                value: i,
                item: i.toString(),
            });
        }
        return years;
    }, []);

    const getOranjelinkData = React.useCallback((): IPipelineOranjegroepData => {
        const result: IPipelineOranjegroepData = {
            ambassadorAmount: 0,
            workedCandidateAmount: 0,
            invitationAmount: 0,
            registrationAmount: 0,
            rejectedAmount: 0,
            firstTierCandidateHours: 0,
            secondTierCandidateHours: 0,
            thirdTierCandidateHours: 0,
            paidFee: 0,
        };

        if (pipelineResponse.data) {
            pipelineResponse.data.pipelineAmbassadors.forEach((ambassador) => {
                result.ambassadorAmount++;
                result.workedCandidateAmount += ambassador.wrokedCandidateAmount;
                result.invitationAmount += ambassador.invitationAmount;
                result.registrationAmount += ambassador.registeredAmount;
                result.rejectedAmount += ambassador.rejectedAmount;
                if (ambassador.firstTierCandidateHours) {
                    result.firstTierCandidateHours += ambassador.firstTierCandidateHours;
                }
                if (ambassador.secondTierCandidateHours) {
                    result.secondTierCandidateHours += ambassador.secondTierCandidateHours;
                }
                if (ambassador.thirdTierCandidateHours) {
                    result.thirdTierCandidateHours += ambassador.thirdTierCandidateHours;
                }
                if (ambassador.paidFee) {
                    result.paidFee += ambassador.paidFee;
                }
            });
        }

        return result;
    }, [pipelineResponse.data]);

    const getDepartmentData = React.useCallback((): IPipelineDepartmentData[] => {
        const result: IPipelineDepartmentData[] = [];
        if (departmentsResponse.data && pipelineResponse.data) {
            departmentsResponse.data.forEach((department) => {
                let ambassadorAmount = 0;
                let workedCandidateAmount = 0;
                let invitationAmount = 0;
                let registrationAmount = 0;
                let rejectedAmount = 0;
                let firstTierCandidateHours = 0;
                let secondTierCandidateHours = 0;
                let thirdTierCandidateHours = 0;
                let paidFee = 0;

                pipelineResponse.data.pipelineAmbassadors
                    .filter((ambassador) => ambassador.department.id === department.id)
                    .forEach((ambassador) => {
                        ambassadorAmount++;
                        workedCandidateAmount += ambassador.wrokedCandidateAmount;
                        invitationAmount += ambassador.invitationAmount;
                        registrationAmount += ambassador.registeredAmount;
                        rejectedAmount += ambassador.rejectedAmount;
                        if (ambassador.firstTierCandidateHours) {
                            firstTierCandidateHours += ambassador.firstTierCandidateHours;
                        }
                        if (ambassador.secondTierCandidateHours) {
                            secondTierCandidateHours += ambassador.secondTierCandidateHours;
                        }
                        if (ambassador.thirdTierCandidateHours) {
                            thirdTierCandidateHours += ambassador.thirdTierCandidateHours;
                        }
                        if (ambassador.paidFee) {
                            paidFee += ambassador.paidFee;
                        }
                    });

                result.push({
                    department,
                    ambassadorAmount,
                    workedCandidateAmount,
                    invitationAmount,
                    registrationAmount,
                    rejectedAmount,
                    firstTierCandidateHours,
                    secondTierCandidateHours,
                    thirdTierCandidateHours,
                    paidFee,
                });
            });
        }
        return result;
    }, [departmentsResponse.data, pipelineResponse.data]);

    if (departmentsResponse.error) {
        return handleQueryError(departmentsResponse.error, history);
    }
    if (pipelineResponse.error) {
        return handleQueryError(pipelineResponse.error, history);
    }
    return (
        <Page style={styles.root} title={t('text.oranjelinkSalesPipeline')}>
            <div style={styles.filter}>
                <NumberSelectInput
                    css={styles.filterNumberSelect}
                    label={t('form.label.year')}
                    value={year}
                    setValue={setYear}
                    items={getYears()}
                    cs={{
                        fontSize: '1.5rem',
                    }}
                />
            </div>
            {departmentsResponse.isLoading || pipelineResponse.isLoading ? (
                <React.Fragment>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={styles.oranjegroepGridSkeleton}
                    />
                    <Skeleton variant="rectangular" animation="wave" style={styles.gridSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.gridSkeleton} />
                </React.Fragment>
            ) : null}
            {departmentsResponse.data &&
            pipelineResponse.data &&
            pipelineResponse.data.pipelineAmbassadors.length > 0 ? (
                <React.Fragment>
                    <PipelineOranjegroepTable data={getOranjelinkData()} />
                    <PipelineDepartmentTable departments={getDepartmentData()} maxHeight="21.5vh" />
                    <PipelineAmbassadorsTable
                        ambassadors={pipelineResponse.data.pipelineAmbassadors}
                        maxHeight="21.5vh"
                    />
                </React.Fragment>
            ) : null}
            {departmentsResponse.data &&
            pipelineResponse.data &&
            pipelineResponse.data.pipelineAmbassadors.length === 0 ? (
                <div style={styles.noDataContainer}>
                    <p style={styles.noData}>{t('text.noDataToShow')}</p>
                </div>
            ) : null}
        </Page>
    );
};
