import * as React from 'react';
import { IPayload, IState, TAction } from './types';

const reducer = (state: IState, action: TAction): IState => {
    switch (action.type) {
        case 'OPEN_FUNCTIONS':
            return {
                ...state,
                candidateName: action.payload!.candidateName,
                professions: action.payload!.professions,
                isProfessionsOpen: true,
            };
        case 'CLOSE_FUNCTIONS':
            return { ...state, isProfessionsOpen: false };
        default:
            return state;
    }
};

export const useProfessionsModal = () => {
    const [{ isProfessionsOpen, candidateName, professions }, dispatch] = React.useReducer(
        reducer,
        {
            isProfessionsOpen: false,
            candidateName: '',
            professions: [],
        }
    );

    return {
        isProfessionsOpen,
        candidateName,
        professions,
        openProfessions: (payload: IPayload) => dispatch({ type: 'OPEN_FUNCTIONS', payload }),
        closeProfessions: () => dispatch({ type: 'CLOSE_FUNCTIONS' }),
    };
};
