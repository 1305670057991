import { PhoneNumberUtil } from 'google-libphonenumber';

export const isValidPhoneNumber = (phone: string): boolean => {
    const phoneUtil = PhoneNumberUtil.getInstance();

    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

/* export const isValidPhoneNumber = (text: string): boolean => {
    const regEx: RegExp = /^([+](?!0))?\d{10,13}$/g;
    return regEx.test(text);
}; */
