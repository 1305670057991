/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useStyles } from './style';

interface IProps {
    children?: React.ReactNode;
    open: boolean;
    noBlur?: boolean;
    style?: React.CSSProperties;
    className?: string;
}

export const Backdrop = ({ children, open, noBlur, style, className }: IProps) => {
    const styles = useStyles(open, noBlur ? true : false);

    React.useLayoutEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'initial';
        }

        return () => {
            document.body.style.overflow = 'initial';
        };
    }, [open]);

    return (
        <div className={className} css={[styles.root, style as Interpolation<Theme>]}>
            {children}
        </div>
    );
};
