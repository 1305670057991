/**@jsxImportSource @emotion/react */
import { Theme, alpha } from '@mui/material';
import { colors } from '../../../helpers';
import { ICustomStyle } from './types';

export const useStyles = (theme: Theme, cs?: ICustomStyle) => ({
    formControl: {
        display: 'inline-flex',
        width: '100%',
        margin: 0,
        '& .MuiSwitch-root': {
            order: 1,
        },
        '& .MuiTypography-root': {
            fontSize: cs && cs.fontSize ? cs.fontSize : '2rem',
            fontFamily: cs && cs.fontFamily ? cs.fontFamily : 'inherit',
            color: cs && cs.labelColor ? cs.labelColor : colors.blue,
            userSelect: 'none',
        },
        '& .MuiSwitch-switchBase': {
            color: cs && cs.uncheckedColor ? cs.uncheckedColor : colors.gray,
            '&:hover': {
                backgroundColor:
                    cs && cs.uncheckedColor
                        ? alpha(cs.uncheckedColor, theme.palette.action.hoverOpacity)
                        : alpha(colors.gray, theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: cs && cs.uncheckedColor ? cs.uncheckedColor : colors.gray,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: cs && cs.checkedColor ? cs.checkedColor : colors.blue,
            '&:hover': {
                backgroundColor:
                    cs && cs.checkedColor
                        ? alpha(cs.checkedColor, theme.palette.action.hoverOpacity)
                        : alpha(colors.blue, theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: cs && cs.checkedColor ? cs.checkedColor : colors.blue,
        },
    },
});
