/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { ListComboBoxInput } from '../../../../components';

export const EmailInput = (props: any) => {
    return (
        <ListComboBoxInput
            {...props}
            options={props.data}
            getOptionLabel={(user) => `${user.name}<${user.email}>`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};
