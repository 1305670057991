import { IChangeEmailSubscriptionVariables } from './types';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useChangeEmailSubscription = (userId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<void>, any, IChangeEmailSubscriptionVariables>(({ body }) =>
        api.patch(`/users/${userId}/subscription`, body)
    );
};
