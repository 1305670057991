import { ICreateAmbassadorVariables } from './types';
import { IAmbassador } from '../../../hooks';
import { useApi } from '../../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useCreateAmbassador = () => {
    const api = useApi();

    return useMutation<AxiosResponse<IAmbassador>, any, ICreateAmbassadorVariables>(({ body }) =>
        api.post('/ambassadors', body)
    );
};
