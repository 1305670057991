/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IAmbassador, IDepartment } from '../../../../hooks';
import { isAmbassadorActive } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { FcApproval, FcCancel } from 'react-icons/fc';

interface IProps {
    ambassador: IAmbassador;
}

export const MainPanel = ({ ambassador }: IProps) => {
    const styles = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <div style={styles.panel}>
            <p
                style={styles.panelText}
            >{`${ambassador.user.firstName} ${ambassador.user.lastName}`}</p>
            <p style={styles.panelEmailText}>{ambassador.user.email}</p>
            <div>
                <p style={styles.panelText}>{t('text.employeeNumber')}:&nbsp;</p>
                <p style={styles.panelDataText}>{ambassador.employeeNumber.toUpperCase()}</p>
            </div>
            {ambassador.isContractSigned ? (
                <div>
                    <p style={styles.panelText}>{t('text.isAddendumSigned')}:&nbsp;</p>
                    <FcApproval style={styles.panelBooleanIcon} />
                </div>
            ) : (
                <div style={styles.panelBooleanBox}>
                    <p style={styles.panelText}>{t('text.isAddendumSigned')}:&nbsp;</p>
                    <FcCancel style={styles.panelBooleanIcon} />
                </div>
            )}
            <div>
                <p style={styles.panelText}>{t('text.language')}:&nbsp;</p>
                <p style={styles.panelDataText}>{ambassador.user.language.toUpperCase()}</p>
            </div>
            <div>
                <p style={styles.panelText}>{t('text.department')}:&nbsp;</p>
                <p style={styles.panelDataText}>
                    {ambassador.user.department
                        ? ambassador.user.department[i18n.language as keyof IDepartment]
                        : ''}
                </p>
            </div>
            <div style={styles.panelBooleanBox}>
                <p style={styles.panelText}>{t('text.active')}:&nbsp;</p>
                {isAmbassadorActive(ambassador) ? (
                    <FcApproval style={styles.panelBooleanIcon} />
                ) : (
                    <FcCancel style={styles.panelBooleanIcon} />
                )}
            </div>
        </div>
    );
};
