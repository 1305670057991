/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useFetchCandidate, useUpdateCandidateInvitation } from './hooks';
import {
    IRouteParams,
    Language,
    handleError,
    handleQueryError,
    routePath,
    colors,
    isRichTextEditorEmpty,
} from '../../../helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { Page, Button, LanguageSelectInput, RichTextEditorInput } from '../../../components';
import { useMediaQuery, Skeleton } from '@mui/material';

export const UpdateCandidateInvitation = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const { id } = useParams<IRouteParams>();
    const history = useHistory();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchCandidate(id);
    const updateInvitation = useUpdateCandidateInvitation(id);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page isPageSubmitting={updateInvitation.isLoading} style={styles.root}>
            <div style={styles.content}>
                <h1 css={styles.contentTitle}>{t('text.resendInvitation')}</h1>
                {isLoading ? (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        css={styles.candidateNameSkeleton}
                    />
                ) : null}
                {data ? (
                    <p css={styles.contentCandidateName}>{`${t('text.candidate')}: ${
                        data.user.firstName
                    } ${data.user.lastName}`}</p>
                ) : null}
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            language: '' as Language,
                            invitationMessage: '',
                        } as IFormValues
                    }
                    validate={({ language, invitationMessage }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!language) errors.language = t('form.error.requiredField');
                        if (
                            !isRichTextEditorEmpty(invitationMessage) &&
                            invitationMessage.length > 1023
                        ) {
                            errors.invitationMessage = t('form.error.messageTooLong');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ language, invitationMessage }) => {
                        try {
                            await updateInvitation.mutateAsync({
                                body: {
                                    language,
                                    invitationMessage: !isRichTextEditorEmpty(invitationMessage)
                                        ? invitationMessage
                                        : undefined,
                                },
                            });
                            history.push(routePath.candidates.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form style={styles.contentForm} noValidate>
                            <Field
                                style={styles.formField}
                                name="language"
                                label={t('form.label.language')}
                                disabled={isSubmitting}
                                component={LanguageSelectInput}
                            />
                            <Field
                                style={styles.formField}
                                name="invitationMessage"
                                label={t('form.label.messageToCandidate')}
                                controls={['bold', 'italic', 'underline', 'undo', 'redo']}
                                placeholder={t('form.placeholder.messageToCandidate')}
                                component={RichTextEditorInput}
                            />
                            <Button
                                css={styles.formButton}
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.send')}
                            </Button>
                            <Button
                                css={styles.formButton}
                                type="button"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                                onClick={() => history.push(routePath.candidates.create())}
                            >
                                {t('text.back')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Page>
    );
};
