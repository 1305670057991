import moment from 'moment';
import { IAmbassador } from '../hooks';

export const isAmbassadorActive = (ambassador: IAmbassador): boolean => {
    let result = false;
    const year = moment().year();
    const week = moment().isoWeek();

    ambassador.activityPeriods.forEach((activityPeriod) => {
        if (
            (!activityPeriod.endYear &&
                !activityPeriod.endWeek &&
                ((activityPeriod.startYear === year && activityPeriod.startWeek <= week) ||
                    activityPeriod.startYear < year)) ||
            (activityPeriod.endYear &&
                activityPeriod.endWeek &&
                ((activityPeriod.startYear === year && activityPeriod.startWeek <= week) ||
                    activityPeriod.startYear < year) &&
                ((activityPeriod.endYear === year && activityPeriod.endWeek >= week) ||
                    activityPeriod.endYear > year))
        ) {
            activityPeriod.employedPeriods.forEach((employedPeriod) => {
                if (
                    (!employedPeriod.endYear &&
                        !employedPeriod.endWeek &&
                        ((employedPeriod.startYear === year && employedPeriod.startWeek <= week) ||
                            employedPeriod.startYear < year)) ||
                    (employedPeriod.endYear &&
                        employedPeriod.endWeek &&
                        ((employedPeriod.startYear === year && employedPeriod.startWeek <= week) ||
                            employedPeriod.startYear < year) &&
                        ((employedPeriod.endYear === year && employedPeriod.endWeek >= week) ||
                            employedPeriod.endYear > year))
                ) {
                    result = true;
                }
            });
        }
    });
    return result;
};
