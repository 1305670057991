/**@jsxImportSource @emotion/react */
import React from 'react';
import { useStyles } from './style';

export const Dutch = () => {
    const styles = useStyles();

    return (
        <div style={styles.root}>
            <h3 css={styles.titel}>ORANJEGROEP Privacy policy statement</h3>
            <p style={styles.bottomSpaceParagraph}>
                ORANJEGROEP neemt jouw privacy zeer serieus en zal informatie over jou (de
                betrokkene) op een veilige manier verwerken en gebruiken. In dit Privacy Statement
                leggen wij je uit welke gegevens wij verwerken, en met welk doel we dat doen.
                Daarnaast kun je in dit Privacy Statement lezen over jouw rechten met betrekking tot
                onze verwerking van jouw persoonsgegevens. We raden je aan dit Privacy Statement
                aandachtig door te nemen.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Wanneer je informatie aan ons verstrekt, zullen wij hier zorgvuldig mee omgaan. Deze
                privacy statement beschrijft (samen met de documenten waarnaar wordt verwezen) welke
                persoonsgegevens wij van jouw verzamelen en hoe wij deze verwerken. We raden je aan
                dit Privacy Statement aandachtig door te nemen.
            </p>
            <p style={styles.subTitel}>Wie zijn wij?</p>
            <p style={styles.bottomSpaceParagraph}>
                De Oranjegroep is een uitzendbureau in de bouw, techniek en industrie.
                Vanzelfsprekend, komt een uitzendbureau, veel in contact met persoonsgegevens van
                zowel werkzoekenden als werknemers (in welke vorm dan ook) als die van haar
                leveranciers en opdrachtgevers.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Dit privacy statement is van toepassing op alle persoonsgegevens die De Oranjegroep
                verwerkt van iedereen die weleens contact heeft (gehad) met De Oranjegroep, zoals
                onze bezoekers, klanten en zakelijke contactpersonen.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                De Oranjegroep heeft zowel binnen- als buitenlandse werkmaatschappijen, die zich
                bezighouden met het leveren van diensten met betrekking tot arbeid en
                arbeidsbemiddeling. Onze maatregelen voor het beschermen van al deze
                persoonsgegevens, benoemen wij in dit privacy statement.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Ons moederbedrijf is Oranjegroep Holding B.V. genaamd, welke haar hoofdkantoor heeft
                aan de Van Vollenhovenstraat 10 te (3016 BH) Rotterdam.
            </p>
            <p style={styles.subTitel}>Wat zijn persoonsgegevens?</p>
            <p style={styles.bottomSpaceParagraph}>
                Persoonsgegevens zijn alle gegevens die herleidbaar zijn tot jou als individu. Denk
                maar aan jouw naam, telefoonnummer, adres of e-mailadres. Maar ook gegevens zoals je
                IP-adres zijn persoonsgegevens.
            </p>
            <p style={styles.subTitel}>Waarvoor gebruiken wij deze persoonsgegevens?</p>
            <p style={styles.bottomSpaceParagraph}>
                Jouw persoonsgegevens worden verwerkt overeenkomstig wet- en regelgeving en voor
                zolang noodzakelijk voor de doeleinden waarvoor deze gegevens zijn verzameld.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ het verlenen van toegang tot de website, portalen en systemen van Oranjegroep;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ het gebruik maken van de functionaliteiten op onze website, portalen en systemen;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ het aangaan en uitvoeren van overeenkomsten via onze website, portalen en
                systemen;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ het opnemen van contact met jou, indien hierom wordt verzocht;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ het verkrijgen van inzicht in het gebruik van onze website, portalen en systemen;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ het verbeteren en evalueren van onze website en diensten;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ het in kaart brengen van onze doelgroep en bijbehorende interesses, o.a. om het
                internetaanbod voor jou interessanter te maken middels het tonen van reclame welke
                aansluit bij jouw interesses via zowel onze eigen websites als websites van derden;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ het toesturen van (een van) onze algemene en/of gepersonaliseerde nieuwsbrieven
                indien je je hiervoor hebt ingeschreven;
            </p>
            <p style={styles.firstTabParagraph}>⦿ versturen van vacaturealerts;</p>
            <p style={styles.firstTabParagraph}>⦿ aanmelding voor events;</p>
            <p style={styles.firstTabParagraph}>⦿ arbeidsbemiddeling;</p>
            <p style={styles.firstTabParagraph}>
                ⦿ sollicitaties vaste/indirecte medewerkers (bij de eigen onderneming zonder dat
                sprake is van arbeidsbemiddeling);
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ (potential) (potentieel) zakelijke relatie (opdrachtgever dan wel leverancier);
            </p>
            <p style={styles.lastFirstTabParagraph}>
                ⦿ verwerken van sollicitaties. Voor het doen van sollicitaties geldt een aparte
                privacy statement, deze is te vinden bij het inschrijf-/sollicitatieformulier.
            </p>
            <p style={styles.subTitel}>Welke persoonsgegevens verwerken wij?</p>
            <p style={styles.bottomSpaceParagraph}>
                Inschrijfproces middels inschrijfformulier (hardcopy), website (toekomst) of
                registratie in uitzendsoftware.
            </p>
            <p style={styles.firstTabParagraph}>⦿ Werkzoekenden</p>
            <p style={styles.secondTabParagraph}>‣ NAW-gegevens (naam, adres, woonplaats)</p>
            <p style={styles.secondTabParagraph}>
                ‣ E-mailadres, telefoonnummer en eventuele andere contactgegevens
            </p>
            <p style={styles.secondTabParagraph}>‣ Nationaliteit, geslacht</p>
            <p style={styles.secondTabParagraph}>‣ Geboortedatum, geboorteplaats</p>
            <p style={styles.secondTabParagraph}>
                ‣ Curriculum vitae (CV), werkervaring, opleiding- en scholingsgegevens
            </p>
            <p style={styles.secondTabParagraph}>‣ IP-adres</p>
            <p style={styles.secondTabParagraph}>‣ Cookie ID's</p>
            <p style={styles.lastSecondTabParagraph}>
                ‣ Eventuele persoonsgegevens die door jou worden ingevuld als inhoud van een bericht
                bijvoorbeeld via de chatfunctie, het contactformulier, sociale media, winactie
                aanmeldformulieren of feedback tools
            </p>
            <p style={styles.firstTabParagraph}>⦿ Opdrachtgevers</p>
            <p style={styles.secondTabParagraph}>
                ‣ NAW-gegevens (naam, adres, woonplaats) bedrijf
            </p>
            <p style={styles.secondTabParagraph}>
                ‣ NAW-gegevens (naam, adres, woonplaats) projectlocaties
            </p>
            <p style={styles.secondTabParagraph}>
                ‣ E-mailadres, telefoonnummer en eventuele andere contactgegevens
            </p>
            <p style={styles.secondTabParagraph}>‣ Klantnummer</p>
            <p style={styles.secondTabParagraph}>‣ Financiële gegevens zoals bankrekeningnummer</p>
            <p style={styles.lastSecondTabParagraph}>
                ‣ Andere informatie benodigd voor de uitvoering van onze samenwerking, opdracht
                en/of werkzaamheden.
            </p>
            <p style={styles.firstTabParagraph}>⦿ Leveranciers</p>
            <p style={styles.secondTabParagraph}>‣ NAW-gegevens (naam, adres, woonplaats)</p>
            <p style={styles.secondTabParagraph}>
                ‣ E-mailadres, telefoonnummer en eventuele andere contactgegevens
            </p>
            <p style={styles.secondTabParagraph}>‣ Leveranciersnummer</p>
            <p style={styles.lastSecondTabParagraph}>
                ‣ Andere informatie benodigd voor de uitvoering van uw dienstverband, opdracht en/of
                werkzaamheden.
            </p>
            <p style={styles.subTitel}>Hoe worden de persoonsgegevens verzameld?</p>
            <p style={styles.bottomSpaceParagraph}>
                De persoonsgegevens van de bezoekers worden op onderstaande wijzen verkregen:
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ Via het gebruik van de website alsmede de daarop geplaatste cookies;
            </p>
            <p style={styles.lastFirstTabParagraph}>
                ⦿ Door verstrekking van persoonsgegevens door de gebruiker zelf.
            </p>
            <p style={styles.subTitel}>
                Op welke rechtsgronden verwerken wij jouw persoonsgegevens?
            </p>
            <p style={styles.bottomSpaceParagraph}>
                De verwerking van persoonsgegevens doen wij op basis van volgende rechtsgronden:
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ de verwerking is noodzakelijk voor de uitvoering van een overeenkomst (waaronder
                arbeidsovereenkomst, opdrachtovereenkomst, stageovereenkomst) die wordt aangegaan of
                is aangegaan en waarbij jij partij van bent;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ de gegevensverwerking is voor ons noodzakelijk om een wettelijke verplichting na
                te komen bijvoorbeeld de controle van jouw ID-bewijs bij het aangaan van een
                arbeidsovereenkomst;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ de gegevensverwerking is noodzakelijk in verband met een gerechtvaardigd
                (bedrijfs-)belang dat wij hebben, te weten:
            </p>
            <p style={styles.secondTabParagraph}>
                ‣ legitieme bedrijfsprocessen en activiteiten, waaronder het genereren van
                managementinformatie en -rapportages, het uitvoeren van marktanalyses, data
                analyses, bijhouden van statistieken, (interne en externe) controles, audits en
                accountantscontroles, bedrijfstransacties (samenwerkingsverbanden, koop/verkoop) en
                het bevorderen en handhaven van de bedrijfsveiligheid;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ naleving van of onderzoek naar (vermoedelijke) overtreding van overeengekomen
                afspraken, interne beleidsregels/instructies en/of wet- en regelgeving;
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ om je te informeren over zaken die voor de uitvoering van uw werkzaamheden
                relevant zijn, over onze organisatie, activiteiten en/of vakinhoudelijke informatie
                (bijvoorbeeld via e-mail, nieuwsbrieven, bedrijfsmagazines en vakliteratuur).
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ de persoonsgegevens worden verwerkt met jouw toestemming of jouw uitdrukkelijke
                toestemming, bijvoorbeeld toestemming voor het verwerken van persoonsgegevens in het
                kader van een sollicitatieproces. Een gegeven toestemming kan je te allen tijde weer
                intrekken, wat betekent dat wij vanaf dat moment het betreffende persoonsgegeven
                niet meer verwerken voor het doeleinde waarvoor toestemming is gevraagd.
            </p>
            <p style={styles.lastFirstTabParagraph}>
                ⦿ de gegevensverwerking is noodzakelijk met het oog op de uitvoering van
                verplichtingen, die wij als werkgever hebben op het gebied van het arbeidsrecht en
                sociaalzekerheidsrecht bij verzuim en re-integratie
            </p>
            <p style={styles.subTitel}>Met wie worden jouw persoonsgegevens gedeeld?</p>
            <p style={styles.bottomSpaceParagraph}>
                De Oranjegroep verkoopt jouw gegevens nooit aan derden. Wel maken wij gebruik van de
                dienstverlening van andere partijen die namens ons gegevens verwerken. Wij sluiten
                verwerkersovereenkomsten af met onze verwerkers (partners/leveranciers) zodat ook
                zij zorgvuldig omgaan met jouw persoonsgegevens. Wij blijven immers verantwoordelijk
                voor bescherming van jouw gegevens. Wij kunnen jouw gegevens verstrekken aan de
                volgende bedrijven die deze gegevens in onze opdracht verwerken:
            </p>
            <p style={styles.firstTabParagraph}>⦿ Externe ICT-dienstverleners</p>
            <p style={styles.lastFirstTabParagraph}>⦿ De partijen die cookies aanleveren</p>
            <p style={styles.bottomSpaceParagraph}>
                Wij bewaren jouw gegevens niet langer dan strikt noodzakelijk voor het doel waar de
                gegevens voor verkregen zijn.
            </p>
            <p style={styles.subTitel}>Hoe zijn jouw gegevens beveiligd?</p>
            <p style={styles.bottomSpaceParagraph}>
                Wij nemen de bescherming van de persoonsgegevens van de bezoekers van onze website
                serieus en nemen daarom passende organisatorische en technische maatregelen om
                misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking, ongeoorloofde
                wijziging of een anderszins onrechtmatige verwerking tegen te gaan.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Onze medewerkers die toegang hebben tot jouw persoonsgegevens zijn gehouden aan een
                geheimhoudingsbeding en hebben uitsluitend toegang tot deze persoonsgegevens indien
                nodig voor de uitoefening van hun functie en/of het ondersteunen van de website,
                portalen en systemen van Oranjegroep.
            </p>
            <p style={styles.subTitel}>Wat zijn jouw rechten?</p>
            <p style={styles.bottomSpaceParagraph}>
                Krachtens de Algemene verordening gegevensbescherming (AVG) heeft u de volgende
                rechten:
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Recht op informatie</strong> <br /> Je hebt het recht op een begrijpelijke
                en transparante uitleg over hoe wij met jouw persoonsgegevens omgaan en welke
                rechten je in dat verband kan uitoefenen. In dit Privacy Statement hebben wij daarom
                uitgebreid uitgelegd welke gegevens wij van jou verzamelen en hoe wij met jouw
                gegevens omgaan.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Recht op inzage</strong> <br /> Je hebt het recht om te allen tijde inzage
                van ons te vragen in de gegevens die wij over jou verwerkt hebben.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Recht op correctie</strong> <br /> Je hebt het recht om jouw
                persoonsgegevens te laten corrigeren als deze onjuist of verouderd zijn en/of om ze
                te laten aanvullen als ze onvolledig zijn.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Recht om bezwaar te maken</strong> <br /> YJe hebt het recht om bezwaar te
                maken tegen de verwerking van jouw gegevens als je het niet eens bent met de manier
                waarop we jouw persoonsgegevens verwerken.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Recht op dataportabiliteit</strong> <br /> Je hebt het recht om gegevens
                die je aan ons hebt gegeven in het kader van de overeenkomst(en) die je met ons hebt
                gesloten te ontvangen in een machine-leesbaar formaat, zodat je deze gegevens kan
                bewaren in een database van jou of van een andere partij.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Recht op beperking</strong> <br /> Je hebt het recht om beperking van de
                verwerking van jouw gegevens te verzoeken. Dit betekent dat wij jouw gegevens mogen
                bewaren maar niet gebruiken. Dit recht ontstaat in een aantal gevallen. Als je van
                mening bent dat hier sprake van is, kan je contact met ons opnemen.
            </p>
            <p style={styles.firstTabParagraph}>
                ⦿ <strong>Recht om vergeten te worden/verzoek om account te verwijderen</strong>
                <br /> Je hebt het recht om ons te verzoeken om alle gegevens die wij van je hebben
                te verwijderen.
            </p>
            <p style={styles.lastFirstTabParagraph}>
                ⦿ <strong>Recht om een klacht in te dienen</strong> <br /> Jij hebt het recht om een
                klacht in te dienen over de wijze waarop wij met jouw gegevens omgaan.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Afhankelijk van de mate waarin het verwerken van jouw persoonsgegevens is gebaseerd
                op jouw toestemming, heb je ook het recht om jouw toestemming op enig moment in te
                trekken. Het intrekken van jouw toestemming is echter niet van invloed op de
                rechtmatigheid van enige verwerking op basis van jouw eerdere toestemming voordat
                wij een dergelijke intrekking hebben ontvangen.
            </p>
            <p style={styles.subTitel}>Wat zijn cookies?</p>
            <p style={styles.bottomSpaceParagraph}>
                Cookies zijn kleine stukjes (tekst)informatie die bij het bezoek van de website
                worden meegestuurd aan je browser en vervolgens op de harde schijf of in het
                geheugen van je computer, tablet, mobiele telefoon of ander apparaat (hierna:
                “Apparaat”) worden opgeslagen. De cookies die via de website worden geplaatst,
                kunnen jouw Apparaat of de bestanden die daarop opgeslagen staan niet beschadigen.
                Als we het over 'cookies' hebben, bedoelen we niet alleen deze kleine stukjes
                (tekst)informatie, maar ook vergelijkbare technieken waarmee informatie verzameld
                wordt, zoals bijvoorbeeld device fingerprinting.
            </p>
            <p style={styles.subTitel}>Vragen, opmerkingen of klachten?</p>
            <p style={styles.bottomSpaceParagraph}>
                Mocht je nog vragen hebben over dit Privacy Statement en de wijze waarop wij je
                gegevens verwerken, dan kun je ons bereiken op de gegevens onderaan het document.
                Ook als je een klacht hebt over de manier waarop wij jouw gegevens verwerken, kun je
                ons bereiken op de gegevens onderaan het document. Daarnaast kan je ook altijd
                contact opnemen met de bevoegde nationale toezichthouder op het gebied van
                privacybescherming. In Nederland is dit de Autoriteit Persoonsgegevens.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Heb je een vraag over de veiligheid van uw persoonsgegevens of wil je een verzoek
                doen? Neem dan contact met ons op via e-mail: plas@oranjegroep.nl of via
                telefoonnummer 010-4123399.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Bij klachten kun je direct contact opnemen met onze Functionaris voor
                Gegevensbescherming, ook wel Data Privacy Officer (DPO) genoemd.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                Naam: Corniels van der Plas <br /> E-mailadres: plas@oranjegroep.nl <br /> <br />
                ORANJEGROEP <br />
                T.a.v. Functionaris voor de Gegevensbescherming <br /> Van Vollenhovenstraat 10{' '}
                <br /> 3016 BH Rotterdam
            </p>
            <p style={styles.subTitel}>Gegevensbeveiliging</p>
            <p style={styles.bottomSpaceParagraph}>
                Hoewel wij ons uiterste best doen om jouw persoonsgegevens te beschermen, dien je je
                ervan bewust te zijn dat het verzenden van informatie via het internet nooit
                volledig veilig is en kunnen wij de veiligheid van uw persoonsgegevens die naar de
                website, portalen en/of systemen van Oranjegroep of een derde worden verzonden, niet
                garanderen; om deze reden is iedere overdracht op eigen risico. Wij zullen strenge
                operationele procedures en adequate, technische en bedrijfsorganisatorische
                beveiligingsmaatregelen toepassen om ongeoorloofde toegang, verandering,
                verwijdering of overdracht van persoonsgegevens te voorkomen.
            </p>
            <p style={styles.subTitel}>Meer informatie</p>
            <p style={styles.bottomSpaceParagraph}>
                Wij kunnen dit privacy statement aanpassen. Wij adviseren je daarom regelmatig het
                Privacy Statement na te lezen op eventuele wijzigingen. Het meest actuele privacy
                statement is altijd via onze websites te raadplegen.
            </p>
            <p style={styles.bottomSpaceParagraph}>
                <i>
                    Deze privacy- en cookieverklaring is voor het laatst aangepast op 05 februari
                    2024.
                </i>
            </p>
        </div>
    );
};
