import { ISendFeedbackVariables } from './types';
import { useApi } from '../../../../helpers';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';

export const useSendFeedback = () => {
    const api = useApi();

    return useMutation<AxiosResponse<any>, any, ISendFeedbackVariables>(({ body }) =>
        api.post('/emails/feedback', body)
    );
};
