import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useApi } from '../../../../../helpers';
import { IPromoteCandidateVariables } from './types';
import { IAmbassador } from '../../../../ambassadors/hooks';

export const usePromoteCandidate = (candidateId: string) => {
    const api = useApi();

    return useMutation<AxiosResponse<IAmbassador>, any, IPromoteCandidateVariables>(({ body }) =>
        api.patch(`/candidate/${candidateId}/promote/ambassador`, body)
    );
};
