/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { IFormValues } from './types';
import { useVerifyLogin, useVerifyResetPassword, useVerifyEmailSubscription } from './hooks';
import {
    useAuth,
    decodeToken,
    routePath,
    colors,
    handleError,
    Role,
    SocialMediaLink,
} from '../../helpers';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Formik, Field, Form, FormikErrors } from 'formik';
import { LanguageMenu, TextFieldInput, Button } from '../../components';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';

export const Vertify = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const widthBelow200px = useMediaQuery('(max-width: 200px)');
    const styles = useStyles(widthBelow600px, widthBelow200px);
    const { setCurrentUser } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const verifyLogin = useVerifyLogin();
    const verifyEmailSubscription = useVerifyEmailSubscription();
    const verifyResetPassword = useVerifyResetPassword();

    React.useEffect(() => {
        const timer = setTimeout(() => {
            localStorage.removeItem('lvid');
            localStorage.removeItem('esvid');
            localStorage.removeItem('rpvid');
            history.push(routePath.login.create());
        }, 3 * 60 * 1000 - 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [history]);

    return (
        <div style={styles.root}>
            <div style={styles.header}>
                <h1 css={styles.headerText}>Oranjelink</h1>
                <LanguageMenu />
            </div>
            <div style={styles.content}>
                <Formik
                    initialValues={{ code: '' } as IFormValues}
                    validate={({ code }) => {
                        let errors: FormikErrors<IFormValues> = {};
                        if (!code) errors.code = t('form.error.requiredField');
                        else if (isNaN(Number(code))) {
                            errors.code = t('from.error.invalidCode');
                        }
                        return errors;
                    }}
                    onSubmit={async ({ code }, { setErrors }) => {
                        if (location.pathname.split('/')[1] === 'verify-login') {
                            const token = localStorage.getItem('lvid');
                            if (token) {
                                try {
                                    const {
                                        data: { accessToken, refreshToken },
                                    } = await verifyLogin.mutateAsync({ body: { token, code } });
                                    const currentUser = decodeToken(accessToken);
                                    setCurrentUser(currentUser);
                                    if (currentUser) {
                                        localStorage.setItem('rtid', refreshToken);
                                        localStorage.removeItem('lvid');
                                        if (currentUser.roles.includes(Role.superAdmin)) {
                                            history.push(routePath.salesHoursDashboard.create());
                                        } else if (currentUser.roles.includes(Role.admin)) {
                                            history.push(routePath.salesHoursDashboard.create());
                                        } else if (currentUser.roles.includes(Role.recruiter)) {
                                            history.push(routePath.recruitmentDashboard.create());
                                        } else if (currentUser.roles.includes(Role.ambassador)) {
                                            history.push(
                                                routePath.ambassadorHoursDashboard.create()
                                            );
                                        } else if (
                                            currentUser.roles.includes(Role.recruitmentAgency)
                                        ) {
                                            history.push(routePath.candidates.create());
                                        }
                                    }
                                } catch (e) {
                                    handleError(e, ({ status, statusText, data }) => {
                                        if (status === 404) {
                                            localStorage.removeItem('lvid');
                                            history.push(
                                                routePath.notFoundVerifyLoginError.create()
                                            );
                                        } else if (status === 401 || status === 403) {
                                            localStorage.removeItem('lvid');
                                            history.push(
                                                routePath.unauthorizedVerifyLoginError.create()
                                            );
                                        } else if (status === 400) {
                                            if (data.message === 'Terms need to be accepted') {
                                                setErrors({
                                                    code: t(
                                                        'form.error.needToAgreeWithGeneralTerms'
                                                    ),
                                                });
                                            } else if (data.message === 'Invalid secret') {
                                                setErrors({
                                                    code: t('form.error.invalidCode'),
                                                });
                                            } else {
                                                alert(statusText);
                                            }
                                        } else if (status === 500) {
                                            localStorage.removeItem('lvid');
                                            history.push(
                                                routePath.internalServerVerifyLoginError.create()
                                            );
                                        } else alert(statusText);
                                    });
                                }
                            } else {
                                history.push(routePath.unauthorizedVerifyLoginError.create());
                            }
                        } else if (
                            location.pathname.split('/')[1] === 'verify-email-subscription'
                        ) {
                            const token = localStorage.getItem('esvid');
                            if (token) {
                                try {
                                    await verifyEmailSubscription.mutateAsync({
                                        body: { token, code },
                                    });
                                    localStorage.removeItem('esvid');
                                    history.push(routePath.login.create());
                                } catch (e) {
                                    handleError(e, ({ status, statusText }) => {
                                        if (status === 404) {
                                            localStorage.removeItem('esvid');
                                            history.push(
                                                routePath.notFoundVerifyEmailSubscriptionError.create()
                                            );
                                        } else if (status === 400) {
                                            setErrors({
                                                code: t('form.error.invalidCode'),
                                            });
                                        } else if (status === 500) {
                                            localStorage.removeItem('esvid');
                                            history.push(
                                                routePath.internalServerVerifyEmailSubscriptionError.create()
                                            );
                                        } else alert(statusText);
                                    });
                                }
                            } else {
                                history.push(
                                    routePath.unauthorizedVerifyEmailSubscriptionError.create()
                                );
                            }
                        } else {
                            const token = localStorage.getItem('rpvid');
                            if (token) {
                                try {
                                    await verifyResetPassword.mutateAsync({
                                        body: { token, code },
                                    });
                                    localStorage.removeItem('rpvid');
                                    history.push(routePath.login.create());
                                } catch (e) {
                                    handleError(e, ({ status, statusText }) => {
                                        if (status === 404) {
                                            localStorage.removeItem('rpvid');
                                            history.push(
                                                routePath.notFoundVerifyResetPasswordError.create()
                                            );
                                        } else if (status === 401 || status === 403) {
                                            localStorage.removeItem('rpvid');
                                            history.push(
                                                routePath.unauthorizedVerifyResetPasswordError.create()
                                            );
                                        } else if (status === 400) {
                                            setErrors({
                                                code: t('form.error.invalidCode'),
                                            });
                                        } else if (status === 500) {
                                            localStorage.removeItem('rpvid');
                                            history.push(
                                                routePath.internalServerVerifyResetPasswordError.create()
                                            );
                                        } else {
                                            alert(statusText);
                                        }
                                    });
                                }
                            } else {
                                history.push(
                                    routePath.unauthorizedVerifyResetPasswordError.create()
                                );
                            }
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form noValidate style={styles.contentForm}>
                            <h1 css={styles.formTitle}>
                                {location.pathname.split('/')[1] === 'verify-login'
                                    ? t('text.verificationLogin')
                                    : location.pathname.split('/')[1] === 'verify-reset-password'
                                    ? t('text.verificationResetPassword')
                                    : t('text.verificationEmailSubscription')}
                            </h1>
                            <Field
                                style={styles.formField}
                                name="code"
                                type="text"
                                label={t('form.label.verificationCode')}
                                placeholder={t('form.placeholder.verificationCode')}
                                autoFocus
                                spinner={isSubmitting}
                                disabled={isSubmitting}
                                component={TextFieldInput}
                            />
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                spinner={isSubmitting}
                                cs={{
                                    color: colors.blue,
                                }}
                            >
                                {t('text.verify')}
                            </Button>
                        </Form>
                    )}
                </Formik>
                <div style={styles.description}>
                    <p>{t('text.verifyDescriptionPart1')}</p>
                    <p>{t('text.verifyDescriptionPart2')}</p>
                </div>
            </div>
            <div style={styles.footer}>
                <div style={styles.footerLinkBox}>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillFacebook />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.Instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillInstagram />
                    </a>
                    <a
                        css={styles.footerlinkBoxLink}
                        href={SocialMediaLink.LinkedIn}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <AiFillLinkedin />
                    </a>
                </div>
                <div style={styles.footerRightContainer}>
                    <div>
                        <Link css={styles.footerRightContainerLink} to={routePath.terms.create()}>
                            {t('text.generalTerms')}
                        </Link>
                        &nbsp;/&nbsp;
                        <Link
                            css={styles.footerRightContainerLink}
                            to={routePath.privacyPolicy.create()}
                        >
                            {t('text.privacyPolicy')}
                        </Link>
                    </div>
                    <p>
                        {`De Oranjegroep \u00a9 ${new Date().getFullYear()} Designed by Csaba Fulop`}
                    </p>
                </div>
            </div>
        </div>
    );
};
