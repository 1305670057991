/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        contentTitle: {
            marginBottom: '4rem',
            alignSelf: 'center',
            color: colors.blue,
            userSelect: 'none',
            marginTop: '8rem',
            transition: 'all 0.2s',
            '&:hover': {
                transform: 'skewY(2deg) skewx(15deg) scale(1.1)',
                textShadow: `0.5rem 1rem 2rem ${colors.darkBoxShadow}`,
            },
        },
        contentLinkButton: {
            width: widthBelow600px ? '30rem' : '40rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
    });
