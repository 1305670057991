/**@jsxImportSource @emotion/react */
import { designStyles, colors, LightenDarkenColor } from '../../../../helpers';

export const useStyles = () =>
    designStyles({
        menuItem: {
            textTransform: 'uppercase',
            textDecoration: 'none',
            display: 'inline-block',
            padding: '1rem',
            transition: 'all 0.2s',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            fontFamily: 'inherit',
            border: 'none',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            userSelect: 'none',
            backgroundColor: 'transparent',
            color: colors.white,
            overflow: 'hidden',
            '&:hover': {
                backgroundColor: LightenDarkenColor(colors.blue, -10),
            },
        },
        subMenuItem: {
            padding: '0.6rem 1.5rem !important',
            minHeight: '5rem',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: colors.blue,
        },
    });
