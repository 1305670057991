/**@jsxImportSource @emotion/react */
import { designStyles } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        form: {
            margin: '4rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: widthBelow600px ? '30rem' : '40rem',
        },
        formField: {
            marginBottom: '2rem',
        },
        formButton: {
            width: '15rem',
            '&:not(:last-child)': {
                marginBottom: '0.8rem',
            },
        },
    });
