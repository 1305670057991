/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = (titleEffect?: boolean) =>
    designStyles({
        root: {
            color: colors.blue,
            fontSize: '1.75rem',
            userSelect: 'none',
            marginTop: '4rem',
        },
        titel: {
            textAlign: 'center',
            marginBottom: '4rem',
            transition: 'all 0.2s',
            '&:hover': {
                transform: titleEffect ? 'skewY(2deg) skewx(15deg) scale(1.1)' : 'none',
                textShadow: titleEffect ? `0.5rem 1rem 2rem ${colors.darkBoxShadow}` : 'none',
            },
        },
        bottomSpaceParagraph: {
            marginBottom: '1rem',
        },
        mainSubTitle: {
            fontWeight: 'bold',
        },
        mainSubTitelFirstParagraph: {
            display: 'inline-block',
            width: '4rem',
            paddingLeft: '1.25rem',
        },
        mainSubTitelSecondParagraph: {
            display: 'inline-block',
        },
        subTitle: {
            fontWeight: 'bold',
        },
        subTitelFirstParagraph: {
            display: 'inline-block',
            width: '4rem',
        },
        subTitelSecondParagraph: {
            display: 'inline-block',
        },
        textTable: {
            borderCollapse: 'collapse',
            marginBottom: '1.25rem',
        },
        textTableRow: {
            verticalAlign: 'top',
            textAlign: 'left',
        },
        textTableRowFirstCell: {
            width: '4rem',
        },
        textTableRowSecondCell: {
            textAlign: 'left',
        },
    });
