import { localTextEN } from './local-text-en';
import { localTextNL } from './local-text-nl';
import { localTextHU } from './local-text-hu';
import { localTextRO } from './local-text-ro';
import { localTextPL } from './local-text-pl';
import { localTextPT } from './local-text-pt';
import { localTextHR } from './local-text-hr';

export const localText: Record<string, Record<string, string>> = {
    en: localTextEN,
    nl: localTextNL,
    hu: localTextHU,
    ro: localTextRO,
    pl: localTextPL,
    pt: localTextPT,
    hr: localTextHR,
};
