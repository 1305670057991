/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = () =>
    designStyles({
        navBox: {
            height: '100%',
            width: 0,
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 999,
            opacity: 0,
            transition: 'all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
        },
        navBoxList: {
            position: 'absolute',
            display: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            listStyle: 'none',
            textAlign: 'center',
            width: '100%',
        },
        listButton: {
            width: '30rem',
            marginBottom: '1rem',
        },
        background: {
            height: '3rem',
            width: '3rem',
            zIndex: 998,
            borderRadius: '50%',
            position: 'fixed',
            top: '1rem',
            right: '1rem',
            backgroundImage:
                'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(35,82,157,1) 40%)',
            userSelect: 'none',
            transition: 'transform 0.5s cubic-bezier(0.86, 0, 0.07, 1)',
        },
        input: {
            display: 'none',
            '&:checked ~ .background': {
                display: 'block',
                transform: 'scale(180)',
            },
            '&:checked ~ .navContainer': {
                opacity: '1 !important',
                width: '100% !important',
            },
            '&:checked ~ .navContainer > .navLinks': {
                display: 'block !important',
            },
        },
        button: {
            background: colors.blue,
            position: 'fixed',
            top: '1rem',
            right: '1rem',
            zIndex: 1000,
        },
        backButton: {
            position: 'absolute',
            bottom: '1rem',
            left: '1rem',
            color: colors.white,
            fontSize: '2rem',
            textTransform: 'uppercase',
        },
    });
