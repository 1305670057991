/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean) =>
    designStyles({
        root: {
            marginTop: '4rem',
            maxWidth: '90vw',
        },
        wrapper: {
            position: 'relative',
            overflow: 'auto',
            whiteSpace: 'nowrap',
            maxHeight: '55vh',
            border: `1px solid ${colors.blue}`,
            borderRadius: '1rem',
            boxShadow: `5px 10px 8px 10px ${colors.darkBoxShadow}`,
        },
        table: {
            tableLayout: 'fixed',
            borderCollapse: 'separate',
            borderSpacing: 0,
            fontSize: '1.5rem',
            zIndex: 1,
            userSelect: 'none',
            userDrag: 'none',
            color: colors.blue,
            fontFamily: 'inherit',
        },
        tableBody: {
            zIndex: 1,
        },
        //First row
        firstRow: {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            height: '2rem',
        },
        firstRowFirstCell: {
            minWidth: '30rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.lightGrey,
            paddingLeft: '0.5rem',
            zIndex: 2,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        firstRowSecondCell: {
            minWidth: '30rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '30rem',
            backgroundColor: colors.lightGrey,
            paddingLeft: '0.5rem',
            zIndex: 2,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        firstRowFirstRowHourCells: {
            minWidth: '5rem',
            textAlign: 'center',
            backgroundColor: colors.lightGrey,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
            borderBottom: `1px solid ${colors.blue}`,
        },
        //Second row
        secondRow: {
            position: 'sticky',
            top: '2rem',
            zIndex: 1,
            height: '2rem',
        },
        secondRowFirstCell: {
            minWidth: '30rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        secondRowSecondCell: {
            minWidth: '30rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '30rem',
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        secondRowHourCells: {
            minWidth: '5rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
            borderBottom: `1px solid ${colors.blue}`,
        },
        //Third row
        thirdRow: {
            position: 'sticky',
            top: '4rem',
            zIndex: 1,
            height: '2rem',
        },
        thirdRowFirstCell: {
            minWidth: '30rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.lightGrey,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        thirdRowSecondCell: {
            minWidth: '20rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '30rem',
            backgroundColor: colors.lightGrey,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        thirdRowThirdCell: {
            minWidth: '10rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '50rem',
            textAlign: 'center',
            backgroundColor: colors.lightGrey,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        thirdRowFourthCell: {
            backgroundColor: colors.lightGrey,
            borderBottom: `1px solid ${colors.blue}`,
        },
        //No candidate row
        noCandidateRow: {},
        noCandidateRowFirstCell: {
            minWidth: '60rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            textAlign: 'center',
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        noCandidateRowSecondCell: {
            backgroundColor: colors.white,
            borderBottom: `1px solid ${colors.blue}`,
        },
        //Candidate row
        candidateRow: {},
        candidateRowFirstCell: {
            minWidth: '30rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        candidateRowSecondCell: {
            minWidth: '20rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '30rem',
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        candidateRowThridCell: {
            minWidth: '10rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '50rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        candidateRowHourCells: {
            minWidth: '5rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
            borderBottom: `1px solid ${colors.blue}`,
        },
        //FirstEmptyRow
        firstEmptyRow: {
            position: 'sticky',
            bottom: '14rem',
            zIndex: 1,
            height: '2rem',
        },
        firstEmptyRowFirstCell: {
            minWidth: '30rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.lightGrey,
            paddingLeft: '0.5rem',
        },
        firstEmptyRowSecondCell: {
            backgroundColor: colors.lightGrey,
        },
        //Total hours first tier
        totalHoursFirstTierRow: {
            position: 'sticky',
            bottom: '12rem',
            zIndex: 1,
            height: '2rem',
        },
        totalHoursFirstTierRowFirstCell: {
            minWidth: '50rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
            borderTop: `1px solid ${colors.blue}`,
        },
        totalHoursFirstTierRowSecondCell: {
            minWidth: '10rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '50rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
            borderTop: `1px solid ${colors.blue}`,
        },
        totalHoursFirstTierRowAmountCells: {
            minWidth: '5rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
            borderBottom: `1px solid ${colors.blue}`,
            borderTop: `1px solid ${colors.blue}`,
        },
        //Total hours second tier
        totalHoursSecondTierRow: {
            position: 'sticky',
            bottom: '10rem',
            zIndex: 1,
            height: '2rem',
        },
        totalHoursSecondTierRowFirstCell: {
            minWidth: '50rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        totalHoursSecondTierRowSecondCell: {
            minWidth: '10rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '50rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        totalHoursSecondTierRowAmountCells: {
            minWidth: '5rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
            borderBottom: `1px solid ${colors.blue}`,
        },
        //Total hours third tier
        totalHoursThirdTierRow: {
            position: 'sticky',
            bottom: '8rem',
            zIndex: 1,
            height: '2rem',
        },
        totalHoursThirdTierRowFirstCell: {
            minWidth: '50rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        totalHoursThirdTierRowSecondCell: {
            minWidth: '10rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '50rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        totalHoursThirdTierRowAmountCells: {
            minWidth: '5rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
            borderBottom: `1px solid ${colors.blue}`,
        },
        //Status row
        statusRow: {
            position: 'sticky',
            bottom: '6rem',
            zIndex: 1,
            height: '2rem',
        },
        statusRowFirstCell: {
            minWidth: '60rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        statusRowStatusCells: {
            minWidth: '5rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
            borderBottom: `1px solid ${colors.blue}`,
        },
        //Second empty row
        secondEmptyRow: {
            position: 'sticky',
            bottom: '4rem',
            zIndex: 1,
            height: '2rem',
        },
        secondEmptyRowFirstCell: {
            minWidth: '30rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.lightGrey,
            paddingLeft: '0.5rem',
        },
        secondEmptyRowSecondCell: {
            backgroundColor: colors.lightGrey,
        },
        //Paid fee row
        paidFeeRow: {
            position: 'sticky',
            bottom: '2rem',
            zIndex: 1,
            height: '2rem',
        },
        paidFeeRowFirstCell: {
            minWidth: '60rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
            borderTop: `1px solid ${colors.blue}`,
        },
        paidFeeRowAmountCells: {
            minWidth: '5rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
            borderBottom: `1px solid ${colors.blue}`,
            borderTop: `1px solid ${colors.blue}`,
        },
        //Week fee paid row
        weekFeePaidRow: {
            position: 'sticky',
            bottom: 0,
            zIndex: 1,
            height: '2rem',
        },
        weekFeePaidRowFirstCell: {
            minWidth: '60rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
        },
        weekFeePaidRowWeekCells: {
            minWidth: '5rem',
            textAlign: 'center',
            backgroundColor: colors.white,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
        },
        cellOrange: {
            backgroundColor: colors.dashboardOrange,
        },
        cellGreen: {
            backgroundColor: colors.dashboardGreen,
        },
        cellYellow: {
            backgroundColor: colors.dashboardYellow,
        },
    });
