import { IInvitedCandidate } from './types';
import { Cache, useApi } from '../../../../../helpers';
import { useQuery } from 'react-query';

export const useFetchInvitedCandidate = (invitationToken: string) => {
    const api = useApi();

    return useQuery<IInvitedCandidate, any>([Cache.invitedCandidates, invitationToken], () =>
        api.get(`/candidates/${invitationToken}/invited`).then((res) => res.data)
    );
};
