/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchAdmins } from './hooks';
import {
    handleQueryError,
    useDeleteModal,
    useResendQRModal,
    routePath,
    View,
    getViewLocalStorage,
} from '../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page, ViewToggleButton, ResendQRCodeModal } from '../../components';
import { useMediaQuery, Tooltip, IconButton } from '@mui/material';
import { DeleteModal } from './delete-modal/DeleteModal';
import { CardView, CardViewSkeleton } from './card-view';
import { GridView, GridSkeleton } from './grid-view';
import { AiOutlinePlus } from 'react-icons/ai';

export const Admins = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { isDeleteOpen, itemToDeleteId, itemToDeleteName, openDelete, closeDelete } =
        useDeleteModal();
    const { isResendQROpen, resendQRUserId, resendQRUsername, openResendQR, closeResendQR } =
        useResendQRModal();
    const [view, setView] = React.useState<View>(
        widthBelow600px ? View.Grid : getViewLocalStorage()
    );
    const [search, setSearch] = React.useState('');

    const { isLoading, data, error } = useFetchAdmins(search);

    React.useLayoutEffect(() => {
        if (widthBelow600px) {
            setView(View.Card);
        }
    }, [widthBelow600px]);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page style={styles.root} title={t('text.administrators')}>
            {isDeleteOpen && (
                <DeleteModal
                    open={isDeleteOpen}
                    itemToDeleteId={itemToDeleteId}
                    itemToDeleteName={itemToDeleteName}
                    closeModal={closeDelete}
                />
            )}
            {isResendQROpen && (
                <ResendQRCodeModal
                    open={isResendQROpen}
                    userId={resendQRUserId}
                    userName={resendQRUsername}
                    closeModal={closeResendQR}
                />
            )}
            <div style={styles.actionBar}>
                <div style={styles.actionBarViewToogle}>
                    {!widthBelow600px && <ViewToggleButton view={view} setView={setView} />}
                </div>
                <Tooltip arrow placement="left" title={<h1>{t('text.createAdministrator')}</h1>}>
                    <IconButton
                        size="large"
                        style={styles.actionBarCreateIcon}
                        onClick={() => history.push(routePath.createAdmin.create())}
                    >
                        <AiOutlinePlus />
                    </IconButton>
                </Tooltip>
            </div>
            {isLoading && view === View.Card && <CardViewSkeleton />}
            {isLoading && view === View.Grid && <GridSkeleton />}
            {data && view === View.Card && (
                <CardView
                    isLoading={isLoading}
                    search={search}
                    setSearch={setSearch}
                    openDelete={openDelete}
                    openResendQR={openResendQR}
                    data={data}
                />
            )}
            {data && view === View.Grid && (
                <GridView data={data} openDelete={openDelete} openResendQR={openResendQR} />
            )}
        </Page>
    );
};
