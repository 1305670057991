/**@jsxImportSource @emotion/react */
import React from 'react';
import { useStyles } from './style';
import { useDeleteCandidate } from './hooks';
import { ICandidate } from '../hooks';
import { handleError, Cache, routePath, colors } from '../../../helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Backdrop, Button } from '../../../components';
import { AiOutlineClose } from 'react-icons/ai';

interface IProps {
    open: boolean;
    itemToDeleteId: string;
    itemToDeleteName: string;
    closeModal: () => void;
}

export const DeleteModal = ({ open, itemToDeleteId, itemToDeleteName, closeModal }: IProps) => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const deleteAdmin = useDeleteCandidate();

    return (
        <Backdrop open={open}>
            <div style={styles.root}>
                <div style={styles.header}>
                    <h2 style={styles.headerTitle}>{t('text.deleteCandidate')}</h2>
                    <AiOutlineClose
                        css={styles.headerCloseButton}
                        onClick={() => {
                            if (!deleteAdmin.isLoading) {
                                closeModal();
                            }
                        }}
                    />
                </div>
                <div style={styles.content}>
                    <p style={styles.contentText}>{`${itemToDeleteName} ${t(
                        'text.deleteModalText'
                    )}`}</p>
                </div>
                <div style={styles.actions}>
                    <Button
                        style={styles.actionsDeleteButton}
                        disabled={deleteAdmin.isLoading}
                        spinner={deleteAdmin.isLoading}
                        cs={{
                            color: colors.red,
                            padding: '1rem 1.5rem',
                            fontSize: '1rem',
                            textColor: colors.white,
                        }}
                        onClick={async () => {
                            try {
                                const { data } = await deleteAdmin.mutateAsync({
                                    candidateId: itemToDeleteId,
                                });
                                queryClient.setQueryData<ICandidate[]>(
                                    [Cache.candidates, ''],
                                    (candidates) => {
                                        if (candidates) {
                                            return candidates.filter(
                                                (candidate) => candidate.id !== data
                                            );
                                        }
                                        return [];
                                    }
                                );
                                closeModal();
                            } catch (e) {
                                handleError(e, ({ status, statusText }) => {
                                    if (status === 401 || status || 403) {
                                        history.push(routePath.unauthorizedError.create());
                                    } else if (status === 404) {
                                        history.push(routePath.notFoundError.create());
                                    } else if (status === 500) {
                                        history.push(routePath.internalServerError.create());
                                    } else {
                                        alert(statusText);
                                        closeModal();
                                    }
                                });
                            }
                        }}
                    >
                        {t('text.delete')}
                    </Button>
                    <Button
                        disabled={deleteAdmin.isLoading}
                        spinner={deleteAdmin.isLoading}
                        onClick={closeModal}
                        cs={{
                            color: colors.blue,
                            padding: '1rem 1.5rem',
                            fontSize: '1rem',
                        }}
                    >
                        {t('text.cancel')}
                    </Button>
                </div>
            </div>
        </Backdrop>
    );
};
