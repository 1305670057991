/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Backdrop } from '../backdrop/Backdrop';
import { Spinner } from '../spinner/Spinner';
import { colors } from '../../helpers';

interface IProps {
    open: boolean;
    noBlur?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const Loader = ({ open, noBlur, className, style }: IProps) => {
    return (
        <Backdrop
            open={open}
            noBlur={noBlur}
            css={style as Interpolation<Theme>}
            className={className}
        >
            <Spinner
                variant="rond"
                cs={{ radius: '10rem', color: colors.blue, thickness: '0.5rem' }}
            />
        </Backdrop>
    );
};
