/**@jsxImportSource @emotion/react */
import { designStyles, colors } from '../../../helpers';

export const useStyles = (widthBelow600px: boolean, maxHeight: string) =>
    designStyles({
        root: {
            marginTop: '4rem',
            maxWidth: '90vw',
            alignSelf: 'start',
            marginLeft: '5vw',
        },
        wrapper: {
            position: 'relative',
            overflow: 'auto',
            whiteSpace: 'nowrap',
            maxHeight: maxHeight,
            border: `1px solid ${colors.blue}`,
            borderRadius: '1rem',
            boxShadow: `5px 10px 8px 10px ${colors.darkBoxShadow}`,
        },
        table: {
            tableLayout: 'fixed',
            borderCollapse: 'separate',
            borderSpacing: 0,
            fontSize: '1.5rem',
            userSelect: 'none',
            userDrag: 'none',
            color: colors.blue,
            fontFamily: 'inherit',
        },
        tableBody: {},
        firstRow: {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            height: '2rem',
        },
        firstRowFirstCell: {
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.lightGrey,
            textAlign: 'center',
            zIndex: 2,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        firstRowSecondCell: {
            textAlign: 'center',
            backgroundColor: colors.lightGrey,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        firstRowThirdCell: {
            textAlign: 'center',
            backgroundColor: colors.lightGrey,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        firstRowFourthCell: {
            textAlign: 'center',
            backgroundColor: colors.lightGrey,
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        firstRowFifthCell: {
            textAlign: 'center',
            backgroundColor: colors.lightGrey,
            borderBottom: `1px solid ${colors.blue}`,
        },
        secondRow: {
            position: 'sticky',
            top: '2rem',
            zIndex: 1,
            height: '2rem',
        },
        secondRowFirstCell: {
            minWidth: '25rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.lightGrey,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        secondRowSecondCell: {
            minWidth: '15rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '25rem',
            backgroundColor: colors.lightGrey,
            textAlign: 'center',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        secondRowDataCells: {
            minWidth: '12.5rem',
            backgroundColor: colors.lightGrey,
            textAlign: 'center',
            borderBottom: `1px solid ${colors.blue}`,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
        },
        departmentRow: {},
        departmentRowFirstCell: {
            minWidth: '25rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: 0,
            backgroundColor: colors.white,
            paddingLeft: '0.5rem',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        departmentRowSecondCell: {
            minWidth: '15rem',
            position: widthBelow600px ? 'static' : 'sticky',
            left: '25rem',
            backgroundColor: colors.white,
            textAlign: 'center',
            borderRight: `1px solid ${colors.blue}`,
            borderBottom: `1px solid ${colors.blue}`,
        },
        departmentRowsDataCells: {
            minWidth: '12.5rem',
            backgroundColor: colors.white,
            textAlign: 'center',
            borderBottom: `1px solid ${colors.blue}`,
            '&:not(:last-child)': {
                borderRight: `1px solid ${colors.blue}`,
            },
        },
    });
