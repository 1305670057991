import { useQuery } from 'react-query';
import { Cache, useApi } from '../../../../../helpers';
import { INewsletterMailReceiver } from './types';

export const useFetchNewsletterMailReceivers = () => {
    const api = useApi();

    return useQuery<INewsletterMailReceiver[], any>(Cache.newsletterMailReceivers, ({ signal }) =>
        api.get('/emails/newsletter', { signal }).then((res) => res.data)
    );
};
