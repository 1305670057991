/**@jsxImportSource @emotion/react */
import * as React from 'react';
import { useStyles } from './style';
import { useFetchProfession, useUpdateProfession } from './hooks';
import { handleError, handleQueryError, IRouteParams, routePath } from '../../../../helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Skeleton } from '@mui/material';
import { ProfessionForm } from '../profession-form/ProfessionForm';
import { Page } from '../../../../components';

export const UpdateProfession = () => {
    const widthBelow600px = useMediaQuery('(max-width: 600px)');
    const styles = useStyles(widthBelow600px);
    const history = useHistory();
    const { id } = useParams<IRouteParams>();
    const { t } = useTranslation();

    const { isLoading, data, error } = useFetchProfession(id);
    const update = useUpdateProfession(id);

    if (error) {
        return handleQueryError(error, history);
    }
    return (
        <Page
            isPageSubmitting={update.isLoading}
            title={t('text.updateProfession')}
            style={styles.root}
        >
            {isLoading ? (
                <div style={styles.formSkeleton}>
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" style={styles.fieldSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                    <Skeleton variant="rectangular" animation="wave" css={styles.buttonSkeleton} />
                </div>
            ) : null}
            {data ? (
                <ProfessionForm
                    mutationType="update"
                    initialValues={{
                        en: data.en,
                        nl: data.nl,
                        ro: data.ro,
                        pl: data.pl,
                        hr: data.hr,
                        pt: data.pt,
                        hu: data.hu,
                        professionGroup: data.professionGroup,
                    }}
                    onSubmit={async (
                        { en, nl, ro, pl, hr, pt, hu, professionGroup },
                        { setErrors }
                    ) => {
                        try {
                            await update.mutateAsync({
                                body: {
                                    en: en.trim(),
                                    nl: nl.trim(),
                                    ro: ro.trim(),
                                    pl: pl.trim(),
                                    hr: hr.trim(),
                                    pt: pt.trim(),
                                    hu: hu.trim(),
                                    professionGroupId: professionGroup!.id,
                                },
                            });
                            history.push(routePath.professionsSettings.create());
                        } catch (e) {
                            handleError(e, ({ status, statusText }) => {
                                if (status === 401 || status === 403) {
                                    history.push(routePath.unauthorizedError.create());
                                } else if (status === 404) {
                                    history.push(routePath.notFoundError.create());
                                } else if (status === 400) {
                                    setErrors({
                                        en: t('form.error.oneOfTheProfessionsIsRegistered'),
                                        nl: t('form.error.oneOfTheProfessionsIsRegistered'),
                                        ro: t('form.error.oneOfTheProfessionsIsRegistered'),
                                        pl: t('form.error.oneOfTheProfessionsIsRegistered'),
                                        hr: t('form.error.oneOfTheProfessionsIsRegistered'),
                                        pt: t('form.error.oneOfTheProfessionsIsRegistered'),
                                        hu: t('form.error.oneOfTheProfessionsIsRegistered'),
                                    });
                                } else if (status === 500) {
                                    history.push(routePath.internalServerError.create());
                                } else alert(statusText);
                            });
                        }
                    }}
                />
            ) : null}
        </Page>
    );
};
