import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useApi } from '../../../../helpers';
import { IAcceptTermsVariables } from './types';

export const useAcceptTerms = () => {
    const api = useApi();

    return useMutation<AxiosResponse<void>, any, IAcceptTermsVariables>(({ body }) =>
        api.post('/accept-terms', body)
    );
};
